/** @format */

exports.default = {
  name: { id: 'sports.mma', defaultMessage: 'MMA' },
  shortName: { id: 'sports.mma.short', defaultMessage: 'MMA' },
  featuredCompetitions: ['62871', '62940'],
  featuredCompetitionsName: [],
  enabled: true,
  maxTimeout: 600,
  correlated: {},
  correlatedOrder: ['mma'],
  trustScores: false,
  noDraws: true,
  matchDataOpener: 'for,dnb,a/null',
  offerGroupsOrder: [
    'ml',
    'ah',
    'ahou',
    'dnb',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    WDW: 'ml',
    'timeWin,tp,all,ml': 'ml',
    'timeAh,tp,all': 'ah',
    'timeAhou,tp,all': 'ahou',
    'tennisMatch,all': 'ml',
    'tennisAh,all,set': 'ah',
    'tennisAhou,all,game': 'ahou',
  },
};

/** @format */

exports.hiddenSports = new Set([
  'fb_2h',
  'fb_corn_ht',
  'fb_et_ht',
  'fb_htft',
  'basket_q1',
  'basket_q2',
  'basket_q3',
  'basket_q4',
  'ih_ht',
  'esports',
  'mma',
  'ru',
  'boxing',
  'rl',
  'arf',
]);

/** @format */

import DSM from '../../lib/DSM';
import { toCamelCase } from '../../lib/camelSnake';
import { toBetParams } from '../../lib/betTypes';
import config from '../../config';
import { fromJS } from 'immutable';

let initialState = fromJS({
  //map of open price histories
  priceHistories: {},
});

const functions = {
  //you need this action itterator wherever you use the pricefeed websocket
  data: (state, action) => {
    state = state.asMutable();

    //apply multiple things
    if (action.data.data && typeof action.data.data === 'object') {
      for (let act of action.data.data) {
        state = reducer(state, { type: act[0], data: typeof act[1] !== 'object' ? act : act[1] });
      }
    }

    return state.asImmutable();
  },

  ////// PRICEFEED STREAM

  //whenever we receive an event update we should refresh the price history because goals might show up
  //[PRICEFEED]
  event: (state, action) => {
    let eventId = action.data[0][1];
    let streams = DSM.getAllStartingWith(`priceHistory/${eventId}/`);
    for (let stream of streams) {
      stream.forceIntervalRequest(true);
    }
    return state;
  },

  ////// PRICE HISTORY

  //opening action
  //this needs to load the data points and any orders
  priceHistoryOpen: (state, action) => {
    //we need something better than just event it
    let priceHistoryId = `${action.data.eventId}/${action.data.sport}/${action.data.betType}`;

    //check if there is the same thing already open
    //perhaps we should reposition it?
    if (state.getIn(['priceHistories', priceHistoryId])) {
      return state;
    }

    //we need it to manage its own update stream, so we need a reference to it
    DSM.ensureOne(
      `/web/offerhist/${action.data.sport}/${action.data.eventId}/${action.data.betType}/`,
      {
        method: 'GET',
        message: 'priceHistoryOpenResponse',
        extras: {
          sport: action.data.sport,
          eventId: action.data.eventId,
          betType: action.data.betType,
        },
        interval: config.timings.priceHistoriesRefresh,
      },
      action.data.actions,
      `priceHistory/${priceHistoryId}`
    );

    let body = {
      status: config.ordersWithStakeStatuses, //only these actually may have stake
      sport: action.data.sport,
      eventId: action.data.eventId,
    };

    if (!action.data.agentPositionGrids) {
      body['pageSize'] = config.pageSizes.positionPageSize; ///smaller page size
      body['placer'] = action.data.placer; //restrict username
    } else {
      body['pageSize'] = config.pageSizes.agentPositionPageSize; ///large page size
    }

    DSM.ensureOne(
      '/v1/orders/',
      {
        method: 'GET',
        body,
        extras: {
          status: config.ordersWithStakeStatuses, //only these actually may have stake
          sport: action.data.sport,
          eventId: action.data.eventId,
          betType: action.data.betType,
        },
        interval: config.timings.priceHistoriesOrderRefresh,
        message: 'priceHistoryOrdersOpenResponse',
      },
      action.data.actions,
      `priceHistoryOrders/${priceHistoryId}`
    );

    //we don't need the actions for the next part
    delete action.data.actions;
    return state.setIn(
      ['priceHistories', priceHistoryId],
      fromJS({
        ...action.data,
        priceHistoryId,
        isLoading: true,
        zIndex: +new Date(),
      })
    );
  },

  //handle the response of price history data retrieval
  priceHistoryOpenResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let priceHistoryId = `${action.data.extras.eventId}/${action.data.extras.sport}/${action.data.extras.betType}`;
      state = state.mergeDeepIn(['priceHistories', priceHistoryId], action.data.data);
      return state.setIn(['priceHistories', priceHistoryId, 'isLoading'], false);
    } else {
      //handled by base
      return state;
    }
  },

  //handle the orders related to this bet type
  priceHistoryOrdersOpenResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let priceHistoryId = `${action.data.extras.eventId}/${action.data.extras.sport}/${action.data.extras.betType}`;
      let _offerGroup = toBetParams(action.data.extras.betType).offerGroup;
      let prep = {};
      for (let order of action.data.data) {
        if (toBetParams(order.betType).offerGroup === _offerGroup) {
          prep[order.orderId] = order;
        }
      }
      return state.mergeDeepIn(['priceHistories', priceHistoryId, 'orders'], prep);
    } else {
      //handled by base
      return state;
    }
  },

  //close a price history
  priceHistoryClose: (state, action) => {
    let priceHistoryId = action.data.priceHistoryId;
    DSM.stop(`priceHistory/${priceHistoryId}`);
    DSM.stop(`priceHistoryOrders/${priceHistoryId}`);
    return state.removeIn(['priceHistories', priceHistoryId]);
  },

  //bring to foreground
  priceHistoryPop: (state, action) => {
    let priceHistoryId = action.data.priceHistoryId;
    return state.setIn(['priceHistories', priceHistoryId, 'zIndex'], +new Date());
  },

  //for when we want to get rid of everything
  //typically when closing or navigating away
  closeAllPriceHistories: (state, _action) => {
    //stop all the streams
    DSM.stopAllStartingWith('priceHistory/');
    DSM.stopAllStartingWith('priceHistoryOrders/');
    return state.remove('priceHistories');
  },

  ////// LOGOUT

  //reset some state
  logout: (state, _action) => {
    //stop all the streams
    DSM.stopAllStartingWith('priceHistory/');
    DSM.stopAllStartingWith('priceHistoryOrders/');
    return state.remove('priceHistories');
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

/** @format */

exports.default = {
  //errors after deploy
  reloadAferChunkFailure: 0.25 * 1000, //how long to wait after failing to load a JS split chunk
  versionCheckInterval: 30 * 60 * 1000, //how often to check if a new application version has been released

  //data stream timings for various internal things
  websocketGracePeriod: 10 * 1000, //how long to wait for the websocket to connect before we assume it won't
  websocketConnectionConst: 4 * 1000, //a constant we derive various values from
  heartbeatInterval: 2.5 * 1000, //how often to check for heartbeat
  forcedIntervalDebounce: 1 * 1000, //how much to debounce a forced interval request if asked
  minReconnectDelay: 100, //minimum reconnection delay in ms

  //session save
  sessionSaveDebounce: 1 * 1000, //how long to debounce session save

  //generic/stats
  logoutDelay: 0.25 * 1000, //how long to delay page reload afer logout (so we can catch errors)
  resizeThrottle: 2 * 1000, //hom much to throttle resize
  dontPlaySoundsAndNotificationsFor: 10 * 1000, //how long to wait before playing sounds a popping notifications
  sudoRefreshDelay: 0.5 * 1000, //how long to wait until reloading the page after a sudo... this avoids a race condition where the page refreshes before actually getting back a new sessionId
  collectBackoff: 10 * 1000, //wait for some data streams to actually be established before first collection
  collectDataStreamLatency: 15 * 60 * 1000, //how often to collect pings for data streams and other info
  collectStreamPings: 15 * 60 * 1000, //collect stream pings
  collectTradePageEvents: 15 * 60 * 1000, //collect number of trade page events
  pingBackoff: 5 * 1000, //wait for some data streams to actually be established before first looking at pings

  //orders/betbar
  isFF: 20 * 1000, //how long until we consider the order effectively future fulfilment
  serverErrorOrderGracePeriod: 1 * 60 * 1000, //see bug 32913 //grace period to prevent double placing when bets cannot be retrieved
  takeBetbarSnapshot: 10 * 60 * 1000, //how often to take betbar snapshot
  //creditUpdateGracePeriod: 5 * 1000, //how long to wait before updating credit after an order was placed

  //data reload intervals
  tickerMessages: 60 * 1000, //look for ticker messages every
  plInfo: 5 * 60 * 1000, //look for pl info every
  eventPositionsRefresh: 5 * 60 * 1000, //refresh event positions every (fallback mechanism)
  eventPositionsRefreshAgent: 10 * 60 * 1000, //refresh event positions every (fallback mechanism)
  xratesRefresh: 60 * 60 * 1000, //refresh exchange rates
  bookieAccountRefresh: 5 * 60 * 1000, //refresh exchange rates

  //streams
  minimumStreamBatch: 50, //this is a minimum batching applied to the trade prices
  baseRecoveryReload: 2 * 1000, //how long to wait after WS recovered to auto-reload the page (only if sync mode is not present)
  recoveryReloadSpread: 4 * 1000, //recovery reload spread (to spread apart people trying to recover at the same time)
  tradeWatchBatchInterval: 50, //how many ms to put in between each batch watch

  //betslip
  pmms: 3 * 1000, //highlight betslip offer changes
  betslipKeepalive: 30 * 1000, //how often to try to keep betslip open
  placerExpire: 2.5 * 60 * 1000, //how long until we try to expire a betslip
  placerAutoClose: 0.5 * 60 * 1000, //how long to keep the betslip open after the warning
  waitPMMLoad: 4 * 1000, //how long to wait for a pmm to load
  betslipWaitPopulateTarget: 5 * 1000, //how long to wait to populate target stake in betslip
  //tentativePMMCalcDebounce: 0.125 * 1000, //try to prevent spamming the bookeeping of betslip pmms
  pmmCountInterval: 2 * 1000, //how often to log pmm count in stress mode

  //parlays
  parlayAutoClose: 3 * 60 * 1000, //how long to keep the parlay open after the warning
  parlayExpire: 12 * 60 * 1000, //how long until we try to expire the parlay betslips
  feedRefresh: 5 * 60 * 1000, //refresh match feed every

  //other draggables data
  orderLogsRefresh: 1 * 60 * 1000, //refresh order log every
  positionRefresh: 5 * 60 * 1000, //refresh a single position every
  positionValueRefresh: 10 * 60 * 1000, //refresh a single position button
  positionEventRefresh: 60 * 1000, //refresh position orders every
  priceHistoriesRefresh: 1 * 60 * 1000, //refresh price feed every
  priceHistoriesOrdersRefresh: 60 * 1000, //refresh price feed orders every

  //trade page
  score: 30 * 1000, //highlight score change
  scoreRedness: 2 * 60 * 1000, //score redness time
  scoreSet: 55 * 1000, //game score highlight (must be consistent with flashscore or you're fired !)
  scoreBasket: 50 * 1000, //basket point score highlight (must be consistent with flashscores or you're fired !)
  scorePoint: 18 * 1000, //point score highlight (must be consistent with flashscores or you're fired !)
  offers: 3 * 1000, //highlight trade page prices
  dontAnimateScoresFor: 5 * 1000, //how long to wait before animating scores
  removeEventKeepalive: 2 * 60 * 1000, //how long before you remove an event that the websocket says is removed, we need this because after a goal it may remove and readd the event
  removeScoreKeepAlive: 15 * 1000, //how long before you remove a score that the websocket says is null, we need this because MF-372 (i.e. always easier to fix in the front-end)
  clearOldWatchedCompetitions: 7 * 24 * 60 * 60 * 1000, //how long to wait to clear up old competitions (haven't been specifically watched in 7 days)
  clearOldFavs: 3 * 24 * 60 * 60 * 1000, //how long until we clear old fav events (x days past eventId date)

  //search
  tradePageSearchStaleness: 4 * 60 * 1000, //we need to debounce when the trade page search is updated based on event/competition changes
  searchNavTimeout: 0.25 * 1000, //we need a timeout sometimes before we go to the navigated item

  //active positions
  activePositionReload: 1 * 60 * 1000, //how often to refresh the active positions
  activePositionEventOrderReload: 30 * 1000, //how often to refresh the active positions event orders
  activePositionEventCashoutReload: 5 * 1000, //how often to reload cashout prices
};

/** @format */

exports.default = {
  //logging levels
  log: {
    log: true,
    warn: true, //used by stat recording
    error: true,
    //'debug': false, //because of EDGE
    info: false,
  },

  toolsUrl: 'https://tools.devmolly.com',
  downForMaintenance: false,

  sentry: {
    ignoreErrors: [
      //these are caused by deploy nonces changing
      /^Loading chunk/,
      /^Loading CSS/,
      /^expected expression/,
      /^ChunkLoadError/,
      /^Unexpected token/,
      //not sure what causese these
      /^removeChild/,
      /^NotFoundError/,
      //play sound errors caused by the user not interacting with the DOM first
      /^NotAllowedError: The play method/,
      /^NotAllowedError: play/,
      /^AbortError: The play/,
      //safari raises this in particular, probably related to sounds
      /^NotAllowedError: The request is not allowed/,
      //https://stackoverflow.com/questions/37674223/domexception-failed-to-load-because-no-supported-source-was-found
      /^NotSupportedError: Failed to load/,
      //failed fetch
      /^TypeError: Failed to fetch/,
      //some mobile auto-complete bs ?
      /instantSearchSDKJSBridgeClearHighlight/,
    ],
  },

  tools: {
    actionHack: true, //exposes window._callAction
    rawActions: process.env.REACT_APP_RAW_ACTIONS === 'true', //log raw actions instead of sending it to the logger
    betslipStress: false, //allow window._betslipStress to be used
    leakPricesWs: true, //exposes window._stream
    leakSession: true, //exposes window.Session so you can fiddle with it
    leakDataStreamManager: true, //exposes window._dataStreams so you can fiddle with it
    gaDebug: false, //google analytics debug

    hijackConsole: true, //hijack default console functionality (required for all the other stuff to work, see below ... )
    consoleLog: process.env.REACT_APP_SHOW_CONSOLE_LOG === 'true', //log stuff (in the levels) to the console as well (this will eat up all your logs, warns, etc...)
    logBuffer: 1000, //how many messages to save in the log buffer
    lognoiceMinReconnectDelay: 500, //lognoicer needs to be more relient with reconnections

    recordLatency: false, //requires lognice
    recordBetbarSnapshots: false, //log state of betbar //requires lognoice
    recordSimilarOrders: false, //log state of orders placed on similar events //requires lognoic

    tradeEventHistory: true, //keep track of the messages that changed and event or a competition (window._eventHistory, window._competitionHistory)
    interestingFields: [
      //when we send stuff to lognoice, we only send this because other stuff might be trash
      //generic
      'error',
      'warn',
      'status',
      'code',
      'reason',
      'responseMessge',
      'apiSync',
      'pricesSync',
      'heartbeat',
      'retries', //data stream recovery related
      'recover', //data stream recovery related
      'length', //trick to get arrays
      'value',
      //specific
      'eventId',
      'competitionId',
      'betslipId',
      'username',
      'bookie',
      'betType',
      'priceList',
      'sport',
      'price',
      'wantPrice',
      'gotPrice',
      'stake',
      'stakeBetslipCcy',
      'stakeCustomer',
      'timeout',
      'stakeGBP',
      'wantStake',
      'gotStake',
      'displayCcy',
      'customerCcy',
      'amount',
      'duration',
      'orderId',
      'betId',
      'rate',
      'ccy',
      'market',
    ],

    //don't log specific actions, particularly internal stuff
    dontLog: [
      'data', //too generic because of batching
      'pong', //echo response
      'response', //generic echo response
      'tickersResponse', //spammy
      //'bet', //don't mirror websocket //but these may be useful ?
      //'order', //don't mirror websocket //but these may be useful ?
      'xrate', //don't mirror websocket
      'xratesResponse', //pointless/spammy
      'applySettings', //spammy
      'event', //don't mirror websocket
      'offersHcap', //don't mirror websocket
      'offersEvent', //don't mirror websocket
      'pmm', //don't mirror websocket
      'betslip', //don't mirror websocket
      'openPositionsResponse', //spammy
      'eventOpenFeedResponse', //spammy
      'orderOpenLogResponse', //spammy
      'priceHistoryOpenResponse', //spammy
      'priceHistoryOrdersOpenResponse', //spammy
      'recordLatencyStats', //records itself
      'eventOpenFeedResponse', //spammy
      'closeMessage', //spammy
      'clearTradeEvents', //debug only
      'priceHistoryPop', //spammy
      'closeAllPriceHistories', //spammy / pointless
      'eventPopPosition', //spammy / pointless
      'eventOpenWinlossGridResponse', //spammy / pointless
      'eventClearWinlossGrid', //spammy / pointless
      'eventOpenPositionResponse', //spammy / pointless
      'eventOpenPositionEventResponse', //spammy / pointless
      'eventPopPosition', //spammy / pointless
      //'eventWatch', //spammy / pointless
      //'eventUnwatch', //spammy / pointless
      'closeAllPositions', //spammy / pointless
      'popPlacer', //spammy / pointless
      'closePlacerResponse', //spammy / pointless
      'placerBetslipsRefreshResponse', //spammy / pointless
      'closeAllPlacers', //spammy / pointless
      'closeParlayResponse', //spammy / pointless
      'closeAllParlays', //spammy / pointless
      'orderOpenLogResponse', //spammy / pointless
      'orderPopLog', //spammy / pointless
      'closeAllOrderLogs', //spammy / pointless
      'eventOpenFeedResponse', //spammy / pointless
      'eventPopFeed', //spammy / pointless
      'closeAllFeeds', //spammy / pointless
      'disconnectPaymentsStream', //don't care
      'unfocusSearchBar', //poinless
      'collectPings', //spammy
      'collectTradePageEvents', //spammy
      'positionValue', //spammy
      'positionValueResponse', //spammy
      'positionValueStop', //spammy / pointless
      'eventExpandExtras', //spammy
      'eventCloseExtras', //spammy
      'closeSearch', //spammy
      'accumulator', //spammy
      'ok', //spammy, comes from watching stuff
      'api', //too much, contains all the api stuff
      'info', //don't need
    ],
  },

  //the base reducer won't generate error toast messages for these because they have
  //specific error handling, like modals for example, or things that are expected to failsome times
  handleOwnErrors: [
    'data', //standard error response
    'dataError', //standard error response
    'wsDataError', //standard ws error response
    'loginResponse', //handle login problems
    'passwordResponse', //password change response
    'betslipPlaceOrderResponse', //betslip order failures are handled in the betslip
    'pmm', //pmm can handle it's own stuff
    'order', //order can handle it's own stuff
    'bet', //bet can handle it's own stuff
    'feedbackConfirm', //form handles its own problems
    'betslipCloseResponse', //sometimes fails, it's ok
    'baseStreamConnect', //handles the fact that the user has no session
    'isUserAuth', //should handle own error
    'loadPositionValue', //ignore error
    'parlayUpdate', //should handle own error
    'parlayPlaceResponse', //should handle own error
    'accumulator', //not sure waht happens here

    //things that are ok to ignore if they fail
    'orderOpenLogResponse',
    'closeParlayResponse',
    'closePlacerResponse',
    'betslipCloseOldResponse',
    'eventOpenPositionEventResponse',
    'orderRecentData',
  ],

  //record these actions in google analytics
  recordGA: {
    logout: {
      value: '', //record a value from the action.data (field name)
      label: [], //compose a label by concatenating some fields
    },
    login: {
      value: '',
      label: [],
    },
    loginResetPasswordRequest: {
      value: '',
      label: [],
    },
    resetPassword: {
      value: '',
      label: [],
    },
    sendFeedback: {
      value: '',
      label: ['type'],
    },
    tradeStreamConnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    tradeStreamDisconnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    tradeStreamFailedConnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    tradeStreamRecoveredConnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    tradeStreamSuccessConnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    baseStreamConnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    baseStreamDisconnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    baseStreamFailedConnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    baseStreamRecoveredConnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    baseStreamSuccessConnect: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    betslipPlaceOrder: {
      value: 'stakeGBP',
      label: ['betslipId', 'eventId', 'sport', 'price', 'stakeCustomer'],
    },
    changeSport: {
      value: '',
      label: ['sport'],
    },
    focusSearchBar: {
      value: '',
      label: [],
    },
    togglePlacementMode: {
      value: '',
      label: [],
    },
    eventAddExpanded: {
      value: '',
      label: ['eventId'],
    },
    eventRemoveExpanded: {
      value: '',
      label: ['eventId'],
    },
    eventExpandExtras: {
      value: '',
      label: ['eventId'],
    },
    eventCloseExtras: {
      value: '',
      label: ['eventId'],
    },
    competitionSubscribe: {
      value: '',
      label: ['competitionId', 'marketId'],
    },
    competitionUnsubscribe: {
      value: '',
      label: ['competitionId', 'marketId'],
    },
    competitionAddFav: {
      value: '',
      label: ['competitionId'],
    },
    competitionRemoveFav: {
      value: '',
      label: ['competitionId'],
    },
    competitionExpand: {
      value: '',
      label: ['competitionId'],
    },
    competitionHide: {
      value: '',
      label: ['competitionId'],
    },
    hideAllCompetitions: {
      value: '',
      label: [],
    },
    openBetbar: {
      value: '',
      label: [],
    },
    toggleBetbarShowClosed: {
      value: '',
      label: [],
    },
    orderCancel: {
      value: '',
      label: ['orderId'],
    },
    priceHistoryOpen: {
      value: '',
      label: ['eventId', 'sport', 'betType'],
    },
    priceHistoryClose: {
      value: '',
      label: ['eventId', 'sport', 'betType'],
    },
    eventOpenPosition: {
      value: '',
      label: ['eventId', 'sport', 'betType'],
    },
    eventClosePosition: {
      value: '',
      label: ['eventId', 'sport', 'betType'],
    },
    positionAddCustomBet: {
      value: '',
      label: [],
    },
    positionRemoveCustomBet: {
      value: '',
      label: [],
    },
    closePlacer: {
      value: '',
      label: ['placerId'],
    },
    placeAllBetslips: {
      value: '',
      label: [],
    },
    closeParlay: {
      value: '',
      label: [],
    },
    openParlay: {
      value: '',
      label: [],
    },
    parlayAddEntry: {
      value: '',
      label: [],
    },
    parlayRemoveEntry: {
      value: '',
      label: [],
    },
    parlayPlace: {
      value: '',
      label: [],
    },
    parlayUpdateStake: {
      value: '',
      label: ['stake'],
    },
    betslipOpen: {
      value: '',
      label: ['eventId', 'sport', 'betType'],
    },
    betslipClose: {
      value: '',
      label: ['betslipId'],
    },
    changeAllBetslipTimeouts: {
      value: '',
      label: ['timeout'],
    },
    dismissPlacementStatus: {
      value: '',
      label: [],
    },
    betslipEnableAccount: {
      value: '',
      label: ['betslipId', 'betslipAccountId'],
    },
    betslipEnableAllAccounts: {
      value: '',
      label: ['betslipId'],
    },
    betslipDisableAccount: {
      value: '',
      label: ['betslipId', 'betslipAccountId'],
    },
    betslipDisableAllAccounts: {
      value: '',
      label: ['betslipId'],
    },
    betslipUpdateStake: {
      value: '',
      label: ['betslipId', 'stake'],
    },
    betslipUpdatePrice: {
      value: '',
      label: ['betslipId', 'price'],
    },
    betslipUpdateTimeout: {
      value: '',
      label: ['betslipId', 'timeout'],
    },
    betslipUpdateParameters: {
      value: '',
      label: [],
    },
    closeMaxBetslipWarning: {
      value: '',
      label: [],
    },
    orderOpenLog: {
      value: '',
      label: ['orderId'],
    },
    orderCloseLog: {
      value: '',
      label: ['orderId'],
    },
    eventOpenFeed: {
      value: '',
      label: ['eventId'],
    },
    eventCloseFeed: {
      value: '',
      label: ['eventId'],
    },
    setBookieAccountsEnabled: {
      value: '',
      label: [],
    },
    updateOrdersParam: {
      value: '',
      label: [],
    },
    updateBetsParam: {
      value: '',
      label: [],
    },
    clearBetsParams: {
      value: '',
      label: [],
    },
    saveBetsView: {
      value: '',
      label: [],
    },
    loadBetsView: {
      value: '',
      label: [],
    },
    resetBetsView: {
      value: '',
      label: [],
    },
    removeBetsView: {
      value: '',
      label: [],
    },
    addDataToHistoryGraph: {
      value: '',
      label: [],
    },
    toggleGraph: {
      value: '',
      label: [],
    },
    acceptTerms: {
      value: '',
      label: [],
    },
    unacceptTerms: {
      value: '',
      label: [],
    },
    acceptedUnsupportedBrowser: {
      value: '',
      label: [],
    },
    //active positions
    toggleEventOrderBets: {
      value: '',
      label: [],
    },
    getEventOrders: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    customerTransferAction: {
      value: '',
      label: ['action'],
    },
    enableLogging: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    disableLogging: {
      value: '',
      label: [],
      nonInteraction: true,
    },
    // new things to track:
    competitionUnsubscribeAll: {
      value: '',
      label: [],
    },
    navigateUserToSonic: {
      value: '',
      label: ['suffix'],
    },
  },

  //GA extra tracking stuff
  //this is for the Olympus GA traacker that contains all the whitelabels
  trackingNames: ['unifiedTracker', 'wlTracker'],
  unifiedTracker: {
    trackingId: 'UA-158255595-2',
    gaOptions: {
      name: 'unifiedTracker',
      debug: false,
    },
  },
};

/** @format */

import DSM from '../../lib/DSM';
import PriceFeed from '../../lib/PriceFeed';
import { toCamelCase } from '../../lib/camelSnake';
import Session from '../../lib/Session';
import Telemetry from '../../lib/Telemetry';
import Logger from '../../lib/Logger';
import config from '../../config';
import _ from 'lodash';
import redcard from '../../media/interface/notifications/redcard.png';
import goal from '../../media/interface/notifications/goal.png';
import tennis from '../../media/interface/notifications/tennis.png';
import corner from '../../media/interface/notifications/corner.png';
import start from '../../media/interface/notifications/start.png';
import {
  switchEventMarket,
  updateOffers,
  upsertEvent,
  removeEvent,
  removeEventForReal,
  removeEventScore,
  updateIRStatus,
  getBaseSport,
  clearOldWatched,
  clearAllNonMainLines,
} from '../../lib/trade';
import { notificationWithPermission, shrinkMatchName } from '../../lib/notifications';
import { toStdDateTime } from '../../lib/time';
import { fromJS } from 'immutable';
import Whitelabel from '../../lib/Whitelabel';
import sports from '../../config/sports';

//some things don't really need to stay in the state
//we don't play notifications/sounds the moment the user lands on the page
//some things may be syncing and cause spam
let itsOkToPlaySoundsAndNotifications = false;
setTimeout(() => {
  itsOkToPlaySoundsAndNotifications = true;
}, config.timings.dontPlaySoundsAndNotificationsFor);

//we have to refresh the user's accounting information based on what bets came via the websocket
//we don't want updates to the same bet to trigger this every time
//the action logic also looks if the bet actually has stake
let _betsTriggeredInterval = []; //bets that have triggered an interval refresh, so that the same 'done' bet doesn't trigger multiple position value refreshes

//we only want to sent telemetry once when the trade page loads
let telemetrySent = false;

let unsupportedSportWarnings = [];

let initialState = fromJS({
  //this contains the actual prices
  prices: {},

  //this contains the basic competition data (name, country, etc...)
  competitions: {},

  //this contains the basic event data (home, away, start_time, etc...)
  events: {},

  //competitions split by sport/market/competition/event (we maintain this because it's a performance thing)
  markets: {},

  //events queued for removal, because we don't have thunks yet we just piggyback on the websocket for removal events
  eventsQueuedForRemoval: {},

  //scores queued for removal, because we don't have thunks yet we just piggyback on the websocket for removal of cached scores
  scoresQueuedForRemoval: {},

  //flags that reflect the loading state of the trade page
  flags: {
    //have some events loaded
    hasEvents: false,
    //have all the events loaded
    eventsSynced: false,
    //is the price stream on
    pricesSync: false,
    //has the price stream failed to connect
    pricesFailedConnect: false,
    //watch events asap, don't wait for the watch action (this is a speed optimization that desktop uses)
    preWatchEvents: false,
  },

  //sport in focus on the trade page
  sport: 'fb',
  //base sport in focus on the trade page
  baseSport: 'fb',

  //for the sound manager
  //defines what trade sounds are playing
  sounds: {
    goal: false,
    redcard: false,
    start: false,
  },

  //customer commsision rate, this may or may not be visible
  //if it is, it will be applied to trade page prices to make them more accurate (hopefully)
  commissionRate: 0,
});

//some extra tools to record all the messages about an event and/or competitions
if (window && config.support.tools.tradeEventHistory) {
  window._eventHistory = fromJS({});
}

const functions = {
  //the API returns an array of things, so we need a secondary handling of actions
  //you need this action itterator wherever you use the pricefeed websocket
  data: (state, action) => {
    state = state.asMutable();

    //apply the stuff we have actually received now
    if (action.data.data && typeof action.data.data === 'object') {
      for (let act of action.data.data) {
        state = reducer(state, {
          type: act[0],
          data: typeof act[1] !== 'object' ? act : act.slice(1),
        });
      }
    }

    //we piggyback this action to trigger real event removal
    let remEvents = state.get('eventsQueuedForRemoval', null);
    let remScores = state.get('scoresQueuedForRemoval', null);

    let currTime = +new Date();

    if (remEvents && remEvents.size) {
      remEvents.forEach((remSports, eventId) => {
        if (remSports && remSports.size) {
          remSports.forEach((remDate, sport) => {
            //if it was removed more than timeout ago
            if (remDate + config.timings.removeEventKeepalive < currTime) {
              state = removeEventForReal(state, eventId, sport);
              state = state.removeIn(['eventsQueuedForRemoval', eventId, sport]);
            }
          });
        } else {
          state = state.removeIn(['eventsQueuedForRemoval', eventId]);
        }
      });
    }

    if (remScores && remScores.size) {
      remScores.forEach((remScores, eventId) => {
        if (remScores && remScores.size) {
          remScores.forEach((remDate, sport) => {
            //if it was removed more than timeout ago
            if (remDate + config.timings.removeScoreKeepAlive < currTime) {
              state = removeEventScore(state, eventId, sport);
              state = state.removeIn(['scoresQueuedForRemoval', eventId, sport]);
            }
          });
        } else {
          state = state.removeIn(['scoresQueuedForRemoval', eventId]);
        }
      });
    }

    return state.asImmutable();
  },

  //api stream is proxied so it has to be unpacked
  api: (state, action) => {
    let _data;
    if (action.data.ts) {
      _data = action.data.data;
    } else {
      _data = action.data[0].data;
    }

    if (_data) {
      state = state.asMutable();
      for (let _action of _data) {
        state = reducer(state, { type: _action[0], data: _action[1] });
      }
      state = state.asImmutable();
    }

    return state;
  },

  //logout action - clears trade state for next log in
  // stops expired events from sticking around
  logout: (state, _action) => {
    state = state.set('competitions', fromJS({}));
    state = state.set('events', fromJS({}));
    state = state.set('markets', fromJS({}));
    return state;
  },

  //we need to pick up a few things from this information
  loginResponse: (state, action) => {
    if (action.data.status === 'ok' && action.data.data) {
      //commision rate so we don't end up passing it around
      if (action.data.data.customerData) {
        let customerData = action.data.data.customerData;
        if (customerData['commissionRate']) {
          state = state.set('commissionRate', customerData['commissionRate']['ratePc']);
        } //we don't like the format of this
      }
    }

    return state;
  },

  //change sport in focus
  changeSport: (state, action) => {
    state = state.set('sport', action.data.sport);
    state = state.set('baseSport', getBaseSport(action.data.sport));

    return state;
  },

  ////// SOUNDS

  //this plays a sound by marking it as playing
  playSound: (state, action) => {
    return state.setIn(['sounds', action.data.sound], true);
  },

  //this stops the playing sound
  stopSound: (state, action) => {
    return state.setIn(['sounds', action.data.sound], false);
  },

  ////// PRICEFEED STREAM

  setPreWatchMode: (state, action) => {
    return state.setIn(['flags', 'preWatchEvents'], action.data.value);
  },

  //initiate pricefeed stream
  baseStreamConnect: (state, _action) => {
    if (document && !document.hidden) {
      setTimeout(() => {
        //have to record when loop released
        Telemetry.startRecording('ts1', 'trade_page_speed');
        Telemetry.startRecording('tp1', 'trade_prices_speed');
      }, 0);
    }

    return state;
  },

  //this does stream disconnection cleanup
  //in theory this is never required
  baseStreamDisconnect: (state, _action) => {
    DSM.stop('positionStream');

    //reset events queued for removal
    state = state.set('eventsQueuedForRemoval', fromJS({}));
    state = state.set('scoresQueuedForRemoval', fromJS({}));
    //reset some flags
    state = state.mergeDeepIn(['flags'], {
      eventsSynced: false,
      pricesSync: false,
      pricesFailedConnect: false,
    });

    //in theory this will not trigger a re-render
    return state;
  },

  //trade stream has failed to connect
  baseStreamFailedConnect: (state, _action) => {
    state = state.setIn(['flags', 'pricesSync'], false); //price feed not connected
    state = state.setIn(['flags', 'pricesFailedConnect'], true); //mark as failed to connect

    Telemetry.count('pricefeed_connection_failure', 'failed_connect'); //record failure
    return state;
  },

  //websocket connection has recovered
  baseStreamRecoveredConnect: (state, action) => {
    state = state.setIn(['flags', 'pricesSync'], true); //price feed connected
    //we need to rewatch everything that was watched before
    //the recover flag in the base reducer will refresh the page anyway

    if (action.data.recover) {
      Telemetry.count('pricefeed_connection_failure', 'slow_recover'); //record slow recovery
      //the base reducer will reload the page if this happens anyway
    } else {
      console.warn('Pricefeed reconnected but we have to rewatch all the events ...');
      PriceFeed.rewatchAll();
    }

    return state;
  },

  //trade stream succesfully connected
  baseStreamSuccessConnect: (state, _action) => {
    state = state.setIn(['flags', 'pricesSync'], true); //price feed connected
    state = state.setIn(['flags', 'pricesFailedConnect'], false); //no connection failure

    return state;
  },

  //in case of too many heartbeats missed
  //[UNUSED] because cpricefeed doesn't have the heartbeat protocol
  baseStreamHeartbeatMissed: (state, _action) => {
    state = state.setIn(['flags', 'pricesSync'], false); //price feed not connected
    Telemetry.count('pricefeed_connection_failure', 'heartbeat'); //record reason
    return state;
  },

  //marks that all the events have been sent
  //[<PRICEFEED]
  synced: (state, _action) => {
    if (document && !document.hidden) {
      setTimeout(() => {
        //have to record when loop released
        Telemetry.stopRecording('tp1', 60);
        Telemetry.startRecording('tr1', 'trade_render_speed');
      }, 0);
    }

    return state.setIn(['flags', 'eventsSynced'], true);
  },

  //watch an event
  //[>PRICEFEED]
  eventWatch: (state, action) => {
    if (action.data.all) {
      PriceFeed.watchEventAll(action.data.competitionId, action.data.sport, action.data.eventId);
    } else {
      PriceFeed.watchEventMain(
        action.data.competitionId,
        action.data.sport,
        action.data.eventId,
        action.data.instant
      );
    }
    return state;
  },

  //unwatch an event
  //[>PRICEFEED]
  eventUnwatch: (state, action) => {
    if (action.data.all) {
      PriceFeed.unwatchEventAll(
        action.data.competitionId,
        action.data.sport,
        action.data.eventId,
        action.data.soft
      );

      if (
        !PriceFeed.isWatchedAll(action.data.competitionId, action.data.sport, action.data.eventId)
      ) {
        state = clearAllNonMainLines(state, action.data.eventId, action.data.sport);
      }
    } else {
      PriceFeed.unwatchEventMain(
        action.data.competitionId,
        action.data.sport,
        action.data.eventId,
        action.data.soft
      );
    }
    return state;
  },

  //this adds a new event or updates an existing one
  //this also updates irstatus
  //[<PRICEFEED]
  event: (state, action) => {
    //reshape event to something we're used to

    let eId = action.data[0]; //the first part containst the sport and eventId
    let eData = action.data[1]; //the second part contains the actuall event info

    //special case for event removal
    if (eData == null) {
      return removeEvent(state, eId[1], eId[0]);
    }

    const isMultirunner = eId[1].includes('multirunner');
    const [_date, homeId, awayId] = eId[1].split(',');

    let event = {
      away: eData.away,
      awayId: !isMultirunner ? parseInt(awayId) : undefined,
      awayEn: eData.awayEn || eData.away,
      //awayCanonical: action.data.awayCanonical,
      competitionCountry: eData.country,
      competitionId: eData.competitionId + '',
      competitionName: eData.competitionName,
      //competitionNameCanonical: action.data.competition.nameCanonical,
      home: eData.home,
      homeId: !isMultirunner ? parseInt(homeId) : undefined,
      homeEn: eData.homeEn,
      //homeCanonical: action.data.homeCanonical,
      eventId: eId[1],
      irStatus: eData.irStatus ? 'in_running' : 'pre_event',
      sport: eId[0],
      startTime: eData.startTs,
      // new multirunner properties
      isMultirunner,
      eventName: eData.eventName,
      teams: eData.teams?.reduce((acc, team) => ({ ...acc, [team.teamId]: team.name }), {}) || {
        [homeId]: eData.home,
        [awayId]: eData.away,
      },
    };

    let irstatus = eData.irStatus;

    if (window && config.support.tools.tradeEventHistory) {
      window._eventHistory = window._eventHistory.setIn(
        [event.sport, event.eventId, `${+new Date()}_${Math.floor(Math.random() * 1000)}`],
        {
          type: action.type,
          data: { ...action.data },
        }
      );
    }

    const baseSport = getBaseSport(event.sport);
    if (!sports.default[baseSport]) {
      if (!unsupportedSportWarnings.includes(event.sport)) {
        console.warn(`Event with unsupported sport '${event.sport}' received.`);
        unsupportedSportWarnings.push(event.sport);
      }
      return state;
    }

    let eventId = eId[1];
    let eventSport = eId[0];

    let currentEvent = state.getIn(['events', eventId], null);
    let isInFavs = currentEvent ? currentEvent.get('isInFavs', false) : null;
    let toastNotifications = Session.get(['settings', 'trade', 'toastNotifications'], false);
    let playSounds = Session.get(['settings', 'trade', 'playSounds'], false);
    let hasPosition = currentEvent ? currentEvent.hasIn(['positions', eventSport]) : null;

    //take note of old info
    let oldIrStatus = currentEvent ? currentEvent.get('irStatus') : null;
    let eventSportStatus = currentEvent ? currentEvent.getIn(['irstatus', eventSport], null) : null;
    let oldRedCards = [
      eventSportStatus ? eventSportStatus.getIn(['rc', '0'], null) : null,
      eventSportStatus ? eventSportStatus.getIn(['rc', '1'], null) : null,
    ];

    let oldScore = [];
    if (eventSport !== 'tennis') {
      oldScore = [
        eventSportStatus ? eventSportStatus.getIn(['score', '0'], null) : null,
        eventSportStatus ? eventSportStatus.getIn(['score', '1'], null) : null,
      ];
    } else {
      let ss = eventSportStatus ? eventSportStatus.get('setScores', null) : null;
      if (ss && ss.size) {
        let h = 0,
          a = 0;
        ss.forEach((score) => {
          if (
            !(
              Math.abs(score.get(0) - score.get(1)) >= 2 &&
              (score.get(0) >= 6 || score.get(1) >= 6)
            )
          ) {
            return;
          }
          if (score.get(0) > score.get(1)) {
            h++;
          } else {
            a++;
          }
        });
        oldScore = [h, a];
      } else {
        oldScore = [0, 0];
      }
    }

    state = upsertEvent(
      state,
      event,
      Session.get(['settings', 'general', 'timezone'], 'UTC'),
      Session
    );
    //update irstatus
    state = updateIRStatus(state, event.eventId, eventSport, irstatus);

    if (
      itsOkToPlaySoundsAndNotifications &&
      (toastNotifications || playSounds) &&
      (isInFavs || hasPosition)
    ) {
      //new info
      let newIrStatus = state.getIn(['events', eventId, 'irStatus']);
      let eventSportStatus = state.getIn(['events', eventId, 'irstatus', eventSport], null);

      let newRedCards = [
        eventSportStatus ? eventSportStatus.getIn(['rc', '0'], null) : null,
        eventSportStatus ? eventSportStatus.getIn(['rc', '1'], null) : null,
      ];

      let newScore = [];
      let setScores = [];
      if (eventSport !== 'tennis') {
        newScore = [
          eventSportStatus ? eventSportStatus.getIn(['score', '0'], null) : null,
          eventSportStatus ? eventSportStatus.getIn(['score', '1'], null) : null,
        ];
      } else {
        let ss = eventSportStatus ? eventSportStatus.get('setScores', null) : null;
        if (ss && ss.size) {
          let h = 0,
            a = 0;
          ss.forEach((score) => {
            setScores.push(score.toJS());
            if (
              !(
                Math.abs(score.get(0) - score.get(1)) >= 2 &&
                (score.get(0) >= 6 || score.get(1) >= 6)
              )
            ) {
              return;
            }
            if (score.get(0) > score.get(1)) {
              h++;
            } else {
              a++;
            }
          });
          newScore = [h, a];
        } else {
          newScore = [0, 0];
        }
      }

      let home = currentEvent ? currentEvent.get('home', null) : null;
      let away = currentEvent ? currentEvent.get('away', null) : null;

      //goal score notification
      if (
        home &&
        away &&
        (eventSport === state.get('sport', '') ||
          state.get('baseSport', '') !== getBaseSport(eventSport, true))
      ) {
        const homeScored = newScore[0] && oldScore[0] !== newScore[0];
        const awayScored = newScore[1] && oldScore[1] !== newScore[1];
        if (homeScored || awayScored) {
          if (toastNotifications) {
            let title = ``;

            if (eventSport === 'tennis') {
              title += `SET ${homeScored ? home : away}`;
            } else if (eventSport.startsWith('fb')) {
              title += `${eventSport.indexOf('corn') === -1 ? 'GOAL' : 'CORNER'} `;
              title += `${homeScored ? home : away} `;
            } else {
              title = `SCORE ${homeScored ? home : away}`;
            }

            //basketball scores update too often
            if (eventSport !== 'basket') {
              notificationWithPermission(title, {
                body: `${home} ${newScore[0]} - ${newScore[1]} ${away}`,
                icon:
                  eventSport === 'tennis'
                    ? tennis
                    : eventSport.indexOf('corn') === -1
                    ? goal
                    : corner,
              });
            }
          }

          if (playSounds) {
            state = state.setIn(
              ['sounds', eventSport.indexOf('corn') === -1 ? 'goal' : 'start'],
              true
            );
          }
        }

        //red card notification
        if (
          (newRedCards[0] && oldRedCards[0] !== newRedCards[0]) ||
          (newRedCards[1] && oldRedCards[1] !== newRedCards[1])
        ) {
          if (toastNotifications) {
            let redCardTeam = '';
            if (newRedCards[0] && oldRedCards[0] !== newRedCards[0]) {
              redCardTeam = home;
            } else {
              redCardTeam = away;
            }

            notificationWithPermission(`RED CARD ${redCardTeam}`, {
              icon: redcard,
              body: `${home} ${newScore[0]} - ${newScore[1]} ${away}`,
            });
          }

          if (playSounds) {
            state = state.setIn(['sounds', 'redcard'], true);
          }
        }

        //IR notification
        if (newIrStatus === 'in_running' && oldIrStatus !== newIrStatus) {
          if (toastNotifications) {
            let title = `START ${shrinkMatchName(home, away, 32, event === 'tennis')}`;
            if (eventSport.startsWith('fb')) {
              title = `KICKOFF ${shrinkMatchName(home, away, 30, false)}`;
            }

            notificationWithPermission(title, {
              body: Whitelabel.name,
              icon: start,
            });
          }

          if (playSounds) {
            state = state.setIn(['sounds', 'start'], true);
          }
        }
      }
    }

    //let's preemptively watch the event as well
    if (
      state.getIn(['flags', 'preWatchEvents'], false) &&
      currentEvent &&
      (isInFavs || eventSport === state.get('sport', ''))
    ) {
      let marketId = currentEvent.get('marketId', '');
      let competitionId = currentEvent.get('competitionId', '');
      if (marketId && competitionId) {
        if (state.getIn(['competitions', competitionId, 'isSubscribed', marketId], 0)) {
          if (!PriceFeed.isWatchedMain(competitionId, eventSport, eventId)) {
            PriceFeed.watchEventMain(competitionId, eventSport, eventId, true);
          }
        }
      }
    }

    //received an event? start rendering...
    return state.setIn(['flags', 'hasEvents'], true);
    //return state
  },

  //update the offers for a sport/event/offer-group
  //[<PRICEFEED]
  offersEvent: (state, action) => {
    let data = action.data;

    //there will be multiple offer updates per action

    //we map the contents
    let [_compId, sport, eventId] = data[0];
    let prices = data[1];

    const baseSport = getBaseSport(sport);
    if (!sports.default[baseSport]) {
      if (!unsupportedSportWarnings.includes(sport)) {
        console.warn(`Offer with unsupported sport '${sport}' received.`);
        unsupportedSportWarnings.push(sport);
      }
      return state;
    }

    if (config.support.tools.tradeEventHistory) {
      window._eventHistory = window._eventHistory.setIn(
        [sport, eventId, `${+new Date()}_${Math.floor(Math.random() * 1000)}`],
        {
          type: action.type,
          data: { ...prices },
        }
      );
    }

    //abstract away complicated update logic
    //but don't update main line
    state = updateOffers(state, eventId, sport, prices, false);
    return state;
  },

  offersHcap: (state, action) => {
    let data = action.data;

    //there will be multiple offer updates per action

    //we map the contents
    let [_compId, sport, eventId] = data[0];
    let prices = data[1];

    const baseSport = getBaseSport(sport);
    if (!sports.default[baseSport]) {
      if (!unsupportedSportWarnings.includes(sport)) {
        console.warn(`Offer with unsupported sport '${sport}' received.`);
        unsupportedSportWarnings.push(sport);
      }
      return state;
    }

    if (window && config.support.tools.tradeEventHistory) {
      window._eventHistory = window._eventHistory.setIn(
        [sport, eventId, `${+new Date()}_${Math.floor(Math.random() * 1000)}`],
        {
          type: action.type,
          data: { ...prices },
        }
      );
    }

    state = updateOffers(state, eventId, sport, prices, true);
    return state;
  },

  ////// EXTRA SYNC DATA

  //load addtional trade data like positions and parlay restrictions
  tradeLoadData: (state, action) => {
    //this is based on a feature flag (featureParlay)
    if (action.data.initParlayRestrictions) {
      DSM.last(
        '/v1/accas/restrictions/',
        {
          method: 'GET',
          message: 'parlayRestrictions',
        },
        action.data.actions,
        'accasRestrictions'
      );
    }

    clearOldWatched(state, Session);

    if (!action.data.agentPositionGrids) {
      let yest2 = new Date();
      yest2.setTime(yest2.getTime() - 12 * 60 * 60 * 1000); //cricket ?
      DSM.ensureOne(
        '/v1/orders/',
        {
          //<--change to active_positions
          method: 'GET',
          body: {
            status: config.ordersWithStakeStatuses, //only these actually may have stake
            pageSize: config.pageSizes.positionPageSize,
            eventStartFrom: toStdDateTime(yest2, 'utc'),
            placer: action.data.currentUser,
          },
          message: 'openPositionsResponse',
          interval: config.timings.eventPositionsRefresh,
        },
        action.data.actions,
        'positionStream'
      );
    } else {
      let yest2 = new Date();
      yest2.setTime(yest2.getTime() - 6 * 60 * 60 * 1000); //cricket ?
      DSM.ensureOne(
        '/v1/orders/',
        {
          method: 'GET',
          body: {
            status: config.ordersWithStakeStatuses, //only these actually may have stake
            eventStartFrom: toStdDateTime(yest2, 'utc'),
            pageSize: config.pageSizes.agentPositionPageSize,
          },
          message: 'openPositionsResponse',
          interval: config.timings.eventPositionsRefreshAgent,
        },
        action.data.actions,
        'positionStream'
      );
    }

    return state;
  },

  //handle parlay restrictions
  parlayRestrictions: (state, action) => {
    if (action.data.status === 'ok') {
      if (action.data.data.ignoreRestrictions === true) {
        // whitelist all competitions
        let competitions = state.get('competitions', null);
        if (competitions) {
          competitions.forEach((competition, competitionId) => {
            state = state.setIn(['competitions', competitionId + '', 'isParlay'], true);
          });
        }
      } else {
        // obey the restrictions passed
        if (action.data.data.competitionWhitelist) {
          for (let competitionId of action.data.data.competitionWhitelist) {
            state = state.setIn(['competitions', competitionId + '', 'isParlay'], true);
          }
        } else {
          // didn't get passed the competitionWhitelist we expected
        }
      }
    } else {
      //error handler will catch
    }
    return state;
  },

  closeAllParlays: (state, _action) => {
    let events = state.get('events');
    if (events) {
      events.forEach((event, eventId) => {
        state = state.removeIn(['events', eventId, 'inParlay']);
      });
    }
    return state;
  },

  //we need to update the parlay restricted event list when a parlay is opened
  openParlayResponse: (state, action) => {
    if (action.data.status === 'ok') {
      if (action.data.extras.toAdd) {
        let eventId = action.data.extras.toAdd.eventId;
        let sport = action.data.extras.toAdd.sport;
        let betType = action.data.extras.toAdd.betType;
        if (eventId && sport && betType) {
          state = state.setIn(['events', eventId, 'inParlay', sport], betType);
        }
      }
    } else {
      //no clue
    }

    return state;
  },

  //we also need to update the parlay restricted event list whenever an entry is added
  parlayUpdate: (state, action) => {
    if (action.data.status === 'ok') {
      let eventId = action.data.extras.eventId;
      let sport = action.data.extras.sport;
      let betType = action.data.extras.betType;
      if (eventId && sport && betType) {
        if (action.data.extras.remove) {
          state = state.removeIn(['events', eventId, 'inParlay', sport]);
        } else {
          state = state.setIn(['events', eventId, 'inParlay', sport], betType);
        }
      }
    } else {
      let eventId = action.data.extras.eventId;
      let sport = action.data.extras.sport;
      state = state.removeIn(['events', eventId, 'inParlay', sport]);
    }

    return state;
  },

  //we need to remove all restrictions when the parlay is closed
  closeParlayResponse: (state, _action) => {
    let events = state.get('events');
    if (events) {
      events.forEach((event, eventId) => {
        state = state.removeIn(['events', eventId, 'inParlay']);
      });
    }
    return state;
  },

  clearAllParlaysInState: (state, action) => {
    let events = state.get('events');
    if (events) {
      events.forEach((event, eventId) => {
        state = state.removeIn(['events', eventId, 'inParlay']);
      });
    }
    return state;
  },

  //update the open positions (just the existence of the buttons)
  openPositionsResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.asMutable();
      for (let order of action.data.data) {
        if (order.stake && order.stake[1]) {
          state = state.setIn(['events', order.eventInfo.eventId, 'positions', order.sport], true);
        }
      }

      return state.asImmutable();
    } else {
      //handled by base
      return state;
    }
  },

  //initiate position value stream
  positionValue: (state, action) => {
    let body = {
      eventId: action.data.eventId,
      sport: action.data.sport,
      placer: action.data.placer,
      pageSize: 1000,
    };

    if (action.data.netPrices) {
      body['displayNetPrices'] = action.data.netPrices;
    }

    let positionId = `${action.data.eventId}/${action.data.sport}`;

    DSM.ensureOne(
      `/v1/orders/position/`,
      {
        method: 'GET',
        body,
        extras: {
          eventId: action.data.eventId,
          sport: action.data.sport,
        },
        interval: config.timings.positionValueRefresh,
        message: 'positionValueResponse',
      },
      action.data.actions,
      `positionValue/${positionId}`
    );

    return state;
  },

  //update the position values in the buttons
  positionValueResponse: (state, action) => {
    if (action.data.status === 'ok') {
      return state
        .setIn(
          ['events', action.data.extras.eventId, 'positionValues', action.data.extras.sport],
          fromJS(action.data.data.payoffGrid)
        )
        .setIn(
          ['events', action.data.extras.eventId, 'totals', action.data.extras.sport],
          fromJS(action.data.data.totals)
        );
    } else {
      //handled by base
      return state;
    }
  },

  //stop stream of position values
  positionValueStop: (state, action) => {
    let positionId = `${action.data.eventId}/${action.data.sport}`;
    DSM.stop(`positionValue/${positionId}`);
    return state;
  },

  ////// API STREAM

  //add position button an order has been maded
  //should be done based on bet with gotStake
  //[API]
  bet: (state, action) => {
    let data = action.data;
    if (data.status && data.status.code && data.status.code === 'done') {
      //is the bet done
      state = state.setIn(['events', data.eventId, 'positions', data.sport], true);

      // we should filter by "placer" as well here but it's not part of the message ?
      // people who spy on group sometimes get position buttons for someone else's bets

      if (_betsTriggeredInterval.indexOf(data.betId) === -1) {
        _betsTriggeredInterval.push(data.betId);
        let positionId = `${data.eventId}/${data.sport}`;
        //if there is a position open for this thing
        DSM.forceIntervalRequest(`positionValue/${positionId}`, true);
      }
    }

    return state;
  },

  ////// EVENT INTERACTIONS

  //this handles the adding of the event to favs
  eventAddFav: (state, action) => {
    state = state.asMutable();
    let eventId = action.data.eventId; //event
    state = switchEventMarket(state, eventId, 'favs');
    return state.asImmutable();
  },

  //remove and event from favorites
  eventRemoveFav: (state, action) => {
    state = state.asMutable();
    let eventId = action.data.eventId; //event
    state = switchEventMarket(state, eventId);
    return state.asImmutable();
  },

  //expand all event sport markets
  eventExpandExtras: (state, action) => {
    let eventId = action.data.eventId;
    return state.setIn(['events', eventId, 'hasExtrasExpanded'], true);
  },

  //contract all event sport markets
  eventCloseExtras: (state, action) => {
    let eventId = action.data.eventId;
    return state.setIn(['events', eventId, 'hasExtrasExpanded'], null);
  },

  //expand event sport to show all the available lines
  eventAddExpanded: (state, action) => {
    //DONE
    //do it here as well for speed, we could just let the component handle it
    PriceFeed.watchEventAll(action.data.competitionId, action.data.sport, action.data.eventId);
    return state.setIn(['events', action.data.eventId, 'isExpanded', action.data.sport], true);
  },

  //contract event sport
  eventRemoveExpanded: (state, action) => {
    //DONE
    //do it here as well for speed, we could just let the component handle it
    PriceFeed.unwatchEventAll(action.data.competitionId, action.data.sport, action.data.eventId);
    return state.setIn(['events', action.data.eventId, 'isExpanded', action.data.sport], false);
  },

  ////// COMPETITION INTERACTIONS

  //this subscribes to a competition (used by the left hand side menu)
  competitionSubscribe: (state, action) => {
    let compId = action.data.competitionId;
    let marketId = action.data.marketId;

    //set the competition as subscribed for the interface
    state = state.setIn(['competitions', compId, 'isSubscribed', marketId], +new Date());

    return state;
  },

  //try to unsubscribe from an competition
  competitionUnsubscribe: (state, action) => {
    let compId = action.data.competitionId;
    let marketId = action.data.marketId;
    let sport = action.data.sport;

    //mark as unsubscribed
    state = state.setIn(['competitions', compId, 'isSubscribed', marketId], 0);

    //get all the actual events
    let events = state.getIn(['markets', sport, marketId, compId], null);
    //for each event
    if (events) {
      state = state.asMutable();
      events.forEach((event, eventId) => {
        let sports = state.getIn(['events', eventId, 'sports'], null);
        if (sports) {
          sports.forEach((has, subSport) => {
            if (has) {
              PriceFeed.unwatchEventMain(compId, subSport, eventId, false, true);
            }
          });
        }

        let expanded = state.getIn(['events', eventId, 'isExpanded'], null);
        if (expanded) {
          expanded.forEach((has, subSport) => {
            if (has) {
              PriceFeed.unwatchEventAll(compId, subSport, eventId, false, true);
            }
          });
        }
      });
      state = state.asImmutable();
    }

    return state;
  },

  //try to unsubscribe from an competition
  competitionUnsubscribeAll: (state, _action) => {
    state = state.asMutable();

    let events = state.getIn(['events'], null);
    //unsubscribe from the events
    if (events) {
      events.forEach((event, eventId) => {
        if (!event.get('isInFavs', false)) {
          let sports = event.get('sports', null);
          let compId = event.get('competitionId');
          let marketId = event.get('marketId');

          if (
            sports &&
            compId &&
            state.getIn(['competitions', compId, 'isSubscribed', marketId], 0)
          ) {
            sports.forEach((has, subSport) => {
              if (has) {
                PriceFeed.unwatchEventMain(compId, subSport, eventId, false, true);
              }
            });
          }

          let expanded = state.getIn(['events', eventId, 'isExpanded'], null);
          if (expanded) {
            expanded.forEach((has, subSport) => {
              if (has) {
                PriceFeed.unwatchEventAll(compId, subSport, eventId, false, true);
              }
            });
          }
        }
      });
    }

    let competitions = state.getIn(['competitions'], null);
    //unsubscribe the competitions in the markets
    if (competitions) {
      competitions.forEach((comp, compId) => {
        for (let marketId of config.markets) {
          //except for fav ones
          if (marketId === 'favs') {
            continue;
          }

          state = state.setIn(['competitions', compId, 'isSubscribed', marketId], 0);
        }
      });
    }

    state = state.asImmutable();
    return state;
  },

  //add a competition to favorites
  competitionAddFav: (state, action) => {
    let compId = action.data.competitionId;
    let sport = action.data.sport;

    for (let marketId of config.markets) {
      //get all the actual events
      let events = state.getIn(['markets', sport, marketId, compId], null);
      //for each event
      if (events) {
        state = state.asMutable();
        events.forEach((event, eventId) => {
          if (event) {
            state = switchEventMarket(state, eventId, 'favs');
            //i have to do this here... therefore breaking some encapsulation but i can't think of anything else
            Session.clear(['settings', 'trade', 'faved', 'events', eventId]);
            Session.clear(['settings', 'trade', 'unfaved', 'events', eventId]);
          }
        });
        state = state.asImmutable();
      }
    }

    return state;
  },

  //remove a competition from favorites
  competitionRemoveFav: (state, action) => {
    let compId = action.data.competitionId;
    let sport = state.getIn(['competitions', compId, 'sport'], '');

    //get all the actual events
    let events = state.getIn(['markets', sport, 'favs', compId], null);
    if (events) {
      state = state.asMutable();
      events.forEach((event, eventId) => {
        if (event) {
          state = switchEventMarket(state, eventId);
          //i have to do this here... therefore breaking some encapsulation but i can't think of anything else
          Session.clear(['settings', 'trade', 'faved', 'events', eventId]);
          Session.clear(['settings', 'trade', 'unfaved', 'events', eventId]);
        }
      });
      state = state.asImmutable();
    }

    return state;
  },

  //expand competition (xena)
  competitionExpand: (state, action) => {
    let competitionId = action.data.competitionId; //event
    return state.setIn(['competitions', competitionId, 'expanded'], true);
  },

  //contract competition (xena)
  competitionHide: (state, action) => {
    let competitionId = action.data.competitionId; //event
    return state.setIn(['competitions', competitionId, 'expanded'], false);
  },

  //contract all competitions (xena)
  hideAllCompetitions: (state, _action) => {
    let competitions = state.get('competitions');
    if (competitions) {
      competitions.forEach((comp, competitionId) => {
        state = state.setIn(['competitions', competitionId, 'expanded'], false);
      });
    }

    return state;
  },

  ////// STATS

  //collect price stream ping
  collectPings: (state, _action) => {
    let rtt = PriceFeed.lastRTT();
    if (rtt) {
      Telemetry.record('pricefeed_latency', rtt / 1000);
    }

    return state;
  },

  //try to count the number of events and competitions visible on the trade page
  collectTradePageEvents: (state, _action) => {
    //let's also record the number of the events on page
    let numEvents = 0;
    let numComps = [];
    let sport = state.get('sport', '');
    for (let marketId of config.markets) {
      //only count expanded markets
      if (!Session.get(['settings', 'trade', 'expanded', 'main', 'markets', marketId])) {
        continue;
      }

      let competitions = state.getIn(['markets', sport, marketId], null);
      if (competitions) {
        competitions.forEach((compEvents, competitionId) => {
          if (
            compEvents &&
            state.getIn(['competitions', competitionId, 'isSubscribed', marketId], 0)
          ) {
            numEvents += compEvents.size;
            numComps.push(competitionId);
          }
        });
      }
    }

    let str = `Trade page events ${sport}: ${numEvents}`;

    if (!telemetrySent) {
      Telemetry.record('watched_competitions', _.uniq(numComps).length);
      Telemetry.record('events_on_page', numEvents);
    }

    //just dump string
    console.warn(str);
    telemetrySent = true;

    return state;
  },

  ////// DEBUGGING

  //reset some state for testing purposes
  clearTradeEvents: (state, _action) => {
    state = state.set('prices', fromJS({}));
    state = state.set('competitions', fromJS({}));
    state = state.set('events', fromJS({}));
    state = state.set('markets', fromJS({}));
    return state;
  },

  //dump this part of the state
  dumpTradeState: (state, _action) => {
    Logger.log(`[tradeStateDump] - ${JSON.stringify(state.toJS())}`, 'warn');
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  if (window._callAction) {
    window._state = state;
  }
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

/** @format */

//this file can be used to forcefully apply a set of changes to every user when he logs in
//record of the time a list of changes was applied is kept in the user prefs so it doesn't apply it every time

const forced = {
  //users who haven't forced settings before or at an earlier time
  date: '2020-10-21',
  settings: {
    trade: {
      superPositionGrid: true
    }
  },
};

export default forced;

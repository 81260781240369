/** @format */

import DSM from '../../lib/DSM';
import config from '../../config';
import Session from '../../lib/Session';
import { notificationWithPermission } from '../../lib/notifications';
import { toCamelCase } from '../../lib/camelSnake';
import Telemetry from '../../lib/Telemetry';
import Logger from '../../lib/Logger';
import orderIcon from '../../media/interface/notifications/order.png';
import { updateOrder, updateOrderAll } from '../../lib/orders';

import { fromJS } from 'immutable';

//some things don't really need to stay in the state
//we don't play notifications/sounds the moment the user lands on the page
//some things may be syncing and cause spam
let itsOkToPlaySoundsAndNotifications = false;
setTimeout(() => {
  itsOkToPlaySoundsAndNotifications = true;
}, config.timings.dontPlaySoundsAndNotificationsFor);

//we have to refresh the user's accounting information based on what bets came via the websocket
//we don't want updates to the same bet to trigger this every time
//the action logic also looks if the bet actually has stake
let _betsTriggeredInterval = []; //bets that have triggered an interval refresh, so that the same 'done' bet doesn't trigger multiple position value refreshes

//a buffer for the price stream in case the connection happens before the sync data returns
//this is highly unlikely, but we store all the websocket messages and replay them on top of the REST response
let orderStreamBuffer = [];

let initialState = fromJS({
  //actual order map
  orders: {},

  //flags
  flags: {
    //are there orders in the betbar
    hasOrders: false,
    //we have some logic that determines if the betbar has changed
    //we send snapshots once in a while over lognoice (for debugging purposes)
    hasChangedSinceLastSnapshot: true,
  },

  //sounds for the betbar
  sounds: {
    money: false,
    failed: false,
    success: false,
  },
});

const functions = {
  //you need this action itterator wherever you use the pricefeed websocket
  data: (state, action) => {
    state = state.asMutable();

    //were there things in the buffer?
    if (state.getIn(['flags', 'hasOrders'], false)) {
      if (orderStreamBuffer.length) {
        //a buffered data action
        for (let buff of orderStreamBuffer) {
          //apply multiple actions
          if (buff.data.data) {
            //huh?
            for (let act of buff.data.data) {
              //apply multiple actions
              state = reducer(state, { type: act[0], data: act[1] }); //we don't retrieve as immutable types
            }
          }
        }

        orderStreamBuffer = [];
      }

      //apply multiple things
      if (action.data.data && typeof action.data.data === 'object') {
        for (let act of action.data.data) {
          state = reducer(state, { type: act[0], data: act[1] });
        }
      }
    } else {
      //not ready yet
      //push stuff to the price stream buffer
      orderStreamBuffer.push(action);
    }

    return state.asImmutable();
  },

  //api stream is proxied so it has to be unpacked
  api: (state, action) => {
    let _data;
    if (action.data.ts) {
      _data = action.data.data;
    } else {
      _data = action.data[0].data;
    }

    if (_data) {
      for (let _action of _data) {
        state = reducer(state, { type: _action[0], data: _action[1] });
      }
    }

    return state;
  },

  ////// API STREAM

  //connect a recent orders stream for an initial sync
  baseStreamSuccessConnect: (state, action) => {
    let yest = new Date();
    yest.setDate(yest.getDate() - 2); //48 hours

    let yest2 = new Date();
    yest2.setDate(yest2.getDate() - 3); //72 hours

    DSM.ensureOne(
      '/v1/orders/',
      {
        message: 'orderRecentData',
        body: {
          dateFrom: yest.toISOString(),
          pageSize: config.pageSizes.allOrdersBetbarPageSize,
        },
        extras: {
          flags: {
            hasOrders: true,
          },
        },
      },
      action.data.actions,
      'recentOrdersStream'
    );

    //orders
    DSM.ensureOne(
      '/v1/orders/',
      {
        message: 'orderRecentData',
        body: {
          dateFrom: yest2.toISOString(),
          status: config.ordersOpenStatuses,
          pageSize: config.pageSizes.oldOpenOrdersBetbarPageSize,
        },
        extras: {
          flags: {
            hasOrders: true,
          },
        },
      },
      action.data.actions,
      'oldOpenOrders'
    );

    return state;
  },

  //update the recent order data
  orderRecentData: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.asMutable();
      for (let order of action.data.data) {
        //bets is an array... we want it to be a map
        let bets = {};
        for (let bet of order.bets) {
          bets[bet.betId] = bet;
        }
        order.bets = bets;
        state = state.setIn(['orders', order.orderId], fromJS(order));
      }

      if (action.data.extras && action.data.extras.flags) {
        state = state.mergeDeep({
          flags: action.data.extras.flags,
        });
      }

      state = updateOrderAll(state);
      state = state.setIn(['flags', 'hasChangedSinceLastSnapshot'], true);
      return state.asImmutable();
    } else {
      //handled by base
      return state;
    }
  },

  //react to receiving an order from the order stream
  //[API]
  order: (state, action) => {
    let orderId = action.data.orderId;

    if (action.data.placer === Session.get(['profile', 'username'])) {
      //DSM.forceIntervalRequest('plStream', true);
    }

    try {
      if (action.data.orderType !== 'accas') {
        let eventId = action.data.eventInfo.eventId;
        let sport = action.data.sport;
        let betType = action.data.betType;
        Telemetry.stopRecording(`${eventId}/${sport}/${betType}/os`); //order speed
      } else {
        let parlayId = action.data.accasInfo ? action.data.accasInfo.id : '';
        if (parlayId) {
          Telemetry.stopRecording(`${parlayId}/as`); //acca speed
        }
      }
    } catch (err) {
      //meh
    }

    state = state.setIn(['orders', orderId, 'shell'], false);

    let oldStatus = state.getIn(['orders', orderId, 'status'], '');
    state = state.mergeDeepIn(['orders', orderId], action.data);
    let newStatus = state.getIn(['orders', orderId, 'status'], '');
    let order = state.getIn(['orders', orderId], null);
    state = updateOrder(state, orderId);

    let toastNotificationsOrders = Session.get(
      ['settings', 'trade', 'toastNotificationsOrders'],
      false
    );
    let playSoundsOrders = Session.get(['settings', 'trade', 'playSoundsOrders'], false);

    if (order) {
      if (order.get('placer', '') === Session.get(['profile', 'username'])) {
        if (
          itsOkToPlaySoundsAndNotifications &&
          (toastNotificationsOrders || playSoundsOrders) &&
          oldStatus !== newStatus
        ) {
          if (toastNotificationsOrders) {
            let away = order.getIn(['eventInfo', 'awayTeam'], 'Away');
            let home = order.getIn(['eventInfo', 'homeTeam'], 'Home');
            let betTypeDescription = order.get('betTypeDescription', '?');
            let closeReason = order.get('closeReason', '');
            let orderType = order.get('orderType', '');
            let status = order.get('status', '?');

            let body = '';
            if (orderType === 'normal') {
              body = `[${home} - ${away}]: ${betTypeDescription}`;
            } else if (orderType === 'accas') {
              body = 'Accumulator';
            } else if (orderType === 'cashout') {
              body = 'Cashout';
            }

            notificationWithPermission(
              `[${orderId}]: ${status}` +
                (closeReason ? ` (${closeReason.replace('_', ' ')})` : ''),
              {
                icon: orderIcon,
                body,
              }
            );
          }

          if (playSoundsOrders) {
            if (newStatus === 'settled') {
              state = state.setIn(['sounds', 'money'], true);
            } else if (newStatus === 'failed') {
              state = state.setIn(['sounds', 'failed'], true);
            } else if (newStatus === 'done') {
              state = state.setIn(['sounds', 'success'], true);
            }
          }
        }
      }
    }

    return state.setIn(['flags', 'hasChangedSinceLastSnapshot'], true);
  },

  //handle the reception of new bet information and update inside an order
  //[API]
  bet: (state, action) => {
    let betId = action.data.betId;
    let orderId = action.data.orderId;
    //someone who has spy on group gets spammed with random bets
    state = state.setIn(['orders', orderId, 'shell'], false);
    state = state.mergeDeepIn(['orders', orderId, 'bets', betId], action.data);
    state = updateOrder(state, orderId);

    //are you the placer
    if (state.getIn(['orders', orderId, 'placer'], '') === Session.get(['profile', 'username'])) {
      //does it meaningfully impact the accounting info ?
      if (
        action.data.gotStake &&
        action.data.gotStake[1] &&
        action.data.status &&
        action.data.status.code === 'done'
      ) {
        if (_betsTriggeredInterval.indexOf(action.data.betId) === -1) {
          _betsTriggeredInterval.push(action.data.betId);
          //if there is a position open for this thing
          //DSM.forceIntervalRequest('plStream', true);
        }
      }
    }

    return state.setIn(['flags', 'hasChangedSinceLastSnapshot'], true);
  },

  //react to a message stating that the order has closed (this is optional, normal order messages should cover this)
  //[API] [deprecated]
  // orderClosed: (state, action) => {
  //   let orderId = action.data.orderId;
  //   state = state.mergeDeepIn(['orders', orderId], {
  //     closed: true,
  //     closeReason: action.data.closeReason,
  //   });
  //   state = updateOrder(state, orderId);
  //   return state.setIn(['flags', 'hasChangedSinceLastSnapshot'], true);
  // },

  ////// ORDERS

  //manually cancel an order
  //we should have a flag so the user doesn't keep spamming close
  orderCancel: (state, action) => {
    let orderId = action.data.orderId;

    DSM.create(
      `/v1/orders/${orderId}/close/`,
      {
        message: 'orderCancelResponse',
        method: 'POST',
        body: {
          orderId,
        },
        extras: {
          orderId,
        },
      },
      action.data.actions
    );

    return state.setIn(['orders', orderId, 'isClosing'], true);
  },

  //the response to the order cancelation
  //could just let the API messages do this
  orderCancelResponse: (state, action) => {
    if (action.data.status === 'ok') {
      return state.mergeDeepIn(['orders', action.data.extras.orderId], {
        isClosing: false,
      });
    } else {
      //handled by base
      return state;
    }
  },

  //shell order logic so we can look like we're working
  //both parlay and normal order behave the same
  //the empty order is to prevent the user from spamming place if the connection is slow
  parlayPlaceResponse: (state, action) => {
    //if the order is not in the betbar already
    if (
      action.data.data &&
      action.data.data.orderId &&
      !state.hasIn(['orders', action.data.data.orderId])
    ) {
      state = state.setIn(
        ['orders', action.data.data.orderId],
        fromJS({
          ...action.data.data,
          shell: true,
        })
      );
      state = updateOrder(state, action.data.data.orderId);
    }

    return state;
  },

  betslipPlaceOrderResponse: (state, action) => {
    //if the order is not in the betbar already
    if (
      action.data.data &&
      action.data.data.orderId &&
      !state.hasIn(['orders', action.data.data.orderId])
    ) {
      state = state.setIn(
        ['orders', action.data.data.orderId],
        fromJS({
          ...action.data.data,
          shell: true,
        })
      );
      state = updateOrder(state, action.data.data.orderId);
    }

    return state;
  },

  ////// SOUND

  //mark sound as playing
  playSound: (state, action) => {
    return state.setIn(['sounds', action.data.sound], true);
  },

  //clear playing sound
  stopSound: (state, action) => {
    return state.setIn(['sounds', action.data.sound], false);
  },

  ////// STATS

  //take a snapshot of the betbar orders for debugging purposes
  //this is because people kept complaining that their orders don't place
  betbarSnapshot: (state, _action) => {
    if (state.getIn(['flags', 'hasChangedSinceLastSnapshot'], false)) {
      let orders = state.get('orders', false);
      if (orders && orders.size) {
        console.warn(`Betbar ----------`);
        orders.forEach((order, orderId) => {
          let bets = order.get('bets', null);
          console.warn(
            `-- [${orderId}][${order.get('status', '?')}][${
              order.get('closed', false) ? order.get('closeReason', 'closed ?') : 'open'
            }]: ${order.getIn(['eventInfo', 'eventId'], null)} / ${order.get('betType', '?')}`
          );
          if (bets && bets.size) {
            bets.forEach((bet, betId) => {
              if (bet) {
                console.warn(
                  `---- [${betId}][${bet.get('bookie', '?')}][${bet.getIn(
                    ['status', 'code'],
                    '?'
                  )}]: ${bet.getIn(['gotStake', '0'], '?')} ${bet.getIn(['gotStake', '1'], '?')}`
                );
              }
            });
          }
        });
      }

      state = state.setIn(['flags', 'hasChangedSinceLastSnapshot'], true);
    }

    return state;
  },

  //let's look for similar orders when a user is trying to bet on the same thing
  //this is because users sometimes claim that their order double placed
  //there is a ridiculous number of logging available but apparently it's never enough
  betslipPlaceOrder: (state, action) => {
    if (config.support.tools.recordSimilarOrders) {
      if (action.data.sport && action.data.eventId) {
        let orders = state.get('orders', false);
        if (orders && orders.size) {
          let sim = false;
          orders.forEach((order, orderId) => {
            if (order.get('placer', '') === Session.get(['profile', 'username'])) {
              if (
                action.data.eventId === order.getIn(['eventInfo', 'eventId'], '') &&
                action.data.sport === order.get('sport', '')
              ) {
                if (!sim) {
                  console.warn(`Similar order warning ----------`);
                  sim = true;
                }
                console.warn(
                  `-- [${orderId}][${order.get('status', '?')}][${
                    order.get('closed', false) ? order.get('closeReason', 'closed ?') : 'open'
                  }]: ${order.getIn(['eventInfo', 'eventId'], null)} / ${order.get('betType', '?')}`
                );
              }
            }
          });
        }
      }
    }

    return state;
  },

  placeAllBetslips: (state, action) => {
    if (config.support.tools.recordSimilarOrders) {
      if (action.data.sport && action.data.eventId) {
        let orders = state.get('orders', false);
        if (orders && orders.size) {
          let sim = false;
          orders.forEach((order, orderId) => {
            if (order.get('placer', '') === Session.get(['profile', 'username'])) {
              if (
                action.data.eventId === order.getIn(['eventInfo', 'eventId'], '') &&
                action.data.sport === order.get('sport', '')
              ) {
                if (!sim) {
                  console.warn(`Similar order warning ----------`);
                  sim = true;
                }
                console.warn(
                  `-- [${orderId}][${order.get('status', '?')}][${
                    order.get('closed', false) ? order.get('closeReason', 'closed ?') : 'open'
                  }]: ${order.getIn(['eventInfo', 'eventId'], null)} / ${order.get('betType', '?')}`
                );
              }
            }
          });
        }
      }
    }

    return state;
  },

  ////// LOGOUT

  //reset some state
  logout: (state, _action) => {
    state = state.set('orders', fromJS({}));
    state = state.set(
      'flags',
      fromJS({
        hasOrders: false,
        hasChangedSinceLastSnapshot: true,
      })
    );
    return state;
  },

  //this does stream disconnection cleanup
  //in theory this is never required
  baseStreamDisconnect: (state, _action) => {
    DSM.stop('recentOrdersStream');
    DSM.stop('oldOpenOrders');

    //in theory this will not trigger a re-render
    return state;
  },

  //dump this part of the state
  dumpBetbarState: (state, _action) => {
    Logger.log(`[betbarStateDump] - ${JSON.stringify(state.toJS())}`, 'warn');
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};

for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

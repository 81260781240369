/** @format */

import ReactGA from 'react-ga';
import Logger from './Logger';
import Whitelabel from './Whitelabel';
import config from '../config.js';

// WL_GA_ID = Whitelabel.gaID

/* HOW WE ARE REASONING ABOUT GOOGLE ANALYTICS 4

      GA4 event send - https://developers.google.com/analytics/devguides/collection/gtagjs/events
          gtag('event', <action>, {
            'event_category': <category>,
            'event_label': <label>,
            'value': <value>
          });

    -Everything is thought of events and then discerned by action, event_category, event_label, value
    -For the data to be useful we need things to be split nicely along these parameters

    -Action types to use:
          1. reducer_action - automatically records every action going through the reducers
          2. user_action - manually recording things the user did in the interface
          3. timing_complete - we are creating a measurement (speed) of how long something takes - timing_complete - https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings
          4. telemetry_count - we are recording the frequency of some event (counting the occurrences will have to be done in the GA interface)
*/

// DONT THINK BELOW IS NECESSARY WITH GA4

// const webVitalsMessageQueue = [];

// function addToWebVitalsMessageQueue(metric) {
//   webVitalsMessageQueue.push(metric);
// }

// function flushQueue() {
//   while (webVitalsMessageQueue.length) {
//     const msg = webVitalsMessageQueue.pop();
//     msg();
//   }
// }

// window.addEventListener('visibilitychange', () => {
//   if (document.visibilityState === 'hidden') {
//     flushQueue();
//   }
// });

// window.addEventListener('pagehide', () => {
//   flushQueue();
// });

/**
 * Telemetry manager that handles time and values recordings.
 * Uses a Logger to send stuff to a telemetry endpoint. The user must be authenticated.
 */
export default class Telemetry {
  static _recordings = {};

  /**
   * Start recording a time span with a key and name
   * @static
   * @param {string} key - the key (unique id of the recording)
   * @param {string} name - the name of the recording (this is sent to the metrics endpoint)
   */
  static startRecording(key, name) {
    Telemetry._recordings[key] = {
      name,
      label: '',
      start: new Date(),
    };
  }

  /**
   * Stop a specific recording, based on key
   * @static
   * @param {string} key - the key (unique id of the recording)
   * @param {number} [maxValue=Infinity] - the maximum value of the timing (this is to avoid strange edge cases and race conditions, anything above this value is discaraded)
   */
  static stopRecording(key, maxValue = Infinity) {
    if (Telemetry._recordings[key]) {
      if (!Telemetry._recordings[key]['end']) {
        //stopped already
        Telemetry._recordings[key]['end'] = new Date();
        //we send values in seconds not ms
        Telemetry._recordings[key]['value'] =
          (Telemetry._recordings[key]['end'] - Telemetry._recordings[key]['start']) / 1000;

        if (Telemetry._recordings[key]['value'] < maxValue) {
          Logger.logMetric(Telemetry._recordings[key]['name'], Telemetry._recordings[key]['value']);

          // NOT USING THIS DATA CURRENTLY SO COMMENTING THIS OUT
          // gtag('event', 'timing_complete', {
          //   name: Telemetry._recordings[key]['name'],
          //   value: parseInt(Telemetry._recordings[key]['value'] * 1000, 10), //back to ms because it has to be Int
          //   event_category: Telemetry._recordings[key]['name'],
          //   non_interaction: true,
          // });
        } else {
          //it's invalid, drop it
          delete Telemetry._recordings[key];
        }
      }
    }
  }

  /**
   * Record a metric with a value
   * @static
   * @param {string} name - name of the metric
   * @param {number} value - value of the metric
   * @param {string} [label=''] - label (if the metric has one)
   * Used by frontend common - not desktop
   */
  static recordWebVitals({ name, delta, value, id, ...other }) {
    //    console.log('faf22 handleWebVitals ', { name, delta, value, id, ...other });
    gtag('event', name, {
      // Built-in params:
      value: delta, // Use `delta` so the value can be summed.
      // Custom params:
      metric_id: id, // Needed to aggregate events.
      metric_value: value, // Optional.
      metric_delta: delta, // Optional.
    });

    // addToWebVitalsMessageQueue(function () {
    //   gtag('event', name, {
    //     // Built-in params:
    //     value: delta, // Use `delta` so the value can be summed.
    //     // Custom params:
    //     metric_id: id, // Needed to aggregate events.
    //     metric_value: value, // Optional.
    //     metric_delta: delta, // Optional.
    //   });
    // });
  }

  /**
   * Record a metric with a value
   * @static
   * @param {string} name - name of the metric
   * @param {number} value - value of the metric
   * @param {string} [label=''] - label (if the metric has one)
   * Used by frontend common - not desktop
   */
  static record(name, value, label = '') {
    Telemetry._recordings[name + label] = {
      name,
      label,
      value,
    };

    Logger.logMetric(name, value);
    // NOT USING THIS DATA CURRENTLY SO COMMENTING THIS OUT
    // gtag('event', 'timing_complete', {
    //   event_category: name,
    //   event_label: label,
    //   value: value,
    //   non_interaction: true,
    // });
  }

  /**
   * Record a metric of type count (i.e. when something happened, like a trade page load)
   * @static
   * @param {string} name - name of the metric
   * @param {string} [label=''] - label (if the metric has one)
   */
  static count(name, label = '') {
    Telemetry._recordings[name] = {
      name,
      label,
    };

    Logger.logMetric(name, 1);

    gtag('event', name, {
      event_category: 'telemetry_count',
      event_label: label,
      value: 1,
      non_interaction: true,
    });
  }

  /**
   * Send a redux action as a Google Analytics event. The shape is based on some definitions in config support.
   * @static
   * @param {Object} action - name of the metric
   */
  static recordAction(action) {
    // THIS IS THE WHITELIST OF ACTIONS TO RECORD _ MIGHT BE MORE USEFUL IF WE SPLIT THEM UP INTO GROUPS MORE
    if (config.support.recordGA[action.type]) {
      /* GA4 event send - https://developers.google.com/analytics/devguides/collection/gtagjs/events
          gtag('event', <action>, {
            'event_category': <category>,
            'event_label': <label>,
            'value': <value>
          });
      */
      // might want to filter out the not useful ones
      gtag('event', action.type, {
        event_category: 'reducer_action',
        event_label: config.support.recordGA[action.type].label
          ? config.support.recordGA[action.type].label
              .map((item) => (action.data ? action.data[item] : '?'))
              .join(' >>> ')
          : '',
        value: config.support.recordGA[action.type].value
          ? parseInt(action.data[config.support.recordGA[action.type].value]) || 0
          : 0, //can only have integers
        non_interaction: !!config.support.recordGA[action.type].nonInteraction,
      });
    }
  }

  /**
   * Wrapper for Google Analytics event function with appropriate trackers.
   * https://support.google.com/analytics/answer/1033068?hl=en
   * @static
   * @param {string} action <-- should be user_action if recording user behaviour
   * @param {string} category
   * @param {string} label
   * @param {number} value
   */
  static recordGA(action, category, label, value = 1) {
    // was using action as a grouping but made filtering hard so removing it
    // gtag('event', action, {
    //   event_category: category,
    //   event_label: label,
    //   value: value,
    //   non_interaction: false,
    // });
    gtag('event', category, {
      event_category: category,
      event_label: label,
      value: value,
      non_interaction: false,
    });
  }

  /**
   * Wrapper for Google Analytics pageView function with appropriate trackers.
   * @static
   * @param {string} page
   * not used atm - GA auto records page views
   */
  static pageView(page) {
    window.gtag('event', 'pageView', {
      page_location: page,
    });
  }
}

/** @format */

exports.default = {
  name: { id: 'sports.baseball', defaultMessage: 'Baseball' },
  shortName: { id: 'sports.baseball.short', defaultMessage: 'Baseball' },
  featuredCompetitions: ['401'],
  featuredCompetitionsName: [],
  enabled: true,
  maxTimeout: 7200,
  correlated: {},
  correlatedOrder: ['baseball'],
  trustScores: false,
  noDraws: true,
  matchDataOpener: 'for,tp,all,ml,a/null',
  offerGroupsOrder: [
    'timeWin,tp,all,ml',
    //'timeWin,tp,all,wdw',
    'timeWin,tp,reg,ml',
    'timeAh,tp,all',
    'timeAhou,tp,all',
    'timeWin,tp,reg,wdw',
    'timeWin,thalf,1,wdw',
    'timeWin,thalf,1,ml',
    'timeAh,thalf,1',
    'timeAhou,thalf,1',
    'tahou,thalf,1,h',
    'tahou,thalf,1,a',
    //'timeWin,thalf,2,wdw',
    //'timeWin,thalf,2,ml',
    //'timeAh,thalf,2',
    //'timeAhou,thalf,2',
    //'timeWin,tinnings,1,ml',
    //'timeAh,tinnings,1',
    //'timeAhou,tinnings,1',
    //'timeWin,tinnings,2,ml',
    //'timeAh,tinnings,2',
    //'timeAhou,tinnings,2',
    //'timeWin,tinnings,3,ml',
    //'timeAh,tinnings,3',
    //'timeAhou,tinnings,3',
    //'timeWin,tinnings,4,ml',
    //'timeAh,tinnings,4',
    //'timeAhou,tinnings,4',
    //'timeWin,tinnings,5,ml',
    //'timeAh,tinnings,5',
    //'timeAhou,tinnings,5',
    //'tahou,thalf,1,a',
    //'tahou,thalf,1,h',
    //'tahou,thalf,2,a',
    //'tahou,thalf,2,h'
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    ml: 'timeWin,tp,all,ml',
    ah: 'timeAh,tp,all',
    ahou: 'timeAhou,tp,all',
    'tennisMatch,all': 'timeWin,tp,all,ml',
    'tennisAh,all,set': 'timeAh,tp,all',
    'tennisAhou,all,game': 'timeAhou,tp,all',
  },
};

/** @format */

import DSM from '../../lib/DSM';
import { toCamelCase } from '../../lib/camelSnake';
import config from '../../config';
import { fromJS } from 'immutable';

let initialState = fromJS({
  //map of parlays (there should usually be only one, but ASSUME NOTHING since changes in the middle are the norm)
  parlays: {},

  //we use this to lock so we can't open 2 parlays at the same time
  isOpeningParlay: false,
});

const functions = {
  //you need this action itterator wherever you use the pricefeed websocket
  data: (state, action) => {
    //batch updates
    state = state.asMutable();
    //apply multiple things
    if (action.data.data && typeof action.data.data === 'object') {
      for (let act of action.data.data) {
        state = reducer(state, { type: act[0], data: act[1] });
      }
    }
    return state.asImmutable();
  },

  //api stream is proxied so it has to be unpacked
  api: (state, action) => {
    let _data;
    if (action.data.ts) {
      _data = action.data.data;
    } else {
      _data = action.data[0].data;
    }

    if (_data) {
      state = state.asMutable();
      for (let _action of _data) {
        state = reducer(state, { type: _action[0], data: _action[1] });
      }
      state = state.asImmutable();
    }

    return state;
  },
  ////// OLD PARLAY CLEANUP

  //if the user is not being sudoed, kill all betslips
  // loginResponse: (state, action) => {
  //   if (action.data.status === 'ok' && action.data.data && !action.data.data.sudoer) {
  //     //we can't check parlay switch anymore so yolo
  //     //get all his parlays
  //     DSM.last(
  //       `/v1/accas/`,
  //       {
  //         method: 'GET',
  //         message: 'getAndCloseAllParlays',
  //         body: {
  //           status: 'creating',
  //         },
  //       },
  //       action.data.actions,
  //       'getAndCloseAllParlays'
  //     );
  //   } else {
  //     //supposedly error handler will do someting
  //   }

  //   return state;
  // },

  // getAndCloseAllParlays: (state, action) => {
  //   if (action.data.status === 'ok') {
  //     if (action.data.data.accumulators) {
  //       for (let acc of action.data.data.accumulators) {
  //         let parlayId = acc.id;
  //         DSM.create(
  //           `/v1/accas/${parlayId}/`,
  //           {
  //             method: 'DELETE',
  //             extras: {
  //               parlayId,
  //             },
  //             message: 'closeParlayResponse',
  //           },
  //           action.data.actions
  //         );
  //       }
  //     }
  //   } else {
  //     //supposedly error handler will do someting
  //   }

  //   return state;
  // },

  ////// PARLAY

  //open a parlay
  openParlay: (state, action) => {
    DSM.create(
      `/v1/accas/`,
      {
        method: 'POST',
        extras: {
          minimised: action.data.minimised,
          ccyCode: action.data.ccyCode,
          events: action.data.events,
        },
        message: 'openParlayResponse',
      },
      action.data.actions
    );

    return state.set('isOpeningParlay', true);
  },

  //handle parlay open with optinally adding an entry after
  openParlayResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let parlayId = action.data.data.id;
      if (parlayId) {
        state = state.setIn(['parlays', parlayId], fromJS(action.data.data));
        state = state.setIn(['parlays', parlayId, 'parlayCcy'], action.data.extras.ccyCode);
        state = state.setIn(['parlays', parlayId, 'minimised'], !!action.data.extras.minimised);

        if (action.data.extras.events) {
          state = state.setIn(['parlays', parlayId, 'events'], fromJS(action.data.extras.events));
        }

        if (action.data.extras.toAdd) {
          DSM.create(
            `/v1/accas/${parlayId}/entry/`,
            {
              method: 'PUT',
              body: {
                betType: action.data.extras.toAdd.betType,
                eventId: action.data.extras.toAdd.eventId,
                sport: action.data.extras.toAdd.sport,
              },
              extras: {
                parlayId,
                eventId: action.data.extras.toAdd.eventId,
                sport: action.data.extras.toAdd.sport,
                betType: action.data.extras.toAdd.betType,
              },
              message: 'parlayUpdate',
            },
            action.data.actions
          );
        }
      }
    } else {
      //error handler will catch
    }

    return state.set('isOpeningParlay', false);
  },

  //attempt to add a parlay entry, open a parlay before if there isn't one
  parlayAddEntry: (state, action) => {
    if (state.get('isOpeningParlay', true)) {
      return state;
    }

    if (action.data.parlayId) {
      let parlayId = action.data.parlayId;
      const accId = state.get('parlays').keySeq().first();

      if (parlayId === undefined && accId !== undefined) {
        parlayId = accId;
      }

      let parlay = state.getIn(['parlays', parlayId], null);

      if (state.getIn(['parlays', parlayId, 'hasPlaced'], false) || !parlay) {
        return state;
      }

      let currentPrice = state.getIn(['parlays', parlayId, 'prices', 'accas'], 1);
      if (currentPrice * action.data.price > config.parlays.maxPrice) {
        return state.setIn(['parlays', parlayId, 'priceIsMaxed'], true);
      }

      DSM.create(
        `/v1/accas/${parlayId}/entry/`,
        {
          method: 'PUT',
          body: {
            betType: action.data.betType,
            eventId: action.data.eventId,
            sport: action.data.sport,
          },
          extras: {
            parlayId,
            eventId: action.data.eventId,
            sport: action.data.sport,
            betType: action.data.betType,
          },
          message: 'parlayUpdate',
        },
        action.data.actions
      );

      state = state.setIn(['parlays', parlayId, 'error'], '');
    } else {
      DSM.create(
        `/v1/accas/`,
        {
          method: 'POST',
          extras: {
            ccyCode: action.data.ccyCode,
            toAdd: {
              betType: action.data.betType,
              eventId: action.data.eventId,
              sport: action.data.sport,
            },
          },
          message: 'openParlayResponse',
        },
        action.data.actions
      );

      state = state.set('isOpeningParlay', true);
    }

    return state;
  },

  //remove a parlay entry
  parlayRemoveEntry: (state, action) => {
    let parlayId = action.data.parlayId;
    let parlay = state.getIn(['parlays', parlayId], null);

    if (state.getIn(['parlays', parlayId, 'hasPlaced'], false) || !parlay) {
      return state;
    }

    DSM.create(
      `/v1/accas/${parlayId}/entry/`,
      {
        method: 'DELETE',
        body: {
          sport: action.data.sport,
          eventId: action.data.eventId,
          betType: action.data.betType,
        },
        extras: {
          sport: action.data.sport,
          eventId: action.data.eventId,
          betType: action.data.betType,
          parlayId,
          remove: true,
        },
        message: 'parlayUpdate',
      },
      action.data.actions
    );

    state = state.setIn(['parlays', parlayId, 'error'], '');
    return state.setIn(['parlays', parlayId, 'priceIsMaxed'], false);
  },

  //don't really need to do anything
  //maybe we can handle an error
  parlayUpdate: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.setIn(['parlays', action.data.extras.parlayId, 'error'], '');
      state = state.setIn(
        ['parlays', action.data.extras.parlayId, 'prices'],
        fromJS(action.data.data.prices)
      );
      state = state.setIn(
        ['parlays', action.data.extras.parlayId, 'maxStakeGbp'],
        action.data.data.maxStakeGbp
      );
    } else {
      if (action.data.code === 'accumulator_not_found') {
        //close accumulator
        if (action.data.extras.parlayId)
          state = state.removeIn(['parlays', action.data.extras.parlayId]);
      } else {
        state = state.setIn(
          ['parlays', action.data.extras.parlayId, 'error'],
          action.data.data || 'update_failed'
        );
      }
    }
    return state;
  },

  //push parlay to the top of draggables
  popParlay: (state, action) => {
    let parlayId = action.data.parlayId; //highlander
    let parlay = state.getIn(['parlays', parlayId], null);

    if (parlay) {
      return state.setIn(['parlays', parlayId, 'zIndex'], +new Date());
    }
    return state;
  },

  //this will close a parlay
  closeParlay: (state, action) => {
    let parlayId = action.data.parlayId;
    let parlay = state.getIn(['parlays', parlayId], null);

    if (parlay) {
      DSM.create(
        `/v1/accas/${parlayId}/`,
        {
          method: 'DELETE',
          extras: {
            parlayId,
          },
          message: 'closeParlayResponse',
        },
        action.data.actions
      );

      state = state.removeIn(['parlays', parlayId]);
    }

    //just in case
    return state.set('isOpeningParlay', false);
  },

  clearAllParlaysInState: (state, action) => {
    state = state.set('parlays', fromJS({}));
    //just in case
    state = state.set('isOpeningParlay', false);
    return state;
  },

  //close all parlays
  closeAllParlays: (state, action) => {
    //get the ids of all the betslips in the parlay
    let parlays = state.get('parlays', null);

    if (parlays) {
      parlays.forEach((parlay, parlayId) => {
        DSM.create(
          `/v1/accas/${parlayId}/`,
          {
            method: 'DELETE',
            extras: {
              parlayId,
            },
            message: 'closeParlayResponse',
          },
          action.data.actions
        );
      });
    }

    state = state.set('parlays', fromJS({}));
    //just in case
    return state.set('isOpeningParlay', false);
  },

  //we don't really need to do anything here... we've wonned already
  closeParlayResponse: (state, action) => {
    if (action.data.status === 'ok') {
      //k
      let parlayId = action.data.extras.parlayId;
      let parlay = state.getIn(['parlays', parlayId], null);

      if (parlay) {
        state = state.removeIn(['parlays', parlayId]);
      }

      state = state.set('isOpeningParlay', false);
    } else {
      //supposedly error handler will do someting
    }
    return state;
  },

  ////// API STREAM

  //this actually handles the websocket message
  //[API]
  accumulator: (state, action) => {
    if (action.data) {
      let acc = action.data;

      let parlay = state.getIn(['parlays', acc.id], null);
      if (!parlay) {
        return state;
      }

      let _entries = {};
      for (let entry of acc.entries) {
        let key = entry.sport + entry.eventId + entry.betType;
        _entries[key] = entry;
      }
      state = state.mergeDeepIn(['parlays', acc.id], fromJS(acc));
      state = state.setIn(['parlays', acc.id, 'entries'], fromJS(_entries));
    }

    return state;
  },

  ////// PARLAY PLACEMENT

  parlayPlace: (state, action) => {
    let parlayId = action.data.parlayId;
    let parlay = state.getIn(['parlays', parlayId], null);
    if (state.getIn(['parlays', parlayId, 'hasPlaced'], false) || !parlay) {
      return state;
    }

    let stake = parlay.get('stakeParlayCcy', null);
    if (stake) {
      stake = stake.replace(/,/g, '.');
      stake = parseFloat(stake);
    }

    DSM.create(
      `/v1/accas/${parlayId}/place/`,
      {
        method: 'POST',
        body: {
          stake,
          bookie: action.data.bookie,
          ccyCode: parlay.get('parlayCcy', '').toUpperCase(),
        },
        extras: {
          parlayId,
        },
        message: 'parlayPlaceResponse',
      },
      action.data.actions
    );

    state = state.setIn(['parlays', parlayId, 'error'], '');
    state = state.setIn(['parlays', parlayId, 'isPlacing'], true);

    return state;
  },

  parlayPlaceResponse: (state, action) => {
    let parlayId = action.data.extras.parlayId;
    if (action.data.status === 'ok') {
      state = state.setIn(['parlays', parlayId, 'hasPlaced'], true);
    } else {
      //error handler will catch
      state = state.setIn(['parlays', action.data.extras.parlayId, 'error'], action.data.data);
    }
    return state.setIn(['parlays', parlayId, 'isPlacing'], false);
  },

  ////// PARLAY INTERACTIONS

  //we make the stake part of the main state otherwise it will get lost on a redraw?
  parlayUpdateStake: (state, action) => {
    let parlayId = action.data.parlayId;

    //update stake
    state = state.setIn(['parlays', parlayId, 'stake'], action.data.stake);
    state = state.setIn(['parlays', parlayId, 'stakeGBP'], action.data.stakeGBP || null);
    state = state.setIn(
      ['parlays', parlayId, 'stakeParlayCcy'],
      action.data.stakeParlayCcy || null
    );

    return state;
  },

  toggleParlayMinimised: (state, action) => {
    let parlayId = action.data.parlayId;
    let minimised = state.getIn(['parlays', parlayId, 'minimised'], false);
    return state.setIn(['parlays', parlayId, 'minimised'], !minimised);
  },

  ////// LOGOUT

  //reset some state
  logout: (state, _action) => {
    //get the ids of all the betslips in the parlay
    let parlays = state.get('parlays', null);

    if (parlays) {
      parlays.forEach((parlay, parlayId) => {
        DSM.create(
          `/v1/accas/${parlayId}/`,
          {
            method: 'DELETE',
            extras: {
              parlayId,
            },
            message: 'closeParlayResponse',
          },
          action.data.actions
        );
      });
    }

    state = state.set('parlays', fromJS({}));
    //just in case
    return state.set('isOpeningParlay', false);
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

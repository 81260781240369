/** @format */

//import * as Sentry from '@sentry/browser';
import { formatAmount } from './formatters.js';

// note: 'order' can be inprogress and success at the same time: the got_stake
// portion is success (amount already matched in exchange), while (want_stake -
// got_stake) portion is inprogress (amount not yet matched in exchange).

// {status: got|want|diff, ...}
// got means use got_stake
// want means use want_stake
// diff means use the difference (want_stake - got_stake)
export const SUCCESS_BET_STATUSES = {
  done: 'got',
  order: 'got',
  settled: 'got',

  placing: 'ignore',
  tracking: 'ignore',
  tracking_error: 'ignore',
  unknown: 'ignore',
  danger: 'ignore',
  dangerorder: 'ignore',
  failed: 'ignore',
  void: 'ignore',
};

export const INPROGRESS_BET_STATUSES = {
  placing: 'want',
  order: 'diff',
  tracking: 'want',
  tracking_error: 'want',
  unknown: 'want',

  done: 'ignore',
  settled: 'ignore',
  danger: 'ignore',
  dangerorder: 'ignore',
  failed: 'ignore',
  void: 'ignore',
};

export const DANGER_BET_STATUSES = {
  danger: 'want',
  dangerorder: 'want',

  placing: 'ignore',
  order: 'ignore',
  tracking: 'ignore',
  tracking_error: 'ignore',
  unknown: 'ignore',
  done: 'ignore',
  settled: 'ignore',
  failed: 'ignore',
  void: 'ignore',
};

export const FINAL_BET_STATUSES = {
  done: true,
  failed: true,
  settled: true,
  void: true,
};

/**
 * Get the total GBP stake in a collection of order bets.
 * @param {Object} bets - a collection of bets (map)
 * @param {Object} statuses - a map of statuses (i.e. how different bet status amounts are collected)
 * @return {Object} - tupple of [totalStake, averagePrice]
 */
export function getTotalStakeOrderCcy(bets, statuses) {
  let totalStake = 0;
  let totalStakeTimesPrice = 0;

  for (let betId in bets) {
    //HACK why is this undefined?
    if (!bets[betId]) {
      continue;
    }

    let bet = bets[betId];
    let status = bets[betId].status.code;

    let whichStake = statuses[status];
    let stake = undefined;

    if (whichStake === undefined) {
      whichStake = statuses[null];
    }

    if (whichStake === undefined) {
      continue;
    }

    if (whichStake === 'ignore') {
      continue;
    }

    let toOrderCcy = bet.orderCcyRate / bet.ccyRate;

    if (whichStake === 'got' && bet.gotStake) {
      stake = bet.gotStake[1] * toOrderCcy;
    } else if (whichStake === 'want') {
      stake = bet.wantStake[1] * toOrderCcy;
    } else if (whichStake === 'diff' && bet.gotStake) {
      stake = (bet.wantStake[1] - bet.gotStake[1]) * toOrderCcy;
    } else {
      // Sentry.captureMessage('Error figuring out total stake GBP', {
      //   level: 'error',
      //   logger: 'Order',
      //   extra: {
      //     bets,
      //   },
      // });

      console.error(`Error figuring out total stake GBP`);
    }

    if (stake) {
      totalStake += stake;
      totalStakeTimesPrice += stake * bet.gotPrice;
    }
  }

  let averagePrice = totalStake > 0 ? totalStakeTimesPrice / totalStake : 0;
  return [totalStake, averagePrice];
}

/**
 * Update some internal order calculations (bet bar widths and stakes by status).
 * @param {Object} state - Immutable object representing state
 * @param {string} orderId - order to update
 * @return {Object} - new mutated state
 */
export function updateOrder(state, orderId) {
  let xrates = state.get('xrates', null);
  let bets = state.getIn(['orders', orderId, 'bets'], null);
  if (bets) {
    bets = bets.toJS();
  } else {
    bets = {};
  }

  let allUnknown = false;
  let allFinal = true;
  if (Object.keys(bets).length > 0) {
    allUnknown = true;
  }

  for (let betId in bets) {
    //HACK why is this undefined?
    if (!bets[betId]) {
      continue;
    }

    let status = 'unknown';
    try {
      status = bets[betId].status.code;
    } catch (err) {} //it's ok

    if (status !== 'unknown') {
      allUnknown = false;
    }

    if (!FINAL_BET_STATUSES[status]) {
      allFinal = false;
    }
  }

  let betsStatus = '';
  if (allUnknown) {
    betsStatus = 'bets-unknown';
  } else {
    if (allFinal) {
      betsStatus = 'bets-final';
    } else {
      betsStatus = 'bets-inprogress';
    }
  }

  let stakeAndPrice = getTotalStakeOrderCcy(bets, SUCCESS_BET_STATUSES);
  let wantStake = state.getIn(['orders', orderId, 'wantStake', '1'], 0);

  let calcStake = {
    'success-stake': stakeAndPrice[0],
    'danger-stake': Math.max(
      0,
      getTotalStakeOrderCcy(bets, DANGER_BET_STATUSES)[0]
    ),
    'inprogress-stake': Math.max(
      0,
      getTotalStakeOrderCcy(bets, INPROGRESS_BET_STATUSES)[0]
    ),
  };

  calcStake['unplaced-stake'] = Math.max(
    0,
    wantStake -
      calcStake['success-stake'] -
      calcStake['danger-stake'] -
      calcStake['inprogress-stake']
  );
  let averagePrice = stakeAndPrice[1];

  let openOrderBetWidth = {
    'success-stake': Math.ceil(((calcStake['success-stake'] || 0) / wantStake) * 100), //one of them has to be ceil, otherwise rounding will eat up 1% most of the time
    'danger-stake': Math.floor(((calcStake['danger-stake'] || 0) / wantStake) * 100),
    'inprogress-stake': Math.floor(((calcStake['inprogress-stake'] || 0) / wantStake) * 100),
    'unplaced-stake': Math.floor(((calcStake['unplaced-stake'] || 0) / wantStake) * 100),
  };

  //minor tweak so that we don't get left-overs
  if (openOrderBetWidth['success-stake'] >= 99) {
    openOrderBetWidth['success-stake'] = 100;
  }
  if (openOrderBetWidth['inprogress-stake'] >= 99) {
    openOrderBetWidth['inprogress-stake'] = 100;
  }
  if (openOrderBetWidth['danger-stake'] >= 99) {
    openOrderBetWidth['danger-stake'] = 100;
  }

  //fix 'unplaced_stake' as remainder
  openOrderBetWidth['unplaced-stake'] =
    100 -
    openOrderBetWidth['success-stake'] -
    openOrderBetWidth['danger-stake'] -
    openOrderBetWidth['inprogress-stake'];

  return state.mergeDeepIn(['orders', orderId, 'calc'], {
    openOrderBetWidth,
    calcStake,
    averagePrice,
    betsStatus,
  });
}

/**
 * Update all internal order calculations.
 * @param {Object} state - Immutable object representing state
 * @return {Object} - new mutated state
 */
export function updateOrderAll(state) {
  let orders = state.get('orders', false);
  if (orders && orders.size) {
    orders.forEach((order, orderId) => {
      state = updateOrder(state, orderId);
    });
  }

  return state;
}

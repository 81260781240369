/** @format */

import 'moment-timezone';
import moment from 'moment';

/**
 * Pad (left or right) a string or a number with another string
 * @param {number|string} n - original string or number
 * @param {number} width - target width
 * @param {string} z - what to pad it with (should be one character)
 * @param {boolean} right - the default behaviour is to pad left, but this can reverse it
 * @return {string} - padded version
 */
export function pad(n, width, z, right) {
  z = z || '0';
  n = n + '';
  let arrlen = Math.round(width - n.length + 1);
  if (arrlen >= 0) {
    return n.length >= width
      ? n
      : !right
      ? new Array(arrlen).join(z) + n
      : n + new Array(arrlen).join(z);
  } else {
    //trying to pad more than width
    return n;
  }
}

/**
 * Convert a string or a date to stdDate format (YYYY-MM-DD); this is used for accounting and therefore timezone agnostic
 * @param {Date|string} date - target date
 * @return {string} - date in stdDate format
 */
export function toStdDate(date) {
  let nd;
  if (!date) {
    nd = new Date();
  } else {
    nd = new Date(date);
  }

  try {
    nd = nd.toISOString().split('T')[0];
  } catch (err) {
    //should never happen, but it's ok
  }

  if (nd) {
    return nd;
  } else {
    return date;
  }
}

/**
 * Format a date in stdDate format (YYYY-MM-DD). Takes into account timezones.
 * @param {Date|string} date - the input date
 * @param {string} tz - timezone (must be a valid moment.js timezone)
 * @return {string}
 */
export function toStdDateTz(date, tz) {
  return moment(date).tz(tz).format('YYYY-MM-DD');
}

/**
 * Returns an object with the dateFrom and dateTo of last week.
 * @return {Object} - {dateFrom:... dateTo:...}
 */
export function lastWeek() {
  let today = new Date();
  let d1 = new Date();
  let d2 = new Date();
  d1.setTime(d1.getTime() - (today.getDay() - 1 + 7) * 24 * 60 * 60 * 1000);
  d2.setTime(d2.getTime() - today.getDay() * 24 * 60 * 60 * 1000);

  return {
    dateFrom: toStdDate(d1),
    dateTo: toStdDate(d2),
  };
}

/**
 * Checks if a date is in valid stdDate (YYYY-MM-DD) format,
 * note: doesn't really check against months with various day counts
 * @param {string} date - date to check
 * @return {boolean}
 */
export function isValidStdDate(date) {
  if (!date) {
    return false;
  } else {
    let atoms = date.split('-');
    let year = parseInt(atoms[0], 10);
    let month = parseInt(atoms[1], 10);
    let day = parseInt(atoms[2], 10);

    if (year > new Date().getFullYear() + 1 || year < 2000) {
      return false;
    }
    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }
  }

  return true;
}

/**
 * Returns yesterday's stdDate
 * @return {string}
 */
export function yesterdayDay() {
  let d1 = new Date();
  //Monday-ify - 7
  d1.setTime(d1.getTime() - 1 * 24 * 60 * 60 * 1000);

  return toStdDate(d1).split('/').reverse().join('-');
}

/**
 * Format a date in stdDate format with time as well (YYYY-MM-DDTHH:mm). Takes into account timezones.
 * @param {Date|string} date - the input date
 * @param {string} tz - timezone (must be a valid moment.js timezone)
 * @return {string}
 */
export function toStdDateTime(date, tz) {
  return moment(date).tz(tz).format('YYYY-MM-DDTHH:mm');
}

/**
 * Wrapper to locale string date output.
 * @param {Date|string} date - the input date
 * @param {boolean} noDate - only output the hours and minutes if true
 * @return {string}
 */
export function formatTimestamp(date, noDate) {
  let ret = new Date(date).toLocaleString();

  if (noDate) {
    return ret.split(', ').pop();
  } else {
    return ret;
  }
}

/**
 * Get the date and time in the users' timezone.
 * @param {Date|string} d - the input date
 * @param {string} tz - timezone (must be a valid moment.js timezone)
 * @return {string} - output date
 */
export function toUserTimezoneDate(d, tz) {
  return new Date(moment(d).tz(tz).format('YYYY-MM-DDTHH:mm:ss'));
}

/**
 * Get the date and time from the users' timezone into UTC.
 * @param {Date|string} d - the input date
 * @return {string} - output date
 */
export function fromUserTimezoneDate(d) {
  return toUserTimezoneDate(d, 'utc');
}

/**
 * Determine where the early market (today midnight) starts based on timezone.
 * @param {string} tz - timezone (must be a valid moment.js timezone)
 * @param {Date} [fromDate] - optional date input mainly for testing
 * @return {Date} - output date
 */
export function earlyBoundaryTime(tz, fromDate) {
  let earlyBoundary = moment(fromDate)
    .tz(tz || 'UTC')
    .add(1, 'day')
    .startOf('day')
    .utc();

  return earlyBoundary.toDate();
}

/**
 * Date to string, based on timezone
 * @param {Date|string} d - the input date
 * @param {string} tz - timezone (must be a valid moment.js timezone)
 * @return {string} - in locale format
 */
export function localTimeString(date, tz) {
  return toUserTimezoneDate(date, tz).toString();
}

/**
 * Local time string, based on timezone
 * @param {Date|string} d - the input date
 * @param {string} tz - timezone (must be a valid moment.js timezone)
 * @return {string} - in locale format
 */
export function localTimeStringLocale(date, tz) {
  return toUserTimezoneDate(date, tz).toLocaleTimeString();
}

/**
 * Converts number of seconds to human time: 1hr 32min 03sec
 * @param {number} sec - number of seconds
 * @param {boolean} padIt - pad values with leading 0s
 * @todo needs to support translations at some point
 * @return {string} - time in human form
 */
export function toHumanTime(sec, padIt) {
  let comp = {};
  comp.hr = Math.floor(sec / 3600);
  comp.min = padIt
    ? pad(Math.floor((sec - comp.hr * 3600) / 60), 2)
    : Math.floor((sec - comp.hr * 3600) / 60);
  comp.sec = padIt
    ? pad(sec - comp.min * 60 - comp.hr * 3600, 2)
    : sec - comp.min * 60 - comp.hr * 3600;

  let ret = [];
  for (let i in comp) {
    if (comp[i]) {
      ret.push(comp[i] + ' ' + i);
    }
  }

  return ret.join(' ');
}

/**
 * Determine if a date is 'today' by comparing stdDate values
 * @param {Date|string} date - the input date
 * @return {boolean}
 */
export function isToday(date) {
  return toStdDate(fromUserTimezoneDate(new Date())) === toStdDate(date);
}

/**
 * Get the date from an eventId (2020-09-23,123,431). It's normally the first part of the id.
 * @param {string} eventId
 * @return {string} - the date
 */
export function timeFromEventId(eventId) {
  if (!eventId || typeof eventId !== 'string') {
    return '';
  } else {
    return eventId.split(',')[0];
  }
}

/**
 * Returns true if the event start time is in the past - this is a hacky way to tell if a game is IR
 * @param {string} startTime - event start time
 * @return {boolean} - is the startTime in the past - true or false
 */
export function eventStartedInThePast(startTime) {}

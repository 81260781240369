/** @format */

import config from '../config';
import * as Sentry from '@sentry/browser';
import PriceFeed from './PriceFeed';

import _ from 'lodash';
import { toStdDate, timeFromEventId } from './time';
import 'moment-timezone';

import { earlyBoundaryTime } from './time';

/**
 * Clear old faved events (3 days in the past) from the session.
 * @param {Object} _session - target Session singleton to apply changes on
 */
export function clearOldFavs(_session) {
  let events = _session.get(['settings', 'trade', 'faved', 'events'], null);
  let past = new Date();
  past.setTime(past.getTime() - config.timings.clearOldFavs);
  if (events) {
    for (let eventId in events) {
      let d = new Date(timeFromEventId(eventId));
      if (d < past) {
        _session.clear(['settings', 'trade', 'faved', 'events', eventId]);
      }
    }
  }

  //cleanup from switching from favs competitions by market
  for (let _market of config.markets) {
    _session.clear(['settings', 'trade', 'faved', 'competitions', _market]);
  }
}

/**
 * Clear old unfaved events (3 days in the past) from the session.
 * An unfaved event happens when you fav a competition and then unfavorite one event.
 * @param {Object} _session - target Session singleton to apply changes on
 */
export function clearOldUnfavs(_session) {
  let events = _session.get(['settings', 'trade', 'unfaved', 'events'], null);
  let past = new Date();
  past.setTime(past.getTime() - config.timings.clearOldFavs);
  if (events) {
    for (let eventId in events) {
      let d = new Date(timeFromEventId(eventId));
      if (d < past) {
        _session.clear(['settings', 'trade', 'unfaved', 'events', eventId]);
      }
    }
  }
}

/**
 * Clear old expanded events (3 days in the past) from the session.
 * @param {Object} _session - target Session singleton to apply changes on
 */
export function clearOldExpanded(_session) {
  let events = _session.get(['settings', 'trade', 'hasExtrasExpanded'], null);
  let past = new Date();
  past.setTime(past.getTime() - config.timings.clearOldFavs);
  if (events) {
    for (let eventId in events) {
      let d = new Date(timeFromEventId(eventId));
      if (d < past) {
        _session.clear(['settings', 'trade', 'hasExtrasExpanded', eventId]);
      }
    }
  }
}

/**
 * Clear old watched competitions from the session.
 * If a competition hasn't had any events for more than (config.timings.clearOldWatchedCompetitions) from when it was watched.
 * I.e. if UEFA CL was watched at one point but hasn't had events for 1 week, it should auto unwatch.
 * @param {Object} state - from state (we need to read events from here)
 * @param {Object} _session - target Session singleton to apply changes on
 */
export function clearOldWatched(state, _session) {
  let now = +new Date();
  let competitions = _session.get(['settings', 'trade', 'watched', 'competitions'], null);
  let compsWithEvents = [];
  let compSports = {};
  let events = state.get('events', null);

  if (events) {
    events.forEach((event) => {
      compsWithEvents.push(event.get('competitionId'));
      compSports[event.get('competitionId')] = event.get('sport');
    });
  }
  compsWithEvents = _.uniq(compsWithEvents);

  if (competitions) {
    for (let market in competitions) {
      for (let compId in competitions[market]) {
        //backwards compatibility (for how long ?)
        if (
          competitions[market][compId] === true ||
          typeof competitions[market][compId] === 'number'
        ) {
          //fixup for old boolean system
          _session.clear(['settings', 'trade', 'watched', 'competitions', market, compId]);
        } else if (compsWithEvents.indexOf(compId) !== -1) {
          //still has events, refresh
          _session.set(
            ['settings', 'trade', 'watched', 'competitions', market, compId, 'time'],
            +new Date()
          );
          _session.set(
            ['settings', 'trade', 'watched', 'competitions', market, compId, 'sport'],
            compSports[compId]
          );
        } else {
          //watched a week ago?
          let timeWatched = _session.get(
            ['settings', 'trade', 'watched', 'competitions', market, compId, 'time'],
            0
          );
          if (timeWatched === false) {
            timeWatched = 0;
          }
          if (timeWatched + config.timings.clearOldWatchedCompetitions < now) {
            _session.clear(['settings', 'trade', 'watched', 'competitions', market, compId]);
          }
        }
      }
    }
  }
}

/**
 * Determines if an event is favorited, either directly or via a favorited competition.
 * @param {Object} ev - an event object with an eventId and competitionId
 * @param {Object} _session - target Session singleton to check
 * @return {boolean} - in favs or not
 */
export function isEventInFavorites(ev, _session) {
  let inFavs = _session.get(
    ['settings', 'trade', 'faved', 'events', ev.eventId],
    _session.get(['settings', 'trade', 'faved', 'competitions', ev.competitionId], false)
  );
  let specificallyUnfaved = _session.get(
    ['settings', 'trade', 'unfaved', 'events', ev.eventId],
    false
  );
  if (inFavs && !specificallyUnfaved) {
    return true;
  } else {
    return false;
  }
}

/**
 * Determines what market and event lands into.
 * @param {Object} ev - an event with an irStatus, startTime and eventId
 * @param {Object} tz - time zone string (must be supported by moment.js)
 * @return {string} - 'ir', 'today', 'early', 'outrights'
 */
export function determineEventMarket(ev, tz) {
  let status;

  // multirunner can not be favourite because the ui simply wouldnt work
  if (ev.isMultirunner) {
    status = 'outrights';
  } else if (ev.irStatus === 'in_running') {
    status = 'ir';
  } else {
    let evd;
    if (ev.startTime) {
      evd = new Date(ev.startTime);
    } else {
      evd = new Date(timeFromEventId(ev.eventId));
    }

    if (evd < earlyBoundaryTime(tz)) {
      status = 'today';
    } else {
      status = 'early';
    }
  }

  return status;
}

/**
 * Determines the base sport of a sub sport. Usually it's the part before the '_' but there are some exceptions.
 * @param {string} sport - a string representing a sport
 * @param {boolean} ignoreComplexSports - the sport might have a parent sport specified which will be always returned; this overrides that behaviour and just returns the sport
 * @return {string} - base sport
 */
export function getBaseSport(sport, ignoreComplexSports) {
  if (config.sports[sport] && config.sports[sport].complexSportOf) {
    if (!ignoreComplexSports) {
      return sport;
    } else {
      return config.sports[sport].complexSportOf;
    }
  } else if (sport) {
    return sport.split('_')[0];
  } else {
    return sport;
  }
}

/**
 * This function moves an event into another  market.
 * It updates the event market, but also the market structure.
 * @param {Object} state - the target state to manipulate
 * @param {string} eventId - the event to move
 * @param {string} toMarket - the destination market
 * @return {Object} - mutated state
 */
export function switchEventMarket(state, eventId, toMarket) {
  //look for the event
  let event = state.getIn(['events', eventId], null);

  if (!event) {
    Sentry.captureMessage('Bad Event Switch', {
      level: 'warning',
      logger: 'trade',
      extra: {
        eventId,
        toMarket,
      },
    });
    console.warn(`Event ${eventId} tried to switch market (${toMarket}) but does not exist`);
    return state;
  }

  //we need to extract the path where the update is going
  let eventMarket = event.get('marketId');
  let isInFavs = event.get('isInFavs', false);

  //spcial case
  //REWRITE: move to own functions
  if (isInFavs && !toMarket) {
    let compId = event.get('competitionId');
    let sports = event.get('sports', null);

    if (sports) {
      sports.forEach((sp, sport) => {
        state = state.removeIn(['markets', sport, 'favs', compId, eventId]);
      });
    }

    state = state.setIn(['events', eventId, 'isInFavs'], false);
    return state;
  } else {
    if (!toMarket) {
      toMarket = event.get('marketId');
    }

    //if they are the same market just leave it
    if (toMarket === eventMarket) {
      return state;
    }

    let compId = event.get('competitionId');
    let sports = event.get('sports', null);

    if (sports) {
      sports.forEach((sp, sport) => {
        //remove from original market
        //only if not in favs
        if (toMarket !== 'favs') {
          for (let _market of config.markets) {
            if (_market !== toMarket) {
              state = state.removeIn(['markets', sport, _market, compId, eventId]);
            }
          }
        }

        if (sp) {
          state = state.setIn(
            ['markets', sport, toMarket, compId, eventId],
            `${event.get('startTime', timeFromEventId(eventId))}::${event.get(
              'home',
              'home'
            )}_${event.get('away', 'away')}_${eventId}`
          );
        }
      });
    }

    //mark as original market, if it's not to favs
    if (toMarket !== 'favs') {
      state = state.setIn(['events', eventId, 'marketId'], toMarket);
    } else {
      state = state.setIn(['events', eventId, 'isInFavs'], true);
    }

    return state;
  }
}

/**
 * You can use this function to munge the price before putting in the state.
 * Currently this doesn't do anything.
 * @param {number} price - the price
 * @return {number} - changed price
 */
function priceFitter(price) {
  if (price && !isNaN(price)) {
    return 1 + (price - 1) * config.parlays.pricePenalty;
  } else {
    return price;
  }
}

/**
 * Add, remove or update the prices of an offer line
 * @param {Object} state - the target state to manipulate
 * @param {string} eventId - the event to update
 * @param {string} sport - the sport to update
 * @param {string} offerGroup - the offer group to update
 * @param {Object} offerGroupLine - the line with it's contents; it typically looks like [handicap, [prices]]
 * @param {boolean} changesMainLine - should this change the main line
 * @return {Object} - mutated state
 */
function updateOfferLine(state, eventId, sport, offerGroup, offerGroupLine, changesMainLine) {
  if (!offerGroupLine) {
    //handle situation where entire offer group is removed
    if (changesMainLine) {
      return state.removeIn(['prices', sport, eventId, offerGroup]);
    } else {
      return state;
    }
  }

  let currentMainLine = state.getIn(['prices', sport, eventId, offerGroup, 'mainLine'], '');

  let commissionRate = state.get('commissionRate', 0) / 100;
  //backup for when commission has wierd values
  if (isNaN(commissionRate)) {
    commissionRate = 0;
  }

  let offerPrices = offerGroupLine[1];
  let offerLine = offerGroupLine[0];

  if (offerLine === null) {
    //empty (no handicap)
    offerLine = 'main';
  } else if (typeof offerLine === 'object') {
    //a pair
    offerLine = offerLine.join(',');
  } else {
    //normal
    offerLine = offerLine + '';
  }

  //don't allow offersHcap prices to override offersEvent main line prices ever
  if (offerLine === currentMainLine && !changesMainLine) {
    return state;
  }

  //eventdata1 doesn't offset the offerLines by the score, but pricefeed does
  //the front-end obviously has to clean up all the backend messes
  let irscore = state.getIn(['events', eventId, 'irstatus', sport, 'score'], null);
  let baseSport = getBaseSport(sport);
  if (
    config.offerGroups[offerGroup].irOffset &&
    config.sports[baseSport] &&
    config.sports[baseSport].useIrTypes &&
    irscore
  ) {
    irscore = irscore.toJS();
    offerLine = parseInt(offerLine, 10) + 4 * (irscore[0] - irscore[1]) + '';
  }

  if (!offerPrices) {
    state = state.removeIn(['prices', sport, eventId, offerGroup, 'lines', offerLine]);
  } else if (!config.offerGroups[offerGroup].score) {
    //score stuff behaves differently
    let foundOffers = [];
    for (let _priceIndex in offerPrices) {
      let realOffer = offerPrices[_priceIndex][0];
      foundOffers.push(realOffer);
      let price = offerPrices[_priceIndex][1];
      if (price) {
        state = state.setIn(
          ['prices', sport, eventId, offerGroup, 'lines', offerLine, 'exchangeOnly'],
          false
        );
        //apply commission
        price = Math.max(price * (1 - commissionRate), 1.001);
      } else {
        price = 0;
      }
      state = state.setIn(
        ['prices', sport, eventId, offerGroup, 'lines', offerLine, 'offers', realOffer, 'max'],
        price
      );
      state = state.setIn(
        ['prices', sport, eventId, offerGroup, 'lines', offerLine, 'offers', realOffer, 'min'],
        priceFitter(price)
      );
    }

    //remove offers that are not present anymore
    // multirunner sides are the team ids so we just need to loop through and check
    if (config.offerGroups[offerGroup].multirunner) {
      const currentOffers = Object.keys(
        state.getIn(['prices', sport, eventId, offerGroup, 'lines', offerLine, 'offers'])?.toJS() ||
          {}
      );
      for (let checkOffer of currentOffers) {
        if (foundOffers.indexOf(checkOffer) === -1) {
          state = state.removeIn([
            'prices',
            sport,
            eventId,
            offerGroup,
            'lines',
            offerLine,
            'offers',
            checkOffer,
          ]);
        }
      }
    }
    // compare against the ordered list of sides in the config
    else if (foundOffers.length !== config.offerGroups[offerGroup].order.length) {
      for (let checkOffer of config.offerGroups[offerGroup].order) {
        if (foundOffers.indexOf(checkOffer) === -1) {
          state = state.removeIn([
            'prices',
            sport,
            eventId,
            offerGroup,
            'lines',
            offerLine,
            'offers',
            checkOffer,
          ]);
        }
      }
    }

    if (changesMainLine) {
      state = state.setIn(['prices', sport, eventId, offerGroup, 'mainLine'], offerLine);
    }
  } else {
    state = state.setIn(
      ['prices', sport, eventId, offerGroup, 'lines', offerLine, 'offers', '*', 'max'],
      offerPrices[0][1]
    );
    state = state.setIn(
      ['prices', sport, eventId, offerGroup, 'lines', offerLine, 'offers', '*', 'min'],
      priceFitter(offerPrices[0][1])
    );

    if (offerPrices[0][1]) {
      state = state.setIn(
        ['prices', sport, eventId, offerGroup, 'lines', offerLine, 'exchangeOnly'],
        false
      );
    }

    if (changesMainLine) {
      state = state.setIn(['prices', sport, eventId, offerGroup, 'mainLine'], offerLine);
    }
  }

  //record maxlines here
  let numLinesNoEx = 0;
  let _lines = state.getIn(['prices', sport, eventId, offerGroup, 'lines'], null);
  if (_lines && _lines.size) {
    _lines.forEach((line) => {
      if (!line.get('exchangeOnly', false)) {
        numLinesNoEx++;
      }
    });
  }
  state = state.setIn(['prices', sport, eventId, offerGroup, 'numLinesNoEx'], numLinesNoEx);

  return state;
}

/**
 * Add, remove or update a bunch of offer groups with a bunch of lines
 * @param {Object} state - the target state to manipulate
 * @param {string} eventId - the event to update
 * @param {string} sport - the sport to update
 * @param {Object} prices - the prices to update (this is a map of offer groups and line maps)
 * @param {boolean} mainLine - should this change the main line
 * @return {Object} - mutated state
 */
export function updateOffers(state, eventId, sport, prices, mainLine) {
  //no prices? gtfo
  if (!prices) {
    return state;
  }

  //look at each offer group
  for (let offerGroup in prices) {
    //unmapped
    if (!config.offerGroups[offerGroup]) {
      console.warn('Unmapped group type', sport, offerGroup);
      continue;
    }

    if (!prices[offerGroup]) {
      state = state.removeIn(['prices', sport, eventId, offerGroup]);
    } else {
      let irrt = mainLine ? [prices[offerGroup]] : prices[offerGroup];
      for (let offerLine of irrt) {
        state = updateOfferLine(state, eventId, sport, offerGroup, offerLine, mainLine);
      }
    }
  }

  return state;
}

/**
 * Clear all non-main lines for a sport and event. There are situation where stale handicap lines have been left up because the user has expanded and contracted.
 * @param {Object} state - the target state to manipulate
 * @param {string} eventId - the event to update
 * @param {string} sport - the sport to update
 * @return {Object} - mutated state
 */
export function clearAllNonMainLines(state, eventId, sport) {
  let offerGroups = state.getIn(['prices', sport, eventId], null);
  if (offerGroups) {
    state = state.asImmutable();
    offerGroups.forEach((offerGroup, ogName) => {
      let currentMainLine = offerGroup.get('mainLine', '');
      let lines = offerGroup.get('lines', null);
      if (lines) {
        lines.forEach((_line, offerLine) => {
          if (offerLine !== currentMainLine) {
            state = state.removeIn(['prices', sport, eventId, ogName, 'lines', offerLine]);
          }
        });
      }
    });
    state = state.asMutable();
  }
  return state;
}

/**
 * Update or insert a new competition with some data.
 * @param {Object} state - the target state to manipulate
 * @param {Object} comp - the competition data (typically contains at leas sport and competitionId, but also should contain the name, country, etc)
 * @param {Object} _session - Session singleton so we can check if it's watched
 * @return {Object} - mutated state
 */
export function upsertCompetition(state, comp, _session) {
  let compId = comp.competitionId;
  let baseSport = getBaseSport(comp.sport, true);

  //if we are subscribed to that competition we will need to mark it so
  //this is a sad way of doing it
  let isSubscribed = {};
  let isSuggested = {};

  let featured = false;
  for (let compName of config.sports[baseSport].featuredCompetitionsName) {
    if (comp.name.toLowerCase().startsWith(compName.toLowerCase())) {
      featured = true;
    }
  }
  if (config.sports[baseSport].featuredCompetitions.indexOf(compId + '') !== -1) {
    featured = true;
  }

  let isFav = _session.get(['settings', 'trade', 'faved', 'competitions', compId], false);
  for (let market of config.markets) {
    let isSub = _session.get(
      ['settings', 'trade', 'watched', 'competitions', market, compId, 'time'],
      0
    );
    if (market === 'favs' || isSub || isFav || (comp.compBookie && market !== 'early')) {
      isSubscribed[market] = true;
    }

    if (featured) {
      isSuggested[market] = true;
    }
  }

  comp.sport = baseSport;
  comp.isSubscribed = isSubscribed;
  comp.isSuggested = isSuggested;

  //record basic info about competition
  //so we can reuse it later
  state = state.mergeDeepIn(['competitions', compId], comp);

  return state;
}

/**
 * Should remove a competition, but we don't really need to do this ever.
 * @param {Object} state - the target state to manipulate
 * @param {Object} _compId - the competition id
 * @return {Object} - mutated state
 */
export function removeCompetition(state, _compId) {
  //we don't really need to remove competitions
  //their existence is just determined by them having events
  return state;
}

/**
 * Add or update an event with some data. This will also attempt to upsert a competition.
 * @param {Object} state - the target state to manipulate
 * @param {Object} event - event data (typically constains sport, competitionId, startTime, eventId, etc)
 * @param {string} tz - timezone string (must be supported by moment.js)
 * @param {Object} _session - Session singleton so we can check if in favorties and for competition upsert
 * @return {Object} - mutated state
 */
export function upsertEvent(state, event, tz, _session) {
  let sport;
  let sports = {};
  let comp = {
    competitionId: event.competitionId,
    country: event.competitionCountry,
    name: event.competitionName,
    sport: event.sport,
  };

  state = upsertCompetition(state, comp, _session);

  //let oldMarketId = state.getIn(['events', event.eventId, 'marketId'], null)
  event.marketId = determineEventMarket(event, tz); //with favs
  event.isInFavs = isEventInFavorites(event, _session); //this flag skips favs

  if (event.sport) {
    sport = getBaseSport(event.sport, true);
    sports = {
      [event.sport]: true,
    };
  } else {
    sport = getBaseSport(event.sports[0], true);
    for (let sp of event.sports) {
      sports[sp] = true;
    }
  }

  //HACK
  //extra time
  //auto-insert full sport as well
  if (event.sport.endsWith('_et')) {
    let _fullSp = event.sport.replace('_et', '');
    sports[_fullSp] = true;
  }

  event.sport = sport;
  event.sports = sports;
  event.hasExtrasExpanded = _session.get(
    ['settings', 'trade', 'hasExtrasExpanded', event.eventId],
    false
  );

  //fix competition markets
  for (let sport in event.sports) {
    //remove from any old markets
    for (let _market of config.markets) {
      if (_market !== event.marketId) {
        state = state.removeIn(['markets', sport, _market, event.competitionId, event.eventId]);
      }
    }
    //add to new market
    state = state.setIn(
      ['markets', sport, event.marketId, event.competitionId, event.eventId],
      `${event.startTime || timeFromEventId(event.eventId)}::${event.home || 'home'}_${
        event.away || 'away'
      }_${event.eventId}`
    );
    if (event.isInFavs) {
      state = state.setIn(
        ['markets', sport, 'favs', event.competitionId, event.eventId],
        `${event.startTime || timeFromEventId(event.eventId)}::${event.home || 'home'}_${
          event.away || 'away'
        }_${event.eventId}`
      );
    }

    //unschedule removal
    state = state.removeIn(['eventsQueuedForRemoval', event.eventId, sport]);
  }

  //record/update basic info about event
  state = state.mergeDeepIn(['events', event.eventId], event);
  state = state.setIn(['events', event.eventId, 'teams'], event.teams);

  return state;
}

/**
 * Update the in-running information of an event as well as its 'irStatus'. Also handles moving the event from `today` to `ir`.
 * @param {Object} state - the target state to manipulate
 * @param {string} eventId - event id
 * @param {string} sport - sport to update for
 * @param {Object} irstatus - in running status information; typically looks like {time: [...], score: [...], rc: [...]}
 * @return {Object} - mutated state
 */
export function updateIRStatus(state, eventId, sport, irstatus) {
  //record ir status of sport
  if (irstatus) {
    if (irstatus.score || irstatus.setScores || (irstatus.homeScore && irstatus.awayScore)) {
      irstatus['dodgyScore'] = false;
      state = state.removeIn(['scoresQueuedForRemoval', eventId, sport]);
    } else {
      delete irstatus['score']; //ignore it, we remove it later
      delete irstatus['setScores']; //ignore it, we remove it later
      delete irstatus['homeScore']; //ignore it, we remove it later
      delete irstatus['awayScore']; //ignore it, we remove it later
      irstatus['dodgyScore'] = true;
      //if there is a removal time already, don't overwrite it
      let removalTime = state.getIn(['scoresQueuedForRemoval', eventId, sport]);
      if (!removalTime) {
        state = state.setIn(['scoresQueuedForRemoval', eventId, sport], +new Date());
      }
    }

    //for tennis it's also useful to compute the current games-scores and sets-score
    //this is different than the game score of each individual set, note the plurals
    if (sport === 'tennis' && irstatus['setScores']) {
      irstatus['totalGameScore'] = irstatus['setScores'].reduce(
        (prev, curr) => {
          prev[0] += curr[0];
          prev[1] += curr[1];
          return prev;
        },
        [0, 0]
      );
      irstatus['setsScore'] = irstatus['setScores'].reduce(
        (prev, curr) => {
          if (curr[0] > curr[1] && curr[0] >= 6) {
            prev[0]++;
          } else if (curr[0] < curr[1] && curr[1] >= 6) {
            prev[1]++;
          }
          return prev;
        },
        [0, 0]
      );
    }

    //cricket also has a special case, we're mainly interested in the run score
    if (sport === 'cricket' && irstatus['homeScore'] && irstatus['awayScore']) {
      let home = 0;
      let away = 0;

      if (irstatus['homeScore']['firstInnings']) {
        home += irstatus['homeScore']['firstInnings'].runs;
      }
      if (irstatus['homeScore']['secondInnings']) {
        home += irstatus['homeScore']['secondInnings'].runs;
      }

      if (irstatus['awayScore']['firstInnings']) {
        away += irstatus['awayScore']['firstInnings'].runs;
      }
      if (irstatus['awayScore']['secondInnings']) {
        away += irstatus['awayScore']['secondInnings'].runs;
      }

      irstatus['score'] = [home, away];
    }

    state = state.mergeDeepIn(['events', eventId, 'irstatus', sport], irstatus);

    //only switch forward
    let oldIRstatus = state.getIn(['events', eventId, 'irStatus'], 'pre_event');
    let _marketId = state.getIn(['events', eventId, 'marketId'], null);

    //this never happens... because realistically event is removed and then readded
    if (oldIRstatus === 'pre_event' && _marketId !== 'favs') {
      state = state.setIn(['events', eventId, 'irStatus'], 'in_running');
      state = switchEventMarket(state, eventId, 'ir');
    }
    //should we also try to switch back?
  } else {
    state = state.removeIn(['events', eventId, 'irstatus', sport]);
  }

  return state;
}

/**
 * Simply remove an event sport score
 * @param {Object} state - the target state to manipulate
 * @param {string} eventId - event id
 * @param {string} sport - sport to remove for
 * @return {Object} - mutated state
 */
export function removeEventScore(state, eventId, sport) {
  //remove event score
  state = state.removeIn(['events', eventId, 'irstatus', sport, 'score']);
  state = state.setIn(['events', eventId, 'irstatus', sport, 'dodgyScore'], false);

  return state;
}

/**
 * Actually remove an event+sport from the state (event registry and market structure).
 * @param {Object} state - the target state to manipulate
 * @param {string} eventId - event id
 * @param {string} sport - sport to update for
 * @return {Object} - mutated state
 */
export function removeEventForReal(state, eventId, sport) {
  let event = state.getIn(['events', eventId], null);

  //event doesn't exist? gtfo
  if (!event) {
    return state;
  }

  let eventMarket = event.get('marketId');
  let compId = event.get('competitionId');

  //remove the sub-sport
  state = state.removeIn(['events', eventId, 'sports', sport]);

  //remove from competition
  state = state.removeIn(['markets', sport, eventMarket, compId, eventId]);
  //just in case
  state = state.removeIn(['markets', sport, 'favs', compId, eventId]);

  //remove from queue
  state = state.removeIn(['eventsQueuedForRemoval', eventId, sport]);
  state = state.removeIn(['scoresQueuedForRemoval', eventId, sport]);

  if (sport.endsWith('_et')) {
    let _fullSp = sport.replace('_et', '');
    state = state.removeIn(['eventsQueuedForRemoval', eventId, _fullSp]);
  }

  return state;
}

/**
 * Mark an event for removal by adding it to a queue (eventsQueuedForRemoval).
 * This is called by the reducer while piggybacking on the websocket. Ideally this should be done async with thunks.
 * @param {Object} state - the target state to manipulate
 * @param {string} eventId - event id
 * @param {string} sport - sport to update for
 * @return {Object} - mutated state
 */
export function removeEvent(state, eventId, sport) {
  let event = state.getIn(['events', eventId], null);

  //event doesn't exist? gtfo
  if (!event) {
    return state;
  }

  //remove the sub-sport
  state = state.setIn(['eventsQueuedForRemoval', eventId, sport], +new Date());

  //HACK
  //extra time
  //should remove full sport as well
  if (sport.endsWith('_et')) {
    let _fullSp = sport.replace('_et', '');
    state = state.setIn(['eventsQueuedForRemoval', eventId, _fullSp], +new Date());
  }

  return state;
}

/*eslint-disable*/
//everything in here is HORRIBLE HAX
/**
 * Run a stress test in the UI by emulating a lot of event status updates and price updates in wdw, ah, ahou
 * nore: this needs leakPricesWs and actionHack enabled in config.support
 * @param {number} numEvents - number of events to emulate
 * @param {number} [updatesPerSec=50] - number of price updates per second
 * @param {number} [irPerSec=10] - number of ir updates per second
 * @param {number} [frequency=1000] - how often (default per second)
 * @return {number} - interval ID (the thing that keeps pumping out fake messages)
 */
function stressTest(numEvents, updatesPerSec = 50, irPerSec = 10, frequency = 1000) {
  const markets = ['ir', 'today', 'early'];
  const IR_RATIO = 0.1;
  const TODAY_RATIO = 0.2;
  const EARLY_RATIO = 0.7;
  const IR_UPDATE_RATIO = 0.8;
  const TODAY_UPDATE_RATIO = 0.15;
  const EARLY_UPDATE_RATIO = 0.05;

  if (!window._callAction || !window._stream) {
    console.warn('You must have leakPricesWs AND actionHack set to true in config.support');
    return; //pointless
  }

  const sport = 'fb';
  PriceFeed.stop();
  window._callAction('clearTradeEvents', {});
  window._callAction('changeSport', { sport });

  if (numEvents) {
    let teams = _.uniq(
      [...Array(numEvents * 2)].map(() => {
        return Math.floor(Math.random() * numEvents * 2);
      })
    );
    let competitions = _.uniq(
      [...Array(Math.floor(numEvents / 3))].map(() => {
        return Math.floor(Math.random() * Math.floor(numEvents / 3));
      })
    );
    let country = 'XX';
    let _td = new Date();
    let today = toStdDate(_td).split('/').reverse().join('-');
    _td.setTime(_td.getTime() + 24 * 60 * 60 * 1000);
    let tomorrow = toStdDate(_td).split('/').reverse().join('-');

    //create competitions
    let messages = [];
    for (let i = 0; i < competitions.length; i++) {
      let compId = competitions[i];
      for (let market of markets) {
        //emulate auto-watch
        window._callAction('competitionSubscribe', {
          data: {
            competitionId: compId,
            marketId: market,
            sport: 'fb',
          },
        });
      }
    }

    // ["event", ["fb", "2020-08-25,36988,39364"], {
    //   "start_ts": "2020-08-25T16:00:00Z",
    //   "competition_id": 60362,
    //   "competition_name": "UEFA Youth League U19",
    //   "country": "EU",
    //   "home": "SL Benfica U19",
    //   "away": "Real Madrid CF U19",
    //   "ir_status": {
    //     "time": ["1h", 42],
    //     "score": [0, 1],
    //     "rc": [0, 0]
    //   }
    // }]

    //we need to keep a map of eventId -> competitionId
    let eventReg = {};

    //create events ir
    let irEvents = [];
    for (let i = 0; i < numEvents * IR_RATIO; i++) {
      let compId = competitions[Math.floor(Math.random() * competitions.length)];
      let evId1 = teams[Math.floor(Math.random() * teams.length)];
      let evId2 = teams[Math.floor(Math.random() * teams.length)];
      let eventId = `${today},${evId1},${evId2}`;
      irEvents.push(eventId);
      eventReg[eventId] = {
        start_ts: `${today}T09:00:00+00:00`,
        competition_id: compId,
        competition_name: `Competition ${compId}`,
        country,
        home: `Home Team ${evId1}`,
        away: `Away Team ${evId2}`,
        ir_status: {
          time: ['1h', `${Math.floor(Math.random() * 45)}`],
          score: [Math.floor(Math.random() * 10), Math.floor(Math.random() * 10)],
          rc: [Math.floor(Math.random() * 3), Math.floor(Math.random() * 3)],
        },
      };
      messages.push(['event', [sport, eventId], eventReg[eventId]]);
      //keep track
    }

    //create events today
    let todayEvents = [];
    for (let i = 0; i < numEvents * TODAY_RATIO; i++) {
      let compId = competitions[Math.floor(Math.random() * competitions.length)];
      let evId1 = teams[Math.floor(Math.random() * teams.length)];
      let evId2 = teams[Math.floor(Math.random() * teams.length)];
      let eventId = `${today},${evId1},${evId2}`;
      todayEvents.push(eventId);
      eventReg[eventId] = {
        start_ts: `${today}T09:00:00+00:00`,
        competition_id: compId,
        competition_name: `Competition ${compId}`,
        country,
        home: `Home Team ${evId1}`,
        away: `Away Team ${evId2}`,
      };
      messages.push(['event', [sport, eventId], eventReg[eventId]]);
      //keep track
    }

    //create events tomorrow
    let earlyEvents = [];
    for (let i = 0; i < numEvents * EARLY_RATIO; i++) {
      let compId = competitions[Math.floor(Math.random() * competitions.length)];
      let evId1 = teams[Math.floor(Math.random() * teams.length)];
      let evId2 = teams[Math.floor(Math.random() * teams.length)];
      let eventId = `${tomorrow},${evId1},${evId2}`;
      earlyEvents.push(eventId);
      eventReg[eventId] = {
        start_ts: `${tomorrow}T09:00:00+00:00`,
        competition_id: compId,
        competition_name: `Competition ${compId}`,
        country,
        home: `Home Team ${evId1}`,
        away: `Away Team ${evId2}`,
      };

      messages.push(['event', [sport, eventId], eventReg[eventId]]);
      //keep track
    }

    window._callAction('data', { data: messages });

    // ["offers_hcap", [156, "fb", "2020-08-25,46456,28741"], {
    //   "wdw": [null, [
    //     ["a", 1.441],
    //     ["d", 4.67],
    //     ["h", 9.427]
    //   ]]
    // }]

    // ["offers_hcap", [166, "fb", "2020-08-25,33097,24545"], {
    //   "ahou": [6, [
    //     ["over", 2.136],
    //     ["under", 1.781]
    //   ]]
    // }]

    return setInterval(() => {
      let messages = [];

      ////////////////////////////////////
      //PRICES
      ////////////////////////////////////
      //ir updates
      for (let j = 0; j < updatesPerSec * IR_UPDATE_RATIO; j++) {
        let eventId = irEvents[Math.floor(Math.random() * irEvents.length)];
        let hand1 = Math.floor(Math.random() * 10);
        let hand2 = Math.floor(Math.random() * 20);
        messages.push([
          'offers_hcap',
          [eventReg[eventId].competitionId, sport, eventId],
          {
            wdw: [
              null,
              [
                ['a', 3 + Math.random()],
                ['d', 2 + Math.random()],
                ['h', 1 + Math.random()],
              ],
            ],
            ah: [
              12,
              [
                ['a', 4 + Math.random()],
                ['h', 2 + Math.random()],
              ],
            ],
            ahou: [
              6,
              [
                ['over', 2 + Math.random()],
                ['under', 3 + Math.random()],
              ],
            ],
          },
        ]);
      }

      //today updates
      for (let j = 0; j < updatesPerSec * TODAY_UPDATE_RATIO; j++) {
        let eventId = todayEvents[Math.floor(Math.random() * irEvents.length)];
        let hand1 = Math.floor(Math.random() * 10);
        let hand2 = Math.floor(Math.random() * 20);
        messages.push([
          'offers_hcap',
          [eventReg[eventId].competitionId, sport, eventId],
          {
            wdw: [
              null,
              [
                ['a', 3 + Math.random()],
                ['d', 2 + Math.random()],
                ['h', 1 + Math.random()],
              ],
            ],
            ah: [
              12,
              [
                ['a', 4 + Math.random()],
                ['h', 2 + Math.random()],
              ],
            ],
            ahou: [
              6,
              [
                ['over', 2 + Math.random()],
                ['under', 3 + Math.random()],
              ],
            ],
          },
        ]);
      }

      //early updates
      for (let j = 0; j < updatesPerSec * EARLY_UPDATE_RATIO; j++) {
        let eventId = earlyEvents[Math.floor(Math.random() * irEvents.length)];
        let hand1 = Math.floor(Math.random() * 10);
        let hand2 = Math.floor(Math.random() * 20);
        messages.push([
          'offers_hcap',
          [eventReg[eventId].competitionId, sport, eventId],
          {
            wdw: [
              null,
              [
                ['a', 3 + Math.random()],
                ['d', 2 + Math.random()],
                ['h', 1 + Math.random()],
              ],
            ],
            ah: [
              12,
              [
                ['a', 4 + Math.random()],
                ['h', 2 + Math.random()],
              ],
            ],
            ahou: [
              6,
              [
                ['over', 2 + Math.random()],
                ['under', 3 + Math.random()],
              ],
            ],
          },
        ]);
      }

      ////////////////////////////////////
      //IRSTATUSES
      ////////////////////////////////////
      for (let j = 0; j < irPerSec / 10; j++) {
        let eventId = irEvents[Math.floor(Math.random() * irEvents.length)];
        let hand1 = Math.floor(Math.random() * 10);
        let hand2 = Math.floor(Math.random() * 20);
        messages.push([
          'event',
          [sport, eventId],
          {
            ...eventReg[eventId],
            ir_status: {
              time: ['1h', `${Math.floor(Math.random() * 45)}`],
              score: [Math.floor(Math.random() * 10), Math.floor(Math.random() * 10)],
              rc: [Math.floor(Math.random() * 3), Math.floor(Math.random() * 3)],
            },
          },
        ]);
      }

      window._callAction('data', { data: messages });
    }, frequency);
  }
}

/**
 * Open a bunch of betslips based on some trade events. Uses bet types: 'for,a', 'for,d', 'for,h'
 * @param {Object} actions - actions passed from a component
 * @param {number} [count=8] - number of betslips to open
 * @param {string} [sport='fb'] - sport to open for
 */
export function betslipStress(actions, count = 8, sport = 'fb') {
  if (!window._callAction) {
    console.warn('You must have actionHack set to true in config.support');
    return;
  } else {
    window._betslip_stress_running = true;
    //bet types to use
    let betTypes = ['a', 'd', 'h'];

    let base = {
      sport,
      actions,
    };

    let ebt = [];

    let events = window._state.get('events', null);
    let prices = window._state.get('prices', null);

    if (events) {
      events.forEach((event, eventId) => {
        if (event.get('sport', '') === sport) {
          for (let bt of betTypes) {
            if (prices.getIn([sport, eventId, 'wdw', 'lines', 'main', 'offers', bt, 'max'], null)) {
              ebt.push({
                eventId,
                betType: `for,${bt}`,
              });
            }
          }
        }
      });
    }

    for (let i = 0; i < count; i++) {
      window._callAction('betslipOpen', {
        ...base,
        ...ebt[i],
      });
    }
  }
}

/**
 * Check for events with dodgy ahou lines; will try to store them in window._dodgyLines
 */
export function checkDodgyLines() {
  window._dodgyLines = [];
  window._state.get('events').forEach((event, eventId) => {
    if (event.get('irStatus') === 'in_running') {
      let score = event.getIn(['irstatus', 'fb', 'score'], []);
      if (score) {
        let cScore = score.reduce((a, b) => a + b, 0);
        let line = parseInt(window._state.getIn(['prices', 'fb', eventId, 'ahou', 'mainLine'])) / 4;
        if (line < cScore) {
          window._dodgyLines.push(
            `line ${line} on ${eventId}: ${event.get('home')} vs ${event.get(
              'away'
            )} - score ${score}`
          );
        }
      }
    }
  });
}

/**
 * Tries to trace event score and handicap line changes
 * @param {string} sport - sport to trace for
 * @param {string} eventId - eventId to trace for
 * @param {string} [offerGroup=''] - optional offer group to trace for
 * @return {Object} - list of things that happened
 */
export function eventTimeline(sport, eventId, offerGroup = '') {
  if (!window._eventHistory) {
    console.warn('You must have eventHistory set to true in config.support');
    return []; //pointless
  }

  if (!sport || !eventId) {
    console.warn('You must specify at least sport and eventId');
    return [];
  }

  let evh = window._eventHistory.toJS();
  let history = evh[sport];
  if (history) {
    history = history[eventId];
  } else {
    return [];
  }

  if (!history) {
    //blank
    return [];
  }

  let outputArr = [];
  Object.keys(history)
    .sort()
    .map((entry) => {
      let timestamp = entry.split('_')[0];
      let { data, type } = history[entry];
      let move = '';
      if (type === 'event') {
        if (data[1] && data[1].irStatus) {
          move = JSON.stringify(data[1].irStatus).replace(/\"/gi, '');
        }
      } else if (type === 'offers_hcap') {
        if (data) {
          for (let group in data) {
            if (offerGroup && offerGroup !== group) {
              continue;
            }

            if (data[group] && data[group][0] !== null) {
              move += `${group} -> ${data[group][0]} [main line]; `;
            } else {
              move += `${group} -> null [no line / removed]; `;
            }
          }
        }
      }

      if (move) {
        let d = new Date(parseInt(timestamp));
        outputArr.push(`${d.toTimeString().split(' ')[0]}: ${move}`);
      }
    });

  return outputArr;
}

if (window) {
  window._stressTest = stressTest;
  window._checkDodgyLines = checkDodgyLines;
  window._eventTimeline = eventTimeline;
}

/** @format */

exports.default = {
  name: { id: 'sports.ru', defaultMessage: 'Rugby Union' },
  shortName: { id: 'sports.ru.short', defaultMessage: 'Rugby Union' },
  featuredCompetitions: ['62398', '63831', '61103', '659', '701', '742', '1999'],
  featuredCompetitionsName: [],
  enabled: true,
  maxTimeout: 600,
  correlated: {},
  correlatedOrder: ['ru'],
  trustScores: false,
  matchDataOpener: 'for,tp,reg,ml,a/null',
  offerGroupsOrder: [
    //'timeWin,tp,all,wdw',
    //'timeWin,tp,all,mlssop',
    //'timeWin,thalf,1,mlssop',
    //'timeWin,thalf,2,mlssop',
    //'timeWin,tp,all,ml',
    'timeAh,tp,all',
    'timeAhou,tp,all',
    'timeWin,tp,reg,wdw',
    //'timeWin,tp,reg,ml',
    'timeAh,tp,reg',
    'timeAhou,tp,reg',
    'tahou,tp,all,h',
    'tahou,tp,all,a',
    //'timeWin,tperiod,1,wdw',
    //'timeAh,tperiod,1',
    //'timeAhou,tperiod,1',
    'timeAhou,thalf,1',
    'timeAh,thalf,1',
    'tahou,thalf,1,h',
    'tahou,thalf,1,a',
    //'timeAhou,thalf,2',
    //'timeAh,thalf,2',
    //'tahou,thalf,2,h',
    //'tahou,thalf,2,a',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    ah: 'timeAh,tp,all',
    wdw: 'timeWin,tp,reg,wdw',
    'tennisMatch,all': 'timeWin,tp,reg,wdw',
    'tennisAh,all,set': 'timeAh,tp,all',
    'tennisAhou,all,game': 'timeAhou,tp,all',
  },
};

/** @format */

import DSM from '../lib/DSM';
import { toCamelCase } from '../lib/camelSnake';
import Telemetry from '../lib/Telemetry';
import { fromJS } from 'immutable';

let initialState = fromJS({
  //error message text
  error: '',
  // login | forgotPassword
  mode: 'login',
  //is logging in
  isLoading: false,
  //is login or password reset a success
  isSuccess: false,
  //should the login redirect somewhere
  redirectTo: '',
});

const functions = {
  //make the login request
  login: (state, action) => {
    DSM.last(
      '/web/sessions/',
      {
        method: 'POST',
        body: {
          username: action.data.username ? action.data.username.trim() : '',
          password: action.data.password,
          lang: action.data.language ? action.data.language.replace('zh', 'zh-hans') : 'en', //HACK
        },
        message: 'loginResponse',
        extras: {
          actions: action.data.actions,
        },
      },
      action.data.actions,
      'login'
    );

    Telemetry.startRecording('login', 'login_speed');

    state = state.set('error', '');
    return state.set('isLoading', true);
  },

  //handle login response
  //other reducers also rely on this action (especially the base one)
  loginResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('isSuccess', true);
    } else if (action.data.status === 'error') {
      if (action.data.code !== 'redirect') {
        state = state.set('error', action.data.code);
      } else {
        state = state.set('redirectTo', action.data.data);
      }
      state = state.set('isSuccess', false);
    }

    return state.set('isLoading', false);
  },

  //reset state of login screen
  logout: (state, _action) => {
    state = state.set('isLoading', false);
    state = state.set('isSuccess', false);
    state = state.set('error', '');
    state = state.set('mode', 'login');

    return state;
  },

  //change login
  loginSetMode: (state, action) => {
    state = state.set('isSuccess', false);
    state = state.set('isLoading', false);
    state = state.set('error', '');
    state = state.set('mode', action.data);

    return state;
  },

  loginResetPasswordRequest: (state, _action) => {
    //TODO: requires totus
    return state;
  },

  // In case we received a password reset response, let the
  // user know their password was reset on the login form
  // after the redirect, instead of making them go back manually
  resetPasswordResponse: (state, _action) => {
    state = state.set('resetPassword', true);

    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

/** @format */

const Generators = require('../positionGrids').Generators

//innings based (baseball, esports)
//see: https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  'timeWin,tinnings,1,ml': {
    name: { id: 'groupNames.timeWin,tinnings,1,ml', defaultMessage: 'Moneyline (1st inning)' },
    shortName: { id: 'groupNames.short.timeWin,tinnings,1,ml', defaultMessage: '1/2 (1I)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,1,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,1,ml,h',
        template: { id: 'betTypes.timeWin,tinnings,1,ml,h', defaultMessage: '{home} (1st inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,1,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,1,ml,a',
        template: { id: 'betTypes.timeWin,tinnings,1,ml,a', defaultMessage: '{away} (1st inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,1,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeAh,tinnings,1': {
    name: { id: 'groupNames.timeAh,tinnings,1', defaultMessage: 'Asian Hcp. (1st inning)' },
    shortName: { id: 'groupNames.short.timeAh,tinnings,1', defaultMessage: 'Asian Hcp. (1I)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,1,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'periodScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,1,ah,h',
        template: {
          id: 'betTypes.timeAh,tinnings,1,h',
          defaultMessage: '{home} {handicap} (Asian, 1st inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,1,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,1,ah,a',
        template: {
          id: 'betTypes.timeAh,tinnings,1,a',
          defaultMessage: '{away} {handicap} (Asian, 1st inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,1,a', defaultMessage: '2' },
      },
    },
  },
  'timeAhou,tinnings,1': {
    name: { id: 'groupNames.timeAhou,tinnings,1', defaultMessage: 'Asian O/U (1st inning)' },
    shortName: { id: 'groupNames.short.timeAhou,tinnings,1', defaultMessage: 'Asian O/U (1I)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tinnings,1,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'periodScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tinnings,1,ahover',
        template: {
          id: 'betTypes.timeAhou,tinnings,1,over',
          defaultMessage: 'Over {handicap} (Asian, 1st inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,1,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tinnings,1,ahunder',
        template: {
          id: 'betTypes.timeAhou,tinnings,1,under',
          defaultMessage: 'Under {handicap} (Asian, 1st inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,1,under', defaultMessage: 'U' },
      },
    },
  },
  'timeWin,tinnings,2,ml': {
    name: { id: 'groupNames.timeWin,tinnings,2,ml', defaultMessage: 'Moneyline (2nd inning)' },
    shortName: { id: 'groupNames.short.timeWin,tinnings,2,ml', defaultMessage: '1/2 (2I)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,2,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,2,ml,h',
        template: { id: 'betTypes.timeWin,tinnings,2,ml,h', defaultMessage: '{home} (2nd inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,2,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,2,ml,a',
        template: { id: 'betTypes.timeWin,tinnings,2,ml,a', defaultMessage: '{away} (2nd inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,2,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeAh,tinnings,2': {
    name: { id: 'groupNames.timeAh,tinnings,2', defaultMessage: 'Asian Hcp. (2nd inning)' },
    shortName: { id: 'groupNames.short.timeAh,tinnings,2', defaultMessage: 'Asian Hcp. (2I)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,2,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'periodScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,2,ah,h',
        template: {
          id: 'betTypes.timeAh,tinnings,2,h',
          defaultMessage: '{home} {handicap} (Asian, 2nd inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,2,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,2,ah,a',
        template: {
          id: 'betTypes.timeAh,tinnings,2,a',
          defaultMessage: '{away} {handicap} (Asian, 2nd inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,2,a', defaultMessage: '2' },
      },
    },
  },
  'timeAhou,tinnings,2': {
    name: { id: 'groupNames.timeAhou,tinnings,2', defaultMessage: 'Asian O/U (2nd inning)' },
    shortName: { id: 'groupNames.short.timeAhou,tinnings,2', defaultMessage: 'Asian O/U (2I)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tinnings,2,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'periodScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tinnings,2,ahover',
        template: {
          id: 'betTypes.timeAhou,tinnings,2,over',
          defaultMessage: 'Over {handicap} (Asian, 2nd inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,2,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tinnings,2,ahunder',
        template: {
          id: 'betTypes.timeAhou,tinnings,2,under',
          defaultMessage: 'Under {handicap} (Asian, 2nd inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,2,under', defaultMessage: 'U' },
      },
    },
  },
  'timeWin,tinnings,3,ml': {
    name: { id: 'groupNames.timeWin,tinnings,3,ml', defaultMessage: 'Moneyline (3rd inning)' },
    shortName: { id: 'groupNames.short.timeWin,tinnings,3,ml', defaultMessage: '1/2 (3I)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,3,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,3,ml,h',
        template: { id: 'betTypes.timeWin,tinnings,3,ml,h', defaultMessage: '{home} (3rd inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,3,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,3,ml,a',
        template: { id: 'betTypes.timeWin,tinnings,3,ml,a', defaultMessage: '{away} (3rd inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,3,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeAh,tinnings,3': {
    name: { id: 'groupNames.timeAh,tinnings,3', defaultMessage: 'Asian Hcp. (3rd inning)' },
    shortName: { id: 'groupNames.short.timeAh,tinnings,3', defaultMessage: 'Asian Hcp. (3I)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,3,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'periodScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,3,ah,h',
        template: {
          id: 'betTypes.timeAh,tinnings,3,h',
          defaultMessage: '{home} {handicap} (Asian, 3rd inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,3,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,3,ah,a',
        template: {
          id: 'betTypes.timeAh,tinnings,3,a',
          defaultMessage: '{away} {handicap} (Asian, 3rd inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,3,a', defaultMessage: '2' },
      },
    },
  },
  'timeAhou,tinnings,3': {
    name: { id: 'groupNames.timeAhou,tinnings,3', defaultMessage: 'Asian O/U (3rd inning)' },
    shortName: { id: 'groupNames.short.timeAhou,tinnings,3', defaultMessage: 'Asian O/U (3I)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tinnings,3,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'periodScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tinnings,3,ahover',
        template: {
          id: 'betTypes.timeAhou,tinnings,3,over',
          defaultMessage: 'Over {handicap} (Asian, 3rd inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,3,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tinnings,3,ahunder',
        template: {
          id: 'betTypes.timeAhou,tinnings,3,under',
          defaultMessage: 'Under {handicap} (Asian, 3rd inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,3,under', defaultMessage: 'U' },
      },
    },
  },
  'timeWin,tinnings,4,ml': {
    name: { id: 'groupNames.timeWin,tinnings,4,ml', defaultMessage: 'Moneyline (4th inning)' },
    shortName: { id: 'groupNames.short.timeWin,tinnings,4,ml', defaultMessage: '1/2 (4I)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,4,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,4,ml,h',
        template: { id: 'betTypes.timeWin,tinnings,4,ml,h', defaultMessage: '{home} (4th inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,4,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,4,ml,a',
        template: { id: 'betTypes.timeWin,tinnings,4,ml,a', defaultMessage: '{away} (4th inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,4,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeAh,tinnings,4': {
    name: { id: 'groupNames.timeAh,tinnings,4', defaultMessage: 'Asian Hcp. (4th inning)' },
    shortName: { id: 'groupNames.short.timeAh,tinnings,4', defaultMessage: 'Asian Hcp. (4I)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,4,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'periodScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,4,ah,h',
        template: {
          id: 'betTypes.timeAh,tinnings,4,h',
          defaultMessage: '{home} {handicap} (Asian, 4th inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,4,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,4,ah,a',
        template: {
          id: 'betTypes.timeAh,tinnings,4,a',
          defaultMessage: '{away} {handicap} (Asian, 4th inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,4,a', defaultMessage: '2' },
      },
    },
  },
  'timeAhou,tinnings,4': {
    name: { id: 'groupNames.timeAhou,tinnings,4', defaultMessage: 'Asian O/U (4th inning)' },
    shortName: { id: 'groupNames.short.timeAhou,tinnings,4', defaultMessage: 'Asian O/U (4I)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tinnings,4,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'periodScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tinnings,4,ahover',
        template: {
          id: 'betTypes.timeAhou,tinnings,4,over',
          defaultMessage: 'Over {handicap} (Asian, 4th inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,4,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tinnings,4,ahunder',
        template: {
          id: 'betTypes.timeAhou,tinnings,4,under',
          defaultMessage: 'Under {handicap} (Asian, 4th inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,4,under', defaultMessage: 'U' },
      },
    },
  },
  'timeWin,tinnings,5,ml': {
    name: { id: 'groupNames.timeWin,tinnings,5,ml', defaultMessage: 'Moneyline (5th inning)' },
    shortName: { id: 'groupNames.short.timeWin,tinnings,5,ml', defaultMessage: '1/2 (5I)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,5,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.4', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,5,ml,h',
        template: { id: 'betTypes.timeWin,tinnings,5,ml,h', defaultMessage: '{home} (5th inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,5,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,5,ml,a',
        template: { id: 'betTypes.timeWin,tinnings,5,ml,a', defaultMessage: '{away} (5th inning)' },
        columnName: { id: 'columnNames.timeWin,tinnings,5,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeAh,tinnings,5': {
    name: { id: 'groupNames.timeAh,tinnings,5', defaultMessage: 'Asian Hcp. (5th inning)' },
    shortName: { id: 'groupNames.short.timeAh,tinnings,5', defaultMessage: 'Asian Hcp. (5I)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tinnings,5,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'periodScores.4', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tinnings,5,ah,h',
        template: {
          id: 'betTypes.timeAh,tinnings,5,h',
          defaultMessage: '{home} {handicap} (Asian, 5th inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,5,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tinnings,5,ah,a',
        template: {
          id: 'betTypes.timeAh,tinnings,5,a',
          defaultMessage: '{away} {handicap} (Asian, 5th inning)',
        },
        columnName: { id: 'columnNames.timeAh,tinnings,5,a', defaultMessage: '2' },
      },
    },
  },
  'timeAhou,tinnings,5': {
    name: { id: 'groupNames.timeAhou,tinnings,5', defaultMessage: 'Asian O/U (5th inning)' },
    shortName: { id: 'groupNames.short.timeAhou,tinnings,5', defaultMessage: 'Asian O/U (5I)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tinnings,5,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'periodScores.4', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tinnings,5,ahover',
        template: {
          id: 'betTypes.timeAhou,tinnings,5,over',
          defaultMessage: 'Over {handicap} (Asian, 5th inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,5,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tinnings,5,ahunder',
        template: {
          id: 'betTypes.timeAhou,tinnings,5,under',
          defaultMessage: 'Under {handicap} (Asian, 5th inning)',
        },
        columnName: { id: 'columnNames.timeAhou,tinnings,5,under', defaultMessage: 'U' },
      },
    },
  },
  //technically this goes to 9 innings... but will you ever have this ?
};

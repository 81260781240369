/** @format */

exports.default = {
  name: { id: 'sports.darts', defaultMessage: 'Darts' }, //name
  shortName: { id: 'sports.darts.short', defaultMessage: 'Darts' }, //short name (used in mobile modes)
  featuredCompetitions: [], //feature competitions if name contains one of these
  featuredCompetitionsName: [],
  enabled: true, //is visible to normal users
  maxTimeout: 600, //maximum order expiry time
  correlated: {},
  correlatedOrder: ['darts'], //for the purpose of + symbol on own page, used in other places as well so should always be defined
  trustScores: false, //do we display the scores for this sport (also applies to subsports)
  noDraws: true, //this sport doesn't have draws (i.e. tennis, esports, etc)
  matchDataOpener: 'for,tp,all,ml,h/null', //what bet type/handicap to use when opening match info window (because of offerhist)
  offerGroupsOrder: [
    'timeWin,tp,all,ml',
    'timeAh,tp,all',
    'timeCs,tp,all',
    'timeAhou,tp,all',
    'win',
  ],
  //symphathetic bet types ... it's a 'good enough' interface remap that makes
  //some betypes have fallback columns. the point of this is that events in favorites
  //that have differents sports may now show some non-exact-matching bet types
  //the way to read is: <bet_type_I_lack> : <bet_type_I_have>
  sympBetTypes: {
    ml: 'timeWin,tp,all,ml',
    ah: 'timeAh,tp,all',
    ahou: 'timeAhou,tp,all',
    cs: 'timeCs,tp,all',
    'tennisMatch,all': 'timeWin,tp,all,ml',
    'tennisAh,all,set': 'timeWin,tp,all,wdw',
    'tennisAh,all,game': 'timeAh,tp,all',
    'tennisAhou,all,game': 'timeAhou,tp,all',
  },
};

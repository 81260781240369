/** @format */

import config from '../../config';
import DSM from '../../lib/DSM';
import { hashParams } from '../../lib/nav';
import { toCamelCaseAll, toCamelCase } from '../../lib/camelSnake';
import { toStdDateTime } from '../../lib/time';
import { fromJS } from 'immutable';

function generateParams(_filters, tz, offsetDate = false) {
  let filters = {};

  for (let flt in _filters) {
    if (_filters[flt]) {
      if (
        flt === 'dateFrom' ||
        flt === 'dateTo' ||
        flt === 'eventStartFrom' ||
        flt === 'eventStartTo'
      ) {
        let dt = new Date(_filters[flt]);
        if (offsetDate && (flt === 'dateTo' || flt === 'eventStartTo')) {
          //we need to increment by one day so we cover the edge cases
          dt.setTime(dt.getTime() + 24 * 60 * 60 * 1000);
        }

        filters[flt] = toStdDateTime(dt, 'utc');
      } else if (flt === 'search') {
        if (parseInt(_filters['search'], 10)) {
          filters['wantedIds'] = decodeURIComponent(_filters['search'])
            .replace(/[^,;0-9]/gi, '')
            .split(',')
            .filter((id) => !!id);
        } else {
          filters['search'] = _filters['search'];
        }
      } else if (flt === 'wantedIds') {
        if (parseInt(_filters['wantedIds'], 10)) {
          filters['wantedIds'] = decodeURIComponent(_filters['wantedIds'])
            .replace(/[^,;0-9]/gi, '')
            .split(',')
            .filter((id) => !!id);
        } else {
          filters['search'] = _filters['wantedIds'];
        }
      } else if (flt === 'status') {
        filters[flt] = _filters[flt].split(',');
      } else {
        filters[flt] = _filters[flt];
      }
    }
  }

  return filters;
}

//we need to cast some things
function prepareParams(params) {
  if (params['dateTo']) {
    //we need to decrement by one day because we covered the edge cases
    let dt = new Date(params['dateTo']);
    dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000);
    params['dateTo'] = toStdDateTime(dt, 'utc') + ':00+00:00';
  }

  if (params['dateFrom']) {
    params['dateFrom'] = params['dateFrom'] + ':00+00:00';
  }

  if (params['eventStartTo']) {
    //we need to decrement by one day because we covered the edge cases
    let dt = new Date(params['eventStartTo']);
    dt.setTime(dt.getTime() - 24 * 60 * 60 * 1000);
    params['eventStartTo'] = toStdDateTime(dt, 'utc') + ':00+00:00';
  }

  if (params['eventStartFrom']) {
    params['eventStartFrom'] = params['eventStartFrom'] + ':00+00:00';
  }

  if (params['pageSize']) {
    params['pageSize'] = parseInt(params['pageSize'], 10);
  }

  if (params['page']) {
    params['page'] = parseInt(params['page'], 10);
  }

  if (params['wantedIds']) {
    params['search'] = params['wantedIds'];
  }

  return params;
}

const INITIAL_STATE = {
  //list of orders
  orders: [],
  //is loading the current orders request
  isLoading: false,
  //is loading some of the order filters
  isLoadingFilters: false,
  //is downloading orders as a file
  isDownloading: false,
  isDownloadFailed: false,
  //is this the first time the page has been loaded
  firstLoad: false,
  //what filter params are being currently applied
  params: {},
  //what parameter is being used to sort by
  sorted: '',
  //is the sort ascending
  sortAsc: false,

  //list of options for various parameters
  //these are retrieved when the user tries to open a filtering modal
  options: {
    //countries competitions might be in
    countries: null,
    //competitions
    competitions: null,
    //events
    events: null,
    //who placed the order (users)
    placers: null,
  },
};

let initialState = fromJS(INITIAL_STATE);

const functions = {
  //you need this action itterator wherever you use the pricefeed websocket
  data: (state, action) => {
    state = state.asMutable();

    //apply multiple things
    if (action.data.data && typeof action.data.data === 'object') {
      for (let act of action.data.data) {
        state = reducer(state, { type: act[0], data: typeof act[1] !== 'object' ? act : act[1] });
      }
    }

    return state.asImmutable();
  },

  //api stream is proxied so it has to be unpacked
  api: (state, action) => {
    let _data;
    if (action.data.ts) {
      _data = action.data.data;
    } else {
      _data = action.data[0].data;
    }

    if (_data) {
      state = state.asMutable();
      for (let _action of _data) {
        state = reducer(state, { type: _action[0], data: _action[1] });
      }
      state = state.asImmutable();
    }

    return state;
  },

  ////// API STREAM

  //handle the reception of new bet information and update inside an order
  bet: (state, action) => {
    let orders = state.getIn(['orders'], null);
    let order;
    if (orders) {
      orders.forEach((_order) => {
        if (_order.get('orderId') === action.data.orderId) {
          order = _order;
        }
      });
    }

    if (order) {
      DSM.last(
        `/v1/orders/${action.data.orderId}/`,
        {
          method: 'GET',
          message: 'refreshOneOrder',
        },
        action.data.actions,
        `refreshOneOrder/${action.data.orderId}`
      );
    }

    return state;
  },

  order: (state, action) => {
    let orders = state.getIn(['orders'], null);
    let order;
    if (orders) {
      orders.forEach((_order) => {
        if (_order.get('orderId') === action.data.orderId) {
          order = _order;
        }
      });
    }

    if (order) {
      DSM.last(
        `/v1/orders/${action.data.orderId}/`,
        {
          method: 'GET',
          message: 'refreshOneOrder',
        },
        action.data.actions,
        `refreshOneOrder/${action.data.orderId}`
      );
    }

    return state;
  },

  ////// ORDER LOADING

  //update order request parameters
  updateOrdersParam: (state, action) => {
    if (action.data.param === 'orderBy') {
      let orderBy = state.getIn(['params', 'orderBy'], '');
      if (!orderBy) {
        action.data.value = `${action.data.value} desc`;
      } else {
        let atoms = orderBy.split(' ');
        if (atoms[0] === action.data.value) {
          if (atoms[1] === 'desc') {
            atoms[1] = 'asc';
            action.data.value = atoms.join(' ');
          } else if (atoms[1] === 'asc') {
            action.data.value = '';
          } else {
            atoms[1] = 'desc';
            action.data.value = atoms.join(' ');
          }
        } else {
          action.data.value = `${action.data.value} desc`;
        }
      }
    }

    if (action.data.param === 'competitionCountry') {
      if (action.data.value) {
        let competitionCountry = state.get(['params', 'competitionCountry'], '');
        if (competitionCountry !== action.data.value) {
          DSM.last(
            '/v1/orders/filters/competitions/',
            {
              method: 'GET',
              message: 'loadOrdersCompetitions',
              body: {
                competitionCountry: action.data.value,
              },
            },
            action.data.actions,
            'loadOrdersCompetitions'
          );
        }
      } else {
        state = state.remove(['options', 'competitions']);
      }
    }

    if (action.data.param === 'competitionId') {
      if (action.data.value) {
        let competitionCountry = state.get(['params', 'competitionId'], '');
        if (competitionCountry !== action.data.value) {
          DSM.last(
            '/v1/orders/filters/events/',
            {
              method: 'GET',
              message: 'loadOrdersEvents',
              body: {
                competitionId: action.data.value,
              },
            },
            action.data.actions,
            'loadOrdersEvents'
          );
        }
      } else {
        state = state.remove(['options', 'events']);
      }
    }

    if (action.data.param === 'search') {
      if (!action.data.value) {
        state = state.setIn(['params', 'wantedIds'], '');
      }
    }

    // if change filterrs reset page to 1 - solves a number of possible user confusions
    if (action.data.param !== 'page') {
      state = state.setIn(['params', 'page'], 0);
    }

    if (action.data.param) {
      if (typeof action.data.value === 'object') {
        action.data.value = fromJS(action.data.value);
      }
      state = state.setIn(['params', action.data.param], action.data.value);
    }

    if (action.data.instant) {
      let params = generateParams(state.get('params').toJS(), action.data.timezone, true);
      let hash = [];

      for (let param in params) {
        if (params[param]) {
          hash.push(param + '=' + params[param]);
        }
      }

      return state.set('setHashTo', hash.join('&'));
    } else {
      return state;
    }
  },

  //load the actual orders orders
  getOrders: (state, action) => {
    /* let's figure out some special bs params */
    let params = toCamelCaseAll(hashParams(), true);

    let paramsToSet = prepareParams({ ...params });
    let paramsToSend = generateParams(params, action.data.timezone);

    let headers = null;
    if (action.data.format) {
      headers = {};
      if (action.data.format === 'csv') {
        headers['Accept'] = 'text/csv';
      } else if (action.data.format === 'xls') {
        headers['Accept'] = 'application/vnd.ms-excel';
      }

      //remove pagination restrictions ... i hope
      delete paramsToSend['page'];
      paramsToSend['pageSize'] = config.pageSizes.historyOrdersFullExportPageSize;

      if (!paramsToSend['dateFrom']) {
        let d = new Date();
        d.setTime(
          d.getTime() - config.pageSizes.historyOrdersFullExportDaysAgo * 24 * 60 * 60 * 1000
        );
        paramsToSend['dateFrom'] = toStdDateTime(d, 'utc');
      }
    }

    if (!paramsToSend['orderBy']) {
      paramsToSend['orderBy'] = ['placement_time desc'];
    } else {
      paramsToSend['orderBy'] = [paramsToSend['orderBy']];
    }

    // MOL-1693: Don't allow mobile users to load more than 100 orders at a time.
    if (paramsToSend['pageSize']) {
      paramsToSend['pageSize'] = action.data.isMobile
        ? Math.min(
            config.pageSizes.historyOrdersMobileMaxPageSize,
            parseInt(paramsToSend['pageSize'])
          )
        : paramsToSend['pageSize'];
    }

    if (!paramsToSend.orderId) {
      DSM.last(
        `/v1/orders/${action.data.format && action.data.full ? 'export/' : ''}`,
        {
          method: 'GET',
          message: 'loadOrders',
          headers,
          downloadAs: action.data.format ? `history-orders.${action.data.format}` : '',
          body: paramsToSend,
          extras: {
            format: action.data.format,
          },
        },
        action.data.actions,
        'loadOrders'
      );
    } else {
      DSM.last(
        `/v1/orders/${params.orderId}/`,
        {
          method: 'GET',
          message: 'refreshOneOrder',
          headers,
          downloadAs: action.data.format ? `history-orders.${action.data.format}` : '',
          extras: {
            clean: true,
            format: action.data.format,
          },
        },
        action.data.actions,
        'loadOrders'
      );
    }

    state = state.set('params', fromJS(paramsToSet));

    if (!action.data.format) {
      return state.set('isLoading', true);
    } else {
      return state.set('isDownloading', action.data.format);
    }
  },

  //load one order in particular (based on ID)
  getOneOrder: (state, action) => {
    if (action.data.orderId) {
      DSM.last(
        `/v1/orders/${action.data.orderId}/`,
        {
          method: 'GET',
          message: 'refreshOneOrder',
          extras: {
            clean: true,
          },
        },
        action.data.actions,
        `refreshOneOrder/${action.data.orderId}`
      );
    }

    return state;
  },

  //handle order data
  loadOrders: (state, action) => {
    if (action.data.status === 'ok') {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', false);
        return state;
      }
      state = state.set('orders', fromJS(action.data.data));
    } else {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', true);
        return state;
      }
    }

    return state.mergeDeep({
      isLoading: false,
      firstLoad: true,
    });
  },

  //update order data for just one order
  refreshOneOrder: (state, action) => {
    if (action.data.status === 'ok') {
      if (action.data.extras.clean) {
        state = state.set('orders', fromJS([action.data.data]));
      } else {
        let orders = state.get('orders', null);
        if (orders) {
          orders = orders.update(
            orders.findIndex(function (item) {
              return item.get('orderId') === action.data.orderId;
            }),
            function (item) {
              if (item) {
                return item.mergeDeep(action.data.data);
              }
            }
          );
        }
        state = state.set('orders', orders);
      }

      return state.setIn(['isLoading'], false);
    } else {
      //handled by base
      return state;
    }
  },

  ////// ORDER INTERACTION

  //toggle if we're showing the bets or not
  toggleOrderBets: (state, action) => {
    let orders = state.get('orders', null);
    if (orders) {
      orders = orders.update(
        orders.findIndex(function (item) {
          return item.get('orderId') === action.data.orderId;
        }),
        function (item) {
          let isExp = item.get('isExpanded', false);
          return item.set('isExpanded', !isExp);
        }
      );
    }

    return state.set('orders', orders);
  },

  ////// POPULATE FILTER VALUES

  //get list of valid order countries
  getOrdersCountries: (state, action) => {
    DSM.last(
      '/v1/orders/filters/countries/',
      {
        method: 'GET',
        message: 'loadOrdersCountries',
      },
      action.data.actions,
      'loadOrdersCountries'
    );

    return state.set('isLoadingFilters', true);
  },

  //get a list of competitions base on country
  getOrdersCompetitions: (state, action) => {
    let competitionCountry = state.get(['params', 'competitionCountry'], '');
    if (competitionCountry) {
      DSM.last(
        '/v1/orders/filters/competitions/',
        {
          method: 'GET',
          message: 'loadOrdersCompetitions',
          body: {
            competitionCountry: state.get(['params', 'competitionCountry'], ''),
          },
        },
        action.data.actions,
        'loadOrdersCompetitions'
      );
    }

    return state.set('isLoadingFilters', true);
  },

  //get list of events based on competition
  getOrdersEvents: (state, action) => {
    let competitionId = state.get(['params', 'competitionId'], '');

    if (competitionId) {
      DSM.last(
        '/v1/orders/filters/competitions/',
        {
          method: 'GET',
          message: 'loadOrdersEvents',
          body: {
            competitionId,
          },
        },
        action.data.actions,
        'loadOrdersEvents'
      );
    }

    return state.set('isLoadingFilters', true);
  },

  //get list of order placers
  //(only agents and spy on group)
  getOrdersPlacers: (state, action) => {
    DSM.last(
      '/v1/orders/filters/placers/',
      {
        method: 'GET',
        message: 'loadOrdersPlacers',
      },
      action.data.actions,
      'loadOrdersPlacers'
    );

    return state.set('isLoadingFilters', true);
  },

  //handle orders countries
  loadOrdersCountries: (state, action) => {
    if (action.data.status === 'ok') {
      let prep = {};
      for (let country of action.data.data) {
        prep[country.competitionCountry] = country;
      }
      state = state.set('isLoadingFilters', false);
      return state.setIn(['options', 'countries'], fromJS(prep));
    } else {
      //handled by base
      return state.set('isLoadingFilters', false);
    }
  },

  //handle orders competitions
  loadOrdersCompetitions: (state, action) => {
    if (action.data.status === 'ok') {
      let prep = {};
      for (let comp of action.data.data) {
        prep[comp.competitionId] = comp;
      }
      state = state.set('isLoadingFilters', false);
      return state.setIn(['options', 'competitions'], fromJS(prep));
    } else {
      //handled by base
      return state.set('isLoadingFilters', false);
    }
  },

  //handle orders events
  loadOrdersEvents: (state, action) => {
    if (action.data.status === 'ok') {
      let prep = {};
      for (let event of action.data.data) {
        prep[event.eventId] = event;
      }
      state = state.set('isLoadingFilters', false);
      return state.setIn(['options', 'events'], fromJS(prep));
    } else {
      //handled by base
      return state.set('isLoadingFilters', false);
    }
  },

  //handle orders placers
  loadOrdersPlacers: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('isLoadingFilters', false);
      return state.setIn(['options', 'placers'], fromJS(action.data.data));
    } else {
      //handled by base
      return state.set('isLoadingFilters', false);
    }
  },

  //close download failed warning modal
  closeDownloadFailedModal: (state, _action) => {
    return state.set('isDownloadFailed', false);
  },

  ////// LOGOUT

  //reset some state
  logout: (state, _action) => {
    state = fromJS(INITIAL_STATE);
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

/** @format */

exports.default = {
  name: { id: 'sports.boxing', defaultMessage: 'Boxing' },
  shortName: { id: 'sports.boxing.short', defaultMessage: 'Boxing' },
  featuredCompetitions: ['62065'],
  featuredCompetitionsName: [],
  enabled: true,
  maxTimeout: 600,
  correlated: {},
  correlatedOrder: ['boxing'],
  trustScores: false,
  matchDataOpener: 'for,dnb,a/null',
  offerGroupsOrder: [
    'ml',
    'wdw',
    'ah',
    'ahou',
    'dnb',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    'timeWin,tp,all,ml': 'ml',
    'timeWin,tp,reg,wdw': 'wdw',
    'tennisMatch,all': 'wdw',
    'timeAh,tp,all': 'ah',
    'timeAhou,tp,all': 'ahou',
    'tennisAh,all,set': 'ah',
    'tennisAhou,all,game': 'ahou',
  },
};

/** @format */

exports.default = {
  minEvent: 3, //minimum events a parlay can have
  parlayAdjacentLines: 2, //what adjancet lines to allow in parlay mode
  allowedSports: ['fb', 'fb_ht', 'af', 'basket'],
  maxPrice: 90000, // ~ 5000EUR / .5GBP
  pricePenalty: 0.96, // flat price margin
  minStake: 0.5, //in GBP (matches min api order)
};

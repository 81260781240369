/** @format */

import config from '../config';

/**
 * Convert snake_case_string to camelCaseString
 * note: leading _ results in a uppercase start
 * note: double _ is replaced with single
 * @param {string} str - snake case
 * @return {string} - camel case
 */
function toCamelCase(str) {
  if (str) {
    return str.replace(/_\w/g, (m) => m[1].toUpperCase());
  } else {
    return str;
  }
}

/**
 * Convert snake_case_string to camelCaseString recursively on all object members
 * @param {Object} str - snake cased object
 * @param {boolean} deleteOrig - delete original keys ?
 * @return {Object} - camel cased object
 */
function toCamelCaseAll(obj, deleteOrig) {
  if (obj && typeof obj === 'object') {
    let keys = Object.keys(obj);
    for (let idx in keys) {
      let key = keys[idx];
      let temp = obj[key];

      let newKey;
      if (config.snakeCamelMap[key]) {
        newKey = config.snakeCamelMap[key];
      } else {
        newKey = toCamelCase(key);
        if (deleteOrig) {
          delete obj[key];
        }
      }

      obj[newKey] = toCamelCaseAll(temp, deleteOrig);
    }
  }

  return obj;
}

/**
 * Convert camelCaseString to snake_case_string
 * @param {string} str - camel case
 * @return {string} - snake case
 */
function toSnakeCase(str) {
  if (str) {
    return str
      .replace(/\.?([A-Z]+)/g, function (x, y) {
        return '_' + y.toLowerCase();
      })
      .replace(/^_/, '');
  } else {
    return str;
  }
}

/**
 * Convert camelCaseString to snake_case_string recursively on all object members
 * note: sadly we can't avoid deleting the object keys here because the API is picky af
 * @param {Object} str - camel cased object
 * @return {Object} - snake cased object
 */
function toSnakeCaseAll(obj) {
  if (obj && typeof obj === 'object') {
    let keys = Object.keys(obj);
    for (let idx in keys) {
      let key = keys[idx];
      if (isNaN(key)) {
        let temp = obj[key];
        delete obj[key];

        let newKey;
        if (config.snakeCamelMap[key]) {
          newKey = config.snakeCamelMap[key];
        } else {
          newKey = toSnakeCase(key);
        }

        obj[newKey] = toSnakeCaseAll(temp);
      }
    }
  }

  return obj;
}

export { toCamelCase, toSnakeCase, toCamelCaseAll, toSnakeCaseAll };

/** @format */

exports.default = {
  name: { id: 'sports.arf', defaultMessage: 'Australian Football' },
  shortName: { id: 'sports.arf.short', defaultMessage: 'Ausie Rules' },
  featuredCompetitions: [],
  featuredCompetitionsName: [],
  enabled: false, //we don't settle this sport
  maxTimeout: 600,
  correlated: {},
  correlatedOrder: ['arf'],
  trustScores: false,
  noDraws: true,
  matchDataOpener: 'for,tp,reg,ml,a/null',
  offerGroupsOrder: [
    //'timeWin,tp,all,mlssop',
    //'timeWin,thalf,1,mlssop',
    //'timeWin,thalf,2,mlssop',
    //'timeWin,tp,all,ml',
    'timeAh,tp,all',
    'timeAhou,tp,all',
    //'timeWin,tp,reg,wdw',
    'timeWin,tp,reg,ml',
    'timeAh,tp,reg',
    'timeAhou,tp,reg',
    'tahou,tp,all,h',
    'tahou,tp,all,a',
    //'timeWin,tperiod,1,wdw',
    //'timeAh,tperiod,1',
    //'timeAhou,tperiod,1',
    'timeWin,thalf,1,ml',
    'timeAhou,thalf,1',
    'timeAh,thalf,1',
    'tahou,thalf,1,h',
    'tahou,thalf,1,a',
    //'timeAhou,thalf,2',
    //'timeAh,thalf,2',
    //'tahou,thalf,2,h',
    //'tahou,thalf,2,a',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    ml: 'timeWin,tp,all,ml',
    ah: 'timeAh,tp,reg',
    ahou: 'timeAhou,tp,all',
    'tennisMatch,all': 'timeWin,tp,all,ml',
    'tennisAh,all,set': 'timeAh,tp,all',
    'tennisAhou,all,game': 'timeAhou,tp,all',
  },
};

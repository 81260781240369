/** @format */

//you might need to gradually increase these as browsers update themselves

exports.default = {
  chrome: '>70',
  chromium: '>70',
  firefox: '>60',
  opera: '>999', //none
  'internet explorer': '>13', //none
  safari: '>10',
  edge: '>17',
};

/** @format */

exports.default = {
  name: { id: 'sports.basket', defaultMessage: 'Basketball' },
  shortName: { id: 'sports.basket.short', defaultMessage: 'Basketball' },
  featuredCompetitions: ['1182', '1272', '1184'],
  featuredCompetitionsName: ['NBA', 'Europe Euroleague'],
  enabled: true,
  maxTimeout: 7200,
  correlated: {
    basket_ht: {
      sport: 'basket_ht',
      name: { id: 'sports.basketht', defaultMessage: 'Basketball: Half-Time' },
      shortName: { id: 'sports.basketht.short', defaultMessage: 'Basketball [HT]' },
      correlatedOrder: ['basket_ht', 'basket', 'basket_q1', 'basket_q2', 'basket_q2', 'basket_q4'], //for the purpose of sub-sport selection
    },
    basket_q1: {
      //we don't really support this but maybe in the future
      sport: 'basket_q1',
      name: { id: 'sports.basketq1', defaultMessage: 'Basketball: 1st Quarter' },
      shortName: { id: 'sports.basketq1.short', defaultMessage: 'Basketball [Q1]' },
      correlatedOrder: ['basket_q1', 'basket_q2', 'basket_ht', 'basket_q3', 'basket_q4', 'basket'], //for the purpose of sub-sport selection
    },
    basket_q2: {
      //we don't really support this but maybe in the future
      sport: 'basket_q2',
      name: { id: 'sports.basketq2', defaultMessage: 'Basketball: 2nd Quarter' },
      shortName: { id: 'sports.basketq2.short', defaultMessage: 'Basketball [Q2]' },
      correlatedOrder: ['basket_q2', 'basket_ht', 'basket_q3', 'basket_q4', 'basket'], //for the purpose of sub-sport selection
    },
    basket_q3: {
      //we don't really support this but maybe in the future
      sport: 'basket_q3',
      name: { id: 'sports.basketq3', defaultMessage: 'Basketball: 3rd Quarter' },
      shortName: { id: 'sports.basketq3.short', defaultMessage: 'Basketball [Q3]' },
      correlatedOrder: ['basket_q3', 'basket_q4', 'basket'], //for the purpose of sub-sport selection
    },
    basket_q4: {
      //we don't really support this but maybe in the future
      sport: 'basket_q4',
      name: { id: 'sports.basketq4', defaultMessage: 'Basketball: 4th Quarter' },
      shortName: { id: 'sports.basketq4.short', defaultMessage: 'Basketball [Q4]' },
      correlatedOrder: ['basket_q4', 'basket'], //for the purpose of sub-sport selection
    },
  },
  // correlatedOrder: ['basket', 'basket_q1', 'basket_q2', 'basket_ht', 'basket_q3', 'basket_q4'],
  correlatedOrder: ['basket', 'basket_ht'],
  trustScores: true,
  scoresFallbacks: {
    basket_ht: 'basket',
    basket_q1: 'basket',
    //don't add other quarters, these just work because of the nature of time
  },
  noDraws: true,
  matchDataOpener: 'for,ml,a/null',
  offerGroupsOrder: [
    'ml',
    'ah',
    'ahou',
    //'ou',
    'tahou,h',
    'tahou,a',
    'oe',
    //'timeWin,tp,all,ml',
    //'timeWin,tp,all,wdw',
    //'timeAh,tp,all',
    //'timeAhou,tp,all',
    //'timeAh,thalf,1',
    //'timeAh,thalf,2',
    //'timeAhou,thalf,1',
    //'timeAhou,thalf,2',
    //'timeWin,thalf,1,ml',
    //'timeWin,thalf,2,ml',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    wdw: 'ml',
    'timeWin,tp,all,ml': 'ml',
    'timeAh,tp,all': 'ah',
    'timeAhou,tp,all': 'ahou',
    'tennisMatch,all': 'ml',
    'tennisAh,all,set': 'ah',
    //'tennisAhou,all,game': 'ahou',
    //'timeWin,tp,reg,wdw': 'ml',
    //'timeOe,tp,all,oe': 'oe',
    //'timeOe,tp,reg,oe': 'oe',
    //'tennisOe,all,game': 'oe',
  },
};

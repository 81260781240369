/** @format */

//Data Stream Manager

import DataStream from './DataStream';
import config from '../config';

export default class DSM {
  static _dataStreams = {};

  /**
   * thin data stream creation wrapper; takes same params
   * @param {string} url - url to call
   * @param {Object} params - parameters
   * @param {Object} actions - map of actions
   * @return {DataStream} - data stream instance
   */
  static create(url, params, actions) {
    return new DataStream(url, params, actions);
  }

  /**
   * this effectively aborts the previous request by ignoring it
   * use this if only need the last request of a certain kind to trigger the action
   * for example you're navigating history/bets and a previous request is slow and suddenly returns
   * the agent side may have similar issues
   * in this case it would trigger the view population and leave the user in a confused state
   * the uid can be anything, you can use the action name since those are unique enough
   * @param {string} url - url to call
   * @param {Object} params - parameters
   * @param {Object} actions - map of actions
   * @param {string} uid - unique identifier used so a new stream can cancel and replace an old stream
   * @return {DataStream} - data stream instance
   */
  static last(url, body, actions, uid) {
    let ds = new DataStream(url, body, actions);
    if (uid) {
      if (DSM._dataStreams[uid]) {
        DSM._dataStreams[uid].stop();
      }
      DSM._dataStreams[uid] = ds;
    } else {
      console.warn(`DSM: take last ${url} without uid`);
    }

    //you shouldn't really do this, it's confusing
    if (body.interval && uid) {
      console.warn(`DSM: using interval (${body.interval}) and last (${uid}) for ${url}`);
    }

    return ds;
  }

  /**
   * return a specific data stream that is under the manager
   * @param {string} uid - stream unique id
   * @return {DataStream} - data stream instance
   */
  static get(uid) {
    return DSM._dataStreams[uid];
  }

  /**
   * stop a specific data stream that is under the manager
   * @param {string} uid - stream unique id
   */
  static stop(uid) {
    if (uid && DSM._dataStreams[uid]) {
      DSM._dataStreams[uid].stop();
      delete DSM._dataStreams[uid];
    }
  }

  /**
   * makes sure there is exactly one of these
   * cancels the previous one if one exists
   * this means we don't need seprate stream hash maps everywhere to keep track of things
   * @param {string} url - url to call
   * @param {Object} params - parameters
   * @param {Object} actions - map of actions
   * @param {string} uid - unique identifier used so a new stream can cancel and replace an old stream
   * @return {DataStream} - data stream instance
   */
  static ensureOne(url, body, actions, uid) {
    if (uid) {
      if (DSM._dataStreams[uid]) {
        DSM._dataStreams[uid].stop();
      }
      DSM._dataStreams[uid] = new DataStream(url, body, actions);
      return DSM._dataStreams[uid];
    } else {
      console.warn(`DSM: ensure one ${url} without uid`);
      return null;
    }
  }

  /**
   * send a payload to a specific data stream instance
   * @param {string} uid - unique identifier used to find the stream
   * @param {Object} data - payload
   * @return {Promise | WebSocket | number} - value of data stream sesnd
   */
  static send(uid, data) {
    if (DSM._dataStreams[uid]) {
      return DSM._dataStreams[uid].send(data);
    } else {
      console.warn(`DSM: cannot send to "${uid}" because it does not exist`);
      return null;
    }
  }

  /**
   * kill streams starting with a set of characters
   * @param {string} match - uses startsWith to match data stream uids
   */
  static stopAllStartingWith(match) {
    for (let uid in DSM._dataStreams) {
      if (uid.startsWith(match)) {
        DSM._dataStreams[uid].stop();
        delete DSM._dataStreams[uid];
      }
    }
  }

  /**
   * get all streams starting with a set of characters
   * @param {string} match - uses startsWith to match data stream uids
   * @return {Object} - array of data streams
   */
  static getAllStartingWith(match) {
    let ret = [];
    for (let uid in DSM._dataStreams) {
      if (uid.startsWith(match)) {
        ret.push(DSM._dataStreams[uid]);
      }
    }

    return ret;
  }

  /**
   * force interval request on a specific string
   * @param {string} uid - data stream id
   * @param {boolean} debounced - apply a specific debounce value (in ms)
   * @return {Promise | null | number} - result of interval call (see DataStream)
   */
  static forceIntervalRequest(uid, debounced) {
    if (uid && DSM._dataStreams[uid]) {
      return DSM._dataStreams[uid].forceIntervalRequest(debounced);
    } else {
      return null;
    }
  }
}

//hack to allow devs to inspect stuff
if (window && config.support.tools.leakDataStreamManager) {
  window._DSM = DSM;
}

/** @format */

exports.default = {
  name: { id: 'sports.hand', defaultMessage: 'Handball' }, //name
  shortName: { id: 'sports.hand.short', defaultMessage: 'Handball' }, //short name (used in mobile modes)
  featuredCompetitions: [], //feature competitions if name contains one of these
  featuredCompetitionsName: [],
  enabled: true, //is visible to normal users
  maxTimeout: 600, //maximum order expiry time
  correlated: {},
  correlatedOrder: ['hand'], //for the purpose of + symbol on own page, used in other places as well so should always be defined
  trustScores: false, //do we display the scores for this sport (also applies to subsports)
  noDraws: true, //this sport doesn't have draws (i.e. tennis, esports, etc)
  matchDataOpener: 'for,tp,reg,ml,a/null', //what bet type/handicap to use when opening match info window (because of offerhist)
  offerGroupsOrder: [
    'timeWin,tp,reg,ml',
    'timeWin,tp,reg,wdw',
    'timeAh,tp,reg',
    'timeAh,thalf,1',
    'timeAhou,tp,reg',
    'timeAhou,thalf,1',
    'tahou,tp,reg,h',
    'tahou,tp,reg,a',
    'tahou,thalf,1,h',
    'tahou,thalf,1,a',
    'win',
  ],
  //symphathetic bet types ... it's a 'good enough' interface remap that makes
  //some betypes have fallback columns. the point of this is that events in favorites
  //that have differents sports may now show some non-exact-matching bet types
  //the way to read is: <bet_type_I_lack> : <bet_type_I_have>
  sympBetTypes: {
    ml: 'timeWin,tp,reg,ml',
    wdw: 'timeWin,tp,reg,wdw',
    ah: 'timeAh,tp,reg',
    ahou: 'timeAhou,tp,reg',
    'tennisMatch,all': 'timeWin,tp,reg,ml',
    'tennisAh,all,set': 'timeWin,tp,reg,wdw',
    'tennisAh,all,game': 'timeAh,tp,reg',
    'tennisAhou,all,game': 'timeAhou,tp,reg',
  },
};

/** @format */

const Generators = require('../positionGrids').Generators

//tennis specific
//see: https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  'tennisMatch,all': {
    name: { id: 'groupNames.tennisMatch,all', defaultMessage: 'Moneyline' },
    shortName: { id: 'groupNames.short.tennisMatch,all', defaultMessage: '1/2' },
    handicap: false,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,all,vwhatever,{outcome}',
    position: { generator: Generators.ml, dimension: 'setsScore', size: 4 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      p1: {
        type: 'for,tset,all,vwhatever,p1',
        template: { id: 'betTypes.tennisMatch,all,p1', defaultMessage: '{home}' },
        columnName: { id: 'columnNames.tennisMatch,all,p1', defaultMessage: '1' },
      },
      p2: {
        type: 'for,tset,all,vwhatever,p2',
        template: { id: 'betTypes.tennisMatch,all,p2', defaultMessage: '{away}' },
        columnName: { id: 'columnNames.tennisMatch,all,p2', defaultMessage: '2' },
      },
    },
  },
  'tennisMatch,1': {
    name: { id: 'groupNames.tennisMatch,1', defaultMessage: 'Winner (1st set)' },
    shortName: { id: 'groupNames.short.tennisMatch,1', defaultMessage: 'Win (1S)' },
    handicap: false,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,1,vwhatever,{outcome}',
    position: { generator: Generators.ml, dimension: 'setScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,1,vwhatever,p1',
        template: { id: 'betTypes.tennisMatch,1,p1', defaultMessage: '{home} (1st set)' },
        columnName: { id: 'columnNames.tennisMatch,1,p1', defaultMessage: '1' },
      },
      p2: {
        type: 'for,tset,1,vwhatever,p2',
        template: { id: 'betTypes.tennisMatch,1,p2', defaultMessage: '{away} (1st set)' },
        columnName: { id: 'columnNames.tennisMatch,1,p2', defaultMessage: '2' },
      },
    },
  },
  'tennisMatch,2': {
    name: { id: 'groupNames.tennisMatch,2', defaultMessage: 'Winner (2nd set)' },
    shortName: { id: 'groupNames.short.tennisMatch,2', defaultMessage: 'Win (2S)' },
    handicap: false,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,2,vwhatever,{outcome}',
    position: { generator: Generators.ml, dimension: 'setScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,2,vwhatever,p1',
        template: { id: 'betTypes.tennisMatch,2,p1', defaultMessage: '{home} (2nd set)' },
        columnName: { id: 'columnNames.tennisMatch,2,p1', defaultMessage: '1' },
      },
      p2: {
        type: 'for,tset,2,vwhatever,p2',
        template: { id: 'betTypes.tennisMatch,2,p2', defaultMessage: '{away} (2nd set)' },
        columnName: { id: 'columnNames.tennisMatch,2,p2', defaultMessage: '2' },
      },
    },
  },
  'tennisMatch,3': {
    name: { id: 'groupNames.tennisMatch,3', defaultMessage: 'Winner (3rd set)' },
    shortName: { id: 'groupNames.short.tennisMatch,3', defaultMessage: 'Win (3S)' },
    handicap: false,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,3,vwhatever,{outcome}',
    position: { generator: Generators.ml, dimension: 'setScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,3,vwhatever,p1',
        template: { id: 'betTypes.tennisMatch,3,p1', defaultMessage: '{home} (3rd set)' },
        columnName: { id: 'columnNames.tennisMatch,3,p1', defaultMessage: '1' },
      },
      p2: {
        type: 'for,tset,3,vwhatever,p2',
        template: { id: 'betTypes.tennisMatch,3,p2', defaultMessage: '{away} (3rd set)' },
        columnName: { id: 'columnNames.tennisMatch,3,p2', defaultMessage: '2' },
      },
    },
  },
  'tennisMatch,4': {
    name: { id: 'groupNames.tennisMatch,4', defaultMessage: 'Winner (4th set)' },
    shortName: { id: 'groupNames.short.tennisMatch,4', defaultMessage: 'Win (4S)' },
    handicap: false,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,4,vwhatever,{outcome}',
    position: { generator: Generators.ml, dimension: 'setScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,4,vwhatever,p1',
        template: { id: 'betTypes.tennisMatch,4,p1', defaultMessage: '{home} (4th set)' },
        columnName: { id: 'columnNames.tennisMatch,4,p1', defaultMessage: '1' },
      },
      p2: {
        type: 'for,tset,4,vwhatever,p2',
        template: { id: 'betTypes.tennisMatch,4,p2', defaultMessage: '{away} (4th set)' },
        columnName: { id: 'columnNames.tennisMatch,4,p2', defaultMessage: '2' },
      },
    },
  },
  'tennisAh,all,set': {
    name: { id: 'groupNames.tennisAh,all,set', defaultMessage: 'Set Handicap' },
    shortName: { id: 'groupNames.short.tennisAh,all,set', defaultMessage: 'Set 1/2' },
    handicap: true,
    isAsian: true,
    canUseInAccas: true,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,all,vwhatever,set,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'setsScore', size: 4 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,all,vwhatever,set,ah,p1',
        template: { id: 'betTypes.tennisAh,all,set,p1', defaultMessage: '{home} {handicap} Sets' },
        columnName: { id: 'columnNames.tennisAh,all,set,p1', defaultMessage: 'A/P1' },
      },
      p2: {
        type: 'for,tset,all,vwhatever,set,ah,p2',
        template: { id: 'betTypes.tennisAh,all,set,p2', defaultMessage: '{away} {handicap} Sets' },
        columnName: { id: 'columnNames.tennisAh,all,set,p2', defaultMessage: 'A/P2' },
      },
    },
  },
  'tennisAh,all,game': {
    name: { id: 'groupNames.tennisAh,all,game', defaultMessage: 'Game Hcp.' },
    shortName: { id: 'groupNames.short.tennisAh,all,game', defaultMessage: 'Game 1/2' },
    handicap: true,
    isAsian: true,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,all,vwhatever,game,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'totalGameScore', size: 36 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,all,vwhatever,game,ah,p1',
        template: {
          id: 'betTypes.tennisAh,all,game,p1',
          defaultMessage: '{home} Games Handicap {handicap}',
        },
        columnName: { id: 'columnNames.tennisAh,all,game,p1', defaultMessage: 'A/P1' },
      },
      p2: {
        type: 'for,tset,all,vwhatever,game,ah,p2',
        template: {
          id: 'betTypes.tennisAh,all,game,p2',
          defaultMessage: '{away} Games Handicap {handicap}',
        },
        columnName: { id: 'columnNames.tennisAh,all,game,p2', defaultMessage: 'A/P2' },
      },
    },
  },
  'tennisAh,1,game': {
    name: { id: 'groupNames.tennisAh,1,game', defaultMessage: 'Game Hcp. (1st set)' },
    shortName: { id: 'groupNames.short.tennisAh,1,game', defaultMessage: 'Game 1/2 (1S)' },
    handicap: true,
    isAsian: true,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,1,vwhatever,game,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'setScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,1,vwhatever,game,ah,p1',
        template: {
          id: 'betTypes.tennisAh,1,game,p1',
          defaultMessage: '{home} Games Handicap {handicap} (1st set)',
        },
        columnName: { id: 'columnNames.tennisAh,1,game,p1', defaultMessage: 'A/P1' },
      },
      p2: {
        type: 'for,tset,1,vwhatever,game,ah,p2',
        template: {
          id: 'betTypes.tennisAh,1,game,p2',
          defaultMessage: '{away} Games Handicap {handicap} (1st set)',
        },
        columnName: { id: 'columnNames.tennisAh,1,game,p2', defaultMessage: 'A/P2' },
      },
    },
  },
  'tennisAh,2,game': {
    name: { id: 'groupNames.tennisAh,2,game', defaultMessage: 'Game Hcp. (2nd set)' },
    shortName: { id: 'groupNames.short.tennisAh,2,game', defaultMessage: 'Game 1/2 (2S)' },
    handicap: true,
    isAsian: true,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,2,vwhatever,game,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'setScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,2,vwhatever,game,ah,p1',
        template: {
          id: 'betTypes.tennisAh,2,game,p1',
          defaultMessage: '{home} Games Handicap {handicap} (2nd set)',
        },
        columnName: { id: 'columnNames.tennisAh,2,game,p1', defaultMessage: 'A/P1' },
      },
      p2: {
        type: 'for,tset,2,vwhatever,game,ah,p2',
        template: {
          id: 'betTypes.tennisAh,2,game,p2',
          defaultMessage: '{away} Games Handicap {handicap} (2nd set)',
        },
        columnName: { id: 'columnNames.tennisAh,2,game,p2', defaultMessage: 'A/P2' },
      },
    },
  },
  'tennisAh,3,game': {
    name: { id: 'groupNames.tennisAh,3,game', defaultMessage: 'Game Hcp. (3rd set)' },
    shortName: { id: 'groupNames.short.tennisAh,3,game', defaultMessage: 'Game 1/2 (3S)' },
    handicap: true,
    isAsian: true,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,3,vwhatever,game,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'setScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,3,vwhatever,game,ah,p1',
        template: {
          id: 'betTypes.tennisAh,3,game,p1',
          defaultMessage: '{home} Games Handicap {handicap} (3rd set)',
        },
        columnName: { id: 'columnNames.tennisAh,3,game,p1', defaultMessage: 'A/P1' },
      },
      p2: {
        type: 'for,tset,3,vwhatever,game,ah,p2',
        template: {
          id: 'betTypes.tennisAh,3,game,p2',
          defaultMessage: '{away} Games Handicap {handicap} (3rd set)',
        },
        columnName: { id: 'columnNames.tennisAh,3,game,p2', defaultMessage: 'A/P2' },
      },
    },
  },
  'tennisAh,4,game': {
    name: { id: 'groupNames.tennisAh,4,game', defaultMessage: 'Game Hcp. (4th set)' },
    shortName: { id: 'groupNames.short.tennisAh,4,game', defaultMessage: 'Game 1/2 (4S)' },
    handicap: true,
    isAsian: true,
    order: ['p1', 'p2'],
    betTypeTemplate: 'for,tset,4,vwhatever,game,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'setScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      p1: {
        type: 'for,tset,4,vwhatever,game,ah,p1',
        template: {
          id: 'betTypes.tennisAh,4,game,p1',
          defaultMessage: '{home} Games Handicap {handicap} (4th set)',
        },
        columnName: { id: 'columnNames.tennisAh,4,game,p1', defaultMessage: 'A/P1' },
      },
      p2: {
        type: 'for,tset,4,vwhatever,game,ah,p2',
        template: {
          id: 'betTypes.tennisAh,4,game,p2',
          defaultMessage: '{away} Games Handicap {handicap} (4th set)',
        },
        columnName: { id: 'columnNames.tennisAh,4,game,p2', defaultMessage: 'A/P2' },
      },
    },
  },
  'tennisAhou,all,game': {
    name: { id: 'groupNames.tennisAhou,all,game', defaultMessage: 'Total Games' },
    shortName: { id: 'groupNames.short.tennisAhou,all,game', defaultMessage: 'Games O/U' },
    handicap: true,
    isOverUnder: true,
    canUseInAccas: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tset,all,vwhatever,game,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'totalGameScore', size: 36 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tset,all,vwhatever,game,ahover',
        template: {
          id: 'betTypes.tennisAhou,all,game,over',
          defaultMessage: 'Total Games Over {handicap}',
        },
        columnName: { id: 'columnNames.tennisAhou,all,game,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tset,all,vwhatever,game,ahunder',
        template: {
          id: 'betTypes.tennisAhou,all,game,under',
          defaultMessage: 'Total Games Under {handicap}',
        },
        columnName: { id: 'columnNames.tennisAhou,all,game,under', defaultMessage: 'U' },
      },
    },
  },
  'tennisAhou,1,game': {
    name: { id: 'groupNames.tennisAhou,1,game', defaultMessage: 'Games O/U (1st set)' },
    shortName: { id: 'groupNames.short.tennisAhou,1,game', defaultMessage: 'Games O/U (1S)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tset,1,vwhatever,game,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'setScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tset,1,vwhatever,game,ahover',
        template: {
          id: 'betTypes.tennisAhou,1,game,over',
          defaultMessage: 'Games Over {handicap} (1st set)',
        },
        columnName: { id: 'columnNames.tennisAhou,1,game,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tset,1,vwhatever,game,ahunder',
        template: {
          id: 'betTypes.tennisAhou,1,game,under',
          defaultMessage: 'Games Under {handicap} (1st set)',
        },
        columnName: { id: 'columnNames.tennisAhou,1,game,under', defaultMessage: 'U' },
      },
    },
  },
  'tennisAhou,2,game': {
    name: { id: 'groupNames.tennisAhou,2,game', defaultMessage: 'Games O/U (2nd set)' },
    shortName: { id: 'groupNames.short.tennisAhou,2,game', defaultMessage: 'Games O/U (2S)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tset,2,vwhatever,game,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'setScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tset,2,vwhatever,game,ahover',
        template: {
          id: 'betTypes.tennisAhou,2,game,over',
          defaultMessage: 'Games Over {handicap} (2nd set)',
        },
        columnName: { id: 'columnNames.tennisAhou,2,game,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tset,2,vwhatever,game,ahunder',
        template: {
          id: 'betTypes.tennisAhou,2,game,under',
          defaultMessage: 'Games Under {handicap} (2nd set)',
        },
        columnName: { id: 'columnNames.tennisAhou,2,game,under', defaultMessage: 'U' },
      },
    },
  },
  'tennisAhou,3,game': {
    name: { id: 'groupNames.tennisAhou,3,game', defaultMessage: 'Games O/U (3rd set)' },
    shortName: { id: 'groupNames.short.tennisAhou,3,game', defaultMessage: 'Games O/U (3S)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tset,3,vwhatever,game,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'setScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tset,3,vwhatever,game,ahover',
        template: {
          id: 'betTypes.tennisAhou,3,game,over',
          defaultMessage: 'Games Over {handicap} (3rd set)',
        },
        columnName: { id: 'columnNames.tennisAhou,3,game,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tset,3,vwhatever,game,ahunder',
        template: {
          id: 'betTypes.tennisAhou,3,game,under',
          defaultMessage: 'Games Under {handicap} (3rd set)',
        },
        columnName: { id: 'columnNames.tennisAhou,3,game,under', defaultMessage: 'U' },
      },
    },
  },
  'tennisAhou,4,game': {
    name: { id: 'groupNames.tennisAhou,4,game', defaultMessage: 'Games O/U (4th set)' },
    shortName: { id: 'groupNames.short.tennisAhou,4,game', defaultMessage: 'Games O/U (4S)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tset,4,vwhatever,game,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'setScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tset,4,vwhatever,game,ahover',
        template: {
          id: 'betTypes.tennisAhou,4,game,over',
          defaultMessage: 'Games Over {handicap} (4th set)',
        },
        columnName: { id: 'columnNames.tennisAhou,4,game,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tset,4,vwhatever,game,ahunder',
        template: {
          id: 'betTypes.tennisAhou,4,game,under',
          defaultMessage: 'Games Under {handicap} (4th set)',
        },
        columnName: { id: 'columnNames.tennisAhou,4,game,under', defaultMessage: 'U' },
      },
    },
  },
  'tennisOe,all,game': {
    name: { id: 'groupNames.tennisOe,all,game', defaultMessage: 'Games Odd/Even' },
    shortName: { id: 'groupNames.short.tennisOe,all,game', defaultMessage: 'Odd/Even' },
    handicap: false,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,tset,all,vwhatever,game,{parity}',
    position: { generator: Generators.oe, dimension: 'totalGameScore', size: 36 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,tset,all,vwhatever,game,odd',
        template: { id: 'betTypes.tennisOe,all,game,odd', defaultMessage: 'Odd Games' },
        columnName: { id: 'columnNames.tennisOe,all,game,odd', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,tset,all,vwhatever,game,even',
        template: { id: 'betTypes.tennisOe,all,game,even', defaultMessage: 'Even Games' },
        columnName: { id: 'columnNames.tennisOe,all,game,even', defaultMessage: 'Even' },
      },
    },
  },
  'tennisOe,1,game': {
    name: { id: 'groupNames.tennisOe,1,game', defaultMessage: 'Games Odd/Even (1st set)' },
    shortName: { id: 'groupNames.short.tennisOe,1,game', defaultMessage: 'Odd/Even (1S)' },
    handicap: false,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,tset,1,vwhatever,game,{parity}',
    position: { generator: Generators.oe, dimension: 'setScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,tset,1,vwhatever,game,odd',
        template: { id: 'betTypes.tennisOe,1,game,odd', defaultMessage: 'Odd Games (1st set)' },
        columnName: { id: 'columnNames.tennisOe,1,game,odd', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,tset,1,vwhatever,game,even',
        template: { id: 'betTypes.tennisOe,1,game,even', defaultMessage: 'Even Games (1st set)' },
        columnName: { id: 'columnNames.tennisOe,1,game,even', defaultMessage: 'Even' },
      },
    },
  },
  'tennisOe,2,game': {
    name: { id: 'groupNames.tennisOe,2,game', defaultMessage: 'Games Odd/Even (2nd set)' },
    shortName: { id: 'groupNames.short.tennisOe,2,game', defaultMessage: 'Odd/Even (2S)' },
    handicap: false,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,tset,2,vwhatever,game,{parity}',
    position: { generator: Generators.oe, dimension: 'setScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,tset,2,vwhatever,game,odd',
        template: { id: 'betTypes.tennisOe,2,game,odd', defaultMessage: 'Odd Games (2nd set)' },
        columnName: { id: 'columnNames.tennisOe,2,game,odd', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,tset,2,vwhatever,game,even',
        template: { id: 'betTypes.tennisOe,2,game,even', defaultMessage: 'Even Games (2nd set)' },
        columnName: { id: 'columnNames.tennisOe,2,game,even', defaultMessage: 'Even' },
      },
    },
  },
  'tennisOe,3,game': {
    name: { id: 'groupNames.tennisOe,3,game', defaultMessage: 'Games Odd/Even (3rd set)' },
    shortName: { id: 'groupNames.short.tennisOe,3,game', defaultMessage: 'Odd/Even (3S)' },
    handicap: false,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,tset,3,vwhatever,game,{parity}',
    position: { generator: Generators.oe, dimension: 'setScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,tset,3,vwhatever,game,odd',
        template: { id: 'betTypes.tennisOe,3,game,odd', defaultMessage: 'Odd Games (3rd set)' },
        columnName: { id: 'columnNames.tennisOe,3,game,odd', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,tset,3,vwhatever,game,even',
        template: { id: 'betTypes.tennisOe,3,game,even', defaultMessage: 'Even Games (3rd set)' },
        columnName: { id: 'columnNames.tennisOe,3,game,even', defaultMessage: 'Even' },
      },
    },
  },
  'tennisOe,4,game': {
    name: { id: 'groupNames.tennisOe,4,game', defaultMessage: 'Games Odd/Even (4th set)' },
    shortName: { id: 'groupNames.short.tennisOe,4,game', defaultMessage: 'Odd/Even (4S)' },
    handicap: false,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,tset,4,vwhatever,game,{parity}',
    position: { generator: Generators.oe, dimension: 'setScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,tset,4,vwhatever,game,odd',
        template: { id: 'betTypes.tennisOe,4,game,odd', defaultMessage: 'Odd Games (4th set)' },
        columnName: { id: 'columnNames.tennisOe,4,game,odd', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,tset,4,vwhatever,game,even',
        template: { id: 'betTypes.tennisOe,4,game,even', defaultMessage: 'Even Games (4th set)' },
        columnName: { id: 'columnNames.tennisOe,4,game,even', defaultMessage: 'Even' },
      },
    },
  },
  'tennisCs,all,set': {
    name: { id: 'groupNames.tennisCs,all,set', defaultMessage: 'Set Score' },
    shortName: { id: 'groupNames.short.tennisCs,all,set', defaultMessage: 'Score' },
    handicap: true,
    score: true,
    order: ['*'],
    betTypeTemplate: 'for,tset,all,vwhatever,set,cs,{home},{away}',
    position: { generator: Generators.cs, dimension: 'setsScore', size: 4 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,tset,all,vwhatever,set,cs',
        template: { id: 'betTypes.tennisCs,all,set', defaultMessage: 'Set Score {handicap}' },
        columnName: null,
      },
    },
  },
  'tennisMatch,all,set,total': {
    name: { id: 'groupNames.tennisMatch,all,set,total', defaultMessage: 'Set Total' },
    shortName: { id: 'groupNames.short.tennisMatch,all,set,total', defaultMessage: 'Sets' },
    handicap: true,
    order: ['*'],
    betTypeTemplate:
      'for,tset,all,vwhatever,set,total,{min}|for,tset,all,vwhatever,set,total,{min},{max}',
    position: { generator: Generators.exactTotal, dimension: 'setsScore', size: 4 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,tset,all,vwhatever,set,total',
        template: {
          id: 'betTypes.tennisMatch,all,set,total',
          defaultMessage: 'Set Total {handicap}',
        },
        columnName: null,
      },
    },
  },
};

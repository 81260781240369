/** @format */

const Generators = require('../positionGrids').Generators;

//time period based (halves, quarters, etc... things like af and ru)
//see: https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  'tahou,tp,all,h': {
    name: { id: 'groupNames.tahou,tp,all,h', defaultMessage: 'Home Points' },
    shortName: { id: 'groupNames.short.tahou,tp,all,h', defaultMessage: 'Home O/U' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tp,all,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tp,all,tahover,h',
        template: {
          id: 'betTypes.tahou,tp,all,h,over',
          defaultMessage: '{home} to score Over {handicap}',
        },
        columnName: { id: 'columnNames.tahou,tp,all,h,over', defaultMessage: '1/O' },
      },
      under: {
        type: 'for,tp,all,tahunder,h',
        template: {
          id: 'betTypes.tahou,tp,all,h,under',
          defaultMessage: '{home} to score Under {handicap}',
        },
        columnName: { id: 'columnNames.tahou,tp,all,h,under', defaultMessage: '1/U' },
      },
    },
  },
  'tahou,tp,all,a': {
    name: { id: 'groupNames.tahou,tp,all,a', defaultMessage: 'Away Points' },
    shortName: { id: 'groupNames.short.tahou,tp,all,a', defaultMessage: 'Away O/U' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tp,all,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tp,all,tahover,a',
        template: {
          id: 'betTypes.tahou,tp,all,a,over',
          defaultMessage: '{away} to score Over {handicap}',
        },
        columnName: { id: 'columnNames.tahou,tp,all,a,over', defaultMessage: '1/O' },
      },
      under: {
        type: 'for,tp,all,tahunder,a',
        template: {
          id: 'betTypes.tahou,tp,all,a,under',
          defaultMessage: '{away} to score Under {handicap}',
        },
        columnName: { id: 'columnNames.tahou,tp,all,a,under', defaultMessage: '1/U' },
      },
    },
  },
  'tahou,tp,reg,h': {
    name: { id: 'groupNames.tahou,tp,reg,h', defaultMessage: 'Home Points (Reg)' },
    shortName: { id: 'groupNames.short.tahou,tp,reg,h', defaultMessage: 'Home O/U' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tp,reg,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tp,reg,tahover,h',
        template: {
          id: 'betTypes.tahou,tp,reg,h,over',
          defaultMessage: '{home} to score Over {handicap} (Regular Time)',
        },
        columnName: { id: 'columnNames.tahou,tp,reg,h,over', defaultMessage: '1/O' },
      },
      under: {
        type: 'for,tp,reg,tahunder,h',
        template: {
          id: 'betTypes.tahou,tp,reg,h,under',
          defaultMessage: '{home} to score Under {handicap} (Regular Time)',
        },
        columnName: { id: 'columnNames.tahou,tp,reg,h,under', defaultMessage: '1/U' },
      },
    },
  },
  'tahou,tp,reg,a': {
    name: { id: 'groupNames.tahou,tp,reg,a', defaultMessage: 'Away Points (Reg)' },
    shortName: { id: 'groupNames.short.tahou,tp,reg,a', defaultMessage: 'Away O/U' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tp,reg,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tp,reg,tahover,a',
        template: {
          id: 'betTypes.tahou,tp,reg,a,over',
          defaultMessage: '{away} to score Over {handicap} (Regular Time)',
        },
        columnName: { id: 'columnNames.tahou,tp,reg,a,over', defaultMessage: '1/O' },
      },
      under: {
        type: 'for,tp,reg,tahunder,a',
        template: {
          id: 'betTypes.tahou,tp,reg,a,under',
          defaultMessage: '{away} to score Under {handicap} (Regular Time)',
        },
        columnName: { id: 'columnNames.tahou,tp,reg,a,under', defaultMessage: '1/U' },
      },
    },
  },
  'timeWin,tp,all,ml': {
    name: { id: 'groupNames.timeWin,tp,all,ml', defaultMessage: 'Moneyline' },
    shortName: { id: 'groupNames.short.timeWin,tp,all,ml', defaultMessage: '1/2' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tp,all,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      h: {
        type: 'for,tp,all,ml,h',
        template: { id: 'betTypes.timeWin,tp,all,ml,h', defaultMessage: '{home}' },
        columnName: { id: 'columnNames.timeWin,tp,all,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tp,all,ml,a',
        template: { id: 'betTypes.timeWin,tp,all,ml,a', defaultMessage: '{away}' },
        columnName: { id: 'columnNames.timeWin,tp,all,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tp,all,mlssop': {
    //this may not be settle-able, check bet types
    name: { id: 'groupNames.timeWin,tp,all,mlssop', defaultMessage: 'Moneyline (draw half push)' },
    shortName: { id: 'groupNames.short.timeWin,tp,all,mlssop', defaultMessage: '1/2 (dhp)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tp,all,mlssop,{outcome}',
    position: { generator: Generators.ml, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tp,all,mlssop,h',
        template: {
          id: 'betTypes.timeWin,tp,all,mlssop,h',
          defaultMessage: '{home} (draw half push)',
        },
        columnName: { id: 'columnNames.timeWin,tp,all,mlssop,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tp,all,mlssop,a',
        template: {
          id: 'betTypes.timeWin,tp,all,mlssop,a',
          defaultMessage: '{away} (draw half push)',
        },
        columnName: { id: 'columnNames.timeWin,tp,all,mlssop,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,thalf,1,mlssop': {
    //this may not be settle-able, check bet types
    name: {
      id: 'groupNames.timeWin,thalf,1,mlssop',
      defaultMessage: 'Moneyline (1st half) (draw half push)',
    },
    shortName: { id: 'groupNames.short.timeWin,thalf,1,mlssop', defaultMessage: '1/2 (1h) (dhp)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,thalf,1,mlssop,{outcome}',
    position: { generator: Generators.ml, dimension: 'htScore.0', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,thalf,1,mlssop,h',
        template: {
          id: 'betTypes.timeWin,thalf,1,mlssop,h',
          defaultMessage: '{home} (1st half) (draw half push)',
        },
        columnName: { id: 'columnNames.timeWin,thalf,1,mlssop,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,thalf,1,mlssop,a',
        template: {
          id: 'betTypes.timeWin,thalf,1,mlssop,a',
          defaultMessage: '{away} (1st half) (draw half push)',
        },
        columnName: { id: 'columnNames.timeWin,thalf,1,mlssop,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,thalf,2,mlssop': {
    //this may not be settle-able, check bet types
    name: {
      id: 'groupNames.timeWin,thalf,2,mlssop',
      defaultMessage: 'Moneyline (2nd half) (draw half push)',
    },
    shortName: { id: 'groupNames.short.timeWin,thalf,2,mlssop', defaultMessage: '1/2 (2h) (dhp)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,thalf,2,mlssop,{outcome}',
    position: { generator: Generators.ml, dimension: 'htScore.1', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,thalf,2,mlssop,h',
        template: {
          id: 'betTypes.timeWin,thalf,2,mlssop,h',
          defaultMessage: '{home} (2nd half) (draw half push)',
        },
        columnName: { id: 'columnNames.timeWin,thalf,2,mlssop,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,thalf,2,mlssop,a',
        template: {
          id: 'betTypes.timeWin,thalf,2,mlssop,a',
          defaultMessage: '{away} (2nd half) (draw half push)',
        },
        columnName: { id: 'columnNames.timeWin,thalf,2,mlssop,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tp,reg,ml': {
    name: { id: 'groupNames.timeWin,tp,reg,ml', defaultMessage: 'Moneyline (reg. time)' },
    shortName: { id: 'groupNames.short.timeWin,tp,reg,ml', defaultMessage: '1/2 (reg)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tp,reg,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tp,reg,ml,h',
        template: { id: 'betTypes.timeWin,tp,reg,ml,h', defaultMessage: '{home} (regular time)' },
        columnName: { id: 'columnNames.timeWin,tp,reg,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tp,reg,ml,a',
        template: { id: 'betTypes.timeWin,tp,reg,ml,a', defaultMessage: '{away} (regular time)' },
        columnName: { id: 'columnNames.timeWin,tp,reg,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tp,reg,wdw': {
    name: { id: 'groupNames.timeWin,tp,reg,wdw', defaultMessage: '1 X 2 (reg. time)' },
    shortName: { id: 'groupNames.short.timeWin,tp,reg,wdw', defaultMessage: '1/X/2 (reg)' },
    handicap: false,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,tp,reg,wdw,{outcome}',
    position: { generator: Generators.wdw, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      h: {
        type: 'for,tp,reg,wdw,h',
        template: { id: 'betTypes.timeWin,tp,reg,wdw,h', defaultMessage: '{home} (regular time)' },
        columnName: { id: 'columnNames.timeWin,tp,reg,wdw,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,tp,reg,wdw,d',
        template: { id: 'betTypes.timeWin,tp,reg,wdw,d', defaultMessage: 'Draw (regular time)' },
        columnName: { id: 'columnNames.timeWin,tp,reg,wdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,tp,reg,wdw,a',
        template: { id: 'betTypes.timeWin,tp,reg,wdw,a', defaultMessage: '{away} (regular time)' },
        columnName: { id: 'columnNames.timeWin,tp,reg,wdw,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tp,reg,dnb': {
    name: {
      id: 'groupNames.timeWin,tp,reg,dnb',
      defaultMessage: 'Moneyline (reg. time, draw no bet)',
    },
    shortName: { id: 'groupNames.short.timeWin,tp,reg,dnb', defaultMessage: '1/2 (reg, dnb)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tp,reg,dnb,{outcome}',
    position: { generator: Generators.dnb, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tp,reg,dnb,h',
        template: {
          id: 'betTypes.timeWin,tp,reg,dnb,h',
          defaultMessage: '{home} (regular time, draw no bet)',
        },
        columnName: { id: 'columnNames.timeWin,tp,reg,dnb,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tp,reg,dnb,a',
        template: {
          id: 'betTypes.timeWin,tp,reg,dnb,a',
          defaultMessage: '{away} (regular time, draw no bet)',
        },
        columnName: { id: 'columnNames.timeWin,tp,reg,dnb,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tp,all,wdw': {
    name: { id: 'groupNames.timeWin,tp,all,wdw', defaultMessage: '1 X 2' },
    shortName: { id: 'groupNames.short.timeWin,tp,all,wdw', defaultMessage: '1/X/2' },
    handicap: false,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,tp,all,wdw,{outcome}',
    position: { generator: Generators.wdw, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      h: {
        type: 'for,tp,all,wdw,h',
        template: { id: 'betTypes.timeWin,tp,all,wdw,h', defaultMessage: '{home}' },
        columnName: { id: 'columnNames.timeWin,tp,all,wdw,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,tp,all,wdw,d',
        template: { id: 'betTypes.timeWin,tp,all,wdw,d', defaultMessage: 'Draw' },
        columnName: { id: 'columnNames.timeWin,tp,all,wdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,tp,all,wdw,a',
        template: { id: 'betTypes.timeWin,tp,all,wdw,a', defaultMessage: '{away}' },
        columnName: { id: 'columnNames.timeWin,tp,all,wdw,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tp,all,dnb': {
    name: { id: 'groupNames.timeWin,tp,all,dnb', defaultMessage: '1 X 2 (draw no bet)' },
    shortName: { id: 'groupNames.short.timeWin,tp,all,dnb', defaultMessage: '1 X 2 (dnb)' },
    handicap: false,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,tp,all,dnb,{outcome}',
    position: { generator: Generators.dnb, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tp,all,dnb,h',
        template: { id: 'betTypes.timeWin,tp,all,dnb,h', defaultMessage: '{home} (draw no bet)' },
        columnName: { id: 'columnNames.timeWin,tp,all,dnb,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,tp,all,dnb,d',
        template: { id: 'betTypes.timeWin,tp,all,dnb,d', defaultMessage: 'Draw (draw no bet)' },
        columnName: { id: 'columnNames.timeWin,tp,all,dnb,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,tp,all,dnb,a',
        template: { id: 'betTypes.timeWin,tp,all,dnb,a', defaultMessage: '{away} (draw no bet)' },
        columnName: { id: 'columnNames.timeWin,tp,all,dnb,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,thalf,1,ml': {
    name: { id: 'groupNames.timeWin,thalf,1,ml', defaultMessage: 'Moneyline (1st half)' },
    shortName: { id: 'groupNames.short.timeWin,thalf,1,ml', defaultMessage: '1/2 (1H)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,thalf,1,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'htScore.0', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,thalf,1,ml,h',
        template: { id: 'betTypes.timeWin,thalf,1,ml,h', defaultMessage: '{home} (1st half)' },
        columnName: { id: 'columnNames.timeWin,thalf,1,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,thalf,1,ml,a',
        template: { id: 'betTypes.timeWin,thalf,1,ml,a', defaultMessage: '{away} (1st half)' },
        columnName: { id: 'columnNames.timeWin,thalf,1,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,thalf,2,ml': {
    name: { id: 'groupNames.timeWin,thalf,2,ml', defaultMessage: 'Moneyline (2nd half)' },
    shortName: { id: 'groupNames.short.timeWin,thalf,2,ml', defaultMessage: '1/2 (2H)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,thalf,2,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'htScore.1', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,thalf,2,ml,h',
        template: { id: 'betTypes.timeWin,thalf,2,ml,h', defaultMessage: '{home} (2nd half)' },
        columnName: { id: 'columnNames.timeWin,thalf,2,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,thalf,2,ml,a',
        template: { id: 'betTypes.timeWin,thalf,2,ml,a', defaultMessage: '{away} (2nd half)' },
        columnName: { id: 'columnNames.timeWin,thalf,2,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,thalf,1,wdw': {
    name: { id: 'groupNames.timeWin,thalf,1,wdw', defaultMessage: '1 X 2 (1st half)' },
    shortName: { id: 'groupNames.short.timeWin,thalf,1,wdw', defaultMessage: '1/X/2 (1H)' },
    handicap: false,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,thalf,1,wdw,{outcome}',
    position: { generator: Generators.wdw, dimension: 'htScore.0', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,thalf,1,wdw,h',
        template: { id: 'betTypes.timeWin,thalf,1,wdw,h', defaultMessage: '{home} (1st half)' },
        columnName: { id: 'columnNames.timeWin,thalf,1,wdw,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,thalf,1,wdw,d',
        template: { id: 'betTypes.timeWin,thalf,1,wdw,d', defaultMessage: 'Draw (1st half)' },
        columnName: { id: 'columnNames.timeWin,thalf,1,wdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,thalf,1,wdw,a',
        template: { id: 'betTypes.timeWin,thalf,1,wdw,a', defaultMessage: '{away} (1st half)' },
        columnName: { id: 'columnNames.timeWin,thalf,1,wdw,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,thalf,2,wdw': {
    name: { id: 'groupNames.timeWin,thalf,2,wdw', defaultMessage: '1 X 2 (2nd half)' },
    shortName: { id: 'groupNames.short.timeWin,thalf,2,wdw', defaultMessage: '1/X/2 (2H)' },
    handicap: false,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,thalf,2,wdw,{outcome}',
    position: { generator: Generators.wdw, dimension: 'htScore.1', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,thalf,2,wdw,h',
        template: { id: 'betTypes.timeWin,thalf,2,wdw,h', defaultMessage: '{away} (2nd half)' },
        columnName: { id: 'columnNames.timeWin,thalf,2,wdw,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,thalf,2,wdw,d',
        template: { id: 'betTypes.timeWin,thalf,2,wdw,d', defaultMessage: 'Draw (2nd half)' },
        columnName: { id: 'columnNames.timeWin,thalf,2,wdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,thalf,2,wdw,a',
        template: { id: 'betTypes.timeWin,thalf,2,wdw,a', defaultMessage: '{home} (2nd half)' },
        columnName: { id: 'columnNames.timeWin,thalf,2,wdw,a', defaultMessage: '2' },
      },
    },
  },
  'timeAhou,tp,all': {
    name: { id: 'groupNames.timeAhou,tp,all', defaultMessage: 'Asian O/U (inc. overtime)' },
    shortName: { id: 'groupNames.short.timeAhou,tp,all', defaultMessage: 'Asian O/U (+ot)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tp,all,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      over: {
        type: 'for,tp,all,ahover',
        template: {
          id: 'betTypes.timeAhou,tp,all,over',
          defaultMessage: 'Over {handicap} (Asian, including overtime)',
        },
        columnName: { id: 'columnNames.timeAhou,tp,all,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tp,all,ahunder',
        template: {
          id: 'betTypes.timeAhou,tp,all,under',
          defaultMessage: 'Under {handicap} (Asian, including overtime)',
        },
        columnName: { id: 'columnNames.timeAhou,tp,all,under', defaultMessage: 'U' },
      },
    },
  },
  'timeAhou,tp,reg': {
    name: { id: 'groupNames.timeAhou,tp,reg', defaultMessage: 'Asian O/U (reg. time)' },
    shortName: { id: 'groupNames.short.timeAhou,tp,reg', defaultMessage: 'Asian O/U (reg)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tp,reg,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      over: {
        type: 'for,tp,reg,ahover',
        template: {
          id: 'betTypes.timeAhou,tp,reg,over',
          defaultMessage: 'Over {handicap} (Asian, regular time)',
        },
        columnName: { id: 'columnNames.timeAhou,tp,reg,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tp,reg,ahunder',
        template: {
          id: 'betTypes.timeAhou,tp,reg,under',
          defaultMessage: 'Under {handicap} (Asian, regular time)',
        },
        columnName: { id: 'columnNames.timeAhou,tp,reg,under', defaultMessage: 'U' },
      },
    },
  },
  'timeAhou,thalf,1': {
    name: { id: 'groupNames.timeAhou,thalf,1', defaultMessage: 'Asian O/U (1st half)' },
    shortName: { id: 'groupNames.short.timeAhou,thalf,1', defaultMessage: 'Asian O/U (1H)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,thalf,1,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'htScore.0', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,thalf,1,ahover',
        template: {
          id: 'betTypestimeAhou,thalf,1,over',
          defaultMessage: 'Over {handicap} (Asian, 1st half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,1,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,thalf,1,ahunder',
        template: {
          id: 'betTypes.timeAhou,thalf,1,under',
          defaultMessage: 'Under {handicap} (Asian, 1st half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,1,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,thalf,1,h': {
    name: { id: 'groupNames.timeAhou,thalf,1,h', defaultMessage: 'Home Score (1st half)' },
    shortName: { id: 'groupNames.short.timeAhou,thalf,1,h', defaultMessage: 'Home O/U (1H)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,thalf,1,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'htScore.0', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,thalf,1,tahover,h',
        template: {
          id: 'betTypes.tahou,thalf,1,h,over',
          defaultMessage: '{home} to score Over {handicap} (1st Half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,1,h,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,thalf,1,tahunder,h',
        template: {
          id: 'betTypes.tahou,thalf,1,h,under',
          defaultMessage: '{home} to score Under {handicap} (1st Half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,1,h,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,thalf,1,a': {
    name: { id: 'groupNames.timeAhou,thalf,1,a', defaultMessage: 'Away Score (1st half)' },
    shortName: { id: 'groupNames.short.timeAhou,thalf,1,a', defaultMessage: 'Away O/U (1H)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,thalf,1,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'htScore.0', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,thalf,1,tahover,a',
        template: {
          id: 'betTypes.tahou,thalf,1,a,over',
          defaultMessage: '{away} to score Over {handicap} (1st Half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,1,a,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,thalf,1,tahunder,a',
        template: {
          id: 'betTypes.tahou,thalf,1,a,under',
          defaultMessage: '{away} to score Under {handicap} (1st Half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,1,a,under', defaultMessage: 'U' },
      },
    },
  },
  'timeAhou,thalf,2': {
    name: { id: 'groupNames.timeAhou,thalf,2', defaultMessage: 'Asian O/U (2nd half)' },
    shortName: { id: 'groupNames.short.timeAhou,thalf,2', defaultMessage: 'Asian O/U (2H)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,thalf,2,ah{direction},{line}',
    position: { generator: Generators.tahou, dimension: 'htScore.1', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,thalf,2,ahover',
        template: {
          id: 'betTypes.timeAhou,thalf,2,over',
          defaultMessage: 'Over {handicap} (Asian, 2nd half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,2,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,thalf,2,ahunder',
        template: {
          id: 'betTypes.timeAhou,thalf,2,under',
          defaultMessage: 'Under {handicap} (Asian, 2nd half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,2,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,thalf,2,h': {
    name: { id: 'groupNames.timeAhou,thalf,2,h', defaultMessage: 'Home Score (2nd half)' },
    shortName: { id: 'groupNames.short.timeAhou,thalf,2,h', defaultMessage: 'Home O/U (1H)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,thalf,2,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'htScore.1', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,thalf,2,tahover,h',
        template: {
          id: 'betTypes.tahou,thalf,2,h,over',
          defaultMessage: '{home} to score Over {handicap} (2nd Half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,2,h,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,thalf,2,tahunder,h',
        template: {
          id: 'betTypes.tahou,thalf,2,h,under',
          defaultMessage: '{home} to score Under {handicap} (2nd Half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,2,h,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,thalf,2,a': {
    name: { id: 'groupNames.timeAhou,thalf,2,a', defaultMessage: 'Away Score (2nd half)' },
    shortName: { id: 'groupNames.short.timeAhou,thalf,2,a', defaultMessage: 'Away O/U (1H)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,thalf,2,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'htScore.1', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,thalf,2,tahover,a',
        template: {
          id: 'betTypes.tahou,thalf,2,a,over',
          defaultMessage: '{away} to score Over {handicap} (2nd Half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,2,a,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,thalf,2,tahunder,a',
        template: {
          id: 'betTypes.tahou,thalf,2,a,under',
          defaultMessage: '{away} to score Under {handicap} (2nd Half)',
        },
        columnName: { id: 'columnNames.timeAhou,thalf,2,a,under', defaultMessage: 'U' },
      },
    },
  },
  'timeAhou,tperiod,1': {
    name: { id: 'groupNames.timeAhou,tperiod,1', defaultMessage: 'Asian O/U (1st period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,1', defaultMessage: 'Asian O/U (1P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,1,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'periodScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,1,ahover',
        template: {
          id: 'betTypes.timeAhou,tperiod,1,over',
          defaultMessage: 'Over {handicap} (Asian, 1st period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,1,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,1,ahunder',
        template: {
          id: 'betTypes.timeAhou,tperiod,1,under',
          defaultMessage: 'Under {handicap} (Asian, 1st period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,1,under', defaultMessage: 'U' },
      },
    },
  },
  'timeAhou,tperiod,2': {
    name: { id: 'groupNames.timeAhou,tperiod,2', defaultMessage: 'Asian O/U (2nd period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,2', defaultMessage: 'Asian O/U (2P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,2,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'periodScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,2,ahover',
        template: {
          id: 'betTypes.timeAhou,tperiod,2,over',
          defaultMessage: 'Over {handicap} (Asian, 2nd period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,2,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,2,ahunder',
        template: {
          id: 'betTypes.timeAhou,tperiod,2,under',
          defaultMessage: 'Under {handicap} (Asian, 2nd period)',
        },
        columnName: { id: 'columnNamestimeAhou,tperiod,2,under', defaultMessage: 'U' },
      },
    },
  },
  'timeAhou,tperiod,3': {
    name: { id: 'groupNames.timeAhou,tperiod,3', defaultMessage: 'Asian O/U (3rd period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,3', defaultMessage: 'Asian O/U (3P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,3,ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'periodScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,3,ahover',
        template: {
          id: 'betTypes.timeAhou,tperiod,3,over',
          defaultMessage: 'Over {handicap} (Asian, 3rd period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,3,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,3,ahunder',
        template: {
          id: 'betTypes.timeAhou,tperiod,3,under',
          defaultMessage: 'Under {handicap} (Asian, 3rd period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,3,under', defaultMessage: 'U' },
      },
    },
  },
  'timeAh,tp,all': {
    name: { id: 'groupNames.timeAh,tp,all', defaultMessage: 'Asian Hcp.' },
    shortName: { id: 'groupNames.short.timeAh,tp,all', defaultMessage: 'Asian Hcp.' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tp,all,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      h: {
        type: 'for,tp,all,ah,h',
        template: { id: 'betTypes.timeAh,tp,all,h', defaultMessage: '{home} {handicap}' },
        columnName: { id: 'columnNames.timeAh,tp,all,h', defaultMessage: 'A/1' },
      },
      a: {
        type: 'for,tp,all,ah,a',
        template: { id: 'betTypes.timeAh,tp,all,a', defaultMessage: '{away} {handicap}' },
        columnName: { id: 'columnNames.timeAh,tp,all,a', defaultMessage: 'A/2' },
      },
    },
  },
  'timeAh,tp,reg': {
    name: { id: 'groupNames.timeAh,tp,reg', defaultMessage: 'Asian Hcp. (reg. time)' },
    shortName: { id: 'groupNames.short.timeAh,tp,reg', defaultMessage: 'Asian (reg)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tp,reg,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      h: {
        type: 'for,tp,reg,ah,h',
        template: {
          id: 'betTypes.timeAh,tp,reg,h',
          defaultMessage: '{home} {handicap} (Asian, regular time)',
        },
        columnName: { id: 'columnNames.timeAh,tp,reg,h', defaultMessage: 'A/1' },
      },
      a: {
        type: 'for,tp,reg,ah,a',
        template: {
          id: 'betTypes.timeAh,tp,reg,a',
          defaultMessage: '{away} {handicap} (Asian, regular time)',
        },
        columnName: { id: 'columnNames.timeAh,tp,reg,a', defaultMessage: 'A/2' },
      },
    },
  },
  'timeAh,thalf,1': {
    name: { id: 'groupNames.timeAh,thalf,1', defaultMessage: 'Asian Hcp. (1st half)' },
    shortName: { id: 'groupNames.short.timeAh,thalf,1', defaultMessage: 'Asian (1H)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,thalf,1,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'htScore.0', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,thalf,1,ah,h',
        template: {
          id: 'betTypes.timeAh,thalf,1,h',
          defaultMessage: '{home} {handicap} (Asian, 1st half)',
        },
        columnName: { id: 'columnNames.timeAh,thalf,1,h', defaultMessage: 'A/1' },
      },
      a: {
        type: 'for,thalf,1,ah,a',
        template: {
          id: 'betTypes.timeAh,thalf,1,a',
          defaultMessage: '{away} {handicap} (Asian, 1st half)',
        },
        columnName: { id: 'columnNames.timeAh,thalf,1,a', defaultMessage: 'A/2' },
      },
    },
  },
  'timeAh,thalf,2': {
    name: { id: 'groupNames.timeAh,thalf,2', defaultMessage: 'Asian Hcp. (2nd half)' },
    shortName: { id: 'groupNames.short.timeAh,thalf,2', defaultMessage: 'Asian (2H)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,thalf,2,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'htScore.1', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,thalf,2,ah,h',
        template: {
          id: 'betTypes.timeAh,thalf,2,h',
          defaultMessage: '{home} {handicap} (Asian, 2nd half)',
        },
        columnName: { id: 'columnNames.timeAh,thalf,2,h', defaultMessage: 'A/1' },
      },
      a: {
        type: 'for,thalf,2,ah,a',
        template: {
          id: 'betTypes.timeAh,thalf,2,a',
          defaultMessage: '{away} {handicap} (Asian, 2nd half)',
        },
        columnName: { id: 'columnNames.timeAh,thalf,2,a', defaultMessage: 'A/2' },
      },
    },
  },
  'timeAh,tperiod,1': {
    name: { id: 'groupNames.timeAh,tperiod,1', defaultMessage: 'Asian Hcp. (1st period)' },
    shortName: { id: 'groupNames.short.timeAh,tperiod,1', defaultMessage: 'Asian (1P)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tperiod,1,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'periodScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,1,ah,h',
        template: {
          id: 'betTypes.timeAh,tperiod,1,h',
          defaultMessage: '{home} {handicap} (Asian, 1st period)',
        },
        columnName: { id: 'columnNames.timeAh,tperiod,1,h', defaultMessage: 'A/1' },
      },
      a: {
        type: 'for,tperiod,1,ah,a',
        template: {
          id: 'betTypes.timeAh,tperiod,1,a',
          defaultMessage: '{away} {handicap} (Asian, 1st period)',
        },
        columnName: { id: 'columnNames.timeAh,tperiod,1,a', defaultMessage: 'A/2' },
      },
    },
  },
  'timeAh,tperiod,2': {
    name: { id: 'groupNames.timeAh,tperiod,2', defaultMessage: 'Asian Hcp. (2nd period)' },
    shortName: { id: 'groupNames.short.timeAh,tperiod,2', defaultMessage: 'Asian (2P)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tperiod,2,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'periodScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,2,ah,h',
        template: {
          id: 'betTypes.timeAh,tperiod,2,h',
          defaultMessage: '{home} {handicap} (Asian, 2nd period)',
        },
        columnName: { id: 'columnNames.timeAh,tperiod,2,h', defaultMessage: 'A/1' },
      },
      a: {
        type: 'for,tperiod,2,ah,a',
        template: {
          id: 'betTypes.timeAh,tperiod,2,a',
          defaultMessage: '{away} {handicap} (Asian, 2nd period)',
        },
        columnName: { id: 'columnNames.timeAh,tperiod,2,a', defaultMessage: 'A/2' },
      },
    },
  },
  'timeAh,tperiod,3': {
    name: { id: 'groupNames.timeAh,tperiod,3', defaultMessage: 'Asian Hcp. (3rd period)' },
    shortName: { id: 'groupNames.short.timeAh,tperiod,3', defaultMessage: 'Asian (3P)' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,tperiod,3,ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'periodScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,3,ah,h',
        template: {
          id: 'betTypes.timeAh,tperiod,3,h',
          defaultMessage: '{home} {handicap} (Asian, 3rd period)',
        },
        columnName: { id: 'columnNames.timeAh,tperiod,3,h', defaultMessage: 'A/1' },
      },
      a: {
        type: 'for,tperiod,3,ah,a',
        template: {
          id: 'betTypes.timeAh,tperiod,3,a',
          defaultMessage: '{away} {handicap} (Asian, 3rd period)',
        },
        columnName: { id: 'columnNames.timeAh,tperiod,3,a', defaultMessage: 'A/2' },
      },
    },
  },
  'timeWin,tperiod,1,wdw': {
    name: { id: 'groupNames.timeWin,tperiod,1,wdw', defaultMessage: '1 X 2 (1st period)' },
    shortName: { id: 'groupNames.short.timeWin,tperiod,1,wdw', defaultMessage: '1X2 (1P)' },
    handicap: false,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,tperiod,1,wdw,{outcome}',
    position: { generator: Generators.wdw, dimension: 'periodScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,1,wdw,h',
        template: { id: 'betTypes.timeWin,tperiod,1,wdw,h', defaultMessage: '{home} (1st period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,1,wdw,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,tperiod,1,wdw,d',
        template: { id: 'betTypes.timeWin,tperiod,1,wdw,d', defaultMessage: 'Draw (1st period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,1,wdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,tperiod,1,wdw,a',
        template: { id: 'betTypes.timeWin,tperiod,1,wdw,a', defaultMessage: '{away} (1st period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,1,wdw,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tperiod,2,wdw': {
    name: { id: 'groupNames.timeWin,tperiod,2,wdw', defaultMessage: '1 X 2 (2nd period)' },
    shortName: { id: 'groupNames.short.timeWin,tperiod,2,wdw', defaultMessage: '1X2 (2P)' },
    handicap: false,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,tperiod,2,wdw,{outcome}',
    position: { generator: Generators.wdw, dimension: 'periodScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,2,wdw,h',
        template: { id: 'betTypes.timeWin,tperiod,2,wdw,h', defaultMessage: '{home} (2nd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,2,wdw,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,tperiod,2,wdw,d',
        template: { id: 'betTypes.timeWin,tperiod,2,wdw,d', defaultMessage: 'Draw (2nd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,2,wdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,tperiod,2,wdw,a',
        template: { id: 'betTypes.timeWin,tperiod,2,wdw,a', defaultMessage: '{away} (2nd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,2,wdw,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tperiod,3,wdw': {
    name: { id: 'groupNames.timeWin,tperiod,3,wdw', defaultMessage: '1 X 2 (3rd period)' },
    shortName: { id: 'groupNames.short.timeWin,tperiod,3,wdw', defaultMessage: '1X2 (3P)' },
    handicap: false,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,tperiod,3,wdw,{outcome}',
    position: { generator: Generators.wdw, dimension: 'periodScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,3,wdw,h',
        template: { id: 'betTypes.timeWin,tperiod,3,wdw,h', defaultMessage: '{home} (3rd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,3,wdw,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,tperiod,3,wdw,d',
        template: { id: 'betTypes.timeWin,tperiod,3,wdw,d', defaultMessage: 'Draw (3rd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,3,wdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,tperiod,3,wdw,a',
        template: { id: 'betTypes.timeWin,tperiod,3,wdw,a', defaultMessage: '{away} (3rd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,3,wdw,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tperiod,1,ml': {
    name: { id: 'groupNames.timeWin,tperiod,1,ml', defaultMessage: 'Moneyline (1st period)' },
    shortName: { id: 'groupNames.short.timeWin,tperiod,1,ml', defaultMessage: 'Moneyline (1P)' },
    order: ['h', 'a'],
    betTypeTemplate: 'for,tperiod,1,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,1,ml,h',
        template: { id: 'betTypes.timeWin,tperiod,1,ml,h', defaultMessage: '{home} (1st period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,1,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tperiod,1,ml,a',
        template: { id: 'betTypes.timeWin,tperiod,1,ml,a', defaultMessage: '{away} (1st period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,1,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tperiod,2,ml': {
    name: { id: 'groupNames.timeWin,tperiod,2,ml', defaultMessage: 'Moneyline (2nd period)' },
    shortName: { id: 'groupNames.short.timeWin,tperiod,2,ml', defaultMessage: 'Moneyline (2P)' },
    order: ['h', 'a'],
    betTypeTemplate: 'for,tperiod,2,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,2,ml,h',
        template: { id: 'betTypes.timeWin,tperiod,2,ml,h', defaultMessage: '{home} (2nd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,2,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tperiod,2,ml,a',
        template: { id: 'betTypes.timeWin,tperiod,2,ml,a', defaultMessage: '{away} (2nd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,2,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tperiod,3,ml': {
    name: { id: 'groupNames.timeWin,tperiod,3,ml', defaultMessage: 'Moneyline (3rd period)' },
    shortName: { id: 'groupNames.short.timeWin,tperiod,3,ml', defaultMessage: 'Moneyline (3P)' },
    order: ['h', 'a'],
    betTypeTemplate: 'for,tperiod,3,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,3,ml,h',
        template: { id: 'betTypes.timeWin,tperiod,3,ml,h', defaultMessage: '{home} (3rd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,3,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tperiod,3,ml,a',
        template: { id: 'betTypes.timeWin,tperiod,3,ml,a', defaultMessage: '{away} (3rd period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,3,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tperiod,4,ml': {
    name: { id: 'groupNames.timeWin,tperiod,4,ml', defaultMessage: 'Moneyline (4th period)' },
    shortName: { id: 'groupNames.short.timeWin,tperiod,4,ml', defaultMessage: 'Moneyline (4P)' },
    order: ['h', 'a'],
    betTypeTemplate: 'for,tperiod,4,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,4,ml,h',
        template: { id: 'betTypes.timeWin,tperiod,4,ml,h', defaultMessage: '{home} (4th period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,4,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tperiod,4,ml,a',
        template: { id: 'betTypes.timeWin,tperiod,4,ml,a', defaultMessage: '{away} (4th period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,4,ml,a', defaultMessage: '2' },
      },
    },
  },
  'timeWin,tperiod,5,ml': {
    name: { id: 'groupNames.timeWin,tperiod,5,ml', defaultMessage: 'Moneyline (5th period)' },
    shortName: { id: 'groupNames.short.timeWin,tperiod,5,ml', defaultMessage: 'Moneyline (5P)' },
    order: ['h', 'a'],
    betTypeTemplate: 'for,tperiod,5,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'periodScores.4', size: 8 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,tperiod,5,ml,h',
        template: { id: 'betTypes.timeWin,tperiod,5,ml,h', defaultMessage: '{home} (5th period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,5,ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,tperiod,5,ml,a',
        template: { id: 'betTypes.timeWin,tperiod,5,ml,a', defaultMessage: '{away} (5th period)' },
        columnName: { id: 'columnNames.timeWin,tperiod,5,ml,a', defaultMessage: '2' },
      },
    },
  },
  //these don't work... should they ?
  'timeOe,tp,all,oe': {
    //theoretical
    name: { id: 'groupNames.timeOe,tp,all,oe', defaultMessage: 'Odd/Even' },
    shortName: { id: 'groupNames.short.timeOe,tp,all,oe', defaultMessage: 'Odd/Even' },
    handicap: false,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,tp,all,{parity}',
    position: { generator: Generators.oe, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,tp,all,odd',
        template: { id: 'betTypes.timeOe,tp,all,oe,odd', defaultMessage: 'Odd Score' },
        columnName: { id: 'columnNames.timeOe,tp,all,oe,odd', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,tp,all,even',
        template: { id: 'betTypes.timeOe,tp,all,oe,even', defaultMessage: 'Even Score' },
        columnName: { id: 'columnNames.timeOe,tp,all,oe,even', defaultMessage: 'Even' },
      },
    },
  },
  'tahou,tperiod,1,h': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,1,h', defaultMessage: 'Home Score (1st period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,1,h', defaultMessage: 'Home O/U (1P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,1,tah{direction},h,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,1,tahover,h',
        template: {
          id: 'betTypes.tahou,tperiod,1,h,over',
          defaultMessage: '{home} to score Over {handicap} (1st Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,1,h,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,1,tahunder,h',
        template: {
          id: 'betTypes.tahou,tperiod,1,h,under',
          defaultMessage: '{home} to score Under {handicap} (1st Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,1,h,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,tperiod,1,a': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,1,a', defaultMessage: 'Away Score (1st period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,1,a', defaultMessage: 'Away O/U (1P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,1,tah{direction},a,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.0', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,1,tahover,a',
        template: {
          id: 'betTypes.tahou,tperiod,1,a,over',
          defaultMessage: '{away} to score Over {handicap} (1st Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,1,a,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,1,tahunder,a',
        template: {
          id: 'betTypes.tahou,tperiod,1,a,under',
          defaultMessage: '{away} to score Under {handicap} (1st Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,1,a,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,tperiod,2,h': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,2,h', defaultMessage: 'Home Score (2nd period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,2,h', defaultMessage: 'Home O/U (2P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,2,tah{direction},h,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,2,tahover,h',
        template: {
          id: 'betTypes.tahou,tperiod,2,h,over',
          defaultMessage: '{home} to score Over {handicap} (2nd Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,2,h,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,2,tahunder,h',
        template: {
          id: 'betTypes.tahou,tperiod,2,h,under',
          defaultMessage: '{home} to score Under {handicap} (2nd Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,2,h,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,tperiod,2,a': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,2,a', defaultMessage: 'Away Score (2nd period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,2,a', defaultMessage: 'Away O/U (2P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,2,tah{direction},a,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.1', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,2,tahover,a',
        template: {
          id: 'betTypes.tahou,tperiod,2,a,over',
          defaultMessage: '{away} to score Over {handicap} (2nd Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,2,a,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,2,tahunder,a',
        template: {
          id: 'betTypes.tahou,tperiod,2,a,under',
          defaultMessage: '{away} to score Under {handicap} (2nd Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,2,a,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,tperiod,3,h': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,3,h', defaultMessage: 'Home Score (3rd period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,3,h', defaultMessage: 'Home O/U (3P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,3,tah{direction},h,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,3,tahover,h',
        template: {
          id: 'betTypes.tahou,tperiod,3,h,over',
          defaultMessage: '{home} to score Over {handicap} (3rd Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,3,h,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,3,tahunder,h',
        template: {
          id: 'betTypes.tahou,tperiod,3,h,under',
          defaultMessage: '{home} to score Under {handicap} (3rd Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,3,h,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,tperiod,3,a': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,3,a', defaultMessage: 'Away Score (3rd period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,3,a', defaultMessage: 'Away O/U (3P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,3,tah{direction},a,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.2', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,3,tahover,a',
        template: {
          id: 'betTypes.tahou,tperiod,3,a,over',
          defaultMessage: '{away} to score Over {handicap} (3rd Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,3,a,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,3,tahunder,a',
        template: {
          id: 'betTypes.tahou,tperiod,3,a,under',
          defaultMessage: '{away} to score Under {handicap} (3rd Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,3,a,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,tperiod,4,h': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,4,h', defaultMessage: 'Home Score (4th period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,4,h', defaultMessage: 'Home O/U (4P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,4,tah{direction},h,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,4,tahover,h',
        template: {
          id: 'betTypes.tahou,tperiod,4,h,over',
          defaultMessage: '{home} to score Over {handicap} (4th Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,4,h,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,4,tahunder,h',
        template: {
          id: 'betTypes.tahou,tperiod,4,h,under',
          defaultMessage: '{home} to score Under {handicap} (4th Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,4,h,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,tperiod,4,a': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,4,a', defaultMessage: 'Away Score (4th period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,4,a', defaultMessage: 'Away O/U (4P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,4,tah{direction},a,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.3', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,4,tahover,a',
        template: {
          id: 'betTypes.tahou,tperiod,4,a,over',
          defaultMessage: '{away} to score Over {handicap} (4th Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,4,a,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,4,tahunder,a',
        template: {
          id: 'betTypes.tahou,tperiod,4,a,under',
          defaultMessage: '{away} to score Under {handicap} (4th Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,4,a,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,tperiod,5,h': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,5,h', defaultMessage: 'Home Score (5th period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,5,h', defaultMessage: 'Home O/U (5P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,5,tah{direction},h,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.4', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,5,tahover,h',
        template: {
          id: 'betTypes.tahou,tperiod,5,h,over',
          defaultMessage: '{home} to score Over {handicap} (5th Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,5,h,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,5,tahunder,h',
        template: {
          id: 'betTypes.tahou,tperiod,5,h,under',
          defaultMessage: '{home} to score Under {handicap} (5th Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,5,h,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,tperiod,5,a': {
    //not supported, but xena customers are opening betslips ?
    name: { id: 'groupNames.timeAhou,tperiod,5,a', defaultMessage: 'Away Score (5th period)' },
    shortName: { id: 'groupNames.short.timeAhou,tperiod,5,a', defaultMessage: 'Away O/U (5P)' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tperiod,5,tah{direction},a,{line}',
    position: { generator: Generators.tahou, dimension: 'periodScores.4', size: 8 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tperiod,5,tahover,a',
        template: {
          id: 'betTypes.tahou,tperiod,5,a,over',
          defaultMessage: '{away} to score Over {handicap} (5th Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,5,a,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,tperiod,5,tahunder,a',
        template: {
          id: 'betTypes.tahou,tperiod,5,a,under',
          defaultMessage: '{away} to score Under {handicap} (5th Period)',
        },
        columnName: { id: 'columnNames.timeAhou,tperiod,5,a,under', defaultMessage: 'U' },
      },
    },
  },
  'timeOe,tp,reg,oe': {
    //theoretical
    name: { id: 'groupNames.timeOe,tp,reg,oe', defaultMessage: 'Odd/Even' },
    shortName: { id: 'groupNames.short.timeOe,tp,reg,oe', defaultMessage: 'Odd/Even' },
    handicap: false,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,tp,reg,{parity}',
    position: { generator: Generators.oe, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,tp,reg,odd',
        template: {
          id: 'betTypes.timeOe,tp,reg,oe,odd',
          defaultMessage: 'Odd Score (Regular Time)',
        },
        columnName: { id: 'columnNames.timeOe,tp,reg,oe,odd', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,tp,reg,even',
        template: {
          id: 'betTypes.timeOe,tp,reg,oe,even',
          defaultMessage: 'Even Score (Regular Time)',
        },
        columnName: { id: 'columnNames.timeOe,tp,reg,oe,even', defaultMessage: 'Even' },
      },
    },
  },
};

/** @format */

//this may still need to be split up because it has a lot of UI stuff

exports.default = {
  //bookies to  ALWAYS show in price history regardless if the users has them
  priceHistoryBookies: [],
  //valid options as trade page bookies
  tradePageBookies: ['pin', 'isn', 'pin88', /*'sing2',*/ 'bf', 'mbook', '18bet', '3et'],
};

/** @format */

exports.default = {
  name: { id: 'sports.fb_htft', defaultMessage: 'Football: Half/Full' },
  shortName: { id: 'sports.fb_htft.short', defaultMessage: 'Football [Hl/Fl]' },
  featuredCompetitions: [
    '1', // England Premier League
    '301', // FIFA World Cup
    '38', // France Ligue 1
    '12', // Germany Bundesliga
    '19', // Italy Serie A
    '16', // Spain La Liga
    '28', // UEFA Champions League
    '29', // UEFA Europa League
    '32', // UEFA European Championship
  ],
  featuredCompetitionsName: [],
  enabled: true,
  maxTimeout: 259200,
  correlated: {},
  correlatedOrder: ['fb_htft'],
  complexSportOf: 'fb', //basically this tells us that this is a spinoff of fb, but we have to treat it as its own base sport
  trustScores: true,
  matchDataOpener: 'for,a,a/null', //uses fb one anyway
  scoresFallbacks: {
    fb_htft: 'fb',
  },
  useIrTypes: false,
  noMultiline: true,
  offerGroupsOrder: ['htft'],
  sympBetTypes: {},
};

/** @format */

const Generators = require('../positionGrids').Generators

//combination bet types (i.e. away win and over x goals)
//see: https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  htft: { //warning: only works with fb_htft sport type
    name: { id: 'groupNames.htft', defaultMessage: 'Half/Full Time' },
    shortName: { id: 'groupNames.short.htft', defaultMessage: 'Half/Full' },
    handicap: false,
    width: 3, //2,3,4 are there; if you want more you have to tweak the sass as well
    order: ['h,h', 'h,d', 'h,a', 'd,h', 'd,d', 'd,a', 'a,h', 'a,d', 'a,a'],
    betTypeTemplate: 'for,{htOutcome},{outcome}',
    position: { generator: Generators.htftWdw, dimension: 'halfOutcome', size: 3 }, //TODO
    overroundFactor: 1,
    betTypes: {
      'h,h': {
        type: 'for,h,h',
        template: { id: 'betTypes.htft,h,h', defaultMessage: '{home} Half, {home} Full' },
        columnName: { id: 'columnNames.htft,h,h', defaultMessage: '1/1' },
      },
      'h,d': {
        type: 'for,h,d',
        template: { id: 'betTypes.htft,h,d', defaultMessage: '{home} Half, Draw Full' },
        columnName: { id: 'columnNames.htft,h,d', defaultMessage: '1/X' },
      },
      'h,a': {
        type: 'for,h,a',
        template: { id: 'betTypes.htft,h,a', defaultMessage: '{home} Half, {away} Full' },
        columnName: { id: 'columnNames.htft,h,a', defaultMessage: '1/2' },
      },
      'd,h': {
        type: 'for,d,h',
        template: { id: 'betTypes.htft,d,h', defaultMessage: 'Draw Half, {home} Full' },
        columnName: { id: 'columnNames.htft,d,h', defaultMessage: 'X/1' },
      },
      'd,d': {
        type: 'for,d,d',
        template: { id: 'betTypes.htft,d,d', defaultMessage: 'Draw Half, Draw Full' },
        columnName: { id: 'columnNames.htft,d,d', defaultMessage: 'X/X' },
      },
      'd,a': {
        type: 'for,d,a',
        template: { id: 'betTypes.htft,d,a', defaultMessage: 'Draw Half, {away} Full' },
        columnName: { id: 'columnNames.htft,d,a', defaultMessage: 'X/2' },
      },
      'a,h': {
        type: 'for,a,h',
        template: { id: 'betTypes.htft,a,h', defaultMessage: '{away} Half, {home} Full' },
        columnName: { id: 'columnNames.htft,a,h', defaultMessage: '2/1' },
      },
      'a,d': {
        type: 'for,a,d',
        template: { id: 'betTypes.htft,a,d', defaultMessage: '{away} Half, Draw Full' },
        columnName: { id: 'columnNames.htft,a,d', defaultMessage: '2/X' },
      },
      'a,a': {
        type: 'for,a,a',
        template: { id: 'betTypes.htft,a,a', defaultMessage: '{away} Half, {away} Full' },
        columnName: { id: 'columnNames.htft,a,a', defaultMessage: '2/2' },
      },
    },
  },
  wg: { //theoretical (can't find offers)
    name: { id: 'groupNames.wg', defaultMessage: 'Win and score at least' },
    shortName: { id: 'groupNames.short.wg', defaultMessage: '1X2+O' },
    score: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,wg,{team},{goals}',
    position: { generator: Generators.wg, dimension: 'score', size: 15 },
    overroundFactor: 0, //doesn't cover all the outcomes
    betTypes: {
      h: {
        type: 'for,wg,h',
        template: {
          id: 'betTypes.wg,h',
          defaultMessage: '{home} Win and score at least {handicap} Goals',
        },
        columnName: { id: 'columnNames.wg,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,wg,a',
        template: {
          id: 'betTypes.wg,a',
          defaultMessage: '{away} Win and score at least {handicap} Goals',
        },
        columnName: { id: 'columnNames.wg,a', defaultMessage: '2' },
      },
    },
  },
  wmo: { //theoretical (can't find offers)
    name: { id: 'groupNames.wmo', defaultMessage: 'Win by goals and total goals over' },
    shortName: { id: 'groupNames.short.wmo', defaultMessage: 'Win by and total over' },
    score: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,wmo,{team},{margin},{goals}',
    position: { generator: Generators.wmo, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,wmo,h',
        template: {
          id: 'betTypes.wmo,h',
          defaultMessage: '{home} Win by goals and Over {handicap} goals',
        },
        columnName: { id: 'columnNames.wmo,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,wmo,a',
        template: {
          id: 'betTypes.wmo,a',
          defaultMessage: '{away} Win by goals and Over {handicap} goals',
        },
        columnName: { id: 'columnNames.wmo,a', defaultMessage: '2' },
      },
    },
  },
  moou: { //1X2 and Over/Under combo
    name: { id: 'groupNames.moou', defaultMessage: '1X2 O/U' },
    shortName: { id: 'groupNames.short.moou', defaultMessage: '1X2 O/U' },
    handicap: true,
    displayAsRaw: true,
    order: ['h,over', 'h,under', 'd,over', 'd,under', 'a,over', 'a,under'],
    betTypeTemplate: 'for,moou,{team},{direction},{goals}',
    position: { generator: Generators.moou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    width: 2,
    betTypes: {
      'h,over': {
        type: 'for,moou,h,over',
        template: { id: 'betTypes.moou,h,over', defaultMessage: '{home} AND Over {handicap}' },
        columnName: { id: 'columnNames.moou,h,over', defaultMessage: '1+O' },
      },
      'h,under': {
        type: 'for,moou,h,under',
        template: { id: 'betTypes.moou,h,under', defaultMessage: '{home} AND Under {handicap}' },
        columnName: { id: 'columnNames.moou,h,under', defaultMessage: '1+U' },
      },
      'd,over': {
        type: 'for,moou,d,over',
        template: { id: 'betTypes.moou,d,over', defaultMessage: 'Draw AND Over {handicap}' },
        columnName: { id: 'columnNames.moou,d,over', defaultMessage: 'X+O' },
      },
      'd,under': {
        type: 'for,moou,d,under',
        template: { id: 'betTypes.moou,d,under', defaultMessage: 'Draw AND Under {handicap}' },
        columnName: { id: 'columnNames.moou,d,under', defaultMessage: 'X+U' },
      },
      'a,over': {
        type: 'for,moou,a,over',
        template: { id: 'betTypes.moou,a,over', defaultMessage: '{away} AND Over {handicap}' },
        columnName: { id: 'columnNames.moou,a,over', defaultMessage: '2+O' },
      },
      'a,under': {
        type: 'for,moou,a,under',
        template: { id: 'betTypes.moou,a,under', defaultMessage: '{away} AND Under {handicap}' },
        columnName: { id: 'columnNames.moou,a,under', defaultMessage: '2+U' },
      },
    },
  },
  moBothScore: { //1X2 and will both score
    name: { id: 'groupNames.moBothScore', defaultMessage: '1X2 + Both Score' },
    shortName: { id: 'groupNames.short.moBothScore', defaultMessage: '1X2 + Score' },
    order: ['h,yes', 'h,no', 'd,yes', 'd,no', 'a,yes', 'a,no'],
    betTypeTemplate: 'for,mo_both_score,{team},{will}',
    position: { generator: Generators.moBothScore, dimension: 'score', size: 15 },
    overroundFactor: 0,
    width: 2,
    betTypes: {
      'h,yes': {
        type: 'for,mo_both_score,h,yes',
        template: { id: 'betTypes.moBothScore,h,yes', defaultMessage: '{home} AND Both Score' },
        columnName: { id: 'columnNames.moBothScore,h,yes', defaultMessage: '1+Yes' },
      },
      'h,no': {
        type: 'for,mo_both_score,h,no',
        template: {
          id: 'betTypes.moBothScore,h,no',
          defaultMessage: '{home} AND At least one does not score',
        },
        columnName: { id: 'columnNames.moBothScore,h,no', defaultMessage: '1+No' },
      },
      'd,yes': {
        type: 'for,mo_both_score,d,yes',
        template: { id: 'betTypes.moBothScore,d,yes', defaultMessage: 'Draw AND Both Score' },
        columnName: { id: 'columnNames.moBothScore,d,yes', defaultMessage: 'X+Yes' },
      },
      'd,no': {
        type: 'for,mo_both_score,d,no',
        template: {
          id: 'betTypes.moBothScore,d,no',
          defaultMessage: 'Draw AND At least one does not score',
        },
        columnName: { id: 'columnNames.moBothScore,d,no', defaultMessage: 'X+No' },
      },
      'a,yes': {
        type: 'for,mo_both_score,a,yes',
        template: { id: 'betTypes.moBothScore,a,yes', defaultMessage: '{away} AND Both Score' },
        columnName: { id: 'columnNames.moBothScore,a,yes', defaultMessage: '2+Yes' },
      },
      'a,no': {
        type: 'for,mo_both_score,a,no',
        template: {
          id: 'betTypes.moBothScore,a,no',
          defaultMessage: '{away} AND At least one does not score',
        },
        columnName: { id: 'columnNames.moBothScore,a,no', defaultMessage: '2+No' },
      },
    },
  },
  aou: { //1X2 and more than X goals scored
    name: { id: 'groupNames.aou', defaultMessage: '1X2+O' },
    shortName: { id: 'groupNames.short.aou', defaultMessage: '1X2 O' },
    handicap: true,
    displayAsRaw: true,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,aou,{outcome},{goals}',
    position: { generator: Generators.aou, dimension: 'score', size: 15 },
    overroundFactor: 0, //doesn't cover entire outcome space
    width: 1,
    betTypes: {
      h: {
        type: 'for,aou,h',
        template: {
          id: 'betTypes.aou,h',
          defaultMessage: '{home} to win AND score MORE than {handicap} goals',
        },
        columnName: { id: 'columnNames.aou,h', defaultMessage: '1+O' },
      },
      d: {
        type: 'for,aou,d',
        template: {
          id: 'betTypes.aou,d',
          defaultMessage: 'Draw AND each team scores MORE than {handicap} goals',
        },
        columnName: { id: 'columnNames.aou,d', defaultMessage: 'X+O' },
      },
      a: {
        type: 'for,aou,a',
        template: {
          id: 'betTypes.aou,a',
          defaultMessage: '{away} to win AND score MORE than {handicap} goals',
        },
        columnName: { id: 'columnNames.aou,a', defaultMessage: '2+O' },
      },
    },
  },
};

/** @format */

// import * as Sentry from '@sentry/browser';

const WIN = 'w';
const LOSS = 'l';
const VOID = 'v';
const VOID_WIN = 'v/w';
const LOSS_VOID = 'l/v';

const REVERSES = {
  [WIN]: LOSS,
  [LOSS]: WIN,
  [VOID]: VOID,
  [VOID_WIN]: LOSS_VOID,
  [LOSS_VOID]: VOID_WIN,
};

//map of reversible results
function reverseOutcomes(val) {
  return val ? REVERSES[val] : val;
}

// Unwraps asian handicap code 'asian_arg' attribute from 'params'
// decodes it and splits it into two eg. 7 -> (1.5, 2) or 8 -> (2, 2).
// Then passes the decoded values as the last parameter to the wrapped
// function and adds the Results together eg. 'w' + 'v' -> 'v/w'.
function asianSplit(func, hcap) {
  let d = hcap / 4.0;
  let r = hcap % 4;
  let up, down;

  if ([0, 2].includes(r)) {
    up = d;
    down = d;
  } else {
    up = d - 0.25;
    down = d + 0.25;
  }

  let fst = func(up);
  if (up === down) {
    return fst;
  } else {
    return fst.add(func(down));
  }
}

class Result {
  constructor(value) {
    this.value = value;
  }

  and(other) {
    if (this.value === other.value) {
      return this;
    }

    let outcome = `${this.value || LOSS},${other.value || LOSS}`;
    if (['w,l', 'l,w', 'l,l'].indexOf(outcome) !== -1) {
      return new Result(LOSS);
    } else {
      throw new Error(`Position grid can't handle ${this.value} and ${other.value}`);
      // Sentry.captureMessage(`Position grid can't handle ${this.value} and ${other.value}`, {
      //   level: 'error',
      //   logger: 'config:positionGrids'
      // });
      // return new Result(null);
    }
  }

  or(other) {
    if (this.value) {
      return this;
    } else if (other.value) {
      return other;
    } else {
      return new Result(null);
    }
  }

  add(other) {
    if (!this.value) {
      return other;
    } else if (!other.value) {
      return this;
    } else if (this.value === other.value) {
      return this;
    }

    let outcome = `${this.value},${other.value}`;
    if (['w,v', 'v,w'].includes(outcome)) {
      return new Result(VOID_WIN);
    } else if (['l,v', 'v,l'].includes(outcome)) {
      return new Result(LOSS_VOID);
    } else {
      throw new Error(`Position grid can't handle ${this.value} add ${other.value}`);
      // Sentry.captureMessage(`Position grid can't handle ${this.value} and ${other.value}`, {
      //   level: 'error',
      //   logger: 'config:positionGrids'
      // });
      // return new Result(null);
    }
  }

  then(value) {
    if (this.value === WIN) {
      return new Result(value);
    } else {
      return this;
    }
  }

  otherwise(value) {
    if (this.value === null) {
      return new Result(value);
    } else {
      return this;
    }
  }

  reverse() {
    return new Result(reverseOutcomes(this.value));
  }
}

class ScoreBoard {
  //team flip ?
  static other(team) {
    if (team === 'h') {
      return 'a';
    } else {
      return 'h';
    }
  }

  //handicap is homeHandicap
  static teamWon({ home, away }, outcome, handicap = 0) {
    if (outcome === 'h') {
      if (home + handicap > away) {
        return new Result(WIN);
      } else {
        return new Result(null);
      }
    } else if (outcome === 'a') {
      if (home + handicap < away) {
        return new Result(WIN);
      } else {
        return new Result(null);
      }
    } else if (outcome === 'd') {
      if (home + handicap === away) {
        return new Result(WIN);
      } else {
        return new Result(null);
      }
    }
  }

  static totalLt({ home, away }, total, strict = true) {
    if (total === 'inf') {
      return new Result(WIN);
    }

    if (strict) {
      if (home + away < total) {
        return new Result(WIN);
      } else {
        return new Result(null);
      }
    } else if (home + away <= total) {
      return new Result(WIN);
    } else {
      return new Result(null);
    }
  }

  static totalGt({ home, away }, total, strict = true) {
    if (total === '-inf') {
      return new Result(WIN);
    }

    if (strict) {
      if (home + away > total) {
        return new Result(WIN);
      } else {
        return new Result(null);
      }
    } else if (home + away >= total) {
      return new Result(WIN);
    } else {
      return new Result(null);
    }
  }

  static totalEq({ home, away }, total) {
    if (home + away === total) {
      return new Result(WIN);
    } else {
      return new Result(null);
    }
  }

  static total({ home, away }, direction, total, strict = true) {
    if (direction === 'over') {
      return ScoreBoard.totalGt({ home, away }, total, strict);
    } else {
      return ScoreBoard.totalLt({ home, away }, total, strict);
    }
  }

  static teamScoreLt({ home, away }, team, total, strict = true) {
    if (total === 'inf') {
      return new Result(WIN);
    }

    if (team === 'h') {
      if (strict) {
        if (home < total) {
          return new Result(WIN);
        } else {
          return new Result(null);
        }
      } else {
        if (home <= total) {
          return new Result(WIN);
        } else {
          return new Result(null);
        }
      }
    } else if (team === 'a') {
      if (strict) {
        if (away < total) {
          return new Result(WIN);
        } else {
          return new Result(null);
        }
      } else {
        if (away <= total) {
          return new Result(WIN);
        } else {
          return new Result(null);
        }
      }
    }
  }

  static teamScoreGt({ home, away }, team, total, strict = true) {
    if (total === '-inf') {
      return new Result(WIN);
    }

    if (team === 'h') {
      if (strict) {
        if (home > total) {
          return new Result(WIN);
        } else {
          return new Result(null);
        }
      } else {
        if (home >= total) {
          return new Result(WIN);
        } else {
          return new Result(null);
        }
      }
    } else if (team === 'a') {
      if (strict) {
        if (away > total) {
          return new Result(WIN);
        } else {
          return new Result(null);
        }
      } else {
        if (away >= total) {
          return new Result(WIN);
        } else {
          return new Result(null);
        }
      }
    }
  }

  static teamScoreEq({ home, away }, team, total) {
    if (team === 'h') {
      if (home === total) {
        return new Result(WIN);
      } else {
        return new Result(null);
      }
    } else if (team === 'a') {
      if (away === total) {
        return new Result(WIN);
      } else {
        return new Result(null);
      }
    }
  }

  static teamScore({ home, away }, team, direction, total, strict = true) {
    if (direction === 'over') {
      return ScoreBoard.teamScoreGt({ home, away }, team, total, strict);
    } else {
      return ScoreBoard.teamScoreLt({ home, away }, team, total, strict);
    }
  }
}

//betType params:
// {
//   outcome: h|d|a
//   outcomes: [h|d|a, h|d|a] //for dc
//   htOutcome: h|d|a
//   team: h|a
//   direction: over|under
//   parity: odd|even
//   who: both|neither|either|one|a|h
//   will: true|false

//   line: line
//   hcap: handicap (kinda like line)
//   min: for range stuff
//   max: for range stuff (can have special value 'inf')
//   goals: total goals
//   lowerBound: {home, away} //othercs
//   upperBound: {home, away} //othercs
//   range: {min, max}
//   goals_over
//   draw: quatro ??
// }

exports.Generators = class Generators {
  static wdw({ outcome }, score) {
    if (outcome === 'sd') {
      return ScoreBoard.teamWon(score, 'd').and(ScoreBoard.totalGt(score, 0).otherwise(LOSS));
    } else {
      return ScoreBoard.teamWon(score, outcome).otherwise(LOSS);
    }
  }

  static ml({ outcome }, score) {
    return ScoreBoard.teamWon(score, 'd')
      .then(VOID)
      .or(ScoreBoard.teamWon(score, outcome).otherwise(LOSS));
  }

  static dnb({ outcome }, score) {
    return ScoreBoard.teamWon(score, 'd')
      .then(VOID)
      .or(ScoreBoard.teamWon(score, outcome).otherwise(LOSS));
  }

  static anb({ outcome }, score) {
    return ScoreBoard.teamWon(score, 'a')
      .then(VOID)
      .or(ScoreBoard.teamWon(score, outcome).otherwise(LOSS));
  }

  static hnb({ outcome }, score) {
    return ScoreBoard.teamWon(score, 'h')
      .then(VOID)
      .or(ScoreBoard.teamWon(score, outcome).otherwise(LOSS));
  }

  static moou({ team, direction, goals }, score) {
    return ScoreBoard.teamWon(score, team).and(
      ScoreBoard.total(score, direction, goals).otherwise(LOSS)
    );
  }

  static win90({ team }, score) {
    return ScoreBoard.teamWon(score, team).otherwise(LOSS);
  }

  static exactTotal({ min, max }, score) {
    let res;
    if (max === 'inf') {
      res = ScoreBoard.totalGt(score, min, false);
    } else {
      res = ScoreBoard.totalEq(score, min);
    }

    return res.otherwise(LOSS);
  }

  static oueq({ direction, line }, score) {
    return ScoreBoard.total(score, direction, line, false).otherwise(LOSS);
  }

  static ou({ direction, line }, score) {
    return ScoreBoard.total(score, direction, line).otherwise(LOSS);
  }

  static ah({ irhome, iraway, team, hcap }, score) {
    return asianSplit((hcp) => {
      hcp = parseFloat(hcp); //just in case
      if (irhome !== undefined && iraway !== undefined) {
        hcp = hcp - (irhome - iraway);
      }
      return ScoreBoard.teamWon(score, 'd', hcp)
        .then(VOID)
        .or(ScoreBoard.teamWon(score, team, hcp).otherwise(LOSS));
    }, hcap);
  }

  static ahou({ line, direction }, score) {
    return asianSplit((ln) => {
      return ScoreBoard.totalEq(score, parseFloat(ln))
        .then(VOID)
        .or(ScoreBoard.total(score, direction, ln).otherwise(LOSS));
    }, line);
  }

  static tahou({ team, line, direction }, score) {
    return asianSplit((ln) => {
      return ScoreBoard.teamScoreEq(score, team, parseFloat(ln))
        .then(VOID)
        .or(ScoreBoard.teamScore(score, team, direction, ln).otherwise(LOSS));
    }, line);
  }

  static eh({ team, hcap }, score) {
    return ScoreBoard.teamWon(score, team, hcap).otherwise(LOSS);
  }

  static cs({ home, away }, score) {
    return ScoreBoard.teamScoreEq(score, 'h', home)
      .and(ScoreBoard.teamScoreEq(score, 'a', away))
      .otherwise(LOSS);
  }

  static othercs({ maxHome, maxAway, minHome, minAway }, score) {
    let res = ScoreBoard.teamScoreGt(score, 'h', maxHome).or(
      ScoreBoard.teamScoreGt(score, 'a', maxAway)
    );

    if (minHome !== undefined && minAway !== undefined) {
      res = res
        .or(ScoreBoard.teamScoreLt(score, 'h', minHome))
        .or(ScoreBoard.teamScoreLt(score, 'a', minAway));
    }

    return res.otherwise(LOSS);
  }

  static oe({ parity, team }, score) {
    const par = parity === 'odd' ? 1 : 0;
    let acc;
    if (!team) {
      acc = score.home + score.away;
    } else if (team === 'h') {
      acc = score.home;
    } else {
      acc = score.away;
    }

    if (acc % 2 === par) {
      return new Result(WIN);
    } else {
      return new Result(LOSS);
    }
  }

  static gr({ min, max }, score) {
    return ScoreBoard.totalGt(score, min, false).and(
      ScoreBoard.totalLt(score, max, false).otherwise(LOSS)
    );
  }

  static teamgr({ min, max, team }, score) {
    return ScoreBoard.teamScoreGt(score, team, min, false).and(
      ScoreBoard.teamScoreLt(score, team, max, false).otherwise(LOSS)
    );
  }

  static wg({ team, goals }, score) {
    return ScoreBoard.teamWon(score, team).and(
      ScoreBoard.teamScoreGt(score, team, goals, false).otherwise(LOSS)
    );
  }

  static wintonil({ team, will }, score) {
    let res = ScoreBoard.teamScoreGt(score, team, 0).and(
      ScoreBoard.teamScoreEq(score, ScoreBoard.other(team), 0).otherwise(LOSS)
    );

    if (will) {
      return res;
    } else {
      return res.reverse();
    }
  }

  static swm({ outcome }, score) {
    if (outcome === 'no_goal') {
      return ScoreBoard.totalEq(score, 0).otherwise(LOSS);
    } else {
      return ScoreBoard.teamWon(score, 'd').and(ScoreBoard.totalGt(score, 0).otherwise(LOSS));
    }
  }

  static wm({ team, min, max }, score) {
    let otherScoreMin = team === 'h' ? score.away : score.home;
    let otherScoreMax = team === 'h' ? score.away : score.home;

    if (max === 'inf') {
      otherScoreMax = 'inf';
    } else {
      otherScoreMax += max;
    }
    if (min === '-inf') {
      otherScoreMin = '-inf';
    } else {
      otherScoreMin += min;
    }

    return ScoreBoard.teamScoreGt(score, team, otherScoreMin, false)
      .and(ScoreBoard.teamScoreLt(score, team, otherScoreMax, false))
      .otherwise(LOSS);
  }

  static wmo({ team, margin, goals }, score) {
    let otherScore = team === 'h' ? score.away : score.home;
    return ScoreBoard.teamScoreEq(score, team, otherScore + margin)
      .and(ScoreBoard.totalGt(score, goals))
      .otherwise(LOSS);
  }

  static fg({ outcome }, score) {
    if (outcome === 'no_goal') {
      return ScoreBoard.totalEq(score, 0).otherwise(LOSS);
    } else {
      throw new Error(`Not implemented: fg ${outcome}`);
    }
  }

  static quatro({ teamOrDraw, direction, line }, score) {
    let res = teamOrDraw.indexOf('d') !== -1 ? ScoreBoard.teamWon(score, 'd') : new Result(null);
    const team = teamOrDraw.replace('d', '');
    res = res.or(ScoreBoard.teamWon(score, team || 'd'));
    res = res.and(ScoreBoard.total(score, direction, line));
    return res.otherwise(LOSS);
  }

  static dc({ outcome, outcome2 }, score) {
    return ScoreBoard.teamWon(score, outcome)
      .or(ScoreBoard.teamWon(score, outcome2))
      .otherwise(LOSS);
  }

  static clean({ who, will }, score) {
    let res;
    if (who === 'both') {
      res = ScoreBoard.totalEq(score, 0);
    } else if (who === 'neither') {
      res = ScoreBoard.teamScoreGt(score, 'h', 0).and(ScoreBoard.teamScoreGt(score, 'a', 0));
    } else if (who === 'either') {
      res = ScoreBoard.teamScoreEq(score, 'h', 0).or(ScoreBoard.teamScoreEq(score, 'a', 0));
    } else if (who === 'one') {
      res = ScoreBoard.teamScoreEq(score, 'h', 0)
        .or(ScoreBoard.teamScoreEq(score, 'a', 0))
        .and(ScoreBoard.totalGt(score, 0));
    } else {
      res = ScoreBoard.teamScoreEq(score, ScoreBoard.other(who), 0);
    }

    res = res.otherwise(LOSS);
    if (will) {
      return res;
    } else {
      return res.reverse();
    }
  }

  static score({ who, will }, score) {
    let res;
    if (who === 'both') {
      res = ScoreBoard.teamScoreGt(score, 'h', 0).and(ScoreBoard.teamScoreGt(score, 'a', 0));
    } else if (who === 'either') {
      res = ScoreBoard.totalGt(score, 0);
    } else if (who === 'neither') {
      res = ScoreBoard.totalEq(score, 0);
    } else if (who === 'one') {
      res = ScoreBoard.teamScoreEq(score, 'h', 0)
        .or(ScoreBoard.teamScoreEq(score, 'a', 0))
        .and(ScoreBoard.totalGt(score, 0));
    } else {
      res = ScoreBoard.teamScoreGt(score, who, 0);
    }

    res = res.otherwise(LOSS);
    if (will) {
      return res;
    } else {
      return res.reverse();
    }
  }

  static awm({ margin }, score) {
    return ScoreBoard.teamScoreEq(score, 'h', score.away + margin)
      .or(ScoreBoard.teamScoreEq(score, 'a', score.home + margin))
      .otherwise(LOSS);
  }

  static aou({ outcome, goals }, score) {
    if (outcome === 'd') {
      return ScoreBoard.teamWon(score, 'd').and(
        ScoreBoard.teamScoreGt(score, 'h', goals).otherwise(LOSS)
      );
    } else {
      return ScoreBoard.teamWon(score, outcome).and(
        ScoreBoard.teamScoreGt(score, outcome, goals).otherwise(LOSS)
      );
    }
  }

  static moBothScore({ team, will }, score) {
    let res = ScoreBoard.teamWon(score, team)
      .and(Generators.score({ who: 'both', will }, score))
      .otherwise(LOSS);

    return res;
  }

  static htftWdw({ htOutcome, outcome }, score) {
    if (score.home > 3 || score.away > 3) {
      return new Result(null);
    }
    const order = ['h', 'd', 'a'];
    if (score.home === order.indexOf(htOutcome) && score.away === order.indexOf(outcome)) {
      return new Result(WIN);
    } else {
      return new Result(LOSS);
    }
  }
}

/** @format */

const Generators = require('../positionGrids').Generators;

//these are the most widely used ones (football-ish)
//see: https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  custom: {
    name: { id: 'groupNames.custom', defaultMessage: 'custom' }, //full name of group
    shortName: { id: 'groupNames.short.custom', defaultMessage: 'custom' }, //name to show in group column and mobile view
    handicap: false, //does this bet type use a handicap
    //isAsian: true, //is asian handicap, i.e. offset and divided
    //isOverUnder: true, //is it an over under bet type, mainly for display purposes
    //width: 3, //should this column take up more space? i.e 3 times the normal; 2,3,4 are there; if you want more you have to tweak the sass as well
    //score: true, //is this a score bet type
    //displayAsRaw: true, //don't divide "handicap" by 4
    //range: true, //does it represent a range of outcomes, i.e. above 2, below 4
    //yesNo: false, //is this a yes or no type bet
    order: ['h', 'd', 'a'], //sd is not standard, means score-draw
    betTypeTemplate: 'for,{outcome}', //used to decode bet parameters
    position: {
      // describes how position works
      generator: Generators.wdw, //generator function
      dimension: 'score', //event dimension to be used as the score, to calculate position and PnL
      size: 15, //size position grid can go up to (just visually)
    },
    overroundFactor: 1, //does the overround need to be multiplied by something, usually because bet cases overlap; 0 means no overround
    canUseInAccas: true, //is usable in accumulators
    //irOffset: true, //does this group depend on in running score offset
    betTypes: {
      //map of bet types associated with this group
      custom: {
        type: 'custom', //actual bet type
        template: { id: 'betTypes.wdw,h', defaultMessage: '{home}' }, //used to translate bet types
        columnName: { id: 'columnNames.wdw,h', defaultMessage: '1' }, //name to show in column
        // irType: (hcap, [home, away]) => //special in running bet type function, used when event is in running;
        //   `for,ir,${parseInt(home, 10)},${parseInt(away, 10)},ah,h,${parseInt(hcap, 10)}`,
        // irTemplate: { //used in front-end translation of bet type
        //   id: 'betTypes.ah,ir,h',
        //   defaultMessage: '{home} {handicap} (Asian, {homeScore} - {awayScore})',
        // },
      },
      h: {
        type: 'for,h', //actual bet type
        template: { id: 'betTypes.wdw,h', defaultMessage: '{home}' }, //used to translate bet types
        columnName: { id: 'columnNames.wdw,h', defaultMessage: '1' }, //name to show in column
        // irType: (hcap, [home, away]) => //special in running bet type function, used when event is in running;
        //   `for,ir,${parseInt(home, 10)},${parseInt(away, 10)},ah,h,${parseInt(hcap, 10)}`,
        // irTemplate: { //used in front-end translation of bet type
        //   id: 'betTypes.ah,ir,h',
        //   defaultMessage: '{home} {handicap} (Asian, {homeScore} - {awayScore})',
        // },
      },
      d: {
        type: 'for,d',
        template: { id: 'betTypes.wdw,d', defaultMessage: 'Draw' },
        columnName: { id: 'columnNames.wdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,a',
        template: { id: 'betTypes.wdw,a', defaultMessage: '{away}' },
        columnName: { id: 'columnNames.wdw,a', defaultMessage: '2' },
      },
      sd: {
        //score draw: for completeness, not used
        type: 'for,sd',
        template: { id: 'betTypes.wdw,sd', defaultMessage: 'Score Draw' },
        columnName: { id: 'columnNames.wdw,sd', defaultMessage: 'SD' },
      },
    },
  },
};

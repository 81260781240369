/** @format */

import config from '../config';
import DSM from './DSM';

// this can be used to diff watched maps

// function objDiff(obj1, obj2) {
//   let diffs = {};
//   for (let key in obj2) {
//     if (obj2[key] !== obj1[key]) {
//       if (!obj1[key]) {
//         diffs[key] = 'add ' + obj2[key];
//       } else if (obj2[key]) {
//         if (obj2[key] > obj1[key]) {
//           diffs[key] = obj1[key] + ' +' + (obj2[key] - obj1[key]);
//         } else {
//           diffs[key] = obj1[key] + ' -' + (obj1[key] - obj2[key]);
//         }
//       }
//     }
//   }
//
//   for (let key in obj1) {
//     if (obj2[key] !== obj1[key]) {
//       if (!obj2[key]) {
//         diffs[key] = 'remove ' + obj1[key];
//       }
//     }
//   }
//
//   return diffs;
// }

let priceStream = null; //ref to the price stream

let watchedEventsMain = {}; //events that just have their main handicap lines watched
let toWatchBatchMain = []; //queue

//we watch things in batches because it's better ux
//if a bunch of components mount+watch at the same time the server can process them in batches
setInterval(() => {
  if (priceStream && toWatchBatchMain.length) {
    let _span = toWatchBatchMain.slice(0, config.eventWatchBatchSize);
    toWatchBatchMain = toWatchBatchMain.slice(config.eventWatchBatchSize);
    priceStream.send([
      'watch_hcaps',
      _span.map((_toWatch) => {
        let _spl = _toWatch.split('/');
        _spl[0] = parseInt(_spl[0], 10);
        return _spl;
      }),
    ]);
  }
}, config.timings.tradeWatchBatchInterval);

let watchedEventsAll = {}; //events that we watch everything for
let toWatchBatchAll = []; //queue

//we watch things in batches because it's better ux
//if a bunch of components mount+watch at the same time the server can process them in batches
setInterval(() => {
  if (priceStream && toWatchBatchAll.length) {
    let _span = toWatchBatchAll.slice(0, config.eventWatchBatchSize);
    toWatchBatchAll = toWatchBatchAll.slice(config.eventWatchBatchSize);
    for (let _toWatch of _span) {
      let _spl = _toWatch.split('/');
      _spl[0] = parseInt(_spl[0], 10);
      priceStream.send(['watch_event', _spl]);
    }
  }
}, config.timings.tradeWatchBatchInterval);

//PriceFeed is just a wrapper do a DSM managed DataStream
export default class PriceFeed {
  /**
   * this static method starts the price feed by instantiating a DataStream
   * @static
   * @param {Object} params - extra parameters
   * @param {string} [params.sessionId] - the session ID used to connect
   * @param {string} [params.language] - what language to use to the cpricefeed
   * @param {Object} [params.bookies] - array of bookies to be passed to cpricefeed (note that not all bookies work; see trade page bookies in backend)
   * @param {boolean} [params.monitorHeartBeats] - should it monitor heartbeats
   * @param {boolean} [params.recover] - should it attempt recovery
   * @return {DataStream} - instance of pricefeed DataStream
   */
  static start(params, actions) {
    PriceFeed.stop(); //try to stop an old PriceFeed

    //some DataStream setup variables
    const setup = {
      batch: config.timings.minimumStreamBatch, //batch some of the stream updats to make UI more fluid (results  may vary)
      ws: true, //is a websocket
      monitorHeartBeats: params.monitorHeartBeats, //monitor heartbeats
      //actions to call for various stream events
      wsConnectionFailureMessage: 'baseStreamFailedConnect',
      wsConnectionRecoveryMessage: params.recover ? 'baseStreamRecoveredConnect' : null,
      wsConnectionSuccessMessage: 'baseStreamSuccessConnect',
      wsHeartbeatMissedMessage: 'baseStreamHeartbeatMissed',
      wsHeartbeatRecoveryMessage: params.recover ? 'baseStreamRecoveredConnect' : null,
      heartBeatMonitorInterval: config.timings.heartbeatInterval,
    };

    window?.Timings?.handleCPriceFeedConnecting?.();

    //there can be only one
    DSM.ensureOne(
      `/cpricefeed/?token=${params.sessionId}&lang=${params.language}&prices_bookies=${
        params.bookies ? params.bookies.join(',') : ''
      }`,
      setup,
      actions,
      'priceStream', //id
    );
    priceStream = DSM.get('priceStream');

    //for debug purposes we can leak the price stream so we can interact with it
    if (window && config.support.tools.leakPricesWs) {
      window._stream = priceStream;
    }

    return priceStream;
  }

  /**
   * stop the existing price stream
   */
  static stop() {
    if (priceStream) {
      priceStream.stop();
      priceStream = null;
    }
  }

  /**
   * check if there is a price stream
   * @return {boolean} - is there a price stream
   */
  static isStarted() {
    return !!priceStream;
  }

  /**
   * try to send something to the stream; currently it's just dropped if price stream unavailable
   * we could have a queue here and send when stream is live
   * @static
   * @param {Object} data - payload to send
   */
  static send(data) {
    if (priceStream) {
      priceStream.send(data);
    }
  }

  /**
   * get last round-trip-time
   * @static
   * @return {number} - value in ms
   */
  static lastRTT() {
    if (priceStream) {
      return priceStream.lastRTT();
    } else {
      return 0;
    }
  }

  /**
   * attempt to re-watch all the things that were watched before
   * this is useful when handling disconnect + reconnect
   * @static
   */
  static rewatchAll() {
    if (priceStream) {
      toWatchBatchMain = Object.keys(watchedEventsMain);
      console.warn('Pricefeed rewatchAll main batch: ', toWatchBatchMain);
      toWatchBatchAll = Object.keys(watchedEventsAll);
      console.warn('Pricefeed rewatchAll all batch: ', toWatchBatchAll);
    }
  }

  /**
   * return number of sent heartbeats
   * @return {number} - value
   */
  static sentHeartbeats() {
    if (priceStream) {
      return priceStream._sentHeartbeats.length;
    } else {
      return 0;
    }
  }

  /**
   * watch the event main handicap only
   * @static
   * @param {string} compId - competition ID
   * @param {string} sport - sport (i.e. fb, fb_ht, basket, etc.)
   * @param {string} eventId - event ID
   * @param {boolean} instant - should it be done instantly or via the batch interval (instant is useful for things like betslips)
   */
  static watchEventMain(compId, sport, eventId, instant) {
    let _uid = `${compId}/${sport}/${eventId}`;
    //let old = {...watchedEventsMain}
    if (!watchedEventsMain[_uid]) {
      watchedEventsMain[_uid] = 1;
    } else {
      watchedEventsMain[_uid]++;
    }

    if (watchedEventsMain[_uid] === 1) {
      if (instant && priceStream) {
        priceStream.send(['watch_hcaps', [[parseInt(compId, 10), sport, eventId]]]);
      } else {
        if (toWatchBatchMain.indexOf(_uid) === -1) {
          //cpricefeed handles duplicate with error
          toWatchBatchMain.push(_uid);
        }
      }
    }

    //console.warn('watchEventMain', objDiff(old, watchedEventsMain))
  }

  /**
   * watch the event all handicaps (automatically batched, no instant option)
   * @static
   * @param {string} compId - competition ID
   * @param {string} sport - sport (i.e. fb, fb_ht, basket, etc.)
   * @param {string} eventId - event ID
   */
  static watchEventAll(compId, sport, eventId) {
    let _uid = `${compId}/${sport}/${eventId}`;
    //let old = {...watchedEventAll}
    if (!watchedEventsAll[_uid]) {
      watchedEventsAll[_uid] = 1;
    } else {
      watchedEventsAll[_uid]++;
    }

    if (watchedEventsAll[_uid] === 1) {
      if (priceStream) {
        priceStream.send(['watch_event', [parseInt(compId, 10), sport, eventId]]);
      } else {
        if (toWatchBatchAll.indexOf(_uid) === -1) {
          //cpricefeed handles duplicate with error
          toWatchBatchAll.push(_uid);
        }
      }
    }

    //console.warn('watchEventAll', objDiff(old, watchedEventsAll))
  }

  /**
   * unwatch event main handicap
   * @static
   * @param {string} compId - competition ID
   * @param {string} sport - sport (i.e. fb, fb_ht, basket, etc.)
   * @param {string} eventId - event ID
   * @param {boolean} soft - soft means that just the counter is decremented, but the command is not sent; the use-case for this is the component unmounting but we want the prices to update in the background
   * @param {boolean} force - force is used for cleanup in certain situations (counter goes to 0, unwatch is sent)
   */
  static unwatchEventMain(compId, sport, eventId, soft, force) {
    let _uid = `${compId}/${sport}/${eventId}`;
    //let old = {...watchedEventsMain}
    if (watchedEventsMain[_uid] === undefined) {
      watchedEventsMain[_uid] = 0;
    } else {
      if (force) {
        watchedEventsMain[_uid] = 0;
      } else {
        watchedEventsMain[_uid]--;
      }
    }

    if (watchedEventsMain[_uid] <= 0) {
      delete watchedEventsMain[_uid];
      // if (priceStream && !soft) {
      if (priceStream) {
        priceStream.send(['unwatch_hcaps', [[parseInt(compId, 10), sport, eventId]]]);
      }
      // remove from the to watch queue if it is in there
      if (toWatchBatchMain.indexOf(_uid) !== -1) {
        toWatchBatchMain.splice(toWatchBatchMain.indexOf(_uid), 1);
      }
    }

    //console.warn('unwatchEventMain', objDiff(old, watchedEventsMain))
  }

  /**
   * unwatch event all handicaps
   * @static
   * @param {string} compId - competition ID
   * @param {string} sport - sport (i.e. fb, fb_ht, basket, etc.)
   * @param {string} eventId - event ID
   * @param {boolean} soft - soft means that just the counter is decremented, but the command is not sent; the use-case for this is the component unmounting but we want the prices to update in the background
   * @param {boolean} force - force is used for cleanup in certain situations (counter goes to 0, unwatch is sent)
   */
  static unwatchEventAll(compId, sport, eventId, soft, force) {
    let _uid = `${compId}/${sport}/${eventId}`;
    //let old = {...watchedEventAll}
    if (watchedEventsAll[_uid] === undefined) {
      watchedEventsAll[_uid] = 0;
    } else {
      if (force) {
        watchedEventsAll[_uid] = 0;
      } else {
        watchedEventsAll[_uid]--;
      }
    }

    if (watchedEventsAll[_uid] <= 0) {
      delete watchedEventsAll[_uid];
      // if (priceStream && !soft) {
      if (priceStream) {
        priceStream.send(['unwatch_event', [parseInt(compId, 10), sport, eventId]]);
      }
      if (toWatchBatchAll.indexOf(_uid) !== -1) {
        toWatchBatchAll.splice(toWatchBatchAll.indexOf(_uid), 1);
      }
    }

    //console.warn('unwatchEventAll', objDiff(old, watchedEventsAll))
  }

  /**
   * is the event's main handicap line watched
   * @static
   * @param {string} compId - competition ID
   * @param {string} sport - sport (i.e. fb, fb_ht, basket, etc.)
   * @param {string} eventId - event ID
   * @return {boolean} - is the event main handicap line watched
   */
  static isWatchedMain(compId, sport, eventId) {
    let _uid = `${compId}/${sport}/${eventId}`;
    return !!watchedEventsMain[_uid];
  }

  /**
   * are all the event handicap lines watched
   * @static
   * @param {string} compId - competition ID
   * @param {string} sport - sport (i.e. fb, fb_ht, basket, etc.)
   * @param {string} eventId - event ID
   * @return {boolean} - are all the event handicap lines watched
   */
  static isWatchedAll(compId, sport, eventId) {
    let _uid = `${compId}/${sport}/${eventId}`;
    return !!watchedEventsAll[_uid];
  }

  /**
   * for testing purposes, resets all the watched maps
   * @static
   */
  static clearAll() {
    watchedEventsAll = {};
    toWatchBatchAll = [];
    watchedEventsMain = {};
    toWatchBatchMain = [];
  }
}

/** @format */

exports.default = {
  name: { id: 'sports.horse', defaultMessage: 'Horse Racing' }, //name
  shortName: { id: 'sports.horse.short', defaultMessage: 'Horse Racing' }, //short name (used in mobile modes)
  featuredCompetitions: [],
  featuredCompetitionsName: [], //feature competitions if name contains one of these
  noDraws: false, //this sport doesn't have draws (i.e. tennis, esports, etc)
  enabled: true, //is visible to normal users
  multirunner: true, // the sport should only have multirunner events
  maxTimeout: 259200, //maximum order expiry time
  correlated: {},
  correlatedOrder: [
    //for the purpose of + symbol on own page, used in other places as well so should always be defined
    'horse',
  ],
  trustScores: true, //do we display the scores for this sport (also applies to subsports)
  scoresFallbacks: {},
  useIrTypes: true, //do we use the ir variations when opening the betslip (for,ir,x,y...)
  //this also determines if the offer update function will apply handicap offsets based on score
  noMultiline: false, //this sport doesn't support the multiline feature
  matchDataOpener: 'for,a/null', //what bet type/handicap to use when opening match info window (because of offerhist)
  offerGroupsOrder: [
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  //symphathetic bet types ... it's a 'good enough' interface remap that makes
  //some betypes have fallback columns. the point of this is that events in favorites
  //that have differents sports may now show some non-exact-matching bet types
  //the way to read is: <bet_type_I_lack> : <bet_type_I_have>
  sympBetTypes: {},
};

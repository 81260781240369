/** @format */

import DSM from '../../lib/DSM';
import { toCamelCase } from '../../lib/camelSnake';
import config from '../../config';
import { toBetParams } from '../../lib/betTypes';
import { fromJS } from 'immutable';
import { parse } from 'cookie';

let initialState = fromJS({
  //map of open positions
  positions: {},
});

//similar to refreshing accounting information
let _betsTriggeredInterval = []; //bets that have triggered an interval refresh, so that the same 'done' bet doesn't trigger multiple position value refreshes

const functions = {
  //you need this action itterator wherever you use the pricefeed websocket
  data: (state, action) => {
    //batch updates
    //apply multiple things
    state = state.asMutable();
    if (action.data.data && typeof action.data.data === 'object') {
      for (let act of action.data.data) {
        state = reducer(state, { type: act[0], data: act[1] });
      }
    }
    return state.asImmutable();
  },

  //api stream is proxied so it has to be unpacked
  api: (state, action) => {
    let _data;
    if (action.data.ts) {
      _data = action.data.data;
    } else {
      _data = action.data[0].data;
    }

    if (_data) {
      state = state.asMutable();
      for (let _action of _data) {
        state = reducer(state, { type: _action[0], data: _action[1] });
      }
      state = state.asImmutable();
    }

    return state;
  },

  ////// BETSLIPS

  //this handles the respons from opening a betslip on the server
  //it is possible that the placer exists already
  //check if an open betslip is related to a position
  betslipOpenResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let betslipId = action.data.data.betslipId;

      if (betslipId) {
        let positionId = `${action.data.data.eventId}/${action.data.data.sport}`;
        //if there is a position open for this thing
        let position = state.getIn(['positions', positionId], null);
        if (position) {
          return state.mergeDeepIn(['positions', positionId, 'betslips', betslipId], {
            betType: action.data.data.betType,
            betTypeDescription: action.data.data.betTypeDescription,
          });
        }
      }
    }

    return state;
  },

  ////// API STREAM

  //reload position when a bet is updated (this should automatically cover orders too)
  //[API]
  bet: (state, action) => {
    if (
      action.data.gotStake &&
      action.data.gotStake[1] &&
      action.data.status &&
      action.data.status.code === 'done'
    ) {
      if (_betsTriggeredInterval.indexOf(action.data.betId) === -1) {
        _betsTriggeredInterval.push(action.data.betId);
        let positionId = `${action.data.eventId}/${action.data.sport}`;
        //if there is a position open for this thing
        DSM.forceIntervalRequest(`position/${positionId}`, true);
      }
    }

    return state;
  },

  ////// POSITIONS

  order: (state, action) => {
    // handle the case where the order is part of a position
    const parsedOrder = action.data;
    if (parsedOrder?.betType && parsedOrder?.orderType === 'custom') {
      const positionId = `${parsedOrder?.eventInfo?.eventId}/${parsedOrder.sport}`;
      if (!state.hasIn(['positions', positionId])) {
        // This means the order was placed on another window/we dont have position information,
        // updating state in this case would cause a ghost position grid window.
      } else if (parsedOrder.status === 'done') {
        state = state.setIn(['positions', positionId, 'cashoutInProgress'], false);
        state = state.setIn(['positions', positionId, 'cashoutCompleted'], true);
        state = state.setIn(['positions', positionId, 'cashoutError'], false);
      } else if (parsedOrder.status === 'failed') {
        state = state.setIn(['positions', positionId, 'cashoutCompleted'], false);
        state = state.setIn(['positions', positionId, 'cashoutInProgress'], false);
        state = state.setIn(['positions', positionId, 'cashoutError'], 'Cashout failed');
      }
    }

    return state;
  },

  cashoutClearError: (state, action) => {
    const { positionId } = action.data;
    state = state.setIn(['positions', positionId, 'cashoutInProgress'], false);
    state = state.setIn(['positions', positionId, 'cashoutError'], false);
    state = state.setIn(['positions', positionId, 'cashoutCompleted'], false);
    return state;
  },

  eventCashoutPosition: (state, action) => {
    const { eventId, sport, positionId, requestedValuation, requestedStake } = action.data;
    state = state.setIn(['positions', positionId, 'cashoutInProgress'], true);

    const requestValuationStake = requestedValuation?.get(1);
    const requestValuationCurrency = requestedValuation?.get(0);

    const requestStakeStake = requestedStake?.get(1);
    const requestStakeCurrency = requestedStake?.get(0);

    DSM.create(
      '/v1/position_management/cashout/',
      {
        method: 'POST',
        body: {
          eventId,
          sport,
          requestedValuation: [requestValuationCurrency, requestValuationStake],
          requestedStake: [requestStakeCurrency, requestStakeStake],
        },
        extras: {
          eventId,
          sport,
          positionId,
        },
        message: 'eventCashoutPositionResponse',
      },
      action.data.actions
    );
    return state;
  },

  eventCashoutPositionResponse: (state, action) => {
    const { positionId } = action.data.extras;
    // FIXME: Added at 8pm on a friday to stop ghost position grids popping up
    // need to find what is calling this!
    if (state.hasIn(['positions', positionId])) {
      state = state.setIn(['positions', positionId, 'cashoutInProgress'], false);
      const { code, data } = action.data;
      if (code === 'error') {
        state = state.setIn(['positions', positionId, 'cashoutError'], data);
      } else {
        state = state.setIn(['positions', positionId, 'cashoutCompleted'], true);
      }
    }
    // else we listen to the order being placed to change InProgress to false
    return state;
  },

  eventPollPositionCashoutValue: (state, action) => {
    const body = {
      eventId: action.data.eventId,
      sport: action.data.sport,
      pageSize: config.pageSizes.maxPositionPageSize,
    };
    DSM.ensureOne(
      '/v1/orders/position/',
      {
        method: 'GET',
        body,
        extras: {
          positionId: action.data.positionId,
        },
        message: 'eventPollPositionCashoutValueResponse',
      },
      action.data.actions,
      `position/${action.data.positionId}-cashoutCheck`
    );
    return state;
  },

  eventPollPositionCashoutValueResponse: (state, action) => {
    const { positionId } = action.data.extras;
    if (action.data.status === 'ok') {
      const cashoutData = action.data?.data?.cashoutInfo;
      if (cashoutData === undefined) {
        return state.removeIn(['positions', positionId, 'cashoutInfo']);
      } else {
        return state.mergeDeepIn(
          ['positions', positionId],
          fromJS({ cashoutInfo: { ...cashoutData } })
        );
      }
    } else {
      //handled in base
      return state;
    }
  },

  //this opens a position holder with the grid
  //request the position information and also grids for any custom bets
  eventOpenPosition: (state, action) => {
    //position id is based on sport and events
    let positionId = `${action.data.eventId}/${action.data.sport}`;
    let position = state.getIn(['positions', positionId], null);

    let openDraggable = true;
    if (action.data.openDraggable === false) {
      openDraggable = false;
    }

    //if there is no position, create a new one
    if (!position) {
      let body = {
        eventId: action.data.eventId,
        sport: action.data.sport,
        pageSize: config.pageSizes.maxPositionPageSize,
      };

      if (action.data.netPrices) {
        body['displayNetPrices'] = action.data.netPrices;
      }

      if (!action.data.agentPosition) {
        body['placer'] = action.data.placer;
      }

      if (action.data?.type !== 'pt')
        DSM.ensureOne(
          '/v1/orders/position/',
          {
            method: 'GET',
            body,
            extras: {
              status: config.ordersWithStakeStatuses, //only these actually may have stake
              eventId: action.data.eventId,
              sport: action.data.sport,
              openDraggable: openDraggable,
            },
            interval: !action.data.agentPosition ? config.timings.positionRefresh : null,
            message: 'eventOpenPositionResponse',
          },
          action.data.actions,
          `position/${positionId}`
        );

      state = state.setIn(
        ['positions', positionId],
        fromJS({
          eventId: action.data.eventId,
          orderId: action.data.orderId,
          sport: action.data.sport,
          homeName: action.data.homeName,
          awayName: action.data.awayName,
          competitionName: action.data.competitionName,
          customBets: action.data.customBets || {},
          isLoading: action.data?.type !== 'pt',
          type: action.data?.type,
          zIndex: +new Date(),
          openDraggable,
        })
      );

      //load any custom bet position grids
      if (action.data.customBets) {
        for (let betId in action.data.customBets) {
          //get Win/Loss grid for this specific bet type so we can use it in calculation
          DSM.create(
            `/v1/sports/${action.data.sport}/bet_types/${action.data.customBets[betId].betType}/`,
            {
              method: 'GET',
              body: {
                //won't work otherwise
                homeTeam: 'home',
                awayTeam: 'away',
              },
              //this data is not included in the response, so we pin in on
              extras: {
                betType: action.data.customBets[betId].betType,
                eventId: action.data.eventId,
                sport: action.data.sport,
              },
              message: 'eventOpenWinlossGridResponse',
            },
            action.data.actions
          );
        }
      }
    }
    return state;
  },

  //the data from the position open call
  eventOpenPositionResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let positionId = `${action.data.extras.eventId}/${action.data.extras.sport}`;
      return state.mergeDeepIn(['positions', positionId], {
        ...action.data.data,
        openDraggable: action.data.extras.openDraggable,
        isLoading: false,
      });
    } else {
      //handled in base
      return state;
    }
  },

  // this populates the win/loss grid for a certain betType position
  eventOpenWinlossGrid: (state, action) => {
    //get Win/Loss grid for this specific bet type so we can use it in calculation
    DSM.create(
      `/v1/sports/${action.data.sport}/bet_types/${action.data.betType}/`,
      {
        method: 'GET',
        body: {
          //won't work otherwise
          homeTeam: 'home',
          awayTeam: 'away',
        },
        //this data is not included in the response, so we pin in on
        extras: {
          betType: action.data.betType,
          eventId: action.data.eventId,
          sport: action.data.sport,
        },
        message: 'eventOpenWinlossGridResponse',
      },
      action.data.actions
    );

    return state;
  },

  //handle the win/loss grid information
  eventOpenWinlossGridResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let positionId = `${action.data.extras.eventId}/${action.data.extras.sport}`;
      return state.mergeDeepIn(
        ['positions', positionId, 'wlGrids', action.data.extras.betType],
        action.data.data
      );
    } else {
      //handled in base
      return state;
    }
  },

  eventClearWinlossGrid: (state, action) => {
    let positionId = `${action.data.eventId}/${action.data.sport}`;
    return state.removeIn(['positions', positionId, 'wlGrids', action.data.betType]);
  },

  //close position holder and perform cleanup
  eventClosePosition: (state, action) => {
    let positionId = `${action.data.eventId}/${action.data.sport}`;
    DSM.stop(`position/${positionId}`);

    return state.removeIn(['positions', positionId]);
  },

  //bring position holder to foreground
  eventPopPosition: (state, action) => {
    let positionId = `${action.data.eventId}/${action.data.sport}`;
    return state.setIn(['positions', positionId, 'zIndex'], +new Date());
  },

  //close all the positions and do cleanup of streams
  closeAllPositions: (state, _action) => {
    DSM.stopAllStartingWith('position/');
    return state.remove('positions');
  },

  ////// CUSTOM BET

  //add a custom bet to position grid
  positionAddCustomBet: (state, action) => {
    let positionId = `${action.data.eventId}/${action.data.sport}`;
    let betId = +new Date() + '';
    let offerGroup = toBetParams(action.data.customBet.betType).offerGroup;
    let hand = action.data.customBet.handicap;

    if (config.offerGroups[offerGroup].handicap) {
      if (!config.offerGroups[offerGroup].score && !config.offerGroups[offerGroup].displayAsRaw) {
        hand = 4 * parseFloat(hand);
        if (isNaN(hand)) {
          hand = 0;
        }
      }
    }
    let betType = `${action.data.customBet.betType}${hand ? `,${hand}` : ''}`;

    state = state.setIn(
      ['positions', positionId, 'customBets', betId],
      fromJS({
        gotStake: [action.data.displayCcy, parseFloat(action.data.customBet.stake)],
        gotPrice: action.data.customBet.price,
        betTypeDescription: action.data.betTypeDescription,
        betId,
        betType,
      })
    );

    //get Win/Loss grid for this specific bet type so we can use it in calculation
    DSM.create(
      `/v1/sports/${action.data.sport}/bet_types/${betType}/`,
      {
        method: 'GET',
        body: {
          //won't work otherwise
          homeTeam: 'home',
          awayTeam: 'away',
        },
        //this data is not included in the response, so we pin in on
        extras: {
          betType,
          eventId: action.data.eventId,
          sport: action.data.sport,
        },
        message: 'eventOpenWinlossGridResponse',
      },
      action.data.actions
    );

    return state;
  },

  //remove a custom bet from position grid
  positionRemoveCustomBet: (state, action) => {
    let positionId = `${action.data.eventId}/${action.data.sport}`;
    return state.removeIn(['positions', positionId, 'customBets', action.data.betId]);
  },

  ////// LOGOUT

  //reset some state
  logout: (state, _action) => {
    DSM.stopAllStartingWith('position/');
    return state.remove('positions');
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

/** @format */

exports.default = {
  name: { id: 'sports.af', defaultMessage: 'American Football' },
  shortName: { id: 'sports.af.short', defaultMessage: 'American Football' },
  featuredCompetitions: ['545', '546'],
  featuredCompetitionsName: [],
  enabled: true,
  maxTimeout: 7200,
  correlated: {},
  correlatedOrder: ['af'],
  trustScores: true,
  noDraws: true,
  matchDataOpener: 'for,tp,all,ml,a/null',
  offerGroupsOrder: [
    'timeWin,tp,all,ml',
    //'timeWin,tp,all,wdw',
    'timeAh,tp,all',
    'timeAhou,tp,all',
    //'timeWin,tp,reg,wdw',
    //'timeAh,tp,reg',
    //'timeAhou,tp,reg',
    'timeWin,tp,reg,ml',
    'timeWin,thalf,1,ml',
    //'timeWin,thalf,1,wdw',
    'timeAh,thalf,1',
    //'timeAhou,thalf,1',
    'timeWin,thalf,2,ml',
    //'timeWin,thalf,2,wdw',
    'timeAh,thalf,2',
    //'timeAhou,thalf,2',
    //'timeOe,tp,all,oe',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    ml: 'timeWin,tp,all,ml',
    'tennisMatch,all': 'timeWin,tp,all,ml',
    ah: 'timeAh,tp,all',
    'tennisAh,all,set': 'timeAh,tp,all',
    ahou: 'timeAhou,tp,all',
    'tennisAhou,all,game': 'timeAhou,tp,all',
    //oe: 'timeOe,tp,all,oe',
    //'timeOe,tp,reg,oe': 'timeOe,tp,all,oe',
    //'tennisOe,all,game': 'timeOe,tp,all,oe',
  },
};

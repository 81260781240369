/** @format */

// @flow
import { MINUS_PREFIX, toMaxFixed } from './formatters';

/**
 * Figure out how to put commas in a long numeric value
 * @param {number|string} input - snake case
 * @return {string}
 */
function comma(input: number | string) {
  const number = Math.abs(Number(input)).toString();

  if (number.length > 3) {
    let mod = number.length % 3;
    let output = mod > 0 ? number.substring(0, mod) : '';
    for (let i = 0; i < Math.floor(number.length / 3); i++) {
      if (mod === 0 && i === 0) {
        output += number.substring(mod + 3 * i, mod + 3 * i + 3);
      } else {
        output += ',' + number.substring(mod + 3 * i, mod + 3 * i + 3);
      }
    }
    return output;
  } else {
    return number;
  }
}

/**
 * Currency instantiation class
 */
class Currency {
  symbol: string;
  decimal: boolean;

  /**
   * @param {string} symbol - symbol of the currency
   * @param {boolean} decimal - does this currency have decimal places
   */
  constructor(symbol: string, decimal: boolean) {
    this.symbol = symbol;
    this.decimal = decimal;
  }

  /**
   * @param {string} val - stake amount to format
   * @param {boolean} useSymbol - should use currency symbol
   * @return {string} - formatted currency
   */
  formatFromStake(val: number, useSymbol?: boolean = true) {
    let symb = val < 0 ? MINUS_PREFIX : '';
    val = Math.abs(val);

    let symbol = useSymbol ? this.symbol : '';
    if (!this.decimal) {
      return symb + symbol + comma(toMaxFixed(val, 0));
    } else {
      if (val - Math.round(val * 100) / 100 < 0.005) {
        val = Math.round(val * 100) / 100;
      } else {
        val = Math.round((val + 0.005) * 100) / 100;
      }
      let i = toMaxFixed(val, 2);
      let bits = i.split('.');
      return symb + symbol + comma(bits[0]) + '.' + bits[1];
    }
  }
}

//TODO let flow infer this type more correctly
//? The inference algorithm (correctly) infers this as a
//? shaped, sealed object type. But some of our old code
//? that was annotated without refactoring doesn't like it
const curr: { [ccyCode: string]: void | Currency, ... } = {
  gbp: new Currency('\u00a3', true),
  eur: new Currency('\u20ac', true),
  usd: new Currency('$', true),
  hkd: new Currency('HK$', true),
  cny: new Currency('\u00a5', true),
  thb: new Currency('\u0e3f', true),
  twd: new Currency('TWD', true),
  krw: new Currency('\u20a9', false),
  idr: new Currency('Rp', false),
  kidr: new Currency('kRp', true),
  aud: new Currency('AU$', true),
  sgd: new Currency('S$', true),
  myr: new Currency('MYR', true),
  nok: new Currency('NOK', true),
  sek: new Currency('SEK', true),
  smrkgbp: new Currency('smrkGBP', true),
  brl: new Currency('R$', true),
  usdt: new Currency('\u20AE', true),
  singeur: new Currency('SINGEUR', true),
};

export default curr;

/** @format */

const Generators = require('../positionGrids').Generators;

//these are the most widely used ones (football-ish)
//see: https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  wdw: {
    name: { id: 'groupNames.wdw', defaultMessage: '1 X 2' }, //full name of group
    shortName: { id: 'groupNames.short.wdw', defaultMessage: '1X2' }, //name to show in group column and mobile view
    handicap: false, //does this bet type use a handicap
    //isAsian: true, //is asian handicap, i.e. offset and divided
    //isOverUnder: true, //is it an over under bet type, mainly for display purposes
    //width: 3, //should this column take up more space? i.e 3 times the normal; 2,3,4 are there; if you want more you have to tweak the sass as well
    //score: true, //is this a score bet type
    //displayAsRaw: true, //don't divide "handicap" by 4
    //range: true, //does it represent a range of outcomes, i.e. above 2, below 4
    //yesNo: false, //is this a yes or no type bet
    order: ['h', 'd', 'a'], //sd is not standard, means score-draw
    betTypeTemplate: 'for,{outcome}', //used to decode bet parameters
    position: {
      // describes how position works
      generator: Generators.wdw, //generator function
      dimension: 'score', //event dimension to be used as the score, to calculate position and PnL
      size: 15, //size position grid can go up to (just visually)
    },
    overroundFactor: 1, //does the overround need to be multiplied by something, usually because bet cases overlap; 0 means no overround
    canUseInAccas: true, //is usable in accumulators
    //irOffset: true, //does this group depend on in running score offset
    betTypes: {
      //map of bet types associated with this group
      h: {
        type: 'for,h', //actual bet type
        template: { id: 'betTypes.wdw,h', defaultMessage: '{home}' }, //used to translate bet types
        columnName: { id: 'columnNames.wdw,h', defaultMessage: '1' }, //name to show in column
        // irType: (hcap, [home, away]) => //special in running bet type function, used when event is in running;
        //   `for,ir,${parseInt(home, 10)},${parseInt(away, 10)},ah,h,${parseInt(hcap, 10)}`,
        // irTemplate: { //used in front-end translation of bet type
        //   id: 'betTypes.ah,ir,h',
        //   defaultMessage: '{home} {handicap} (Asian, {homeScore} - {awayScore})',
        // },
      },
      d: {
        type: 'for,d',
        template: { id: 'betTypes.wdw,d', defaultMessage: 'Draw' },
        columnName: { id: 'columnNames.wdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,a',
        template: { id: 'betTypes.wdw,a', defaultMessage: '{away}' },
        columnName: { id: 'columnNames.wdw,a', defaultMessage: '2' },
      },
      sd: {
        //score draw: for completeness, not used
        type: 'for,sd',
        template: { id: 'betTypes.wdw,sd', defaultMessage: 'Score Draw' },
        columnName: { id: 'columnNames.wdw,sd', defaultMessage: 'SD' },
      },
    },
  },
  dc: {
    name: { id: 'groupNames.dc', defaultMessage: 'Double Chance' },
    shortName: { id: 'groupNames.short.dc', defaultMessage: 'Double' },
    handicap: false,
    order: ['h,d', 'h,a', 'a,d'],
    betTypeTemplate: 'for,dc,{outcome},{outcome2}',
    position: { generator: Generators.dc, dimension: 'score', size: 15 },
    overroundFactor: 0.5,
    betTypes: {
      'h,d': {
        type: 'for,dc,h,d',
        template: { id: 'betTypes.dc,h,d', defaultMessage: '{home} or Draw' },
        columnName: { id: 'columnNames.dc,h,d', defaultMessage: '1X' },
      },
      'h,a': {
        type: 'for,dc,h,a',
        template: { id: 'betTypes.dc,h,a', defaultMessage: '{home} or {away}' },
        columnName: { id: 'columnNames.dc,h,a', defaultMessage: '12' },
      },
      'a,d': {
        type: 'for,dc,a,d',
        template: { id: 'betTypes.dc,a,d', defaultMessage: '{away} or Draw' },
        columnName: { id: 'columnNames.dc,a,d', defaultMessage: 'X2' },
      },
      //for completeness ??
      'd,a': {
        type: 'for,dc,d,a',
        template: { id: 'betTypes.dc,d,a', defaultMessage: '{away} or Draw' },
        columnName: { id: 'columnNames.dc,d,a', defaultMessage: 'X2' },
      },
      'd,h': {
        type: 'for,dc,d,h',
        template: { id: 'betTypes.dc,d,h', defaultMessage: 'Draw or {home}' },
        columnName: { id: 'columnNames.dc,d,h', defaultMessage: 'X1' },
      },
      'a,h': {
        type: 'for,dc,a,h',
        template: { id: 'betTypes.dc,a,h', defaultMessage: '{away} or {home}' },
        columnName: { id: 'columnNames.dc,a,h', defaultMessage: '21' },
      },
    },
  },
  ml: {
    name: { id: 'groupNames.ml', defaultMessage: 'Moneyline' },
    shortName: { id: 'groupNames.short.ml', defaultMessage: '1/2' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,ml,{outcome}',
    position: { generator: Generators.ml, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      h: {
        type: 'for,ml,h',
        template: { id: 'betTypes.ml,h', defaultMessage: '{home}' },
        columnName: { id: 'columnNames.ml,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,ml,a',
        template: { id: 'betTypes.ml,a', defaultMessage: '{away}' },
        columnName: { id: 'columnNames.ml,a', defaultMessage: '2' },
      },
    },
  },

  ah: {
    name: { id: 'groupNames.ah', defaultMessage: 'Asian Handicap' },
    shortName: { id: 'groupNames.short.ah', defaultMessage: 'Asian Hcp.' },
    handicap: true,
    isAsian: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,ah,{team},{hcap}|for,ir,{irhome},{iraway},ah,{team},{hcap}',
    position: { generator: Generators.ah, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    irOffset: true,
    betTypes: {
      h: {
        type: 'for,ah,h',
        template: { id: 'betTypes.ah,h', defaultMessage: '{home} {handicap} (Asian)' },
        irType: (hcap, [home, away]) =>
          `for,ir,${parseInt(home, 10)},${parseInt(away, 10)},ah,h,${parseInt(hcap, 10)}`,
        irTemplate: {
          id: 'betTypes.ah,ir,h',
          defaultMessage: '{home} {handicap} (Asian, {homeScore} - {awayScore})',
        },
        columnName: { id: 'columnNames.ah,h', defaultMessage: 'A/1' },
      },
      a: {
        type: 'for,ah,a',
        template: { id: 'betTypes.ah,a', defaultMessage: '{away} {handicap} (Asian)' },
        irType: (hcap, [home, away]) =>
          `for,ir,${parseInt(home, 10)},${parseInt(away, 10)},ah,a,${parseInt(hcap, 10)}`,
        irTemplate: {
          id: 'betTypes.ah,ir,a',
          defaultMessage: '{away} {handicap} (Asian, {homeScore} - {awayScore})',
        },
        columnName: { id: 'columnNames.ah,a', defaultMessage: 'A/2' },
      },
    },
  },
  ahou: {
    name: { id: 'groupNames.ahou', defaultMessage: 'Asian Total' },
    shortName: { id: 'groupNames.short.ahou', defaultMessage: 'Asian O/U' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,ah{direction},{line}|for,ir,{irhome},{iraway},ah{direction},{line}',
    position: { generator: Generators.ahou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    canUseInAccas: true,
    betTypes: {
      over: {
        type: 'for,ahover',
        template: { id: 'betTypes.ahou,over', defaultMessage: 'Over {handicap} (Asian)' },
        irType: (hcap, [home, away]) => `for,ir,${home},${away},ahover,${hcap}`,
        irTemplate: {
          id: 'betTypes.ahou,ir,over',
          defaultMessage: 'Over {handicap} (Asian, {homeScore} - {awayScore})',
        },
        columnName: { id: 'columnNames.ahou,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,ahunder',
        template: { id: 'betTypes.ahou,under', defaultMessage: 'Under {handicap} (Asian)' },
        irType: (hcap, [home, away]) => `for,ir,${home},${away},ahunder,${hcap}`,
        irTemplate: {
          id: 'betTypes.ahou,ir,under',
          defaultMessage: 'Under {handicap} (Asian, {homeScore} - {awayScore})',
        },
        columnName: { id: 'columnNames.ahou,under', defaultMessage: 'U' },
      },
    },
  },
  'tahou,a': {
    name: { id: 'groupNames.tahou,a', defaultMessage: 'Away Goals' },
    shortName: { id: 'groupNames.short.tahou,a', defaultMessage: 'Away O/U' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tahover,a',
        template: {
          id: 'betTypes.tahou,a,over',
          defaultMessage: '{away} to score Over {handicap}',
        },
        columnName: { id: 'columnNames.tahou,a,over', defaultMessage: '2/O' },
      },
      under: {
        type: 'for,tahunder,a',
        template: {
          id: 'betTypes.tahou,a,under',
          defaultMessage: '{away} to score Under {handicap}',
        },
        columnName: { id: 'columnNames.tahou,a,under', defaultMessage: '2/U' },
      },
    },
  },
  'tahou,h': {
    name: { id: 'groupNames.tahou,h', defaultMessage: 'Home Goals' },
    shortName: { id: 'groupNames.short.tahou,h', defaultMessage: 'Home O/U' },
    handicap: true,
    isOverUnder: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,tah{direction},{team},{line}',
    position: { generator: Generators.tahou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,tahover,h',
        template: {
          id: 'betTypes.tahou,h,over',
          defaultMessage: '{home} to score Over {handicap}',
        },
        columnName: { id: 'columnNames.tahou,h,over', defaultMessage: '1/O' },
      },
      under: {
        type: 'for,tahunder,h',
        template: {
          id: 'betTypes.tahou,h,under',
          defaultMessage: '{home} to score Under {handicap}',
        },
        columnName: { id: 'columnNames.tahou,h,under', defaultMessage: '1/U' },
      },
    },
  },
};

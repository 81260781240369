/** @format */

exports.default = {
  name: { id: 'sports.tennis', defaultMessage: 'Tennis' },
  shortName: { id: 'sports.tennis.short', defaultMessage: 'Tennis' },
  featuredCompetitions: [],
  featuredCompetitionsName: [
    'Grand Slam',
    'WTA Premier',
    'ATP World Tour',
    'ATP Masters',
    'Davis Cup',
    'Fed Cup',
    'WTA Elite',
  ],
  enabled: true,
  maxTimeout: 7200,
  correlated: {},
  correlatedOrder: ['tennis'],
  trustScores: true,
  noDraws: true,
  matchDataOpener: 'for,tset,all,p1/null',
  offerGroupsOrder: [
    'tennisMatch,all',
    'tennisAh,all,set',
    'tennisAh,all,game',
    'tennisAhou,all,game',
    'tennisOe,all,game',
    'tennisMatch,1',
    'tennisAh,1,game',
    'tennisAhou,1,game',
    'tennisOe,1,game',
    'tennisMatch,2',
    'tennisAh,2,game',
    'tennisAhou,2,game',
    'tennisOe,2,game',
    'tennisMatch,3',
    'tennisAh,3,game',
    'tennisAhou,3,game',
    'tennisOe,3,game',
    'tennisMatch,4',
    'tennisAh,4,game',
    'tennisAhou,4,game',
    'tennisOe,4,game',
    'tennisCs,all,set',
    'tennisMatch,all,set,total',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    wdw: 'tennisMatch,all',
    ml: 'tennisMatch,all',
    ah: 'tennisAh,all,set',
    cs: 'tennisCs,all,set',
    ahou: 'tennisAhou,all,game',
    'timeWin,tp,reg,wdw': 'tennisMatch,all',
    'timeAh,tp,all': 'tennisAh,all,set',
    'timeAhou,tp,all': 'tennisAhou,all,game',
    'timeWin,tp,all,ml': 'tennisMatch,all',
    'timeOe,tp,all,oe': 'tennisOe,all,game',
    //'timeOe,tp,reg,oe': 'tennisOe,all,game',
    oe: 'tennisOe,all,game',
    exactTotal: 'tennisMatch,all,set,total',
  },
};

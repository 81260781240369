/** @format */

exports.markets = ['favs', 'outrights', 'ir', 'today', 'early']; //ordered list
exports.marketNames = {
  //translations
  favs: { id: 'marketNames.favs', defaultMessage: 'Favourites' },
  ir: { id: 'marketNames.ir', defaultMessage: 'In Running' },
  today: { id: 'marketNames.today', defaultMessage: 'Today' },
  early: { id: 'marketNames.early', defaultMessage: 'Early' },
  outrights: { id: 'marketNames.outrights', defaultMessage: 'Outrights' },
  fullTime: { id: 'markets.fullTime', defaultMessage: 'Full Time' },
  halfTime: { id: 'markets.halfTime', defaultMessage: 'Half Time' },
  halfTimeFullTime: { id: 'markets.halfTimeFullTime', defaultMessage: 'Half Time/Full Time' },
  corners: { id: 'markets.corners', defaultMessage: 'Corners' },
  halfTimeCorners: { id: 'markets.cornersHalfTime', defaultMessage: 'Half Time Corners' },
  extraTime: { id: 'markets.extraTime', defaultMessage: 'Extra Time' },
};

/** @format */

//here is where you add sports
//note the require and .default

const fb = require('./sports/fb').default;
const fb_htft = require('./sports/fb_htft').default;
const basket = require('./sports/basket').default;
const tennis = require('./sports/tennis').default;
const af = require('./sports/af').default;
const golf = require('./sports/golf').default;
const baseball = require('./sports/baseball').default;
const ih = require('./sports/ih').default;
const cricket = require('./sports/cricket').default;
const ru = require('./sports/ru').default;
const boxing = require('./sports/boxing').default;
const mma = require('./sports/mma').default;
const rl = require('./sports/rl').default;
const arf = require('./sports/arf').default;
const esports = require('./sports/esports').default;
const hand = require('./sports/hand').default;
const darts = require('./sports/darts').default;
const horse = require('./sports/horse').default;

exports.default = {
  fb,
  fb_htft,
  basket,
  tennis,
  af,
  ih,
  golf,
  horse,
  baseball,
  esports,
  mma,
  cricket,
  ru,
  boxing,
  rl,
  arf,
  hand,
  darts,
};

/** @format */

//import * as Sentry from '@sentry/browser';
let NOTIFS = [];

/**
 * try to pop a browser notification after requesting permissions to do so
 * @param {string} title - notification title
 * @param {Object} notif - notification body (looks like https://developer.mozilla.org/en-US/docs/Web/API/notification)
 */
export function notificationWithPermission(title, notif) {
  if (window && window.Notification) {
    const forWhat = () => {
      try {
        NOTIFS.push(new window.Notification(title, notif));
      } catch (err) {
        //can't do anything about this
        //Sentry.captureException(err);
      }
    };

    if (window.Notification.permission !== 'granted') {
      try {
        window.Notification.requestPermission().then(forWhat);
      } catch (err) {
        //it's ok to fail here
        //can't do anything anyway
        //Sentry.captureException(err);
        console.warn('Could not request notification permission ...');
      }
    } else {
      forWhat();
    }
  }
}

/**
 * close all the notifications that have been popped in the browser
 */
export function closeAllNotifications() {
  for (let notif of NOTIFS) {
    try {
      notif.close();
    } catch (err) {
      //Sentry.captureException(err);
      //its ok to fail here
    }
  }

  NOTIFS = [];
}

/**
 * because of long team/player names sometimes the information doesn't fit in the notification box
 * so it needs to be shortened to a target length
 * @param {string} home - home name
 * @param {string} away - away name
 * @param {number} target - target match name length
 * @param {boolean} isName - notification title
 * @param {string} truncator - what to use if information doesn't fit
 * @return {string} - shrunken match info
 */
export function shrinkMatchName(home, away, target, isName = false, truncator = '…') {
  const nameSeperator = ' v ';

  if (home.length + away.length + nameSeperator.length <= target)
    return `${home}${nameSeperator}${away}`;

  if (isName)
    return shrinkMatchName(
      home.split(' ').slice(1).join(' '),
      away.split(' ').slice(1).join(' '),
      target
    );

  let amountToTrim = home.length + away.length + nameSeperator.length - target;
  let awayToTrim = 0;
  let homeToTrim = 0;

  if (home.length > away.length) {
    homeToTrim += Math.min(amountToTrim, home.length - away.length);
    amountToTrim -= homeToTrim;
  } else {
    awayToTrim += Math.min(amountToTrim, away.length - home.length);
    amountToTrim -= awayToTrim;
  }

  homeToTrim += Math.floor(amountToTrim / 2);
  homeToTrim += homeToTrim ? truncator.length : 0;
  awayToTrim += Math.ceil(amountToTrim / 2);
  awayToTrim += awayToTrim ? truncator.length : 0;

  let result = '';
  result += `${home.slice(0, home.length - homeToTrim)}${homeToTrim ? '' : truncator}`;
  result += `${nameSeperator}`;
  result += `${away.slice(0, away.length - awayToTrim)}${awayToTrim ? '' : truncator}`;
  return result;
}

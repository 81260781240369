/** @format */

import DSM from '../../lib/DSM';
import { toCamelCase } from '../../lib/camelSnake';
import config from '../../config';

import { fromJS } from 'immutable';

const INITIAL_STATE = {
  //a map of active positions
  activePositions: {},
  //a map of active parlays
  activeParlays: {},
  //have active positions been loaded
  activePositionsLoaded: false,
  //have active parlays been loaded
  activeParlaysLoaded: false,

  //are focused position orders being loaded
  isLoadingEventOrders: false,
  //map of event orders
  eventOrders: {},

  //the position currently in focus (displayed on the side)
  focusedPosition: {
    eventId: '',
    sport: '',
    orderId: '',
  },
};

let initialState = fromJS(INITIAL_STATE);

//we don't want updates to the same bet to trigger this every time
//the action logic also looks if the bet actually has stake
let _betsTriggeredInterval = []; //bets that have triggered an interval refresh, so that the same 'done' bet doesn't trigger multiple position value refreshes

const functions = {
  //you need this action itterator wherever you use the pricefeed websocket
  data: (state, action) => {
    state = state.asMutable();

    //apply multiple things
    if (action.data.data && typeof action.data.data === 'object') {
      for (let act of action.data.data) {
        state = reducer(state, { type: act[0], data: typeof act[1] !== 'object' ? act : act[1] });
      }
    }

    return state.asImmutable();
  },

  //api stream is proxied so it has to be unpacked
  api: (state, action) => {
    let _data;
    if (action.data.ts) {
      _data = action.data.data;
    } else {
      _data = action.data[0].data;
    }

    if (_data) {
      state = state.asMutable();
      for (let _action of _data) {
        state = reducer(state, { type: _action[0], data: _action[1] });
      }
      state = state.asImmutable();
    }

    return state;
  },

  ////// API STREAM

  //reload position when a bet is updated (should also cover orders)
  //[API]
  bet: (state, action) => {
    if (
      action.data.gotStake &&
      action.data.gotStake[1] &&
      action.data.status &&
      action.data.status.code === 'done'
    ) {
      if (_betsTriggeredInterval.indexOf(action.data.betId) === -1) {
        _betsTriggeredInterval.push(action.data.betId);
        let positionId = `${action.data.eventId}/${action.data.sport}`;

        //refresh just that position
        if (state.hasIn(['activePositions', positionId])) {
          DSM.last(
            `/v1/orders/position/`,
            {
              method: 'GET',
              body: {
                status: config.ordersWithStakeStatuses, //only these actually may have stake
                eventId: action.data.eventId,
                sport: action.data.sport,
                pageSize: config.pageSizes.maxPositionPageSize,
                //placer: '???'
              },
              extras: {
                eventId: action.data.eventId,
                sport: action.data.sport,
              },
              message: 'eventOpenPositionResponse',
            },
            action.data.actions,
            `position/${positionId}`
          );
        } else {
          DSM.forceIntervalRequest('activePositionStream', true);
          DSM.forceIntervalRequest('activeParlaysStream', true);
        }

        if (
          state.getIn(['focusedPosition', 'eventId']) === action.data.eventId &&
          state.getIn(['focusedPosition', 'sport']) === action.data.sport
        ) {
          DSM.forceIntervalRequest('activePositionsEventOrdersStream', true);
        }
      }
    }

    return state;
  },

  //update one particular even position because of the above
  eventOpenPositionResponse: (state, action) => {
    if (action.data.status === 'ok') {
      let positionId = `${action.data.extras.eventId}/${action.data.extras.sport}`;
      if (state.hasIn(['activePositions', positionId])) {
        return state.mergeDeepIn(['activePositions', positionId, 'position'], {
          ...action.data.data,
        });
      } else {
        return state;
      }
    } else {
      //handled in base
      return state;
    }
  },

  ////// ACTIVE POSITIONS

  //load the normal order and parlays
  getActivePositions: (state, action) => {
    DSM.ensureOne(
      `/v1/orders/active_positions/`,
      {
        method: 'GET',
        body: {
          placer: action.data.placer,
        },
        message: 'loadActivePositions',
        interval: config.timings.activePositionReload,
      },
      action.data.actions,
      'activePositionStream'
    );

    if (action.data.parlays) {
      DSM.ensureOne(
        `/v1/orders/`,
        {
          method: 'GET',
          body: {
            placer: action.data.placer,
            orderType: 'accas',
            status: config.ordersWithStakeStatuses,
          },
          message: 'loadActiveParlays',
          interval: config.timings.activePositionReload,
        },
        action.data.actions,
        'activeParlaysStream'
      );

      state = state.set('activeParlaysLoaded', false);
    } else {
      state = state.set('activeParlaysLoaded', true);
    }

    return state.set('activePositionsLoaded', false);
  },

  //handle active positions data
  loadActivePositions: (state, action) => {
    if (action.data.status === 'ok') {
      let _positions = {};
      //we need a dict for better access
      for (let position of action.data.data) {
        _positions[`${position.eventId}/${position.sport}`] = position;
      }
      state = state.set('activePositions', fromJS(_positions));
      state = state.set('activePositionsLoaded', true);
    } else {
      //handled by base
    }
    return state;
  },

  //handle active parlays data
  loadActiveParlays: (state, action) => {
    if (action.data.status === 'ok') {
      let _parlays = {};
      //we need a dict for better access
      for (let parlay of action.data.data) {
        _parlays[parlay.orderId] = parlay;
      }
      state = state.set('activeParlays', fromJS(_parlays));
      state = state.set('activeParlaysLoaded', true);
    } else {
      //handled by base
    }
    return state;
  },

  //get all orders for a particular event + sport
  //this happens when a certain position is focused (not on parlays)
  getEventOrders: (state, action) => {
    DSM.ensureOne(
      `/v1/orders/`,
      {
        method: 'GET',
        message: 'loadEventOrders',
        body: action.data.orderId
          ? {
              orderId: action.data.orderId,
            }
          : {
              status: config.ordersWithStakeStatuses, //only these actually may have stake
              eventId: action.data.eventId,
              sport: action.data.sport,
              pageSize: config.pageSizes.activePositionsEventOrders,
            },
        extras: {
          eventId: action.data.eventId,
        },
        interval: action.data.orderId ? null : config.timings.activePositionEventOrderReload,
      },
      action.data.actions,
      'activePositionsEventOrdersStream'
    );

    state = state.set('focusedPosition', fromJS(action.data));
    state = state.set('eventOrders', fromJS({}));
    return state.set('isLoadingEventOrders', true);
  },

  //whenever a cashout happens, we have to reload activePositionsEventOrdersStream
  cashOutPositionResponse: (state, _action) => {
    state = state.set('eventOrders', fromJS({}));
    state = state.set('isLoadingEventOrders', true);
    DSM.forceIntervalRequest('activePositionsEventOrdersStream', true);
    return state;
  },

  //handle event orders
  loadEventOrders: (state, action) => {
    if (action.data.status === 'ok') {
      let orders = {};
      for (let order of action.data.data) {
        orders[order.orderId + ''] = order;
      }
      state = state.mergeDeepIn(['eventOrders'], fromJS(orders));
    } else {
      //handled by base
    }

    return state.set('isLoadingEventOrders', false);
  },

  //toggle if we're showing the bets of an event order
  toggleEventOrderBets: (state, action) => {
    let isExp = state.getIn(['eventOrders', action.data.orderId + '', 'isExpanded'], false);
    return state.setIn(['eventOrders', action.data.orderId + '', 'isExpanded'], !isExp);
  },

  //cleanup of positions, parlays and position event orders
  cleanActivePositionsStreams: (state, _action) => {
    DSM.stop('activePositionStream');
    DSM.stop('activePositionsEventOrdersStream');
    DSM.stop('activeParlaysStream');
    return state;
  },

  ////// LOGOUT

  //reset some state
  logout: (state, _action) => {
    DSM.stop('activePositionStream');
    DSM.stop('activePositionsEventOrdersStream');
    DSM.stop('activeParlaysStream');
    state = fromJS(INITIAL_STATE);
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

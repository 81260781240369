/** @format */

import { toBetParams } from './betTypes';
import { formatPriceToDecimal, formatAmount } from './formatters';
import config from '../config';

import _ from 'lodash';

/**
 * Returns if a bookie is an exchange bookie.
 * @param {string} bookie - the amount
 * @return {boolean}
 */
export function isExchange(bookie) {
  return !!(config.bookies[bookie] && config.bookies[bookie].isExchange);
}

/**
 * Generate a placer id based on some betslip data. This should function as a unique ID generator.
 * Betslips with the same bet type type but different handicap should be groupped under the same placer.
 * @param {Object} data - the amount
 * @return {string} - placer id
 */
export function generatePlacerId(data) {
  let { offerGroup, offer } = toBetParams(data.betType);
  let ret = `${data.eventId}/${data.sport}/${data.betType.split(',')[0]}/${offerGroup}/${offer}`;
  return ret;
}

/**
 * When displaying accounts names we sometimes don't want the full name because it's long and contains special characters like - and @.
 * @param {string} str - the full account name
 * @return {string} - significant part more than 6 characters long
 */
export function significantPart(str) {
  if (str && typeof str === 'string') {
    var ret = str.split('-')[0].split('@')[0];
    if (ret.length < 6) {
      if (str.split('-')[1]) {
        ret += str.split('-')[1].split('@')[0];
      } else if (str.split('-')[0].split('@')[1]) {
        ret += str.split('-')[0].split('@')[1];
      }
    }

    return ret;
  } else {
    return '-';
  }
}

/**
 * This function checks some of the basic conditions for a betslip being able to place and returns a string representing a reason why it can't. Alternatively it will return null.
 * @param {Object} bsl - an object representation of a betslip (comes from .toJS())
 * @return {string|null} - the reason
 */
export function reasonCantPlaceBetslip(bsl, isNormalBackBetslip = true) {
  if (isNormalBackBetslip) {
    // normal back betslip

    if (!bsl.allTermsAccepted) {
      return 'accept terms';
    } else if (!bsl.webPlaceBets) {
      return 'betting disabled';
    } else if (bsl.reselectAccounts === 'never' && !bsl.selectedAccounts) {
      return 'no accounts';
    } else if (bsl.reselectAccounts === 'only_placement_bookies' && !bsl.selectedBookies) {
      return 'no bookies';
    } else if (!bsl.stake || !parseFloat(bsl.stake)) {
      return 'no stake';
    } else if (
      bsl.creditLimit !== null &&
      bsl.stake &&
      parseFloat(bsl.stake) > bsl.availableCredit + bsl.smartCredit
    ) {
      return 'low credit';
    } else if (!bsl.price) {
      return 'no price';
    } else if (formatPriceToDecimal(bsl.price, bsl.priceType) < 1) {
      return 'bad price';
    } else if (bsl.maxOrder && parseFloat(bsl.maxOrder) < bsl.stake) {
      return 'above max order';
    } else if (
      // can not place if stake is less than minOrder available
      bsl.minOrder &&
      parseFloat(bsl.stake) < parseFloat(bsl.minOrder) &&
      // AND
      // it is not possible to put the order on molly exchange
      !(
        !bsl.takeOnly &&
        bsl.cupid &&
        bsl.mollyExchangeSelected &&
        parseFloat(bsl.stake) > bsl.minMakeInGBP
      )
    ) {
      // const canTake = !(bsl.minOrder && parseFloat(bsl.stake) < parseFloat(bsl.minOrder));
      // const canMake = !!(
      //   !bsl.takeOnly &&
      //   bsl.cupid &&
      //   bsl.mollyExchangeSelected &&
      //   parseFloat(bsl.stake) > bsl.minMakeInGBP
      // );
      return 'below min order';
    } else {
      return null;
    }
  } else {
    // lay betslip

    if (!bsl.allTermsAccepted) {
      return 'accept terms';
    } else if (!bsl.webPlaceBets) {
      return 'betting disabled';
    } else if (bsl.reselectAccounts === 'never' && !bsl.selectedAccounts) {
      return 'no accounts';
    } else if (bsl.reselectAccounts === 'only_placement_bookies' && !bsl.selectedBookies) {
      return 'no bookies';
    } else if (!bsl.price) {
      return 'no price';
    } else if (formatPriceToDecimal(bsl.price, bsl.priceType) <= 1) {
      return 'bad price';
    } else if (!bsl.stake || !parseFloat(bsl.stake)) {
      return 'no stake';
    } else if (
      bsl.creditLimit !== null &&
      bsl.stake &&
      Math.abs(parseFloat(bsl.stake * (formatPriceToDecimal(bsl.price, bsl.priceType) - 1))) >
        bsl.availableCredit + bsl.smartCredit
    ) {
      return 'low credit';
    } else if (
      bsl.maxOrder &&
      parseFloat(bsl.maxOrder) <
        Math.abs(parseFloat(bsl.stake * (formatPriceToDecimal(bsl.price, bsl.priceType) - 1)))
    ) {
      return 'above max order';
    } else if (
      bsl.minOrder &&
      Math.abs(parseFloat(bsl.stake * (formatPriceToDecimal(bsl.price, bsl.priceType) - 1))) <
        parseFloat(bsl.minOrder) &&
      // it is not possible to put the order on molly exchange
      !(
        !bsl.takeOnly &&
        bsl.cupid &&
        bsl.mollyExchangeSelected &&
        Math.abs(parseFloat(bsl.stake * (bsl.price - 1))) > bsl.minMakeInGBP
      )
    ) {
      return 'below min order';
    } else {
      return null;
    }
  }
}

/**
 * This calculates a bunch of misc. crap required by the betslip.
 * We do this here because doing it in the betslip means it has to subscribe to a lot of stuff that triggers rerenders.
 * Also this is only triggered periodically in the reducer by piggibacking.
 * REFACTOR: no need for both placerId and betslipId
 * @param {Object} state - initial state
 * @param {string} placerId - placerId to do it for
 * @param {string} betslipId - betslipId to do it for
 * @return {Object} - the mutated state
 */
export function tentativePMMComposition(state, placerId, betslipId) {
  //how do we factor in ccy rate?
  let targetStake = state.getIn(['placers', placerId, 'betslips', betslipId, 'stakeGBP'], null);
  let xrates = state.get('xrates', null);
  let numAccounts = 0;

  let accounts = state.getIn(['placers', placerId, 'betslips', betslipId, 'accounts'], null);
  let flatPMMs = [];

  let customerCcy = state
    .getIn(['placers', placerId, 'betslips', betslipId, 'customerCcy'], '?')
    .toLowerCase();

  let accs = [];
  let bookies = [];

  let maxOrder = 0;
  let minOrder = Infinity;
  let maxPrice = 0;
  let minPrice = 0;
  let totOrder = 0;
  let totWeight = 0;
  let betslipPrice = state.getIn(['placers', placerId, 'betslips', betslipId, 'price'], null);

  if (accounts) {
    accounts.forEach((account, accountId) => {
      let isUsed = account.get('isUsed', false);
      numAccounts++;

      if (isUsed) {
        let bookie = account.get('bookie');
        bookies.push(bookie);
        accs.push(`${bookie}/${account.get('username')}`);

        let pmms = account.get('priceList', null);
        let foundPrices = false;
        if (pmms) {
          pmms.forEach((pmm, pmmKey) => {
            if (!pmm) return; //why would this happen

            let price = pmm.getIn(['effective', 'price'], null);
            let max = parseFloat(
              formatAmount(
                pmm.getIn(['effective', 'max', 1], 0),
                pmm.getIn(['effective', 'max', 0], '?'),
                customerCcy,
                xrates,
                false,
                true
              )
            ); //ccy conversion needed
            let min = parseFloat(
              formatAmount(
                pmm.getIn(['effective', 'min', 1], 0),
                pmm.getIn(['effective', 'min', 0], '?'),
                customerCcy,
                xrates,
                false,
                true
              )
            ); //ccy conversion needed

            //sometimes we get invalid stuff
            if (isNaN(min)) {
              min = 0;
            }

            if (max && price) {
              foundPrices = true;

              flatPMMs.push({
                accountId,
                pmmKey,
                stake: max,
                price,
                min,
              });

              if (price > maxPrice) {
                maxPrice = price;
              }
              if (price < minPrice || !minPrice) {
                minPrice = price;
              }
              if (betslipPrice) {
                if (price >= betslipPrice) {
                  if (max) {
                    maxOrder += max;
                    totWeight += max - min;
                    totOrder += price * (max - min);
                  }

                  if (min < minOrder) {
                    minOrder = min;
                  }
                }
              } else {
                if (max) {
                  maxOrder += max;
                  totWeight += max - min;
                  totOrder += price * (max - min);
                }

                if (min < minOrder) {
                  minOrder = min;
                }
              }
            }
          });
        }
        state = state.setIn(
          ['placers', placerId, 'betslips', betslipId, 'accounts', accountId, 'hasPrices'],
          foundPrices
        );
      }
    });

    for (let pmm of flatPMMs) {
      state = state.setIn(
        [
          'placers',
          placerId,
          'betslips',
          betslipId,
          'accounts',
          pmm.accountId,
          'priceList',
          pmm.pmmKey,
          'inComposition',
        ],
        false
      );
    }
    flatPMMs = _.sortBy(flatPMMs, 'price').reverse();

    if (targetStake) {
      let bestPrice = 1;

      for (let pmm of flatPMMs) {
        if (targetStake > 0) {
          targetStake -= pmm.stake;
          if (pmm.price > betslipPrice) {
            if (pmm.min < targetStake) {
              state = state.setIn(
                [
                  'placers',
                  placerId,
                  'betslips',
                  betslipId,
                  'accounts',
                  pmm.accountId,
                  'priceList',
                  pmm.pmmKey,
                  'inComposition',
                ],
                true
              );
            }
          }
          bestPrice = pmm.price;
        }
      }

      bestPrice = Math.max(1, Math.floor(bestPrice * 1000) / 1000);
      state = state.setIn(['placers', placerId, 'betslips', betslipId, 'bestPrice'], bestPrice);
    } else if (flatPMMs.length) {
      state = state.setIn(
        ['placers', placerId, 'betslips', betslipId, 'bestPrice'],
        flatPMMs[0].price
      );
    }
  }

  let currNumAccounts = state.getIn(['placers', placerId, 'numMaxAccounts'], 0);
  if (currNumAccounts < numAccounts) {
    state = state.setIn(['placers', placerId, 'numMaxAccounts'], numAccounts);
  }

  state = state.setIn(
    ['placers', placerId, 'betslips', betslipId, 'averagePrice'],
    totWeight ? formatPriceToDecimal(totOrder / totWeight, 'dec', 3) : 0
  );
  state = state.setIn(['placers', placerId, 'betslips', betslipId, 'maxPrice'], maxPrice);
  state = state.setIn(['placers', placerId, 'betslips', betslipId, 'minPrice'], minPrice);
  state = state.setIn(['placers', placerId, 'betslips', betslipId, 'maxOrder'], maxOrder);

  if (minOrder === Infinity) {
    minOrder = 0;
  }
  state = state.setIn(['placers', placerId, 'betslips', betslipId, 'minOrder'], minOrder);

  accs = _.uniq(accs);
  bookies = _.uniq(bookies);

  state = state.setIn(
    ['placers', placerId, 'betslips', betslipId, 'selectedAccounts'],
    accs.join(', ')
  );
  state = state.setIn(
    ['placers', placerId, 'betslips', betslipId, 'selectedBookies'],
    bookies.join(', ')
  );

  return state;
}

/**
 * This function groups a list of invalid accounts by error code. This is not usually visible to normal users.
 * @param {Object} invs - a map with accounts, bookies and reasons why they are invalid
 * @return {Object} - groupped by bookie
 */
export function groupInvalids(invs) {
  if (!invs) {
    return {
      accs: {},
      count: 0,
    };
  }

  let procInvs = [];
  let count = 0;

  for (let bookie in invs) {
    for (let username in invs[bookie]) {
      procInvs.push({
        username: username,
        bookie: bookie,
        error: invs[bookie][username] ? invs[bookie][username].replace(/_/g, ' ') : '?',
      });
      count++;
    }
  }

  var out = {};
  var bk = _.groupBy(procInvs, 'bookie');

  for (let i in bk) {
    out[i] = _.groupBy(bk[i], 'error');
  }

  return {
    accs: out,
    count: count,
  };
}

/** @format */

exports.default = {
  name: { id: 'sports.esports', defaultMessage: 'eSports' },
  shortName: { id: 'sports.esports.short', defaultMessage: 'eSports' },
  featuredCompetitions: [],
  featuredCompetitionsName: [],
  enabled: true,
  noDraws: true,
  maxTimeout: 600,
  correlated: {},
  correlatedOrder: ['esports'],
  trustScores: false,
  matchDataOpener: 'for,tp,all,ml,a/null',
  offerGroupsOrder: [
    'timeWin,tp,all,ml',
    'timeAh,tp,all',
    'timeAhou,tp,all',
    //'timeWin,tperiod,1,ml',
    //'timeWin,tperiod,2,ml',
    //'timeWin,tperiod,3,ml',
    //'timeWin,tperiod,4,ml',
    //'timeWin,tperiod,5,ml',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    //'ml': 'timeWin,tp,all,ml',
    //'DnB': 'timeWin,tp,all,ml',
    wdw: 'timeWin,tp,all,ml',
    'timeWin,tp,reg,wdw': 'timeWin,tp,all,ml',
    'tennisMatch,all': 'timeWin,tp,all,ml',
    ah: 'timeAh,tp,all',
    ahou: 'timeAhou,tp,all',
    'tennisAh,all,set': 'timeAh,tp,all',
    'tennisAhou,all,game': 'timeAhou,tp,all',
  },
};

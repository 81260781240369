/** @format */

const Generators = require('../positionGrids').Generators;

//everything else (some can't settle and just for reference)
//see: https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  ko: {
    //can't settle
    name: { id: 'groupNames.ko', defaultMessage: 'Kick Off' },
    shortName: { id: 'groupNames.short.ko', defaultMessage: 'Kick Off' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,ko,{outcome}',
    position: { generator: null, dimension: null, size: 2 }, //can't settle anyway
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,ko,h',
        template: { id: 'betTypes.ko,h', defaultMessage: '{home} to Kick Off' },
        columnName: { id: 'columnNames.ko,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,ko,a',
        template: { id: 'betTypes.ko,a', defaultMessage: '{away} to Kick Off' },
        columnName: { id: 'columnNames.ko,a', defaultMessage: '2' },
      },
    },
  },
  eh: {
    //theoretical (can't find offers)
    name: { id: 'groupNames.eh', defaultMessage: 'English Handicap' },
    shortName: { id: 'groupNames.short.eh', defaultMessage: 'En Hcp.' },
    handicap: true,
    displayAsRaw: true,
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,eh,{team},{hcap}',
    position: { generator: Generators.eh, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,eh,h',
        template: { id: 'betTypes.eh,h', defaultMessage: '{home} {handicap} (English)' },
        columnName: { id: 'columnNames.eh,h', defaultMessage: 'E/1' },
      },
      d: {
        type: 'for,eh,d',
        template: { id: 'betTypes.eh,d', defaultMessage: '{home} {handicap} (English)' },
        columnName: { id: 'columnNames.eh,d', defaultMessage: 'E/X' },
      },
      a: {
        type: 'for,eh,a',
        template: { id: 'betTypes.eh,a', defaultMessage: '{away} {handicap} (English)' },
        columnName: { id: 'columnNames.eh,a', defaultMessage: 'E/2' },
      },
    },
  },
  oueq: {
    //theoretical (can't find offers)
    name: { id: 'groupNames.oueq', defaultMessage: 'Total or Equal' },
    shortName: { id: 'groupNames.short.oueq', defaultMessage: 'O/U + Equal' },
    handicap: true,
    isOverUnder: true,
    displayAsRaw: true,
    order: ['overeq', 'undereq'],
    betTypeTemplate: 'for,{direction}eq,{line}',
    position: { generator: Generators.oueq, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      overeq: {
        type: 'for,overeq',
        template: { id: 'betTypes.oueq,over', defaultMessage: 'Over or Equal {handicap}' },
        columnName: { id: 'columnNames.oueq,over', defaultMessage: 'O' },
      },
      undereq: {
        type: 'for,undereq',
        template: { id: 'betTypes.oueq,under', defaultMessage: 'Under or Equal {handicap}' },
        columnName: { id: 'columnNames.oueq,under', defaultMessage: 'U' },
      },
    },
  },
  ou: {
    //already part of ahou in cpricefeed... these come mainly from Betfair
    name: { id: 'groupNames.ou', defaultMessage: 'Total Goals (non Asian)' },
    shortName: { id: 'groupNames.short.ou', defaultMessage: 'O/U (nA)' },
    handicap: true,
    isOverUnder: true,
    displayAsRaw: true,
    order: ['over', 'under'],
    betTypeTemplate: 'for,{direction},{line}',
    position: { generator: Generators.ou, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      over: {
        type: 'for,over',
        template: { id: 'betTypes.ou,over', defaultMessage: 'Over {handicap}' },
        irTemplate: {
          id: 'betTypes.ou,ir,over',
          defaultMessage: 'Over {handicap} ({homeScore} - {awayScore})',
        },
        columnName: { id: 'columnNames.ou,over', defaultMessage: 'O' },
      },
      under: {
        type: 'for,under',
        template: { id: 'betTypes.ou,under', defaultMessage: 'Under {handicap}' },
        irTemplate: {
          id: 'betTypes.ou,ir,under',
          defaultMessage: 'Under {handicap} ({homeScore} - {awayScore})',
        },
        columnName: { id: 'columnNames.ou,under', defaultMessage: 'U' },
      },
    },
  },
  win90: {
    //theoretical (can't find offers)
    name: { id: 'groupNames.win90', defaultMessage: 'Win 90 Mins' },
    shortName: { id: 'groupNames.short.win90', defaultMessage: '1/2 (90)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,win_90,{team}',
    position: { generator: Generators.win90, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,win_90,h',
        template: { id: 'betTypes.win_90,h', defaultMessage: '{home}' },
        columnName: { id: 'columnNames.win_90,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,win_90,a',
        template: { id: 'betTypes.win_90,a', defaultMessage: '{away}' },
        columnName: { id: 'columnNames.win_90,a', defaultMessage: '2' },
      },
    },
  },
  dnb: {
    name: { id: 'groupNames.dnb', defaultMessage: 'Moneyline (draw no bet)' },
    shortName: { id: 'groupNames.short.dnb', defaultMessage: '1/2 (dnb)' },
    handicap: false,
    order: ['h', 'a'],
    betTypeTemplate: 'for,dnb,{outcome}',
    position: { generator: Generators.dnb, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,dnb,h',
        template: { id: 'betTypes.dnb,h', defaultMessage: '{home} (draw no bet)' },
        columnName: { id: 'columnNames.dnb,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,dnb,a',
        template: { id: 'betTypes.dnb,a', defaultMessage: '{away} (draw no bet)' },
        columnName: { id: 'columnNames.dnb,a', defaultMessage: '2' },
      },
    },
  },
  anb: {
    //theoretical (can't find offers)
    name: { id: 'groupNames.anb', defaultMessage: 'Away No Bet' },
    shortName: { id: 'groupNames.short.anb', defaultMessage: '1/X (anb)' },
    handicap: false,
    order: ['h', 'd'],
    betTypeTemplate: 'for,anb,{outcome}',
    position: { generator: Generators.anb, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,anb,h',
        template: { id: 'betTypes.anb,h', defaultMessage: '{home} (away no bet)' },
        columnName: { id: 'columnNames.anb,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,anb,d',
        template: { id: 'betTypes.anb,d', defaultMessage: 'Draw (away no bet)' },
        columnName: { id: 'columnNames.anb,d', defaultMessage: 'X' },
      },
    },
  },
  hnb: {
    //theoretical (can't find offers)
    name: { id: 'groupNames.hnb', defaultMessage: 'Home No Bet' },
    shortName: { id: 'groupNames.short.hnb', defaultMessage: 'X/2 (hnb)' },
    handicap: false,
    order: ['d', 'a'],
    betTypeTemplate: 'for,hnb,{outcome}',
    position: { generator: Generators.hnb, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      d: {
        type: 'for,hnb,d',
        template: { id: 'betTypes.hnb,d', defaultMessage: 'Draw (home no bet)' },
        columnName: { id: 'columnNames.hnb,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,hnb,a',
        template: { id: 'betTypes.hnb,a', defaultMessage: '{home} (home no bet)' },
        columnName: { id: 'columnNames.hnb,a', defaultMessage: '2' },
      },
    },
  },
  fg: {
    //can't settle
    name: { id: 'groupNames.fg', defaultMessage: 'First Goal' },
    shortName: { id: 'groupNames.short.fg', defaultMessage: 'First Goal' },
    order: ['no_goal'],
    betTypeTemplate: 'for,fg,{outcome}',
    position: { generator: Generators.fg, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      no_goal: {
        type: 'for,fg,no_goal',
        template: { id: 'betTypes.fg,no_goal', defaultMessage: 'No goals' },
        columnName: { id: 'columnNames.fg,no_goal', defaultMessage: '1' },
      },
    },
  },
  //this is complicated... is it worth it ?
  othercs: {
    //the names of the bounds might be flipped around sadly, this is to be consistent with rest of the code
    name: { id: 'groupNames.othercs', defaultMessage: 'Correct Score Outside' },
    shortName: { id: 'groupNames.short.othercs', defaultMessage: 'CS+ X/Y' },
    handicap: true,
    score: true,
    order: ['*'],
    betTypeTemplate:
      'for,othercs,{maxHome},{maxAway}|for,othercs,{minHome},{minAway},{maxHome},{maxAway}',
    position: { generator: Generators.othercs, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,othercs',
        template: {
          id: 'betTypes.othercs,othercs',
          defaultMessage: 'Correct Score Outside {handicap}',
        },
        columnName: null,
      },
    },
  },
  awdw: {
    //theoretical (can't find offers)
    name: { id: 'groupNames.awdw', defaultMessage: '1X2 IR' },
    shortName: { id: 'groupNames.short.awdw', defaultMessage: '1X2 IR' },
    order: ['h', 'd', 'a'],
    betTypeTemplate: 'for,ir,{irhome},{iraway},awdw,{outcome}',
    position: { generator: null, dimension: 'score', size: 15 }, //can't settle
    overroundFactor: 1,
    betTypes: {
      h: {
        type: 'for,awdw,h',
        template: { id: 'betTypes.awdw,h', defaultMessage: '{home} after IR offset' },
        irType: (hcap, [home, away]) => `for,ir,${home},${away},awdw,h`,
        irTemplate: {
          id: 'betTypes.awdw,ir,h',
          defaultMessage: '{home} after IR offset ({homeScore} - {awayScore})',
        },
        columnName: { id: 'columnNames.awdw,h', defaultMessage: '1' },
      },
      d: {
        type: 'for,awdw,d',
        template: { id: 'betTypes.awdw,d', defaultMessage: 'Draw after IR offset' },
        irType: (hcap, [home, away]) => `for,ir,${home},${away},awdw,d`,
        irTemplate: {
          id: 'betTypes.awdw,ir,d',
          defaultMessage: 'Draw after IR offset ({homeScore} - {awayScore})',
        },
        columnName: { id: 'columnNames.awdw,d', defaultMessage: 'X' },
      },
      a: {
        type: 'for,awdw,a',
        template: { id: 'betTypes.awdw,a', defaultMessage: '{away} after IR offset' },
        irType: (hcap, [home, away]) => `for,ir,${home},${away},awdw,a`,
        irTemplate: {
          id: 'betTypes.awdw,ir,a',
          defaultMessage: '{away} after IR offset ({homeScore} - {awayScore})',
        },
        columnName: { id: 'columnNames.awdw,a', defaultMessage: '2' },
      },
    },
  },
  quatro: {
    //theoretical (can't find offers)
    name: { id: 'groupNames.quatro', defaultMessage: 'Quatro' },
    shortName: { id: 'groupNames.short.quatro', defaultMessage: 'Quatro' },
    order: ['h,o', 'h,u', 'a,o', 'a,u', 'hd,o', 'hd,u', 'ad,o', 'ad,u'],
    betTypeTemplate: 'for,quatro,{teamOrDraw},{direction},{line}',
    position: { generator: Generators.quatro, dimension: 'score', size: 15 },
    overroundFactor: 1,
    width: 4,
    displayAsRaw: true,
    betTypes: {
      'h,o': {
        type: 'for,quatro,h,o',
        template: { id: 'betTypes.quatro,h,o', defaultMessage: 'Quatro {home}, Over {handicap}' },
        columnName: { id: 'columnNames.quatro,h,o', defaultMessage: '1+O' },
      },
      'h,u': {
        type: 'for,quatro,h,u',
        template: { id: 'betTypes.quatro,h,u', defaultMessage: 'Quatro {home}, Under {handicap}' },
        columnName: { id: 'columnNames.quatro,h,u', defaultMessage: '1+U' },
      },
      'd,o': {
        type: 'for,quatro,d,o',
        template: { id: 'betTypes.quatro,d,o', defaultMessage: 'Quatro Draw, Over {handicap}' },
        columnName: { id: 'columnNames.quatro,d,o', defaultMessage: 'X+O' },
      },
      'd,u': {
        type: 'for,quatro,d,u',
        template: { id: 'betTypes.quatro,d,u', defaultMessage: 'Quatro Draw, Under {handicap}' },
        columnName: { id: 'columnNames.quatro,d,u', defaultMessage: 'X+U' },
      },
      'a,o': {
        type: 'for,quatro,a,o',
        template: { id: 'betTypes.quatro,a,o', defaultMessage: 'Quatro {away}, Over {handicap}' },
        columnName: { id: 'columnNames.quatro,a,o', defaultMessage: '2+O' },
      },
      'a,u': {
        type: 'for,quatro,a,u',
        template: { id: 'betTypes.quatro,a,u', defaultMessage: 'Quatro {away}, Under {handicap}' },
        columnName: { id: 'columnNames.quatro,a,u', defaultMessage: '2+U' },
      },
      'hd,o': {
        type: 'for,quatro,hd,o',
        template: {
          id: 'betTypes.quatro,hd,o',
          defaultMessage: 'Quatro {home} or DRAW, Over {handicap}',
        },
        columnName: { id: 'columnNames.quatro,hd,o,', defaultMessage: '1X+O' },
      },
      'hd,u': {
        type: 'for,quatro,hd,u',
        template: {
          id: 'betTypes.quatro,hd,u',
          defaultMessage: 'Quatro {home} or DRAW, Under {handicap}',
        },
        columnName: { id: 'columnNames.quatro,hd,u', defaultMessage: '1X+U' },
      },
      'ad,o': {
        type: 'for,quatro,ad,o',
        template: {
          id: 'betTypes.quatro,ad,o',
          defaultMessage: 'Quatro {away} or DRAW, Over {handicap}',
        },
        columnName: { id: 'columnNames.quatro,ad,o', defaultMessage: 'X2+O' },
      },
      'ad,u': {
        type: 'for,quatro,ad,u',
        template: {
          id: 'betTypes.quatro,ad,u',
          defaultMessage: 'Quatro {away} or DRAW, Under {handicap}',
        },
        columnName: { id: 'columnNames.quatro,ad,u', defaultMessage: 'X2+U' },
      },
    },
  },
};

/** @format */

exports.default = {
  win: {
    name: { id: 'groupNames.win', defaultMessage: 'Winner' },
    shortName: { id: 'groupNames.short.win', defaultMessage: 'OR' },
    handicap: false,
    multirunner: true,
    order: ['win'],
    betTypeTemplate: 'for,win,{teamId}',
    position: { generator: null, dimension: null, size: 2 }, // position grid doesnt make sense ?
    overroundFactor: 1,
    betTypes: {
      win: {
        type: 'for,win',
        template: { id: 'betTypes.win.win', defaultMessage: 'Winner' },
        columnName: { id: 'columnNames.win.win', defaultMessage: 'Winner' },
      },
    },
  },
};

/** @format */

import _ from 'lodash';
import DataStream from './DataStream';
import Whitelabel from '../lib/Whitelabel';
import { Map, fromJS } from 'immutable';
import cookie from 'cookie';
import config from '../config';

//try to retrieve from cookie
let _from = {};

if (window) {
  let parsedCookie = cookie.parse(window.document.cookie);
  let sessionId = parsedCookie[config.sessionCookiePrefix + '_sid'] || '';
  let username = parsedCookie[config.sessionCookiePrefix + '_username'] || '';
  _from = {
    sessionId,
    profile: {
      username,
    },
  };
}

let _sessData = fromJS(_from);

export const ALLOWED_PREFS_KEYS = ['announcements', 'general', 'history', 'trade'];

/**
 * This is global session manager that allow easy access to the session data across contexts
 * You don't need to use `new` since all its methods are static
 */
export default class Session {
  static _scheduledSave = null;
  static _inited = false;
  static _ongoingSaves = [];

  /**
   * this method retrieves a first level property stored in the session
   * @param {string|Array} prop - what session item to restore; if not specified, whole session will be returned (supports things like 'a.b.c.d')
   * @return {} the thing you stored
   */
  static get(prop, _default) {
    if (!prop) {
      return _sessData.toJS();
    } else {
      if (typeof prop === 'string') {
        prop = prop.split('.');
      }

      let out = _sessData.getIn(prop);
      if (typeof out !== 'undefined') {
        if (out && out.toJS) {
          return out.toJS();
        } else {
          return out;
        }
      } else {
        return _default;
      }
    }
  }

  /**
   * this method sets the property and saves the session
   * @param {string|Array} prop - what session property to store it inder
   * @param {Object} data - stuff to store in the session (will extend the current structure)
   */
  static set(prop, data) {
    let _prop = prop;
    if (typeof prop === 'string') {
      prop = prop.split('.');
    }

    if (typeof data !== 'string') {
      data = fromJS(data);
    }

    _sessData = _sessData.setIn(prop, data);

    if (window) {
      let domainsToSet = [];

      if (Whitelabel.cookieDomain) {
        if (typeof Whitelabel.cookieDomain === 'string') {
          domainsToSet = [Whitelabel.cookieDomain];
        } else {
          domainsToSet = Whitelabel.cookieDomain;
        }
      }

      for (let cookieDomain of domainsToSet) {
        //we want the cookie on the main path on the * comain
        let cookieCommon = {
          maxAge: 60 * 60 * 24 * 2,
          sameSite: 'lax',
          path: '/',
          secure: process.env.NODE_ENV === 'production' || window.location.protocol === 'https:',
          domain: window.location.hostname === 'localhost' ? 'localhost' : cookieDomain,
        };

        //kill harder
        if (!data) {
          cookieCommon['maxAge'] = 0;
          cookieCommon['expires'] = 0;
        }

        if (_prop === 'sessionId') {
          window.document.cookie = cookie.serialize(
            config.sessionCookiePrefix + '_sid',
            data,
            cookieCommon
          );
        } else if (prop[1] === 'username') {
          window.document.cookie = cookie.serialize(
            config.sessionCookiePrefix + '_username',
            data,
            cookieCommon
          );
        }
      }
    }

    Session.save();
  }

  /**
   * this method loads the settings while taking into account top level stuff
   * @param {Object} data - stuff to store in the session (will extend the current structure)
   */
  static loadSettings(data) {
    let toSet = {};
    for (let key of ALLOWED_PREFS_KEYS) {
      toSet[key] = data[key] || {};
    }
    _sessData = _sessData.mergeDeepIn(['settings'], toSet);
    Session.save();
  }

  /**
   * this method markes the session as inited; useful when loading data in muliple goes and keeping
   * the save functionality disabled
   * @param {boolean} [inited=true] - the init value
   */
  static markInited(inited = true) {
    Session._inited = !!inited;
  }

  /**
   * this method clears things stored in the session
   * @param {Object} [prop] - what session item to clear; if none specified, whole session will be emptied (supports things like 'a.b.c.d')
   */
  static clear(prop) {
    if (!prop) {
      _sessData = new Map();
      Session.set('sessionId', '');
      Session.set(['profile', 'username'], '');
    } else {
      let _prop = prop;
      if (typeof prop === 'string') {
        prop = prop.split('.');
      }

      if (_prop === 'sessionId') {
        Session.set('sessionId', '');
      } else if (prop[1] === 'username') {
        Session.set(['profile', 'username'], '');
      } else {
        _sessData = _sessData.removeIn(prop);
      }
    }

    Session.save();
  }

  /**
   * internal save method
   * @param {Function} [success] - what to call in case of success
   * @param {Function} [error] - what to call in case of error
   */
  static _save(success, error) {
    let settings = _sessData.get('settings', null);
    if (settings) {
      let username = Session.get(['profile', 'username'], '');
      if (username) {
        let toSave = settings.toJS();

        //preferences
        let toSend = {};
        for (let key of ALLOWED_PREFS_KEYS) {
          toSend[key] = toSave[key] || {};
        }

        if (Object.keys(toSend).length !== ALLOWED_PREFS_KEYS.length) {
          if (error) {
            error();
          }
        }

        let pr1 = new DataStream(`/web/preferences/${username}/uipreferences/`, {
          method: 'PUT',
          body: toSend,
          startsPaused: true,
        }).start();

        //terms is part of customer settings
        let pr2 = new DataStream(`/web/preferences/${username}/customersettings/`, {
          method: 'PUT',
          body: { terms: toSave['terms'] },
          startsPaused: true,
        }).start();

        //need both
        Promise.all([pr1, pr2]).then(success).catch(error);
      } else {
        if (error) {
          error();
        }
      }
    } else if (error) {
      error();
    }
  }

  /**
   * schedule a save to happen within a time frame (see config)
   */
  static save() {
    if (Session._inited && !Session.get(['profile', 'readonly'], false)) {
      if (Session._scheduledSave) {
        clearTimeout(Session._scheduledSave);
      }
      Session._scheduledSave = setTimeout(function () {
        Session._scheduledSave = null;

        let saveId = new Date().getTime();
        Session._ongoingSaves.push(saveId);

        Session._save(() => {
          //if this save wasn't the last save it may override another that actually went after it
          //this is a networking race condition "exposed" during the tech split
          //under reasonable network circumstances this would never happen, but some requests randomly take 30s
          if (Session._ongoingSaves[Session._ongoingSaves.length - 1] !== saveId) {
            //if it wasn't the last, try to schedule a save again
            //because it may have "landed" as the last and that's not good
            Session.save();
          }
        }, Session.save); //aggressiely try to save on error
      }, config.timings.sessionSaveDebounce);
    }
  }

  /**
   * stop a scheduled save if there is one, clear Session and mark it as not initialized
   */
  static invalidate() {
    //force save, don't wait for debounce
    if (Session._scheduledSave) {
      clearTimeout(Session._scheduledSave);
    }

    //perhaps we want to do other things as well?
    Session.clear();
    //flip init flag
    Session._inited = false;
  }
}

// leak for dev purposes
if (window && config.support.tools.leakSession) {
  window.Session = Session;
}

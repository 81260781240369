/** @format */

import DSM from '../lib/DSM';
import { toCamelCase } from '../lib/camelSnake';
import { fromJS } from 'immutable';

let initialState = fromJS({
  //error message text
  error: '',
  // pending, ok, error, done
  status: 'pending',
});

const functions = {
  //make password reset request
  resetPassword: (state, action) => {
    const resetToken = action.data.resetToken;

    DSM.create(
      `/cs/password/reset/${resetToken}`,
      {
        method: 'POST',
        body: {
          password1: action.data.password1,
          password2: action.data.password2,
        },
        message: 'resetPasswordResponse',
      },
      action.data.actions
    );

    state = state.set('error', '');
    state = state.set('status', 'pending');

    return state;
  },

  //deal with password reset response
  resetPasswordResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('error', '');
      state = state.set('status', 'done');
    } else if (action.data.status === 'error') {
      state = state.set('error', action.data.code);
      state = state.set('status', 'error');
    } else {
      // does this ever happen?
      state = state.set('status', 'error');
      state = state.set('error', 'unknown response');
    }

    return state;
  },

  //use token to validate a password reset
  resetPasswordValidateToken: (state, action) => {
    const resetToken = action.data.resetToken;

    DSM.create(
      `/cs/password/reset/${resetToken}`,
      {
        method: 'GET',
        message: 'resetPasswordValidateTokenResponse',
      },
      action.data.actions
    );

    state = state.set('error', '');
    return state;
  },

  //deal with password reset token validation
  resetPasswordValidateTokenResponse: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('status', 'ok');
    } else if (action.data.status === 'error') {
      state = state.set('error', action.data.code);
      state = state.set('status', 'error');
    } else {
      // does this ever happen?
      state = state.set('status', 'error');
      state = state.set('error', 'unknown response');
    }

    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

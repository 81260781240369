/** @format */

//some of these may be able to be removed because they have either become global on or off

exports.default = {
  parlay: false, //the user can use accumulators
  advancedTradeSettings: false, //catch all for everything piro doesn't want to see in trade settings
  whitelabelUser: false, //can use the direct deposit options
  netPositionGrids: false, //net prices in position grids
  lognoice: false, //mirror actions to the websocket for this particular user
  cashout: false, //is cashout enabled
  flatten: false, //is cashout enabled
  mlutirunner: false, //is mlutirunner enabled
  translatedBetTypes: false, //let the interface try to translate bet types
  equivalentBetsOption: true, //can turn off equivalent bets option
  smartCredit: false, // is live balance enabled
};

/** @format */

const Generators = require('../positionGrids').Generators

//score margins based
//see: https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  gr: {
    name: { id: 'groupNames.gr', defaultMessage: 'Total Between' },
    shortName: { id: 'groupNames.short.gr', defaultMessage: 'Total Min/Max' },
    handicap: true,
    score: true,
    range: true,
    order: ['*'],
    betTypeTemplate: 'for,gr,{min},{max}',
    position: { generator: Generators.gr, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,gr',
        template: { id: 'betTypes.gr,gr', defaultMessage: 'Total Goals {handicap} (inclusive)' },
        columnName: null,
      },
    },
  },
  'teamgr,a': { //theoretical (can't find offers)
    name: { id: 'groupNames.teamgr,a', defaultMessage: 'Away Total Goals Between' },
    shortName: { id: 'groupNames.short.teamgr,a', defaultMessage: 'Away Min/Max' },
    score: true,
    range: true,
    order: ['*'],
    betTypeTemplate: 'for,teamgr,{team},{min},{max}',
    position: { generator: Generators.teamgr, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,teamgr,a',
        template: {
          id: 'betTypes.teamgr,a',
          defaultMessage: 'Away Total Goals {handicap} (inclusive)',
        },
        columnName: null,
      },
    },
  },
  'teamgr,h': { //theoretical (can't find offers)
    name: { id: 'groupNames.teamgr,h', defaultMessage: 'Home Total Goals Between' },
    shortName: { id: 'groupNames.short.teamgr,h', defaultMessage: 'Home Min/Max' },
    score: true,
    range: true,
    order: ['*'],
    betTypeTemplate: 'for,teamgr,{team},{min},{max}',
    position: { generator: Generators.teamgr, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,teamgr,h',
        template: {
          id: 'betTypes.teamgr,h',
          defaultMessage: 'Home Total Goals {handicap} (inclusive)',
        },
        columnName: null,
      },
    },
  },
  awm: { //theoretical (can't find offers)
    name: { id: 'groupNames.awm', defaultMessage: 'Goal Margin' },
    shortName: { id: 'groupNames.short.awm', defaultMessage: 'Margin' },
    score: true,
    order: ['*'],
    betTypeTemplate: 'for,awm,{margin}',
    position: { generator: Generators.awm, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,awm',
        template: { id: 'betTypes.awm,awm', defaultMessage: 'Goal Margin' },
        columnName: null,
      },
    },
  },
  wm: {
    name: { id: 'groupNames.wm', defaultMessage: 'Win by' },
    shortName: { id: 'groupNames.short.wm', defaultMessage: 'Win by' },
    handicap: true,
    range: true,
    order: ['h', 'a'],
    betTypeTemplate: 'for,wm,{team},{min},{max}',
    position: { generator: Generators.wm, dimension: 'score', size: 15 },
    overroundFactor: 0,
    betTypes: {
      h: {
        type: 'for,wm,h',
        template: { id: 'betTypes.wm,h', defaultMessage: '{home} Win By {handicap} Goals' },
        columnName: { id: 'columnNames.wm,h', defaultMessage: '1' },
      },
      a: {
        type: 'for,wm,a',
        template: { id: 'betTypes.wm,a', defaultMessage: '{away} Win By {handicap} Goals' },
        columnName: { id: 'columnNames.wm,a', defaultMessage: '2' },
      },
    },
  },
  swm: { //covered by cs,0,0; not worth
    name: { id: 'groupNames.swm', defaultMessage: 'Win Margin' },
    shortName: { id: 'groupNames.short.swm', defaultMessage: 'Win Margin' },
    order: ['no_goal', 'sd'],
    betTypeTemplate: 'for,swm,{outcome}',
    position: { generator: Generators.swm, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      no_goal: {
        type: 'for,swm,no_goal',
        template: { id: 'betTypes.swm,no_goal', defaultMessage: 'No goals' },
        columnName: { id: 'columnNames.swm,no_goal', defaultMessage: '1' },
      },
      sd: {
        type: 'for,swm,sd',
        template: { id: 'betTypes.swm,sd', defaultMessage: 'Score Draw' },
        columnName: { id: 'columnNames.swm,sd', defaultMessage: '2' },
      },
    },
  },
};

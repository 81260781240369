/** @format */

const Generators = require('../positionGrids').Generators;

//misc score related like odd/even and will/not score
//see: https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  oe: {
    name: { id: 'groupNames.oe', defaultMessage: 'Odd/Even' },
    shortName: { id: 'groupNames.short.oe', defaultMessage: 'Odd/Even' },
    handicap: false,
    isShortLine: true,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,{parity}',
    position: { generator: Generators.oe, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,odd',
        template: { id: 'betTypes.oe,odd', defaultMessage: 'Odd Goals' },
        columnName: { id: 'columnNames.oe,odd', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,even',
        template: { id: 'betTypes.oe,even', defaultMessage: 'Even Goals' },
        columnName: { id: 'columnNames.oe,even', defaultMessage: 'Even' },
      },
    },
  },
  'oe,h': {
    name: { id: 'groupNames.oe,h', defaultMessage: 'Odd/Even Home' },
    shortName: { id: 'groupNames.short.oe,h', defaultMessage: '1 O/E' },
    handicap: false,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,{parity},{team}',
    position: { generator: Generators.oe, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,odd,h',
        template: { id: 'betTypes.oe,odd,h', defaultMessage: 'Odd Goals Home' },
        columnName: { id: 'columnNames.oe,odd,h', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,even,h',
        template: { id: 'betTypes.oe,even,h', defaultMessage: 'Even Goals Home' },
        columnName: { id: 'columnNames.oe,even,h', defaultMessage: 'Even' },
      },
    },
  },
  'oe,a': {
    name: { id: 'groupNames.oe,a', defaultMessage: 'Odd/Even Away' },
    shortName: { id: 'groupNames.short.oe,a', defaultMessage: '2 O/E' },
    handicap: false,
    order: ['odd', 'even'],
    betTypeTemplate: 'for,{parity},{team}',
    position: { generator: Generators.oe, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      odd: {
        type: 'for,odd,a',
        template: { id: 'betTypes.oe,odd,a', defaultMessage: 'Odd Goals Away' },
        columnName: { id: 'columnNames.oe,odd,a', defaultMessage: 'Odd' },
      },
      even: {
        type: 'for,even,a',
        template: { id: 'betTypes.oe,even,a', defaultMessage: 'Even Goals Away' },
        columnName: { id: 'columnNames.oe,even,a', defaultMessage: 'Even' },
      },
    },
  },
  'score,both': {
    name: { id: 'groupNames.score,both', defaultMessage: 'Both To Score' },
    shortName: { id: 'groupNames.short.score,both', defaultMessage: 'Both Score' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,score,{who}|for,score,{who},{will}',
    position: { generator: Generators.score, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,score,both',
        template: { id: 'betTypes.score,both', defaultMessage: 'Both Teams Score' },
        columnName: { id: 'columnNames.score,both', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,score,both,no',
        template: { id: 'betTypes.score,both,no', defaultMessage: 'Both Teams Will Not Score' },
        columnName: { id: 'columnNames.score,both,no', defaultMessage: 'No' },
      },
    },
  },
  'score,one': {
    name: { id: 'groupNames.score,one', defaultMessage: 'One To Score' },
    shortName: { id: 'groupNames.short.score,one', defaultMessage: 'One Score' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,score,{who}|for,score,{who},{will}',
    position: { generator: Generators.score, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,score,one',
        template: { id: 'betTypes.score,one', defaultMessage: 'Only one team to score' },
        columnName: { id: 'columnNames.score,one', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,score,one,no',
        template: { id: 'betTypes.score,one,no', defaultMessage: 'Only one team to not score' },
        columnName: { id: 'columnNames.score,one,no', defaultMessage: 'No' },
      },
    },
  },
  'score,neither': {
    name: { id: 'groupNames.score,neither', defaultMessage: 'Neither To Score' },
    shortName: { id: 'groupNames.short.score,neither', defaultMessage: 'One Score' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,score,{who}|for,score,{who},{will}',
    position: { generator: Generators.score, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,score,neither',
        template: { id: 'betTypes.score,neither', defaultMessage: 'Neither team to score' },
        columnName: { id: 'columnNames.score,neither', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,score,neither,no',
        template: { id: 'betTypes.score,neither,no', defaultMessage: 'Neither team to not score' },
        columnName: { id: 'columnNames.score,neither,no', defaultMessage: 'No' },
      },
    },
  },
  'score,either': {
    name: { id: 'groupNames.score,either', defaultMessage: 'Either To Score' },
    shortName: { id: 'groupNames.short.score,either', defaultMessage: 'One Score' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,score,{who}|for,score,{who},{will}',
    position: { generator: Generators.score, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,score,either',
        template: { id: 'betTypes.score,either', defaultMessage: 'Either team to score' },
        columnName: { id: 'columnNames.score,either', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,score,either,no',
        template: { id: 'betTypes.score,either,no', defaultMessage: 'Either team to not score' },
        columnName: { id: 'columnNames.score,either,no', defaultMessage: 'No' },
      },
    },
  },
  'score,a': {
    name: { id: 'groupNames.score,a', defaultMessage: 'Away To Score' },
    shortName: { id: 'groupNames.short.score,a', defaultMessage: 'Away Score' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,score,{who}|for,score,{who},{will}',
    position: { generator: Generators.score, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,score,a',
        template: { id: 'betTypes.score,a', defaultMessage: '{away} Will Score' },
        columnName: { id: 'columnNames.score,a', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,score,a,no',
        template: { id: 'betTypes.score,a,no', defaultMessage: '{away} Will Not Score' },
        columnName: { id: 'columnNames.score,a,no', defaultMessage: 'No' },
      },
    },
  },
  'score,h': {
    name: { id: 'groupNames.score,h', defaultMessage: 'Home To Score' },
    shortName: { id: 'groupNames.short.score,h', defaultMessage: 'Home Score' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,score,{who}|for,score,{who},{will}',
    position: { generator: Generators.score, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,score,h',
        template: { id: 'betTypes.score,h', defaultMessage: '{home} Will Score' },
        columnName: { id: 'columnNames.score,h', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,score,h,no',
        template: { id: 'betTypes.score,h,no', defaultMessage: '{home} Will Not Score' },
        columnName: { id: 'columnNames.score,h,no', defaultMessage: 'No' },
      },
    },
  },
  'clean,a': {
    name: { id: 'groupNames.clean,a', defaultMessage: 'Away Clean Sheet' },
    shortName: { id: 'groupNames.short.clean,a', defaultMessage: 'Away Clean' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,clean,{who}|for,clean,{who},{will}',
    position: { generator: Generators.clean, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,clean,a',
        template: { id: 'betTypes.clean,a', defaultMessage: '{away} Will Keep Clean Sheet' },
        columnName: { id: 'columnNames.clean,a', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,clean,a,no',
        template: { id: 'betTypes.clean,a,no', defaultMessage: '{away} Will Not Keep Clean Sheet' },
        columnName: { id: 'columnNames.clean,a,no', defaultMessage: 'No' },
      },
    },
  },
  'clean,h': {
    name: { id: 'groupNames.clean,h', defaultMessage: 'Home Clean Sheet' },
    shortName: { id: 'groupNames.short.clean,h', defaultMessage: 'Home Clean' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,clean,{who}|for,clean,{who},{will}',
    position: { generator: Generators.clean, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,clean,h',
        template: { id: 'betTypes.clean,h', defaultMessage: '{home} Will Keep Clean Sheet' },
        columnName: { id: 'columnNames.clean,h', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,clean,h,no',
        template: { id: 'betTypes.clean,h,no', defaultMessage: '{home} Will Not Keep Clean Sheet' },
        columnName: { id: 'columnNames.clean,h,no', defaultMessage: 'No' },
      },
    },
  },
  clean: {
    //theoretical (can't find any offers)
    name: { id: 'groupNames.clean', defaultMessage: 'Clean Sheet' },
    shortName: { id: 'groupNames.short.clean', defaultMessage: 'Clean' },
    handicap: false,
    yesNo: false,
    order: ['both', 'either', 'one', 'neither'],
    betTypeTemplate: 'for,clean,{who}|for,clean,{who},{will}',
    position: { generator: Generators.clean, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      both: {
        type: 'for,clean,both',
        template: { id: 'betTypes.clean,both', defaultMessage: 'Both Will Keep Clean Sheet' },
        columnName: { id: 'columnNames.clean,both', defaultMessage: 'Both' },
      },
      either: {
        type: 'for,clean,either',
        template: { id: 'betTypes.clean,either', defaultMessage: 'Either Will Keep Clean Sheet' },
        columnName: { id: 'columnNames.clean,either', defaultMessage: 'Either' },
      },
      one: {
        type: 'for,clean,one',
        template: { id: 'betTypes.clean,one', defaultMessage: 'One Will Keep Clean Sheet' },
        columnName: { id: 'columnNames.clean,one', defaultMessage: 'One' },
      },
      neither: {
        type: 'for,clean,neither',
        template: { id: 'betTypes.clean,neither', defaultMessage: 'Neither Keep Clean Sheet' },
        columnName: { id: 'columnNames.clean,neither', defaultMessage: 'Neither' },
      },
    },
  },
  'winToNil,a': {
    name: { id: 'groupNames.winToNil,a', defaultMessage: 'Away Win to Nil' },
    shortName: { id: 'groupNames.short.winToNil,a', defaultMessage: 'Away to Nil' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,wintonil,{team}|for,wintonil,{team},{will}',
    position: { generator: Generators.wintonil, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,wintonil,a',
        template: { id: 'betTypes.winToNil,a', defaultMessage: '{away} Wins to 0' },
        columnName: { id: 'columnNames.for,wintonil,a', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,wintonil,a,no',
        template: { id: 'betTypeswinToNil,a,no', defaultMessage: '{away} Not Win to 0' },
        columnName: { id: 'columnNames.for,wintonil,a,no', defaultMessage: 'No' },
      },
    },
  },
  'winToNil,h': {
    name: { id: 'groupNames.winToNil,h', defaultMessage: 'Home Win to Nil' },
    shortName: { id: 'groupNames.short.winToNil,h', defaultMessage: 'Home to Nil' },
    handicap: false,
    yesNo: true,
    order: ['yes', 'no'],
    betTypeTemplate: 'for,wintonil,{team}|for,wintonil,{team},{will}',
    position: { generator: Generators.wintonil, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      yes: {
        type: 'for,wintonil,h',
        template: { id: 'betTypes.winToNil,h', defaultMessage: '{home} Wins to 0' },
        columnName: { id: 'columnNames.for,wintonil,h', defaultMessage: 'Yes' },
      },
      no: {
        type: 'for,wintonil,h,no',
        template: { id: 'betTypes.winToNil,h,no', defaultMessage: '{home} Not Win to 0' },
        columnName: { id: 'columnNames.for,wintonil,h,no', defaultMessage: 'No' },
      },
    },
  },
  cs: {
    name: { id: 'groupNames.cs', defaultMessage: 'Correct Score' },
    shortName: { id: 'groupNames.short.cs', defaultMessage: 'Score' },
    handicap: true,
    score: true,
    order: ['*'],
    betTypeTemplate: 'for,cs,{home},{away}',
    position: { generator: Generators.cs, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,cs',
        template: { id: 'betTypes.cs,cs', defaultMessage: 'Correct Score {handicap}' },
        columnName: null,
      },
    },
  },
  'timeCs,tp,all': {
    name: { id: 'groupNames.cs', defaultMessage: 'Correct Score' },
    shortName: { id: 'groupNames.short.cs', defaultMessage: 'Score' },
    handicap: true,
    score: true,
    order: ['*'],
    betTypeTemplate: 'for,tp,all,cs,{home},{away}',
    position: { generator: Generators.cs, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,tp,all,cs',
        template: { id: 'betTypes.cs,cs', defaultMessage: 'Correct Score {handicap}' },
        columnName: null,
      },
    },
  },
  exactTotal: {
    name: { id: 'groupNames.exactTotal', defaultMessage: 'Exact Total' },
    shortName: { id: 'groupNames.short.exactTotal', defaultMessage: 'Exact' },
    handicap: true,
    //score: true,
    range: true,
    order: ['*'],
    betTypeTemplate: 'for,exact_total,{min}|for,exact_total,{min},{max}',
    position: { generator: Generators.exactTotal, dimension: 'score', size: 15 },
    overroundFactor: 1,
    betTypes: {
      '*': {
        type: 'for,exact_total',
        template: { id: 'betTypes.exactTotal', defaultMessage: 'Exact Total Goals {handicap}' },
        columnName: null,
      },
    },
  },
};

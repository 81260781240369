/** @format */

exports.default = {
  historyOrdersFullExportPageSize: 65535, //API reasons
  historyOrdersFullExportDaysAgo: 90, //API reasons
  historyOrdersMobileMaxPageSize: 100, //max page size for mobile layouts
  positionPageSize: 250, //page size for getting orders to populate trade position buttons
  agentPositionPageSize: 500, //page size for getting orders to populate trade position buttons
  oldOpenOrdersBetbarPageSize: 200, //page size for getting open orders
  allOrdersBetbarPageSize: 100, //page size for getting betbar orders
  maxPositionPageSize: 1000, //max page size for position request
  activePositionsEventOrders: 1000, //how many orders to get while trying to populate active position
  maxOrdersPageSize: 1000, //max orders per page
  orderLogsRequest: 1000, //max order logs page size
};

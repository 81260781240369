/** @format */

exports.default = {
  name: { id: 'sports.rl', defaultMessage: 'Rugby League' },
  shortName: { id: 'sports.rl.short', defaultMessage: 'Rugby League' },
  featuredCompetitions: ['608', '2017', '60479'],
  featuredCompetitionsName: [],
  enabled: true,
  maxTimeout: 600,
  correlated: {},
  correlatedOrder: ['rl'],
  trustScores: false,
  noDraws: true,
  matchDataOpener: 'for,tp,reg,ml,a/null',
  offerGroupsOrder: [
    //'timeWin,tp,all,wdw',
    //'timeWin,tp,all,mlssop',
    //'timeWin,thalf,1,mlssop',
    //'timeWin,thalf,2,mlssop',
    //'timeWin,tp,all,ml',
    'timeAh,tp,all',
    'timeAhou,tp,all',
    //'timeWin,tp,reg,wdw',
    'timeWin,tp,reg,ml',
    'timeAh,tp,reg',
    'timeAhou,tp,reg',
    'tahou,tp,all,a',
    'tahou,tp,all,h',
    //'timeWin,tperiod,1,wdw',
    //'timeAh,tperiod,1',
    //'timeAhou,tperiod,1',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    ml: 'timeWin,tp,all,ml',
    ah: 'timeAh,tp,reg',
    ahou: 'timeAhou,tp,all',
    wdw: 'timeWin,tp,reg,wdw',
    'tennisMatch,all': 'timeWin,tp,all,ml',
    'tennisAh,all,set': 'timeAh,tp,all',
    'tennisAhou,all,game': 'timeAhou,tp,all',
  },
};

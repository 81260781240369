/** @format */

// @flow
import * as Immutable from 'immutable';
import 'moment-timezone';
import moment from 'moment';
import CURRENCY from './currencies';

export { formatPrice, formatPriceToDecimal } from '@mollybet/price-types';

type EventTimeHalf = '1h' | '2h' | 'ht';
type IRTime = [EventTimeHalf, number] | string;

/**
 * format the in running time for an event
 * note: this only really deals with football
 * @param {Object} time - time from cpricefeed
 * @param {string} [time.0] - half (i.e. 1h, 2h)
 * @param {number} [time.1] - time in minutes
 * @param {string} sport - the sport to format IR time for (we basically need to know if it's extra time)
 * @return {string} - string representation of IR time
 */
export function formatIRTime(time: IRTime, sport: string) {
  if (typeof time === 'object') {
    let half = time[0];
    let minute = parseInt(time[1], 10);
    if (sport.indexOf('_et') !== -1) {
      if (half === '1h') {
        if (!minute) {
          return '1H';
        }
        if (minute > 15) {
          return "15'+" + (minute - 15) + "'";
        } else {
          return minute + "'";
        }
      } else if (half === '2h') {
        if (!minute) {
          return '2H';
        }
        if (minute > 15) {
          return "30'+" + (minute - 15) + "'";
        } else {
          return minute + 15 + "'";
        }
      } else if (half === 'ht') {
        return 'HT';
      } else {
        return null;
      }
    } else {
      if (half === '1h') {
        if (!minute || minute < 1) {
          return '1H';
        }
        if (minute > 45) {
          return "45'+" + (minute - 45) + "'";
        } else {
          return minute + "'";
        }
      } else if (half === '2h') {
        if (!minute) {
          return '2H';
        }
        if (minute > 45) {
          return "90'+" + (minute - 45) + "'";
        } else {
          return minute + 45 + "'";
        }
      } else if (half === 'ht') {
        return 'HT';
      } else {
        return null;
      }
    }
  } else {
    return time;
  }
}

type IRStatus = ?{
  time: IRTime,
  ...
} & {
  /* Probably because it's an Immutable.Map. Seperating into an intersection since we plan to retire immutable. */
  toJS(): IRStatus,
  ...
};

/**
 * format the start time of an event; deals with a lot of fallbacks and takes markets into account
 * @param {string} eventId - the event ID
 * @param {string} startTime - ISO string representation for the start time (moment.js deals with it)
 * @param {Object} irstatus - ir status of the event (from cpricefeed) in immutable Map form
 * @param {string} sport - passed on to formatIRTime if required
 * @param {string} market - market ('favs', 'early', 'today', 'ir'); formats vary based on market
 * @param {string} timezone - timezone string (must be supported by moment.js)
 * @return {string} - string representation of IR time
 */
export function formatEventTime(
  eventId: string,
  startTime: string,
  irstatus: IRStatus,
  sport: string,
  market: string,
  timezone: string
) {
  let str = '';
  if (startTime) {
    // $FlowFixMe wrong types in flow-typed, and moment has an awkward API
    startTime = moment(startTime).tz(timezone || 'UTC');
  }

  if (irstatus) {
    irstatus = irstatus.toJS();
  }

  if (irstatus && irstatus.time) {
    return formatIRTime(irstatus.time, sport);
  } else {
    if (startTime) {
      if (market === 'today' || market === 'ir') {
        str = startTime.format('HH:mm');
      } else {
        str = startTime.format('DD/MM');
      }
    } else if (eventId) {
      str = eventId.split(',')[0].split('-')[2] + '/' + eventId.split(',')[0].split('-')[1];
    }

    return str;
  }
}

export const MINUS_PREFIX = '-';
/**
 * format an stake amount
 * @param {string|number} amount - the amount to format
 * @param {string} fromCcy - the original ccy of the amount
 * @param {string} [toCcy] - the target ccy of the amount
 * @param {Object} [xrates] - the map of exchange rates (Immutable Map)
 * @param {boolean} [ignoreCents] - don't return the cents (after .) part of the amount
 * @param {boolean} [noFormat] - don't add the currency symbol
 * @param {boolean} [shorthand] - for large amounts use the short-hand notation (k, M, B)
 * @return {string} - string representation of the amount
 */
export function formatAmount(
  amount: null | string | number,
  fromCcy?: null | string,
  toCcy?: string,
  xrates?: Immutable.Map<string, number>,
  ignoreCents?: boolean,
  noFormat?: boolean,
  shorthand?: boolean
) {
  if (amount === null || amount === '' || amount === Infinity) {
    return '';
  }

  if (typeof amount === 'string') {
    amount = parseFloat(amount.replace(/,/g, '.'));
  }

  fromCcy = fromCcy ? fromCcy.toLowerCase() : '';
  toCcy = toCcy ? toCcy.toLowerCase() : '';

  //we have xrates, we have a target ccy
  if (toCcy && fromCcy !== toCcy) {
    if (!xrates) {
      console.warn(`[currency] no xrates but tried ${fromCcy} to ${toCcy}`);
      return '';
    }

    let fromRate = xrates.get(fromCcy, 0);
    let toRate = xrates.get(toCcy, 0);

    if (fromRate && toRate) {
      amount = (amount * toRate) / fromRate;
    } else {
      // console.warn(
      //   `[currency] failed conversion ${fromCcy} to ${toCcy} (${fromRate} .. ${toRate})`
      // );
      return ''; //better to show nothing if we can't convert
    }
  }

  const abs = Math.abs(amount);
  const prefix = amount < 0 ? MINUS_PREFIX : '';

  //? This has no currency information though
  if (shorthand) {
    return prefix + abbreviateNumber(abs);
  }

  //? This has no currency information either, though
  if (noFormat) {
    return toMaxFixed(amount, 2);
  } else {
    let currency;
    if (toCcy) {
      currency = CURRENCY[toCcy];
    } else {
      currency = CURRENCY[fromCcy];
    }

    if (currency) {
      // We know how to format this currency
      const formatted = currency.formatFromStake(amount);

      if (ignoreCents) {
        return formatted.split('.')[0];
      }

      return formatted;
    } else {
      // We don't know how to format this currency, so just return the CCY code instead
      if (isNaN(amount) || !amount) {
        console.warn(`[currency] invalid raw amount: ${amount}`);
        return '';
      }

      console.warn(`[currency] don't know how to format ${amount} in ${toCcy}`);
      return prefix + (toCcy || fromCcy) + (ignoreCents ? abs : toMaxFixed(abs, 2));
    }
  }
}

// API error messages are like people, they come in many wonderful shapes and sizes
//
// {status: "error", code: "invalid_password", data: "Password must contain at least one numeric digit"}
//
// {status: "error", code: "username_taken", data: null}
//
// {"status":"error","code":"validation_error","data":{"validation_errors":{"credit_limit":["invalid_amount"]}}}
//
// {"status":"error","code":"validation_error","data":{"validation_errors":{"commission_rate":{"rate_pc":["请确保该值大于或者等于 0。"]}}}}
//
// {"status":"error","code":"server_error","data":["An error has occurred, token:","vfEINTxQkFJ4L8wcCFXG"]}
//
// {
//    "status":"ERROR",
//    "data":{
//       "region":[
//          "Missing data for required field."
//       ],
//       "date_of_birth":[
//          "Missing data for required field."
//       ],
//       "telephone":[
//          "Missing data for required field."
//       ],
//       "username":[
//          "Length must be between 1 and 32."
//       ],
//       "address":[
//          "Missing data for required field."
//       ],
//       "first_line":[
//          "Unknown field."
//       ],
//       "second_line":[
//          "Unknown field."
//       ],
//       "county":[
//          "Unknown field."
//       ],
//       "contact_number":[
//          "Unknown field."
//       ],
//       "dob":[
//          "Unknown field."
//       ]
//    },
//    "code":"VALIDATION_ERROR"
// }
//
// {"code": "payment_not_allowed", "data": {"reason": "unsupported_customer_group", "details": null}, "status": "error"}
//
//we're basically trying to flatten the 'data' part into some sort of string
// flowlint-next-line unclear-type:off

// For manually overiding error messages when the frontend wants to give more information
const verboseMessages = {
  username: {
    'This value does not match the required pattern.':
      'Usernames must start with a letter and then may only contain, letters, numbers and underscores.',
  },
};

/**
 * attempt to extract an error message from something the API or backend sends
 * @param {string|Object} error - the amount to format
 * @return {string} - nice string representation of the error
 */
export function extractErrorMessage(error: any) {
  if (error) {
    if (typeof error === 'string') {
      //handle 1 / 2
      return error.replace(/_/gi, ' ');
    } else if (Array.isArray(error)) {
      //handle 5
      return error.join(' ');
    } else {
      //handle 3/4/6
      let arr = [];
      //i guess we could do some sort of recursion here for stuff that's even more deeply nested but ...
      for (let category in error) {
        //validation_errors
        if (typeof error[category] === 'object') {
          for (var field in error[category]) {
            if (Array.isArray(error[category][field])) {
              //array //credit_limit
              arr.push(
                `${field}: ${error[category][field]
                  .map((msg) => verboseMessages?.[field]?.[msg] || msg)
                  .join(', ')}`
              );
            } else {
              //object //commission_rate
              if (typeof error[category][field] === 'object') {
                for (var subfld in error[category][field]) {
                  const value = error[category][field][subfld];
                  //rate_pc
                  if (Array.isArray(value)) {
                    arr.push(
                      `${field}, ${subfld}: ${value
                        .map((msg) => verboseMessages?.[field]?.[subfld]?.[msg] || msg)
                        .join(', ')}`
                    );
                  } else {
                    arr.push(
                      `${field}, ${subfld}: ${verboseMessages?.[field]?.[subfld]?.[value] || value}`
                    );
                  }
                }
              } else {
                const value = error[category][field];
                arr.push(`${field}: ${verboseMessages?.[field]?.[value] || value}`);
              }
            }
          }
        } else {
          //6
          arr.push(`${category}: ${error[category]}`);
        }
      }

      return arr.join('; ').replace(/_/g, ' ');
    }
  } else {
    return '';
  }
}

/**
 * format a number to a fixed number of decimal places
 * @param {number} val - the number to format
 * @param {number} [prec=1] - precision (decimal places)
 * @param {boolean} [truncated=false] - truncate instead of rounding
 * @return {string} - number representation
 */
export const toMaxFixed = (val: number, prec: number = 1, truncate: boolean = false): string => {
  const pow = Math.pow(10, prec);
  const rounded = (Math.round(val * pow) / pow).toFixed(prec);

  return truncate ? Number(rounded).toString() : rounded;
};

/**
 * format the size of a file in bits to human readable format (MB, kB, etc.)
 * @param {number} size - the number to format
 * @return {string} - human readable file size
 */
export const humanFileSize = (size: number): string => {
  const magnitude = size ? Math.floor(Math.log(size) / Math.log(1024)) : 0;
  const unit = ['B', 'kB', 'MB', 'GB', 'TB'][magnitude]; // GB and TB should never happen, but hey

  const scaled = toMaxFixed(size / 1024 ** magnitude, 1, true);

  return `${scaled} ${unit}`;
};

/**
 * create short representation of a number (1k, 2M, etc.)
 * @param {number} number - the number to format
 * @return {string} - shorthand format
 */
export const abbreviateNumber = (number: number): string => {
  const magnitude = Math.floor(Math.log(number) / Math.log(1000));

  if (magnitude < 1) return toMaxFixed(number, 2);

  var suffix = ['', 'k', 'M', 'B'][magnitude];
  var scaled = number / 10 ** (magnitude * 3);

  return toMaxFixed(scaled, 2, true) + suffix;
};

export const parseEventStageForFeed = (eventStage, periodTime = '') => {
  let parsedEvTime = periodTime;
  if (periodTime && periodTime !== '') {
    parsedEvTime = `${periodTime}'`;
  }
  let words = eventStage.split('_');
  words = words.map((word) => {
    return word[0] + word.substring(1).toLowerCase();
  });
  const defaultReturnVal = words.join(' ');
  switch (eventStage) {
    // Ice Hockey
    case 'FIRST_PERIOD':
      return `1st Period ${parsedEvTime}`;
    case 'SECOND_PERIOD':
      return `2nd Period ${parsedEvTime}`;
    case 'THIRD_PERIOD':
      return `3rd Period ${parsedEvTime}`;
    case 'EXTRA_TIME':
      return `Extra-time ${parsedEvTime}`;
    // Basketball cases
    case 'FIRST_QUARTER':
      return `Quarter 1 ${parsedEvTime}`;
    case 'SECOND_QUARTER':
      return `Quarter 2 ${parsedEvTime}`;
    case 'THIRD_QUARTER':
      return `Quarter 3 ${parsedEvTime}`;
    case 'FOURTH_QUARTER':
      return `Quarter 4 ${parsedEvTime}`;
    case 'HALF_TIME':
      return 'Half time';
    case 'EXTRA_TIME':
      return `Extra-time ${parsedEvTime}`;
    case 'AFTER_EXTRA_TIME':
      return `After Extra-time ${parsedEvTime}`;
    case 'PAUSE':
      return 'Paused';
    // All other cases
    default:
      return defaultReturnVal;
  }
};

export const parseEventStageForTradePage = (eventStage, evTime, periodTime = '') => {
  let parsedEvTime = periodTime;
  if (periodTime && periodTime !== '') {
    parsedEvTime = `${periodTime}'`;
  }
  switch (eventStage) {
    // Rugby cases
    case 'FIRST_HALF':
      return `1st ${parsedEvTime}`;
    case 'SECOND_HALF':
      return `2nd ${parsedEvTime}`;
    // Ice Hockey
    case 'FIRST_PERIOD':
      return `1st ${parsedEvTime}`;
    case 'SECOND_PERIOD':
      return `2nd ${parsedEvTime}`;
    case 'THIRD_PERIOD':
      return `3rd ${parsedEvTime}`;
    case 'EXTRA_TIME':
      return `ET ${parsedEvTime}`;
    case 'PENALTIES':
      return `Pen`;
    // Basketball cases
    case 'FIRST_QUARTER':
      return `Q1 ${parsedEvTime}`;
    case 'SECOND_QUARTER':
      return `Q2 ${parsedEvTime}`;
    case 'THIRD_QUARTER':
      return `Q3 ${parsedEvTime}`;
    case 'FOURTH_QUARTER':
      return `Q4 ${parsedEvTime}`;
    case 'HALF_TIME':
      return 'HT';
    case 'EXTRA_TIME':
      return `ET ${parsedEvTime}`;
    case 'AFTER_EXTRA_TIME':
      return `AET ${parsedEvTime}`;
    // Cricket cases
    case 'PAUSE':
      return 'Paused';
    case 'FIRST_INNING':
      return '1st';
    case 'SECOND_INNING':
      return '2nd';
    // Baseball cases
    case 'THIRD_INNING':
      return '3rd';
    case 'FOURTH_INNING':
      return '4th';
    case 'FIFTH_INNING':
      return '5th';
    case 'SIXTH_INNING':
      return '6th';
    case 'SEVENTH_INNING':
      return '7th';
    case 'EIGHTH_INNING':
      return '8th';
    case 'NINTH_INNING':
      return '9th';
    case 'EXTRA_INNING':
      return 'Ext';
    case 'PENDING':
      return 'TBC';
    // Tennis cases
    case 'INTERRUPTED':
      return 'Int';
    case 'FIRST_SET':
    case 'FIRST_SET_TIEBREAK':
      return 'Set 1';
    case 'SECOND_SET':
    case 'SECOND_SET_TIEBREAK':
      return 'Set 2';
    case 'THIRD_SET':
    case 'THIRD_SET_TIEBREAK':
      return 'Set 3';
    case 'FOURTH_SET':
    case 'FOURTH_SET_TIEBREAK':
      return 'Set 4';
    case 'FIFTH_SET':
    case 'FIFTH_SET_TIEBREAK':
      return 'Set 5';
    case 'AFTER_PENALTIES':
    case 'FINISHED':
      return 'Fin';
    case 'SUSPENDED':
      return 'Susp';
    case 'RETIRED':
      return 'Ret';
    case 'WALKOVER':
    case 'SCHEDULED':
    case 'DELAYED':
    case 'POSTPONED':
    case 'CANCELED':
    default:
      return evTime;
  }
};

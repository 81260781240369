/** @format */

import * as Sentry from '@sentry/browser';
import config from '../config';

const TENNIS_VOID = /,v(whole|set[1-5]{1}|game[0-9]+),/gi;

/**
 * Strip away tennis void rules and replace them with vwhatever
 * @param {string} betType - bet type to munge
 * @return {string} - munged bet type
 */

export function normalizeTennisVoidBetType(betType) {
  return betType.replace(TENNIS_VOID, ',vwhatever,');
}

/**
 * A function for formatting various handicaps in various instances
 * @param {string} hand - handicap to format (i.e. '14', '13', '-12', `2,4`)
 * @param {boolean} invert - invert + to -, yes to no, etc. (doesn't work on ranges because it doesn't make sense; yes/no is basically dictated by invert flag)
 * @param {string} offerGroup - the offer group to format for (different groups have different characteristics)
 * @param {string} userTradeView - the asian view wants the haandicaps formatted a bit different
 * @param {string} contentLayout - mobile mode needs a different format as well
 * @todo the above 2 options can be made into 1
 * @return {string} - handicap in user format
 */
export function formatHandicap(hand, invert, offerGroup, userTradeView, contentLayout) {
  if (!hand || !config.offerGroups[offerGroup]) {
    return hand;
  }

  let isAH = config.offerGroups[offerGroup].isAsian;
  let isAHOU = config.offerGroups[offerGroup].isOverUnder;
  let isYesNo = config.offerGroups[offerGroup].yesNo;
  let isRange = config.offerGroups[offerGroup].range;
  let isScore = config.offerGroups[offerGroup].score;
  let displayAsRaw = config.offerGroups[offerGroup].displayAsRaw;

  if (isRange) {
    let [min, max] = hand.split(',');
    if (min !== max) {
      if (max !== '999' && max !== 'inf') {
        //for our intents and purposes 999 = infinity (check mirlistener)
        if (max === undefined) {
          return `${min}`;
        } else {
          return `${min}..${max}`;
        }
      } else {
        //could be -999 in theory
        return `${min}+`;
      }
    } else {
      return min;
    }
  } else if (isScore) {
    return hand.replace(',', '-');
  } else {
    if (displayAsRaw) {
      //do nothing
    } else {
      hand = parseFloat(hand) / 4;
    }

    let prefix = '';
    if (isAH) {
      prefix = (hand < 0 && !invert) || (hand >= 0 && invert) ? '-' : '+';
      if (userTradeView === 'asian') {
        let decs = hand.toFixed(2).split('.')[1];
        if (decs === '25' || decs === '75') {
          if (hand < 0) {
            hand = Math.abs(hand + 0.25) + '/' + Math.abs(hand - 0.25);
          } else {
            hand = Math.abs(hand - 0.25) + '/' + Math.abs(hand + 0.25);
          }
        }
      }
    } else if (isAHOU && userTradeView === 'asian') {
      if (contentLayout === 'narrow') {
        prefix = 'o/u';
      } else {
        prefix = invert ? 'u' : 'o';
      }
    } else if (isYesNo) {
      if (contentLayout === 'narrow') {
        prefix = '';
      } else {
        prefix = invert ? 'No' : 'Yes';
      }
    }

    return `${prefix}${!isNaN(hand) ? Math.abs(hand) : isYesNo ? '' : hand}`;
  }
}

/**
 * Flips a bet type, taking into account scores
 * note: makes the assumption that ir to deadball is impossible
 * @param {string} betType - bet type
 * @param {Object} [score] - the current IR score
 * @param {number} [score.0] - home score
 * @param {number} [score.1] - away score
 * @return {string} - flipped bet type
 */
export function flipBetType(betType, [homeScore, awayScore]) {
  if (betType.indexOf('for,') === 0 || betType.indexOf('against,') === 0) {
    betType = betType.replace(TENNIS_VOID, ',vwhatever,');

    //there are some shenanigans we have to deal with
    //bets with different scores are not considered equivalents but
    //if one has a score and the other doesn't that's fine

    //split
    let atoms = betType.split(',');

    if (
      homeScore !== null &&
      awayScore !== null &&
      homeScore !== undefined &&
      awayScore !== undefined
    ) {
      if (atoms[1] === 'ir') {
        if (atoms[4] === 'ah') {
          //if we placed a bet ir and the score has now changed we have to re-offset the bet before we flip it
          let _homeScore = parseInt(atoms[2], 10);
          let _awayScore = parseInt(atoms[3], 10);

          //we have to recalculate the offset
          if (_homeScore !== homeScore || _awayScore !== awayScore) {
            let handicap = parseInt(atoms[6], 10);
            handicap -= 4 * (_homeScore - _awayScore); //remove old score
            handicap += 4 * (homeScore - awayScore); //add new score
            atoms[6] = handicap;
            atoms[2] = homeScore;
            atoms[3] = awayScore;
          }
        } else if (atoms[4] === 'ahunder' || atoms[4] === 'ahover') {
          //update score anyway
          atoms[2] = homeScore;
          atoms[3] = awayScore;
        }
      } else {
        //if it's deadball we might have to make it an ir bet; we need to check if a score exists
        if (atoms[1] === 'ah') {
          //deadball is 0 - 0
          let _homeScore = 0;
          let _awayScore = 0;

          //we have to recalculate the offset
          if (_homeScore !== homeScore || _awayScore !== awayScore) {
            let handicap = parseInt(atoms[3], 10);
            handicap -= 4 * (_homeScore - _awayScore); //remove old score
            handicap += 4 * (homeScore - awayScore); //add new score
            atoms[3] = handicap;
          }
          //insert ir and score
          atoms.splice(1, 0, 'ir');
          atoms.splice(2, 0, homeScore);
          atoms.splice(3, 0, awayScore);
        } else if (atoms[1] === 'ahunder' || atoms[1] === 'ahover') {
          //insert ir and score
          atoms.splice(1, 0, 'ir');
          atoms.splice(2, 0, homeScore);
          atoms.splice(3, 0, awayScore);
        }
      }
    }

    //rejoin
    betType = atoms.join(',');

    //reverse
    if (betType.indexOf('for,') === 0) {
      return betType.replace('for,', 'against,');
    } else if (betType.indexOf('against,') === 0) {
      return betType.replace('against,', 'for,');
    } else {
      return betType;
    }
  } else {
    //no clue what this is
    return '';
  }
}

/**
 * Determine winner from bet type
 * @param {string} betType - bet type
 * @return {string} - 'home', 'away' or ''
 */
export function winnerFromBetType(betType) {
  if (!betType) {
    return '';
  }

  let atoms = betType.split(',');

  if (atoms[1] === 'dc') {
    return '';
  }

  if (atoms[0] === 'for') {
    if (atoms.indexOf('a') !== -1 || atoms.indexOf('p2') !== -1) {
      return 'away';
    } else if (atoms.indexOf('h') !== -1 || atoms.indexOf('p1') !== -1) {
      return 'home';
    } else {
      return '';
    }
  }

  return '';
}

/**
 * Return the parameters by matching a betType to a betTypeTemplate
 * @param {string} betType - bet type
 * @param {string} betTypeTemplate - bet type template to match against
 * @return {Object} params - information and parameters about the bet
 * @return {boolean} params.flipped - was the bet flipped
 * @return {boolean} params.against - is it an against bet
 * @return {string} params.outcome - outcome {h|d|a}
 * @return {string} params.outcome2 - second outcome {h|d|a}
 * @return {string} params.htOutcome - half time outcome {h|d|a}
 * @return {string} params.team - team it refers to {h|a}
 * @return {boolean} params.will - will or will not happen
 * @return {number} params.hcap - handicap
 * @return {number} params.minHome - minimum range for home
 * @return {number} params.minAway - minimum range for away
 * @return {string|number} params.maxHome - maximum range for home, can also be 'inf'
 * @return {string|number} params.maxAway - maximum range for away, can also be 'inf'
 * @return {string} params.direction - direction of the bet type {over|under}
 * @return {number} params.irhome - in running score home
 * @return {number} params.iraway - in running score away
 * @return {number} params.home - in score home
 * @return {number} params.away - in score away
 * @return {string} params.who - who it refers to {a|h}
 * @return {string} params.teamOrDraw - see bet type config for quatro {a|h|hd|dh|ad|da}
 */
export function parseBetTypeToParameters(betType, betTypeTemplate) {
  let flip = false;
  if (betType.startsWith('flip')) {
    flip = true;
    betType = betType.replace('flip,', '');
  }

  let against = false;
  if (betType.startsWith('against')) {
    against = true;
    betType = betType.replace('against', 'for');
  }

  //tennis void rule
  betType = betType.replace(TENNIS_VOID, ',vwhatever,');

  let extractedParams = {}; //extracted
  let betTypeAtoms = betType.split(',');
  let matchable = betTypeTemplate.split('|');

  for (let template of matchable) {
    //split them into their bits
    let templateAtoms = template.split(',');

    if (templateAtoms.length === betTypeAtoms.length) {
      let matched = {};
      let matches = false;

      for (let len = 0; len < templateAtoms.length; len++) {
        matched[templateAtoms[len]] = betTypeAtoms[len];
      }

      let matchedParams = {};
      for (let key in matched) {
        if (key === matched[key]) {
          //great
        } else if (key.match(/\{.*\}/gi)) {
          //it's a parameter
          matchedParams[key.match(/\{.*\}/gi)[0].replace(/\{|\}/gi, '')] = isNaN(matched[key])
            ? matched[key]
            : parseFloat(matched[key]);
        } else {
          //balls
          break;
        }
      }

      matches = true;
      if (matches) {
        extractedParams = matchedParams;
        //break;
      }
    }
  }

  //sanitize extracted params

  //outcome
  //player to outcome
  if (extractedParams['outcome'] === 'p1') {
    extractedParams['outcome'] = 'h';
  } else if (extractedParams['outcome'] === 'p2') {
    extractedParams['outcome'] = 'a';
  }
  //flip outcome
  if (flip && extractedParams['outcome'] === 'h') {
    extractedParams['outcome'] = 'a';
  } else if (flip && extractedParams['outcome'] === 'a') {
    extractedParams['outcome'] = 'h';
  }

  //outcome2
  //player to outcome2
  if (extractedParams['outcome2'] === 'p1') {
    extractedParams['outcome2'] = 'h';
  } else if (extractedParams['outcome2'] === 'p2') {
    extractedParams['outcome2'] = 'a';
  }
  //flip outcome2
  if (flip && extractedParams['outcome2'] === 'h') {
    extractedParams['outcome2'] = 'a';
  } else if (flip && extractedParams['outcome2'] === 'a') {
    extractedParams['outcome2'] = 'h';
  }

  //htOutcome
  //player to team
  if (extractedParams['htOutcome'] === 'p1') {
    extractedParams['htOutcome'] = 'h';
  } else if (extractedParams['htOutcome'] === 'p2') {
    extractedParams['htOutcome'] = 'a';
  }
  //flip outcome
  if (flip && extractedParams['htOutcome'] === 'h') {
    extractedParams['htOutcome'] = 'a';
  } else if (flip && extractedParams['htOutcome'] === 'a') {
    extractedParams['htOutcome'] = 'h';
  }

  //team
  //player to team
  if (extractedParams['team'] === 'p1') {
    extractedParams['team'] = 'h';
  } else if (extractedParams['team'] === 'p2') {
    extractedParams['team'] = 'a';
  }
  //flip team
  if (flip && extractedParams['team'] === 'h') {
    extractedParams['team'] = 'a';
  } else if (flip && extractedParams['team'] === 'a') {
    extractedParams['team'] = 'h';
  }
  //flip hcap if there is team
  if (flip && extractedParams['team'] && extractedParams['hcap']) {
    extractedParams['hcap'] *= -1;
  }

  //will
  if (extractedParams['will'] === 'no') {
    extractedParams['will'] = false;
  } else {
    extractedParams['will'] = true; //default is positive
  }

  //min/max
  // if (extractedParams['min'] && !extractedParams['max']) {
  //   extractedParams['max'] = 'inf';
  // }
  if (extractedParams['minHome'] && !extractedParams['maxHome']) {
    extractedParams['maxHome'] = 'inf';
  }
  if (extractedParams['minAway'] && !extractedParams['maxAway']) {
    extractedParams['maxAway'] = 'inf';
  }
  //flip min-max
  if (flip) {
    let temp = extractedParams['minHome'];
    extractedParams['minHome'] = extractedParams['minAway'];
    extractedParams['minAway'] = temp;

    temp = extractedParams['maxHome'];
    extractedParams['maxHome'] = extractedParams['maxAway'];
    extractedParams['maxAway'] = temp;
  }

  //direction
  if (
    extractedParams['direction'] === 'o' ||
    extractedParams['direction'] === 'ahover' ||
    extractedParams['direction'] === 'tahover' ||
    extractedParams['direction'] === 'overeq'
  ) {
    extractedParams['direction'] = 'over';
  } else if (
    extractedParams['direction'] === 'u' ||
    extractedParams['direction'] === 'ahunder' ||
    extractedParams['direction'] === 'tahunder' ||
    extractedParams['direction'] === 'undereq'
  ) {
    extractedParams['direction'] = 'under';
  }

  //irscore
  //flip irscore
  if (flip) {
    let temp = extractedParams['irhome'];
    extractedParams['irhome'] = extractedParams['iraway'];
    extractedParams['iraway'] = temp;
  }

  //score
  //flip score
  if (flip) {
    let temp = extractedParams['home'];
    extractedParams['home'] = extractedParams['away'];
    extractedParams['away'] = temp;
  }

  //who
  //flip who
  if (flip) {
    if (extractedParams['who'] === 'h') {
      extractedParams['who'] = 'a';
    } else if (extractedParams['who'] === 'a') {
      extractedParams['who'] = 'h';
    }
    //need to flip either, neither ?
  }

  //teamOrDraw
  if (flip) {
    if (extractedParams['teamOrDraw'] === 'h') {
      extractedParams['teamOrDraw'] = 'a';
    } else if (extractedParams['teamOrDraw'] === 'a') {
      extractedParams['teamOrDraw'] = 'h';
    } else if (extractedParams['teamOrDraw'] === 'hd') {
      extractedParams['teamOrDraw'] = 'ad';
    } else if (extractedParams['teamOrDraw'] === 'dh') {
      extractedParams['teamOrDraw'] = 'da';
    } else if (extractedParams['teamOrDraw'] === 'ad') {
      extractedParams['teamOrDraw'] = 'hd';
    } else if (extractedParams['teamOrDraw'] === 'da') {
      extractedParams['teamOrDraw'] = 'dh';
    }
  }

  return {
    flipped: flip,
    against,
    ...extractedParams,
  };
}

/**
 * Check if the bet is a custom bet - e.g. flatten
 */
export function isCustomBet(betType) {
  if (betType === undefined || betType === null) return false;
  if (betType.includes('custom')) {
    return true;
  }
  return false;
}

export const isUnhandledBet = (betParam) => {
  if (betParam == null || betParam === undefined || betParam.notFound) {
    return true;
  }
  return false;
};

/**
 * Bet type parameters from a string bet type
 * @param {string} betType - bet type
 * @return {Object} params - a dictionary of all the bet type parameters
 * @return {string} params.betType - bet type without handicap parts
 * @return {string} params.clean - bet type without ir score and handicap parts
 * @return {string} params.offerGroup - the offer group of the bet type, i.e. for,a -> wdw
 * @return {string} params.offer - the type of offer, i.e. for,a -> a
 * @return {string} params.betTypeTemplate - a string with some matching parameters, see group configs
 * @return {Object} params.position - position configuration
 * @return {Object} params.params - extracted params from bet type, i.e. flipped, clean, handicap, irscores, etc
 */
export function toBetParams(betType) {
  if (isCustomBet(betType)) {
    return {
      clean: 'custom',
      betType: 'custom',
    };
  }

  //xena needs this (maybe better to fix in beslip urls ?)
  // deal with betTypes which includes under score, so can match betType in config
  betType = betType.replace(/moBothScore/gi, 'mo_both_score');
  betType = betType.replace(/noGoal/gi, 'no_goal');
  betType = betType.replace(/win90/gi, 'win_90');
  betType = betType.replace(/exactTotal/gi, 'exact_total');

  let params = {
    betType,
    //strip out just handicap
    baseBetType: betType
      .replace(TENNIS_VOID, ',vwhatever,')
      .replace(/(,[-]?[.0-9]*)*$/gi, '')
      .replace(/(,[.0-9]*)?,inf/gi, '')
      .replace(/,yes/gi, ''),
    //strip out ir, score (and cs) and handicap
    clean: betType
      .replace('flip,', '')
      .replace('against', 'for')
      .replace(TENNIS_VOID, ',vwhatever,')
      .replace(/,ir(,[0-9]+)*/gi, '')
      .replace(/(,[-]?[.0-9]*)*$/gi, '')
      .replace(/(,[.0-9]*)?,inf/gi, ''),
  };

  //this has ",yes" for some reason...
  if (betType.indexOf('mo_both_score') === -1) {
    params.clean = params.clean.replace(',yes', '');
  }

  let wasFound = false;

  if (betType.includes('custom')) {
    params.clean = 'custom';
    params.betType = 'custom';
  }

  found: for (let og in config.offerGroups) {
    for (let bt in config.offerGroups[og].betTypes) {
      if (params.clean === config.offerGroups[og].betTypes[bt].type) {
        params.offerGroup = og;
        params.offer = bt;
        params.config = config.offerGroups[og].betTypes[bt];
        params.offerGroupConfig = config.offerGroups[og];
        params.betTypeTemplate = config.offerGroups[og].betTypeTemplate;
        params.position = config.offerGroups[og].position;
        params.params = parseBetTypeToParameters(params.betType, params.betTypeTemplate);
        wasFound = true;
        break found;
      }
    }
  }

  if (!wasFound) {
    Sentry.captureMessage(`Bet type ${betType} could not be decoded`, {
      level: 'warning',
      logger: 'lib:betTypes',
      extra: params,
    });
    return {
      notFound: true,
      ...params,
    };
  }

  return params;
}

/**
 * Flatten a position grids to test against the old duma examples
 * @param {Object} positionGrid - position grid representation
 * @return {Object} - flattened position array
 */
export function flattenGrid(positionGrid) {
  let output = [];
  for (let row of positionGrid) {
    for (let element of row) {
      output.push(element);
    }
  }

  return output;
}

/**
 * Compute position grid from a bet type, with a max score, and an optional min score
 * @param {string} betType - bet type
 * @param {number} maxScore - maximum score to calculate up to
 * @param {number} [minScore=0] - minimum score to calculate from
 * @return {Object} - a disctionary of all the bet type parameters
 */
export function computePosition(betType, maxScore, minScore = 0) {
  //if (betType.includes('custom')) return null;
  const betParams = toBetParams(betType);
  let generator;
  if (!config.offerGroups[betParams.offerGroup]?.position?.generator) {
    return null;
  } else {
    generator = config.offerGroups[betParams.offerGroup].position.generator;
    if (!maxScore) {
      maxScore = config.offerGroups[betParams.offerGroup].position.size;
    }
  }

  let output = [];
  for (let i = minScore; i < maxScore; i++) {
    let row = [];
    for (let j = minScore; j < maxScore; j++) {
      let res;
      try {
        res = generator(betParams.params, { home: i, away: j });
      } catch (err) {
        Sentry.captureMessage(`Position value can't be computed for ${betType}: ${i} - ${j}`, {
          level: 'error',
          logger: 'lib:betTypes',
          extra: {
            ...betParams.params,
            _homeScore: i,
            _awayScore: j,
          },
        });

        //bail out
        return null;
      }
      if (betParams.params.against) {
        res = res.reverse();
      }
      row.push(res.value);
    }
    output.push(row);
  }

  return output;
}

window.computePosition = computePosition;

/**
 * Return the PnL based on a bet type, stake, price and current score
 * @param {string} betType - bet type
 * @param {number} stake - stake
 * @param {number} price - price
 * @param {Object} score - score {home: number, away: number}
 * @return {number} - the PnL value
 */
export function scorePositionValue(betType, stake, price, score) {
  if (!stake || !price || !score) {
    return null;
  }
  //this may happen when score changes on the trade page event
  if (score.home === null || score.away === null) {
    return null;
  }
  const betParams = toBetParams(betType);
  let generator;
  if (!config.offerGroups[betParams.offerGroup]?.position?.generator) {
    return null;
  } else {
    generator = config.offerGroups[betParams.offerGroup].position.generator;
  }

  let res;

  try {
    res = generator(betParams.params, score);
  } catch (err) {
    Sentry.captureMessage(
      `Position value can't be computed for ${betType}: ${score.home} - ${score.away}`,
      {
        level: 'error',
        logger: 'lib:betTypes',
        extra: {
          ...betParams.params,
          score,
        },
      }
    );

    return null;
  }

  if (betParams.params.against) {
    res = res.reverse();
  }

  let side = res.value;

  if (side === 'w') return stake * (price - 1);
  if (side === 'l') return -stake;
  if (side === 'v/w') return stake * +0.5 * (price - 1);
  if (side === 'l/v') return stake * -0.5;
  if (side === 'v') return 0;
}

/**
 * Compare two bet position grids and determine if they overlap;
 * Note that by default it compares 300x300
 * @param {string} betType1 - bet type 1
 * @param {string} betType2 - bet type 2
 * @param {string} [min=0] - minimum score
 * @param {string} [max=200] - max score
 * @return {boolean} - are they equivalent
 */
export function areBetTypesEquivalent(betType1, betType2, min = 0, max = 300) {
  if (betType1.includes('custom') || betType2.includes('custom')) return false;

  //skip if we can
  if (normalizeTennisVoidBetType(betType1) === normalizeTennisVoidBetType(betType2)) {
    return true;
  }

  //TODO: this is very expensive overall, you should look to optimize

  const betParams1 = toBetParams(betType1);
  const betParams2 = toBetParams(betType2);

  if (betParams1.position == null || betParams2.position == null) {
    return false;
  }
  //are they across different score dimensions ?
  if (betParams1.position.dimension !== betParams2.position.dimension) {
    return false;
  }

  let generator1;
  if (!config.offerGroups[betParams1.offerGroup].position.generator) {
    return false;
  } else {
    generator1 = config.offerGroups[betParams1.offerGroup].position.generator;
  }

  let generator2;
  if (!config.offerGroups[betParams2.offerGroup].position.generator) {
    return false;
  } else {
    generator2 = config.offerGroups[betParams2.offerGroup].position.generator;
  }

  let isEquiv = true;

  broken: for (let i = min; i < max; i++) {
    for (let j = min; j < max; j++) {
      let res1;
      try {
        res1 = generator1(betParams1.params, { home: i, away: j });
      } catch (err) {
        Sentry.captureMessage(`Position value can't be computed for ${betType1}: ${i} - ${j}`, {
          level: 'error',
          logger: 'lib:betTypes',
          extra: {
            ...betParams1.params,
            _homeScore: i,
            _awayScore: j,
          },
        });

        //bail out
        return false;
      }
      if (betParams1.params.against) {
        res1 = res1.reverse();
      }
      let res2;
      try {
        res2 = generator2(betParams2.params, { home: i, away: j });
      } catch (err) {
        Sentry.captureMessage(`Position value can't be computed for ${betType2}: ${i} - ${j}`, {
          level: 'error',
          logger: 'lib:betTypes',
          extra: {
            ...betParams2.params,
            _homeScore: i,
            _awayScore: j,
          },
        });

        //bail out
        return false;
      }
      if (betParams2.params.against) {
        res2 = res2.reverse();
      }

      if (res1.value !== res2.value) {
        isEquiv = false;
        break broken;
      }
    }
  }

  return isEquiv;
}

// alinp was here :feelsgoodman:

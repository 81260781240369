/** @format */

exports.default = {
  name: { id: 'sports.ih', defaultMessage: 'Ice Hockey' },
  shortName: { id: 'sports.ih', defaultMessage: 'Ice Hockey' },
  featuredCompetitions: ['1294', '2061', '1291', '1292'],
  featuredCompetitionsName: [],
  enabled: true,
  maxTimeout: 600,
  correlated: {
    ih_ht: {
      sport: 'ih_ht',
      name: { id: 'sports.ihht', defaultMessage: 'Ice Hockey: Half-Time' },
      shortName: { id: 'sports.ihht.short', defaultMessage: 'Ice Hockey [HT]' },
      correlatedOrder: ['ih_ht', 'ih'],
    },
  },
  correlatedOrder: ['ih', 'ih_ht'],
  trustScores: false,
  matchDataOpener: 'for,tp,reg,wdw,a/null',
  offerGroupsOrder: [
    'timeWin,tp,all,ml',
    //'timeWin,tp,all,wdw',
    //'timeWin,tp,all,dnb', //???
    'timeAh,tp,all',
    'timeAhou,tp,all',
    'timeWin,tp,reg,wdw',
    //'timeWin,tp,reg,ml',
    //'timeWin,tp,reg,dnb', //???
    'timeAh,tp,reg',
    'timeAhou,tp,reg',
    'tahou,tp,all,a',
    'tahou,tp,all,h',
    'tahou,tp,reg,a',
    'tahou,tp,reg,h',
    //'timeWin,tperiod,1,wdw',
    //'timeAh,tperiod,1',
    //'timeAhou,tperiod,1',
    //'timeWin,tperiod,2,wdw',
    //'timeAh,tperiod,2',
    //'timeAhou,tperiod,2',
    //'timeWin,tperiod,3,wdw',
    //'timeAh,tperiod,3',
    //'timeAhou,tperiod,3',
    //'timeOe,tp,reg,oe',
    //'timeOe,tp,all,oe',
    //'tahou,tperiod,1,h',
    //'tahou,tperiod,1,a',
    //'tahou,tperiod,2,h',
    //'tahou,tperiod,2,a',
    //'tahou,tperiod,3,h',
    //'tahou,tperiod,3,a',
    //'tahou,tperiod,4,h',
    //'tahou,tperiod,4,a',
    //'tahou,tperiod,5,h',
    //'tahou,tperiod,5,a',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    ml: 'timeWin,tp,all,ml',
    ah: 'timeAh,tp,reg',
    ahou: 'timeAhou,tp,reg',
    wdw: 'timeWin,tp,reg,wdw',
    'tennisMatch,all': 'timeWin,tp,all,ml',
    'tennisAh,all,set': 'timeAh,tp,all',
    'tennisAhou,all,game': 'timeAhou,tp,all',
    //'timeOe,tp,all,oe': 'timeOe,tp,reg,oe',
    //oe: 'timeOe,tp,reg,oe',
    //'tennisOe,all,game': 'timeOe,tp,reg,oe',
  },
};

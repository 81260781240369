/** @format */

const defaults = {
  announcements: {
    //[announcement]: [time seen]
  },

  general: {
    autoSearchBind: false, //[trade+] automatically assign ctrl+F to trade page search (or agent users search)
    displayCcy: '', //[DEV] [ADVANCED] [EXPERIMENTAL] interface display ccy; normal users should not have this, it's used to test that conversions are done everywhere
    hideCredit: false, //[general] hide credit in top right
    hideUsername: false, //[general] hide username in top right
    language: 'en', //[general] interface language (options defined in config)
    lastForced: 0, //[DEV] when were the settings last forced to a certain value; use to fix up bad settings (see forced.js)
    //'multipleLogins': false, //[API] is user allowed to have multiple logins
    priceType: 'dec', //[general] price format to be used
    seenTickers: {}, //[general] what ticker messages has the user seen
    showEssentialAnnoucementsOnly: false, //[general] only show announcements that are marked as essential
    theme: 'default', //[general] {dark|light} user theme
    timezone: 'UTC', //[general] time zone to use when formatting timestamps
  },

  history: {
    bets: {
      lastQuery: '', //last query the page was on
      views: {}, //a list of user saved views
    },

    dashboard: {
      lastQuery: '', //last query the page was on
    },

    orders: {
      lastQuery: '', //last query the page was on
    },
  },

  terms: {
    //[term] : [date accepted]
  },

  trade: {
    advancedBetslipInfo: true, //[betslip] replaces max order, min order, returns, average price, minimum price, maximum price
    advancedOrderPlacementOptions: true, //[betslip] show 'keep open IR' and 'take starting' options in betslip
    agentPositionGrids: true, //[ADVANCED] use agent position grids instead of user position grids (useful when a user is also a gambler, on by default)
    allowPutOfferOnExchange: true, //[betslip] allow putting offers up on exchanges when no liquidity available
    alwaysExpandAllMarkets: false, //[ADVANCED] always automatically expand all sub-sports for all events
    //maxBetslips: 8, //[API] how many betslips to allow (this is done via user config, not userprefs)
    autoAddBetsToFavs: false, //[trade] automatically add events you bet on to favorites
    autofillMiddleHandicaps: true, //[trade] [betslip] automatically fill in missing handicap lines to avoid 'skips' in handicap sequences

    //[betbar] betbar specific settings
    betbar: {
      expanded: false, //[betbar] is the betbar open
      height: 100, //[betbar] the betbar height (as dragged by the user)
      showClosed: true, //[betbar] show closed orders
      showOnlyMine: false, //[betbar] show only my bets (must have spy on group)
    },

    betslipAgent: false, //[betslip] show agent name in betslip
    betslipDefaultTimeout: 60, //[betslip] default betslip expiry time
    betslipTint: false, //[betslip] tint betslip background with market color to make it more obvious
    bookieTint: false, //[betslip] tint each of the bookies in the betslip with their own color to make them more distinct

    exchangeMode: 'make_and_take',

    confirmBet: true, //[betslip] pop up confirmation window whenever placing a bet

    showQuickPlace: true, //show quick place buttons
    quickPlaceButtonOneValue: '10', //[betslip] quick place button value
    quickPlaceButtonTwoValue: '20', //[betslip] quick place button value
    quickPlaceButtonThreeValue: '50', //[betslip] quick place button value

    defaultPrice: null, //[betslip] default price to use in betslip (backend forces it to null?)
    defaultStake: null, //[betslip] default stake to use in betslip (backend forces it to null?)
    disabledBookies: [], //[ADVANCED] [betslip] automatically untick some bookies in the betslip (selected in advanced bookie settings screen)

    bookieMinBalances: {}, //everyone says this is something different... so whatever
    /*
      {
      [accountId]: number,
      [accountId]: number,
    }
    */

    //[trade] what columns to show per sport
    enabledGroups: {
      fb: {
        wdw: true,
        ah: true,
        ahou: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      fb_htft: {
        htft: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      basket: {
        ml: true,
        ah: true,
        ahou: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      tennis: {
        'tennisMatch,all': true,
        'tennisAh,all,set': true,
        'tennisAh,all,game': true,
        'tennisAhou,all,game': true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      ih: {
        'timeWin,tp,all,ml': true,
        TPReg1X2: true,
        TPRegAsianHandicap: true,
        'timeAhou,tp,reg': true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      baseball: {
        'timeWin,tp,all,ml': true,
        TPAllAsianHandicap: true,
        'timeAhou,tp,all': true,
        TPReg1X2: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      cricket: {
        'timeWin,tp,all,ml': true,
        TPAllAsianHandicap: true,
        'timeWin,tp,reg,ml': true,
        TPReg1X2: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      boxing: {
        ml: true,
        ah: true,
        ahou: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      esports: {
        'timeWin,tp,all,ml': true,
        TPAllAsianHandicap: true,
        'timeAhou,tp,all': true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      golf: {
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      mma: {
        ml: true,
        ah: true,
        ahou: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      rl: {
        'timeWin,tp,all,ml': true,
        TPRegAsianHandicap: true,
        TPAllAsianHandicap: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      ru: {
        TPAllAsianHandicap: true,
        TPReg1X2: true,
        TPRegAsianHandicap: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      af: {
        'timeWin,tp,all,ml': true,
        'timeWin,tp,reg,ml': true,
        TPAllAsianHandicap: true,
        'timeAhou,tp,all': true,
        TPReg1X2: true,
        TPRegAsianHandicap: true,
        'timeAhou,tp,reg': true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      arf: {
        TPAllAsianHandicap: true,
        TPReg1X2: true,
        TPRegAsianHandicap: true,
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
      hand: {
        'timeWin,tp,all,ml': true,
        'timeWin,tp,reg,ml': true,
        win: true,
      },
      darts: {
        'timeWin,tp,all,ml': true,
        'timeAh,tp,all': true,
        'timeAhou,tp,all': true,
        'timeCs,tp,all': true,
        win: true,
      },
      horse: {
        // Select some outrights markets so it doesnt seem empty
        win: true,
      },
    },
    equivalentBets: true, //[betslip] use equivalent bets when opening a betslip
    //[trade] the state of the side/main market toggles
    expanded: {
      main: {
        markets: {
          favs: true,
          ir: true,
          today: true,
          early: false,
          outrights: false,
        },
      },
      side: {
        markets: {
          favs: true,
          ir: true,
          today: true,
          early: true,
          outrights: false,
        },
      },
    },
    extraEdgeHandicaps: false, //[betslip] automatically show extra handicap lines on the sides of the existing handicap lines (this may be useful to brokerage when the lines they don't want are being offered)

    //[trade]
    favs: {
      competitions: {},
      events: {},
    },
    focusedMarket: 'favs', //[XENA] [trade] what market is in focus on the mobile site

    //[trade] list of events with sub-sports open (we need to also save the main sport of that even to avoid some race conditions/complications)
    hasExtrasExpanded: {
      //'2020-04-05,10024993,10024801': true
    },
    hideExchangeOnlyLines: true, //[trade] hide lines that only contain exchange prices (arrows)
    hideFavsCompetitionsHeaders: false, //[trade] hide competition header in favorite market (to save space)

    ignoreSystemMaintenance: false, //[DEV] [LOCKED] [betslip] ability to ignore system maintenance warning and place orders regardless

    maxOrder: 100000, //[betslip] maximum order in customer currency (to avoid mistakes like 100.00 -> 10000)
    minOrder: 0, //[betslip] minimum order in customer currency (customer can't change this)
    multipleAccounts: false, //[betslip] use multiple accounts from the same bookmaker when opening a betslip (the availability of this is controlled from a gargoyle switch)
    multipleHandicapLines: false, //[betslip] when opening a betslip also open adjacent handicap lines

    openAnySelection: false, //[DEV] [ADVANCED] ability to open any selection on the trade page regardlesss if there is a price (shows up as exchange; used to test new bet types)

    playSounds: false, //[trade] play sounds for red cards and goals for events you have positions in
    playSoundsOrders: false, //[betbar] play sounds for order statuses and bet updates
    pricesBookies: ['pin', 'isn', 'ibc', 'pin88'], //[trade] what prices bookies to use when connecting to the price feed

    reselectAccounts: 'always', //[betslip] {always|never|only_placement_bookies} if bookies should be added to order later even if not in original placement

    showAllScores: false, //[ADVANCED] show scores even for untrusted sports (this is important when adding new IR sports in a few decades)
    //[trade] some state related to the side-bar on the trade page (competition selector)
    side: {
      open: false,
    },
    sortEventsBy: 'comp', //[trade] {comp|time} group trade events by competition or time (favorite market has some special rules)
    sport: 'fb', //[trade] currently selected trade page sport

    toastNotifications: false, //[trade] pop up browser notifications for events you have positions on
    toastNotificationsOrders: false, //[trade] pop up browser notifications for order status changes
    tradeTint: false, //[trade] tint trade page markets background to make them more distinct

    unfaved: {}, //[trade] events that have been specifically unfaved even if a competition is faved
    useSuggested: true, //[trade] put small fire icon next to events suggeted by pithers volume query
    userTradeView: 'default', //[trade] {default|asian} the trade page layout

    valuesInPositionButton: true, //[trade] show current position value (based on score and orders) in the position button

    waitForPmms: 0, //[DEV] [ADVANCED] how long to let the betslip wait for PMMs before returning a response (this may be useful when websockets are poor or cannot connect)
    watched: {
      // These will be added on every login
      //[trade] list of watched competitions, groupped by markets, with time when they were watched and sport (the time is important because there exists an automatic expiry mechanism)
      // 'competitions': {
      //   'ir': {
      //     189: {
      //       time: 1591612634416,
      //       sport: 'fb'
      //     }
      //   }
      // }
    },
  },
};

// These will only be added on first log in
export const initialWatched = {
  //[trade] list of watched competitions, groupped by markets, with time when they were watched and sport (the time is important because there exists an automatic expiry mechanism)
  competitions: {
    ir: {
      1: { time: +new Date(), sport: 'fb' }, // England Premier League
      301: { time: +new Date(), sport: 'fb' }, // FIFA World Cup
      38: { time: +new Date(), sport: 'fb' }, // France Ligue 1
      12: { time: +new Date(), sport: 'fb' }, // Germany Bundesliga
      19: { time: +new Date(), sport: 'fb' }, // Italy Serie A
      16: { time: +new Date(), sport: 'fb' }, // Spain La Liga
      28: { time: +new Date(), sport: 'fb' }, // UEFA Champions League
      29: { time: +new Date(), sport: 'fb' }, // UEFA Europa League
      32: { time: +new Date(), sport: 'fb' }, // UEFA European Championship
    },
    today: {
      1: { time: +new Date(), sport: 'fb' }, // England Premier League
      301: { time: +new Date(), sport: 'fb' }, // FIFA World Cup
      38: { time: +new Date(), sport: 'fb' }, // France Ligue 1
      12: { time: +new Date(), sport: 'fb' }, // Germany Bundesliga
      19: { time: +new Date(), sport: 'fb' }, // Italy Serie A
      16: { time: +new Date(), sport: 'fb' }, // Spain La Liga
      28: { time: +new Date(), sport: 'fb' }, // UEFA Champions League
      29: { time: +new Date(), sport: 'fb' }, // UEFA Europa League
      32: { time: +new Date(), sport: 'fb' }, // UEFA European Championship
    },
    early: {
      1: { time: +new Date(), sport: 'fb' }, // England Premier League
      301: { time: +new Date(), sport: 'fb' }, // FIFA World Cup
      38: { time: +new Date(), sport: 'fb' }, // France Ligue 1
      12: { time: +new Date(), sport: 'fb' }, // Germany Bundesliga
      19: { time: +new Date(), sport: 'fb' }, // Italy Serie A
      16: { time: +new Date(), sport: 'fb' }, // Spain La Liga
      28: { time: +new Date(), sport: 'fb' }, // UEFA Champions League
      29: { time: +new Date(), sport: 'fb' }, // UEFA Europa League
      32: { time: +new Date(), sport: 'fb' }, // UEFA European Championship
    },
  },
};

export default defaults;

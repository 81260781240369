/** @format */

import config from '../config';
import { toCamelCase } from './camelSnake';
import DSM from './DSM';

let lognoiceStream = null;
let _hjijacked = false;
let _applicationName = '';
let _actions = {};
let _loggingEnabled = false;
let MESSAGE_BUFFER = [];
let MAX_BUFFER = config.support.tools.logBuffer;

/**
 * A simple, dumb, generic ojbect serialization method
 * @param {Object} obj - target object
 * @return {string} - stringified value
 */
const _serialise = (obj) => {
  if (typeof obj === 'string') {
    return obj;
  } else {
    let str;
    try {
      str = obj.toString();
    } catch (err) {
      //do nothing
    }

    if (str === '{}') {
      //bad representation
      str = JSON.stringify(obj);
    }

    return str;
  }
};

/**
 * Hijacks the global scope console methods (selectively based on config)
 * @param {boolean} useWs - use lognoice as well
 */
const _hijackConsole = (useWs) => {
  if (_hjijacked) {
    return;
  }

  ['error', 'warn', 'info', 'log'].forEach((method) => {
    //don't do debug because of edge
    const oldMethod = console[method];
    console[method] = (...args) => {
      if (config.support.log[method]) {
        if (config.support.tools.consoleLog) {
          oldMethod.apply(window.console, args);
        }

        if (useWs) {
          let msg = `[${_applicationName} / ${method.toUpperCase()}] - ${args
            .map((a) => _serialise(a))
            .join(', ')}`;
          if (lognoiceStream && _loggingEnabled) {
            lognoiceStream.send(msg);
          } else {
            Logger.pushBuffer(msg);
          }
        }
      }
    };
  });
};

/**
 * Small wrapper for console logging
 * @param {string} message - message that you want to log
 * @param {string} [level='log'] - level of the message (same as console since this is just a wrapper)
 */
export default class Logger {
  /**
   * Takes in a log message and a priority level. Automatically adds timestamp.
   * @static
   * @param {string} message - the message to record.
   * @param {string} [level='log'] - the priority level fo the message log|debug|warn|error
   */
  static log(message, level) {
    console[level || 'log'](message);
  }

  /**
   * Takes in a metric with a name and a value and sends it without the normal message preprocessing
   * It ignores the enabled/disabled flag
   * @static
   * @param {string} type - the message to record.
   * @param {string} name - the message to record.
   * @param {string} value - the message to record.
   * @param {string} [level='log'] - the priority level fo the message log|debug|warn|error
   */
  static logMetric(name, value) {
    let msg = `metric::${name}::${value}`;
    if (lognoiceStream) {
      lognoiceStream.send(msg);
    }
  }

  /**
   * Takes in a log message and a priority level. Automatically adds timestamp.
   * @static
   * @param {Object} action - the reducer action to log; this is done based on some config support definitions
   */
  static logAction(action) {
    let type = action.type ? toCamelCase(action.type) : 'unknown';

    if (config.support.tools.dontLog.indexOf(type) === -1) {
      //ignore certain types of actions that might be a bit ... heavy

      let stripped = '';
      if (action.data) {
        for (let logField of config.support.tools.interestingFields) {
          if (action.data[logField] !== undefined) {
            if (typeof action.data[logField] !== 'object') {
              stripped += `${logField} = ${action.data[logField]}; `;
            } else {
              stripped += `${logField} = ${JSON.stringify(action.data[logField])}; `;
            }
          }
        }
      }

      Logger.log(`[${type}] - ${stripped}`, 'log');
    }
  }

  /**
   * Initializes the logger websocket.
   * @static
   * @param {string} sessionId - the session id required to initialize connection to lognoice (need in conjuntion with useWs)
   */
  static initStream(sessionId) {
    if (config.support.tools.hijackConsole) {
      _hijackConsole(!!sessionId);
    }

    if (!lognoiceStream) {
      if (sessionId) {
        console.info('Lognoice initialized');

        if (sessionId) {
          lognoiceStream = DSM.create(
            `/lognoice?token=${sessionId}`,
            {
              noLog: true,
              ws: true,
              message: ".0",
              minReconnectDelay: config.support.tools.lognoiceMinReconnectDelay
            },
            _actions
          );
        }
      }
    }
  }

  /**
   * Sets the application name the logger should use
   * @static
   * @param {string} name - name of the application; this is prepended to everything sent to the lognoice socket
   */
  static setApplication(name) {
    _applicationName = name;
  }

  /**
   * Sets the actions that the logger has access to
   * @static
   * @param {object} actions - name of the application; this is prepended to everything sent to the lognoice socket
   */
  static setActions(actions) {
    _actions = actions;
  }

  /**
   * Sets if logs should be sent to the websocket
   * @static
   * @param {boolean} flag - true|false
   */
  static setEnabled(flag) {
    _loggingEnabled = flag;

    if (lognoiceStream) {
      let msg = `enabled::${_loggingEnabled ? 1 : 0}`;
      lognoiceStream.send(msg);

      if (flag) {
        for (let _msg of MESSAGE_BUFFER) {
          lognoiceStream.send(_msg);
        }
        MESSAGE_BUFFER = [];
      }
    }
  }

  /**
   * Stop the lognoice stream if there is one
   * @static
   */
  static killStream() {
    if (lognoiceStream) {
      lognoiceStream.stop();
      lognoiceStream = null;
      MESSAGE_BUFFER = [];
    }
  }

  /**
   * Push message to buffer while maintaing a max buffered size
   * @static
   * @param {object} message - message to add
   */

   static pushBuffer (message) {
     if (MESSAGE_BUFFER.length > MAX_BUFFER) {
       MESSAGE_BUFFER = MESSAGE_BUFFER.splice(0,1);
     }
     MESSAGE_BUFFER.push(message);
   }
}

/** @format */

exports.hiddenBookies = {
  ibc: true,
};

exports.initialUnselectedBookies = {
  bf: true,
  bdaq: true,
  mbook: true,
  smk: true,
  ibc: true,
};

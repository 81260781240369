/** @format */

const basic = require('./groups/basic').default;
const combined = require('./groups/combined').default;
const innings = require('./groups/innings').default;
const margins = require('./groups/margins').default;
const other = require('./groups/other').default;
const period = require('./groups/period').default;
const score = require('./groups/score').default;
const tennis = require('./groups/tennis').default;
const custom = require('./groups/custom').default;
const multirunners = require('./groups/multirunners').default;

//https://molly.atlassian.net/wiki/spaces/M/pages/1559166977/Bets+we+settle
//https://molly.atlassian.net/wiki/spaces/M/pages/1526824978/Bet+Types

exports.default = {
  ...basic,
  ...combined,
  ...innings,
  ...margins,
  ...other,
  ...period,
  ...score,
  ...tennis,
  ...custom,
  ...multirunners,
};

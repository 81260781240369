/** @format */

/**
 * extract hash parameters from a given url or get it from window
 * @param {string} [url] - custom url to use
 * @return {Object} - a map of parameters
 */
export function hashParams(url) {
  let base = url || (window ? window.location.hash.split('#')[1] : '');
  let params = {};

  if (base) {
    let parts = base.split('&');
    for (let i in parts) {
      let atom = parts[i].split('=');
      if (!(atom[0] in params)) {
        params[atom[0]] = [];
      }
      params[atom[0]].push(decodeURI(atom[1]));
    }
  }

  for (let k in params) {
    if (params[k].length === 1) {
      params[k] = params[k][0];
    }
  }

  return params;
}

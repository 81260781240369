/** @format */

import DSM from '../../lib/DSM';
import { toCamelCase } from '../../lib/camelSnake';
import config from '../../config';
import { fromJS } from 'immutable';

let initialState = fromJS({
  //map of order logs
  orderLogs: {},
});

const functions = {
  //open an order log
  //push order log to the top of the z-index
  orderPopLog: (state, action) => {
    let orderId = action.data.orderId;
    return state.setIn(['orderLogs', orderId, 'zIndex'], +new Date());
  },

  //close the order log
  orderCloseLog: (state, action) => {
    let orderId = action.data.orderId;
    //stop the stream
    DSM.stop(`orderLog/${orderId}`);
    return state.removeIn(['orderLogs', orderId]);
  },

  //close all order logs
  closeAllOrderLogs: (state, _action) => {
    DSM.stopAllStartingWith('orderLog/');
    return state.remove('orderLogs');
  },

  ////// LOGOUT

  //reset some state
  logout: (state, _action) => {
    DSM.stopAllStartingWith('orderLog/');
    return state.remove('orderLogs');
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}

/** @format */

exports.default = {
  name: { id: 'sports.cricket', defaultMessage: 'Cricket' },
  shortName: { id: 'sports.cricket.short', defaultMessage: 'Cricket' },
  featuredCompetitions: [
    '60619',
    '60618',
    '60644',
    '60642',
    '60707',
    '60672',
    '60625',
    '62687',
    '10002409',
    '61054',
    '60649',
  ],
  featuredCompetitionsName: [],
  enabled: true,
  correlated: {},
  maxTimeout: 600,
  correlatedOrder: ['cricket'],
  trustScores: false,
  matchDataOpener: 'for,tp,all,ml,a/null',
  offerGroupsOrder: [
    'timeWin,tp,all,ml',
    //'timeWin,tp,all,wdw',
    'timeAh,tp,all',
    'timeAhou,tp,all',
    'timeWin,tp,reg,ml',
    'timeWin,tp,reg,wdw',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  sympBetTypes: {
    ml: 'timeWin,tp,all,ml',
    ah: 'timeAh,tp,all',
    ahou: 'timeAhou,tp,all',
    wdw: 'timeWin,tp,reg,wdw',
    'tennisMatch,all': 'timeWin,tp,all,ml',
    'tennisAh,all,set': 'timeAh,tp,all',
    'tennisAhou,all,game': 'timeAhou,tp,all',
  },
};
